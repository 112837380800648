import {Prism as Highlighter} from 'react-syntax-highlighter'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import {vscDarkPlus} from 'react-syntax-highlighter/dist/cjs/styles/prism'
import './SyntaxHighlighter.scss'
import {toast} from 'react-toastify'
import {palette} from '../../../styled/colors'

const SyntaxHighlighter = ({children, language}: {children?: any; language: string}) => {
	return (
		<div className="syntaxHighlighter--container">
			<div className="syntaxHighlighter--lang">{'javascript'}</div>
			<Highlighter
				language={language}
				style={vscDarkPlus}
				className="syntaxHighlighter"
				wrapLongLines={true}
				customStyle={{
					paddingTop: '26px'
				}}>
				{children ?? ''}
			</Highlighter>
			<CopyToClipboard
				text={children}
				onCopy={() => {
					toast.success('Copied to clipboard')
				}}>
				<button className="syntaxHighlighter--copy" style={{background: palette.purple_1}}>
					{'Copy'}
				</button>
			</CopyToClipboard>
		</div>
	)
}

export default SyntaxHighlighter
