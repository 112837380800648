import React from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Text} from '../../styled/shared'
import {useSelector} from 'react-redux'
import DateTimePicker from 'react-datetime-picker'
import {BiCalendarAlt} from 'react-icons/bi'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'

const InputDate = ({label, value, required, type, format, placeholder, handleChange, disabled, error, errorMsg, bgTransparent, maxDate, minDate}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	return (
		<>
			<Label type="small" isDarkTheme={_isDarkTheme} margin="0rem 0rem 0.19rem 0rem">
				{label} {required ? <Mandatory>*</Mandatory> : ''}
			</Label>
			<TextInput
				calendarIcon={<CalendarIconCustom />}
				value={value}
				type={'date'}
				bgTransparent={bgTransparent}
				disabled={disabled}
				disableClock={true}
				format={format ? format :'dd-MM-yyyy'}
				placeholder={placeholder}
				error={error}
				onChange={(event: any) => handleChange(event)}
				clearIcon={null}
				maxDate={maxDate}
				minDate={minDate}
			/>
			{required && error && !disabled && (
				<Text fontSize={0.7} type="small" color="danger">
					{error}
				</Text>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

const TextInput = styled(DateTimePicker)<any>`
	font-family: 'Roboto';
	width: 100%;
	line-height: 2rem;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	padding: 0.5rem 0.8rem;
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({disabled, isDarkTheme}) => (disabled ? (isDarkTheme ? palette.light_gray : palette.gray_400) : isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	width: 100%;
	background: ${({disabled, bgTransparent, isDarkTheme}) => (bgTransparent ? 'transparent' : disabled ? `` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.purple_1}`)};
	}
	&::placeholder {
		color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
		opacity: 0.5;
	}

	&:-ms-input-placeholder {
		color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
	}

	&::-ms-input-placeholder {
	}
`

const ErrorMsg = styled(Text)`
	text-transform: capitalize;
	font-weight: 400;
`
const CalendarIconCustom = styled(BiCalendarAlt)`
	color: ${palette.gray};
	font-size: 1.5rem;
`

export default InputDate
