import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text, Spacer} from '../../styled/shared'
import {GoDotFill} from 'react-icons/go'
import {IoMdClose} from 'react-icons/io'
import {Popup} from '../modals/TrelloTaskModal'
import {palette} from '../../styled/colors'
import moment from 'moment'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {CgWebsite} from 'react-icons/cg'
import {useDispatch} from 'react-redux'
import {updateBoardList} from '../../actions/authActions'

const AttachmentList = ({quote, callBack, onEdit}: any) => {
	const _deletePopRef: any = useRef(null)
	const _editPopRef: any = useRef(null)
	const [linkName, setLinkName] = useState('')
	const [openDeletePop, setOpenDeletePop] = useState(false)
	const [openEditPop, setOpenEditPop] = useState(false)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_deletePopRef?.current && !_deletePopRef?.current?.contains(event.target)) {
				setOpenDeletePop(false)
			}
			if (_editPopRef?.current && !_editPopRef?.current?.contains(event.target)) {
				setOpenEditPop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const updateAttachment = async (filename = '', id = '', makeCover?: any) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_attachment/${quote?.id ? quote?.id : id}`, {cardId: quote?.cardId, filename: linkName ? linkName : filename, makeCover})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success(response?.data?.message, {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 3000
						})
						if (makeCover == false || makeCover == true) {
							dispatch(updateBoardList(true))
						}

						callBack && callBack()

						setOpenEditPop(false)
					} else {
						toast.error(response?.data?.message, {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 3000
						})
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const deleteAttachment = async (id = '') => {
		try {
			setLoading(true)
			await api
				.delete(`/trello/delete_attachment?id=${id}`)
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success(response?.data?.message, {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 3000
						})
						callBack && callBack()
						setOpenDeletePop(false)
					} else {
						toast.error(response?.data?.message, {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 3000
						})
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const handleDownload = async (imageUrl: string, fileName: string) => {
		try {
			const response = await fetch(imageUrl)
			const blob = await response.blob()
			const url = window.URL.createObjectURL(blob)
			const a = document.createElement('a')
			a.href = url
			a.download = fileName
			document.body.appendChild(a)
			a.click()
			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Error downloading image:', error)
		}
	}

	return (
		<>
			<Flexed direction="row" gap={0.5}>
				<ImageContent src={process.env.REACT_APP_IMAGE_URL + quote?.url} alt="img" />
				<div>
					<Text fontWeight={600}>{quote?.filename}</Text>
					<Text>Added {moment(quote?.createdAt).format('DD MMM [at] hh:mm')}</Text>
					<ActionFlex direction="row" gap={0.3} flexWrap="wrap" align="center">
						<ActionsText pointer>Comment</ActionsText>
						<GoDotFill fontSize={7} />
						<ActionsText pointer onClick={() => handleDownload(process.env.REACT_APP_IMAGE_URL + quote?.url, quote?.filename)}>
							Download
						</ActionsText>
						<GoDotFill fontSize={7} />
						<ActionsText ref={_deletePopRef} onClick={() => setOpenDeletePop(!openDeletePop)} pointer>
							Delete
						</ActionsText>
						<GoDotFill fontSize={7} />
						<ActionsText
							ref={_editPopRef}
							onClick={() => {
								setLinkName(quote?.filename)
								setOpenEditPop(!openEditPop)
							}}
							pointer>
							Edit
						</ActionsText>
						{openDeletePop && (
							<Popup onClick={handleModalClick}>
								<Flexed direction="row" align="center" justify="space-between" gap={0.2}>
									<div></div>
									<Text fontWeight={600}>Delete attachment?</Text>
									<IoMdClose cursor="pointer" onClick={() => setOpenDeletePop(false)} />
								</Flexed>
								<Spacer height={0.5} />
								<Text>Deleting an attachment is permanent.</Text>
								<Text>There is no undo.</Text>
								<Spacer height={0.5} />
								<Delete
									onClick={() => {
										deleteAttachment(quote?.id)
									}}>
									Delete
								</Delete>
							</Popup>
						)}
						{openEditPop && (
							<Popup onClick={handleModalClick}>
								<Flexed direction="row" align="center" justify="space-between" gap={0.2}>
									<div></div>
									<Text fontWeight={600}>Edit attachment</Text>
									<IoMdClose cursor="pointer" onClick={() => setOpenEditPop(false)} />
								</Flexed>
								<Spacer height={0.5} />
								<Text fontWeight={500} margin="0rem 0rem 0.1rem 0rem">
									Link name
								</Text>
								<Input
									placeholder="Enter list title"
									value={linkName}
									onChange={(e: any) => {
										setLinkName(e?.target?.value)
									}}
								/>
								<Spacer height={0.5} />
								<Button onClick={() => updateAttachment('', quote?.id)}>Update</Button>
							</Popup>
						)}
					</ActionFlex>
					<Text fontWeight={500} margin={'0.5rem 0 0 0'} style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => updateAttachment(quote?.filename, quote?.id, quote?.makeCover ? false : true)}>
						{quote?.makeCover ? 'Remove cover' : 'Make cover'}
					</Text>
				</div>
			</Flexed>
		</>
	)
}

const ImageContent = styled.img`
	width: 112px;
	height: 80px;
	object-fit: cover;
	border-radius: 3px;
`
const ActionFlex = styled(Flexed)`
	position: relative;
`

const ActionsText = styled(Text)`
	text-decoration: underline;
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({cancel}) => (cancel ? palette.black : palette.white)};
	background: ${({cancel}) => (cancel ? palette.white : palette.purple_1)};
	font-weight: 600;
	cursor: pointer;
	width: 5rem;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		color: ${({cancel}) => (cancel ? palette.black : palette.white)};
		background: ${({cancel}) => (cancel ? palette.silver_14 : palette.purple_2)};
	}
`

const Delete = styled(Button)`
	width: 100%;
	background: ${palette.red};
	&:hover {
		background: ${palette.red_hover};
	}
`

const Input = styled.input<any>`
	width: 100%;
	padding: 6px 12px;
	border: none;
	border-radius: 3px;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
	box-sizing: border-box;
	outline: none;
	font-size: 14px;
	&:focus {
		box-shadow: inset 0 0 0 2px var(--ds-border-input, ${palette.purple_1});
	}
`

export default AttachmentList
