import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {BiCheck, BiDotsHorizontal} from 'react-icons/bi'
import {colorsList} from '../../utils'
import {media} from 'styled-bootstrap-grid'

interface ICategoriesModalProps {
	onCloseModal: any
	onCreateBoard?: any
	setSelectedBackground?: any
	selectedBackground?: any
	selectedBackgroundColor?: any
	setSelectedBackgroundColor?: any
}

const BoardBackgroundsModel = ({onCloseModal, setSelectedBackground, selectedBackground, selectedBackgroundColor, setSelectedBackgroundColor}: ICategoriesModalProps) => {
	const [backgroundImages, setBackgroudImages] = useState<any[]>([])

	useEffect(() => {
		let arr = []
		for (let i = 1; i <= 32; i++) {
			arr.push(`/images/boardImages/image${i}.avif`)
		}
		setBackgroudImages(arr)
	}, [])

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black">
							Create a New Board
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Flexed direction="column" flexWrap="wrap" align={'flex-start'} gap={0.3}>
							<Flexed direction="column" flexWrap="wrap" align={'center'} gap={1} justify={'center'}>
								<Flexed direction="row" flexWrap="wrap" align={'center'} gap={1} justify={'center'}>
									{backgroundImages?.map((e: any, i: any) => (
										<React.Fragment key={i}>
											{' '}
											<SmallImageContainer
												imageUrl={e}
												onClick={() => {
													setSelectedBackground(e)
													setSelectedBackgroundColor('')
												}}>
												<SelectedImage display={e == selectedBackground}>
													<BiCheck color="white" />
												</SelectedImage>
											</SmallImageContainer>{' '}
										</React.Fragment>
									))}
								</Flexed>
								<Flexed direction="row" flexWrap="wrap" align={'center'} gap={1} justify={'center'}>
									{colorsList?.map((color: any, i: any) => (
										<React.Fragment key={i}>
											{' '}
											<ColorButton
												color={color}
												onClick={() => {
													setSelectedBackgroundColor(color)
													setSelectedBackground('')
												}}>
												<SelectedImage display={color == selectedBackgroundColor}>
													<BiCheck color="white" />
												</SelectedImage>
											</ColorButton>
										</React.Fragment>
									))}
								</Flexed>
							</Flexed>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;
	${media.sm`
	width: 100%;
	`}
	${media.md`
	width: 100%;
	`}
	${media.lg`
	width: 30rem;
	`}
`

const Footer = styled(Flexed)`
	padding: 1rem 1.5rem;
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`
const Container = styled.div`
	/* max-height: 372px; */
`

const SmallImageContainer = styled.div<any>`
	background-color: ${(props) => props.backgroundColor};
	background-image: url(${(props) => props.imageUrl});
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.3rem;
	height: 40px;
	background-position: center center;
	border-radius: 3px;
	cursor: pointer;
`

const ColorButton = styled.button<any>`
	background-color: ${(props) => props.color};
	border: none;
	width: 3rem;
	height: 3rem;
	margin-right: 5px;
	cursor: pointer;
	width: 64px;
	height: 40px;
	background-position: center center;
	border-radius: 3px;
`

const SelectedImage = styled.span<any>`
	position: relative;
	z-index: 1;
	flex-basis: 100%;
	font-size: 1.2rem;
	font-weight: 700;
	height: 24px;
	line-height: 24px;
	display: ${({display}) => (display ? 'flex' : 'none')};
	justify-content: center;
`

export default BoardBackgroundsModel
