import {useEffect, useState} from 'react'
import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment, {Moment} from 'moment'
import AttendanceDetailsModal from './modals/AttendanceDetailsModal'
import {Flexed, Loading, Text} from '../styled/shared'
import InputField from './common/InputField'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {RxCalendar} from 'react-icons/rx'
import {BsArrowRightCircle, BsArrowLeftCircle} from 'react-icons/bs'
import {useNavigate} from 'react-router-dom'
import {ProfileImg} from '../pages/Team'

interface Item {
	id: number
	group: number
	title: string
	start_time: Moment
	end_time: Moment
	itemProps: any
	tip: any
}

interface Group {
	id: number
	title: string
}

const EmployeesAttendance = ({allAttendanceList, allHolidayList, allEmployeeList, leaveRequests, setSearchQuery, searchQuery}: any) => {
	const _navigate = useNavigate()
	const [openAttendanceDetailsModal, setOpenAttendanceDetailsModal] = useState(false)
	const [date, setDate] = useState(moment().format('DD-MM-YYYY'))
	const [isLoading, setIsLoading] = useState(false)
	const [refreshCalc, setRefreshCalc] = useState(false)
	const [groups, setGroups] = useState<Group[]>([])
	const [items, setItems] = useState<Item[]>([])
	const [itemDetails, setItemDetails] = useState<any>('')

	useEffect(() => {
		if (allEmployeeList?.length > 0) {
			const _emp: any = []
			const __emp: any = []
			const currentMonth = moment(date, 'DD-MM-YYYY').startOf('month')

			for (let i = 0; i < allEmployeeList.length; i++) {
				_emp.push({
					id: allEmployeeList[i].id,
					title: (
						<Flexed direction="row" align="center" justify="flex-start" gap={0.2}>
							<div>
								<ProfileImg style={{height: '1.5rem', width: '1.5rem'}}>
									<img
										src={
											allEmployeeList[i]?.image
												? process.env.REACT_APP_IMAGE_URL + allEmployeeList[i]?.image
												: allEmployeeList[i]?.gender == 'male'
													? '/images/default_user_profile_img.png'
													: allEmployeeList[i]?.gender == 'female'
														? '/images/default_girl_profile_img.png'
														: '/images/default_user_profile_img.png'
										}
										alt="i"
									/>
								</ProfileImg>
							</div>
							<div style={{fontSize: '0.8rem'}}>
								{allEmployeeList[i].firstName} {allEmployeeList[i].lastName}
							</div>
						</Flexed>
					)
				})

				for (let day = 0; day < currentMonth.daysInMonth(); day++) {
					const currentDate = currentMonth.clone().add(day, 'day')
					const formattedCurrentDate = currentDate.format('YYYY-MM-DD')
					// Check if the joining date is in the past
					if (moment(allEmployeeList[i].joiningDate).isBefore(moment(formattedCurrentDate), 'day')) {
						const isHoliday = allHolidayList.find((holiday: any) => {
							const holidayFrom = moment(holiday.holidayFrom, 'DD-MM-YYYY')
							const holidayTo = moment(holiday.holidayTo, 'DD-MM-YYYY')
							return currentDate.isBetween(holidayFrom, holidayTo, 'day', '[]')
						})
						const leaves = leaveRequests.find((holiday: any) => {
							const holidayFrom = moment(holiday.from, 'YYYY-MM-DD')
							const holidayTo = moment(holiday.to, 'YYYY-MM-DD')
							return currentDate.isBetween(holidayFrom, holidayTo, 'day', '[]') && holiday?.employeeId == allEmployeeList[i].id
						})

						const attendance = allAttendanceList.find((att: any) => att.employeeId == allEmployeeList[i].id && moment(att.attendanceDate, 'DD-MM-YYYY').isSame(moment(formattedCurrentDate), 'day'))

						if (attendance) {
							// Attendance record found, add it to the display items
							__emp.push({
								id: `${formattedCurrentDate}-${allEmployeeList[i].id}`,
								group: allEmployeeList[i].id,
								title: 'P',
								tip: 'Attendance Recorded',
								start_time: moment(formattedCurrentDate).startOf('day'),
								end_time: moment(formattedCurrentDate).endOf('day'),
								itemProps: {
									'data-custom-attribute': 'Present',
									'aria-hidden': true,
									onDoubleClick: () => {
										// setItemDetails({
										// 	...allEmployeeList[i],
										// 	start_time: moment(formattedCurrentDate).startOf('day'),
										// 	end_time: moment(formattedCurrentDate).endOf('day'),
										// 	attendance,
										// 	leaves,
										// 	isPresent: true
										// })
										setOpenAttendanceDetailsModal(true)
									},
									className: 'present',
									style: {
										background: '#1dad18',
										color: '#333',
										border: 'yellow',
										fontWeight: 600,
										fontSize: '10px'
									}
								}
							})
						} else if (leaves) {
							// Mark as leave for holidays
							__emp.push({
								id: `${formattedCurrentDate}-${allEmployeeList[i].id}`,
								group: allEmployeeList[i].id,
								title: 'L',
								tip: 'L',
								start_time: moment(formattedCurrentDate).startOf('day'),
								end_time: moment(formattedCurrentDate).endOf('day'),
								itemProps: {
									'data-custom-attribute': 'Leave',
									'aria-hidden': true,
									onDoubleClick: () => {
										setItemDetails({
											...allEmployeeList[i],
											start_time: moment(formattedCurrentDate).startOf('day'),
											end_time: moment(formattedCurrentDate).endOf('day'),
											attendance,
											leaves,
											isLeave: true
										})
										setOpenAttendanceDetailsModal(true)
									},
									className: 'leave',
									style: {
										background: '#18a38e',
										color: '#fff',
										border: 'yellow',
										fontWeight: 600,
										fontSize: '10px'
									}
								}
							})
						} else if (isHoliday) {
							// Mark as public holidays
							__emp.push({
								id: `${formattedCurrentDate}-${allEmployeeList[i].id}`,
								group: allEmployeeList[i].id,
								title: 'H',
								tip: 'H',
								start_time: moment(formattedCurrentDate).startOf('day'),
								end_time: moment(formattedCurrentDate).endOf('day'),
								itemProps: {
									'data-custom-attribute': 'Holiday',
									'aria-hidden': true,
									onDoubleClick: () => {
										setItemDetails({
											...allEmployeeList[i],
											start_time: moment(formattedCurrentDate).startOf('day'),
											end_time: moment(formattedCurrentDate).endOf('day'),
											attendance,
											leaves,
											isHoliday: true
										})
										setOpenAttendanceDetailsModal(true)
									},
									className: 'leave',
									style: {
										background: '#1881a3',
										color: '#fff',
										border: 'yellow',
										fontWeight: 600,
										fontSize: '10px'
									}
								}
							})
						} else if (
							moment(formattedCurrentDate).weekday() === 6 || // Saturday
							moment(formattedCurrentDate).weekday() === 0 // Sunday
						) {
							// Weekend
							__emp.push({
								id: `${formattedCurrentDate}-${allEmployeeList[i].id}`,
								group: allEmployeeList[i].id,
								title: 'W',
								tip: 'W',
								start_time: moment(formattedCurrentDate).startOf('day'),
								end_time: moment(formattedCurrentDate).endOf('day'),
								itemProps: {
									'data-custom-attribute': 'W',
									'aria-hidden': true,
									onDoubleClick: () => {
										setItemDetails({
											...allEmployeeList[i],
											start_time: moment(formattedCurrentDate).startOf('day'),
											end_time: moment(formattedCurrentDate).endOf('day'),
											attendance,
											leaves,
											isWeekend: true
										})
										setOpenAttendanceDetailsModal(true)
									},
									className: 'weekend',
									style: {
										background: 'yellow',
										color: '#333',
										border: 'yellow',
										fontWeight: 600,
										fontSize: '10px'
									}
								}
							})
						} else if (moment().isAfter(moment(formattedCurrentDate), 'day')) {
							// No attendance record found, mark as absent
							__emp.push({
								id: `${formattedCurrentDate}-${allEmployeeList[i].id}`,
								group: allEmployeeList[i].id,
								title: 'A',
								tip: 'No Attendance Recorded',
								start_time: moment(formattedCurrentDate).startOf('day'),
								end_time: moment(formattedCurrentDate).endOf('day'),
								itemProps: {
									'data-custom-attribute': 'Absent',
									'aria-hidden': true,
									onDoubleClick: () => {
										setItemDetails({
											...allEmployeeList[i],
											start_time: moment(formattedCurrentDate).startOf('day'),
											end_time: moment(formattedCurrentDate).endOf('day'),
											attendance,
											leaves,
											isAbsent: true
										})
										setOpenAttendanceDetailsModal(true)
									},
									className: 'absent',
									style: {
										background: '#E00000',
										color: '#fff',
										border: 'yellow',
										fontWeight: 600,
										fontSize: '10px'
									}
								}
							})
						}
					}
				}
			}

			setItems(__emp)
			setGroups(_emp)
		} else if (allEmployeeList?.length == 0) {
			setItems([])
			setGroups([])
		}
	}, [allEmployeeList, allAttendanceList, allHolidayList, date, leaveRequests])

	useEffect(() => {
		setIsLoading(true)
		const intervalId = setInterval(() => {
			setIsLoading(false)
		}, 1000)
		return () => clearInterval(intervalId)
	}, [date])

	useEffect(() => {
		setRefreshCalc(true)
		const intervalId1 = setInterval(() => {
			setRefreshCalc(false)
		}, 10)
		return () => clearInterval(intervalId1)
	}, [date])

	return (
		<>
			<Flexed margin="0rem 0rem 0.5rem 0rem" direction="row" justify="space-between" align="end">
				<Flexed direction="row" justify="space-between" gap={1}>
					<InputWrapper>
						<Text type="small" fontWeight={500} margin="0rem 0rem 0.19rem 0rem">
							Search
						</Text>
						<InputField valeu={searchQuery} handleChange={(e: any) => setSearchQuery(e)} />
					</InputWrapper>
					<div>
						<Text type="small" fontWeight={500} margin="0rem 0rem 0.19rem 0rem">
							Filter by month
						</Text>
						<Cover direction="row" align="center">
							<ArrowLeft
								onClick={() => {
									setDate(moment(date, 'DD-MM-YYYY').subtract(1, 'month').format('DD-MM-YYYY'))
								}}>
								<BsArrowLeftCircle />
							</ArrowLeft>
							<Date>{moment(date, 'DD-MM-YYYY').format('MMM YYYY')}</Date>
							<ArrowRight
								onClick={() => {
									setDate(moment(date, 'DD-MM-YYYY').add(1, 'month').format('DD-MM-YYYY'))
								}}>
								<BsArrowRightCircle />
							</ArrowRight>
							<CalendarIcon>
								<RxCalendar color="#fff" />
								<InvisibleCal type="date" value={date} onChange={(e: any) => setDate(e.target.value)} />
							</CalendarIcon>
						</Cover>
					</div>
				</Flexed>
				<Flexed direction="row" justify="space-between" align={'center'} gap={1}>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<ColorCircle />
						&nbsp;
						<p style={{fontSize: '0.8rem'}}> P as Present</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<ColorCircle color={'#18a38e'} />
						&nbsp;
						<p style={{fontSize: '0.8rem'}}>L as Leave</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<ColorCircle color={'yellow'} />
						&nbsp;
						<p style={{fontSize: '0.8rem'}}>W as Weekend</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<ColorCircle color={'red'} />
						&nbsp;
						<p style={{fontSize: '0.8rem'}}>A as Absent</p>
					</div>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<ColorCircle color={'#1881a3'} />
						&nbsp;
						<p style={{fontSize: '0.8rem'}}>H as Public Holiday </p>
					</div>

					<Button
						onClick={() => {
							_navigate('/time/add-attendance')
						}}>
						Add Attendance
					</Button>
					<Button
						onClick={() => {
							_navigate('/time/add-holiday')
						}}>
						Add Holiday
					</Button>
				</Flexed>
			</Flexed>

			{refreshCalc ||
				(date && groups?.length ? (
					<Timeline<Item, Group>
						groups={groups}
						items={items}
						className="timeline-calendar"
						defaultTimeStart={moment(date, 'DD-MM-YYYY').startOf('month')}
						defaultTimeEnd={moment(date, 'DD-MM-YYYY').endOf('month')}
						// visibleTimeStart={moment(date, 'DD-MM-YYYY').startOf('month')}
						// visibleTimeEnd={moment(date, 'DD-MM-YYYY').endOf('month')}
					/>
				) : (
					''
				))}
			{openAttendanceDetailsModal && (
				<AttendanceDetailsModal
					itemDetails={itemDetails}
					onCloseModal={() => {
						setOpenAttendanceDetailsModal(false)
					}}
				/>
			)}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const InputWrapper = styled.div`
	& input {
		line-height: 1.4rem;
		height: 38px;
	}
`

const InvisibleCal = styled.input`
	width: 25px;
	opacity: 0;
	position: absolute;
	height: 25px;
	transform: scale(1.8);
`

const Cover = styled(Flexed)``

const CalendarIcon = styled.div`
	width: 38px;
	height: 38px;
	background: ${palette.purple_1};
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	font-size: 20px;
	cursor: pointer;
`

const ArrowLeft = styled(CalendarIcon)`
	background: ${palette.white};
	border: 1px solid #b2c2cd;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	font-size: 16px;
	&:hover {
		color: ${palette.purple_1};
	}
`

const Date = styled.div`
	background: ${palette.white};
	border-top: 1px solid #b2c2cd;
	border-bottom: 1px solid #b2c2cd;
	width: 80px;
	height: 38px;
	font-size: 14px;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${palette.black_5};
`

const ArrowRight = styled(CalendarIcon)`
	background: ${palette.white};
	border-left: 1px solid #b2c2cd;
	border-top: 1px solid #b2c2cd;
	border-bottom: 1px solid #b2c2cd;
	border-radius: 0px !important;
	font-size: 16px;
	&:hover {
		color: ${palette.purple_1};
	}
`

const Button = styled.div`
	height: 38px;
	line-height: 23px;
	cursor: pointer;
	transition: all 0.5s ease;
	background-color: initial;
	color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	border-radius: 3px !important;
	min-width: 85px;
	cursor: pointer;
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	&:hover {
		background-color: ${palette.purple_1};
		color: ${palette.white};
		border-radius: 3px !important;
	}
`

const ColorCircle = styled.div<any>`
	width: 1rem;
	height: 1rem;
	background-color: ${({color}) => (color ? color : '#1dad18')};
	border-radius: 50%;
`
export default EmployeesAttendance
