import * as types from './types'

export const switchTheme = (payload: any) => {
	return {
		type: types.SWITCH_THEME,
		value: payload
	}
}

export const loginUserDetailsAction = (payload: any) => {
	return {
		type: types.LOGIN_USER_DETAILS,
		value: payload
	}
}

export const loginAdminDetailsAction = (payload: any) => {
	return {
		type: types.LOGIN_ADMIN_DETAILS,
		value: payload
	}
}

export const checkOutDetailAction = (payload: any) => {
	return {
		type: types.CHECK_OUT_DETAIL,
		value: payload
	}
}

export const handleHomeBanerSearch = (payload: any) => {
	return {
		type: types.SEARCH,
		value: payload
	}
}

export const updateBoardList = (payload: any) => {
	return {
		type: types.UPDATE_LIST,
		value: payload
	}
}

export const updateBoardListFav = (payload: any) => {
	return {
		type: types.UPDATE_BOARD_LIST,
		value: payload
	}
}

export const updateBoard = (payload: any) => {
	return {
		type: types.UPDATE_BOARD,
		value: payload
	}
}

export const filterBoardCards = (payload: any) => {
	return {
		type: types.FILTER_BOARD_CARDS,
		value: payload
	}
}

export const updatedList = (payload: any) => {
	return {
		type: types.UPDATED_LIST,
		value: payload
	}
}
