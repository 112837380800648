import {useState, useEffect, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {MDBDataTableV5} from 'mdbreact'
import {GiSunglasses} from 'react-icons/gi'
import {RiDeleteBinLine} from 'react-icons/ri'
import {FiEdit2} from 'react-icons/fi'
import {Flexed, Spacer, Text} from '../styled/shared'
import {Link, NavLink, createSearchParams, useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../styled/colors'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import DeleteModal from '../components/modals/DeleteModal'
import InterviewFilter from '../components/common/InterviewFilter'
import Button from '../components/common/Button'
import moment from 'moment'
import {Loading} from '../styled/shared'
import {Back} from '../components/employee/EmployeeDetails'

const Interview = () => {
	const {pathname} = useLocation()
	const _navigate = useNavigate()
	const [modalStatus, setModalStatus] = useState('interview')
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [isLoading, setIsLoading] = useState<any>(false)
	const [interviewId, setInterviewId] = useState('')
	const [sidebar, setSidebar] = useState(false)

	const [users, setUsers] = useState({
		columns: [
			{label: 'Interview Name', field: 'name', width: 200},
			{label: 'From', field: 'from', width: 50},
			{label: 'To', field: 'to', width: 50},
			{label: 'Candidate Name', field: 'candidateName', width: 200},
			{label: 'Posting Title', field: 'postingTitle', width: 50},
			{label: 'Interview Status', field: 'interviewStatus', width: 50},
			{label: '', field: 'view', sort: String('disabled'), width: 50},
			{label: '', field: 'delete', sort: String('disabled'), width: 50},
			{label: '', field: 'update', sort: String('disabled'), width: 50}
		],
		rows: []
	})
	useEffect(() => {
		allInterviews()
	}, [])

	const allInterviews = async () => {
		setIsLoading(true)
		await api
			.get('/interview/all_interviews')
			.then((response) => {
				if (response?.data?.success == true) {
					setUsers((preValue) => {
						return {
							columns: [...preValue.columns],
							rows: []
						}
					})
					if (response?.data?.data?.data?.length > 0) {
						response?.data?.data?.data?.map((data: any, index: any) => {
							createUserTable(data)
						})
					}
					setIsLoading(false)
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const deleteInterview = async (id: any) => {
		setIsLoading(true)
		await api
			.delete(`/interview/remove_interview/${id}`)
			.then((response) => {
				if (response?.data?.success == true) {
					toast.success('Interview deleted successfully')
					allInterviews()
					setOpenDeleteModal(false)
					setIsLoading(false)
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const createUserTable = (data: any) => {
		let _obj = {
			name: data?.interviewLevel,
			from: moment(data?.from).format('DD-MM-YYYY'),
			to: moment(data?.to).format('DD-MM-YYYY'),
			candidateName: data?.candidateName,
			postingTitle: data?.jobTitle,
			interviewStatus: data?.status,

			view: (
				<>
					<ViewIcon
						onClick={() => {
							setSidebar(true)
							setInterviewId(data?.id)
						}}
					/>
				</>
			),

			update: (
				<EditIcon
					onClick={() =>
						_navigate({
							pathname: '/edit-interview',
							search: createSearchParams({
								id: data?.id,
								title: data?.jobTitle,
								candidate: data?.candidateName
							}).toString()
						})
					}
				/>
			),
			delete: <DeleteIcon onClick={() => handelDeleteModal(data?.id)} />
		}
		setUsers((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}
	const handelDeleteModal = (val: any) => {
		setInterviewId(val)
		setOpenDeleteModal(true)
	}

	return (
		<>
			<Wrapper direction="row" align="center">
				<Content direction="row" gap={0.5} align="center" justify="space-between" pointer="pointer" flexWrap="wrap">
					<div>
						<Text type="normal" fontWeight={500} color="black">
							Recruitment
						</Text>
					</div>
					<Flexed direction="row" gap={1}>
						<DashboardMenu to="/recruitment" active={pathname === '/recruitment'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/recruitment'}>
								Job Openings
							</StyledText>
						</DashboardMenu>
						<DashboardMenu to="/candidate" active={pathname === '/candidate'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/candidate'}>
								Candidates
							</StyledText>
						</DashboardMenu>
						<DashboardMenu to="/interview" active={pathname === '/interview'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/interview'}>
								Interviews
							</StyledText>
						</DashboardMenu>
					</Flexed>
					<ButtonWrapper onClick={() => _navigate('/candidate')}>
						<svg _ngcontent-kxy-c151="" width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00acac">
							<path
								_ngcontent-kxy-c151=""
								d="M116 121v260c0 13.785 11.215 25 25 25s25-11.215 25-25V121c0-13.785-11.215-25-25-25S116 107.215 116 121zM211.98 243.107c-5.985 3.555-5.996 12.224 0 15.785l219.648 130.416c7.391 4.39 16.269-2.86 13.317-11.014l-42.561-117.712c-1.455-4.026-1.564-8.549-.078-12.823 45.595-131.625 43.871-124.776 43.871-127.686 0 0 0 0 0-.001 0-6.511-7.428-11.61-13.906-7.763L211.98 243.107zM346 451c0 8.272 6.728 15 15 15 8.271 0 15-6.728 15-15 0-8.271-6.729-15-15-15C352.728 436 346 442.729 346 451zM66 396c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 5.515 4.486 10 10 10S66 401.515 66 396zM226 71c0 13.785 11.215 25 25 25 13.785 0 25-11.215 25-25s-11.215-25-25-25C237.215 46 226 57.215 226 71zM250.998 371.003A10 10 0 10250.998 391.003 10 10 0 10250.998 371.003zM350.998 80.997A10 10 0 10350.998 100.997 10 10 0 10350.998 80.997zM51.003 151.003A10 10 0 1051.003 171.003 10 10 0 1051.003 151.003zM460.998 261.003A10 10 0 10460.998 281.003 10 10 0 10460.998 261.003z"></path>
						</svg>
						Back
					</ButtonWrapper>
				</Content>
			</Wrapper>
			<Section>
				<Container fluid>
					<Row>
						<Col>
							<TableWraper>
								<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={users} fullPagination searchTop searchBottom={false} />
							</TableWraper>
						</Col>
					</Row>
					<DeleteModal
						modalStatus={modalStatus}
						open={openDeleteModal}
						onCloseModal={() => {
							setOpenDeleteModal(false)
						}}
						deletePost={() => deleteInterview(interviewId)}
					/>
				</Container>
			</Section>
			{sidebar && (
				<InterviewFilter
					data={interviewId}
					show={sidebar}
					onClick={() => {
						setSidebar(true)
					}}
					onClose={() => {
						setSidebar(false)
					}}
				/>
			)}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	min-height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`

const Content = styled(Flexed)`
	width: 100%;
`
const DashboardMenu = styled(NavLink)<any>`
	border-bottom: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
`
const StyledText = styled(Text)`
	padding: 10px 0px 10px 0px;
	color: ${({active}) => (active ? `${palette.purple_1}` : 'black')};
`
const ButtonWrapper = styled(Back)`
	margin: 0.5rem 0rem;
`
const Section = styled.div`
	padding: 25px 5px 20px 5px;
`
const TableWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	padding: 15px;
	border-radius: 5px;
`
const ViewIcon = styled(GiSunglasses)`
	font-size: 1.9rem;
	cursor: pointer;
	&:hover {
		background: ${palette.white};
		padding: 0px;
		border-radius: 7px;
	}
`
const EditIcon = styled(FiEdit2)`
	font-size: 1rem;
	cursor: pointer;
	&:hover {
		background: ${palette.white};
		padding: 0px;
		border-radius: 7px;
	}
`
const DeleteIcon = styled(RiDeleteBinLine)`
	font-size: 1rem;
	color: red;
	cursor: pointer;
	&:hover {
		background: ${palette.white};
		padding: 0px;
		border-radius: 7px;
	}
`

export default Interview
