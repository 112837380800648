import {useEffect, useState, ChangeEvent} from 'react'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Spacer, Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import InputField from '../common/InputField'
import Button from '../common/Button'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import {TbClockHour9} from 'react-icons/tb'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import FileUpload from '../common/FileUpload'
import {Logo} from './AddEmployeeAttendanceModal'
import MultiSelect from '../common/MultiSelect'

interface IProps {
	isDarkTheme: boolean
}

const TaskModal = ({open, onCloseModal, editTaskData, refreshTable, setIsLoading}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const [employeeId, setEmployeeId] = useState<any>(editTaskData?.assignedUserId ? editTaskData?.assignedUserId : '')
	const [employeeOption, setEmployeeOption] = useState<any>([])
	const [dueDate, setDueDate] = useState(editTaskData?.dueDate ? editTaskData?.dueDate : '')
	const [document, setDocument] = useState(editTaskData?.document ? editTaskData?.document : '')
	const [documentName, setDocumentName] = useState(editTaskData?.document ? editTaskData?.document : '')
	const [notes, setNotes] = useState(editTaskData?.notes ? editTaskData?.notes : '')
	const [taskDetails, setTaskDetails] = useState(editTaskData?.taskDetails ? editTaskData?.taskDetails : '')
	const [taskName, setTaskName] = useState(editTaskData?.taskName ? editTaskData?.taskName : '')
	const [taskNameError, setTaskNameError] = useState('')
	const [employeeError, setEmployeeError] = useState<any>('')
	const [status, setStatus] = useState(editTaskData?.status ? editTaskData?.status : '')
	const [statusOption, setStatusOption] = useState([
		{label: 'In-Progress', value: 'In-Progress'},
		{label: 'Completed', value: 'Completed'},
		{label: 'Pending', value: 'Pending'}
	])

	useEffect(() => {
		allEmployee()
	}, [])
	useEffect(() => {
		if (editTaskData) setEmployeeId(editTaskData?.assignedUserId)
	}, [editTaskData])

	const allEmployee = () => {
		api.get('/employee/all_employees')
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data?.data?.data.length > 0) {
						const employeeData = response?.data?.data?.data

						const arr = employeeData.map((employee: any) => ({
							label: (
								<span style={{display: 'flex', gap: '0.2rem'}}>
									{' '}
									<Logo src={employee?.image ? `${process.env.REACT_APP_IMAGE_URL + employee?.image}` : '/images/default_user_profile_img.png'}></Logo>
									{`${employee.firstName}`}
									&nbsp;
									{`${employee.id}`}
								</span>
							),
							value: employee
						}))
						setEmployeeOption(arr)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const createTask = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.post(`/admin/task/create_task`, {
				taskName: taskName,
				dueDate: dueDate ? moment(dueDate).format('YYYY-MM-DD') : '',
				assignDate: moment().format('YYYY-MM-DD hh:mm A'),

				notes: notes,
				document: document,
				taskDetails: taskDetails,
				assignedUserId: employeeId,
				status: status
			})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Task created successfully')
						onCloseModal()
						refreshTable()
						_navigate('/tasks')
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}
	const updateTtask = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/admin/task/update_task/${editTaskData?.id}`, {
				taskName: taskName,
				dueDate: dueDate ? moment(dueDate).format('YYYY-MM-DD') : '',
				notes: notes,
				document: document,
				taskDetails: taskDetails,
				status: status,
				assignedUserId: employeeId
			})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Task updated successfully')
						onCloseModal()
						refreshTable()
						_navigate('/tasks')
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (taskName === '') {
			setTaskNameError('Task name is required')
			isValid = false
		}

		if (!employeeId) {
			setEmployeeError('Employee is required')
			isValid = false
		}

		return isValid
	}
	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: ''
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							{editTaskData?.id ? 'Edit Task' : 'Create a New Task'}
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Row>
							<Col lg={8}>
								<Wrapper>
									<Row>
										<Col>
											<InputField
												error={taskNameError}
												label="Task Name"
												placeholder="e.g. i want to..."
												required
												value={taskName}
												handleChange={(e: any) => {
													setTaskNameError('')
													if (e == '') {
														setTaskNameError('task name is required')
													}
													if (e?.length <= 50) {
														setTaskName(e)
													}
												}}
											/>
											<Spacer height={1} />
										</Col>

										<Col>
											<InputDate
												label="Due Date"
												value={dueDate}
												handleChange={(e: any) => {
													setDueDate(e)
												}}
											/>
											<Spacer height={1} />
										</Col>
										<Col>
											<InputField
												type="textarea"
												label="Notes"
												placeholder="insert your notes here..."
												value={notes}
												handleChange={(e: any) => {
													setNotes(e)
												}}
											/>
											<Spacer height={1} />
										</Col>
										<Col>
											<FileUpload label="Upload File" value={documentName} setFileName={setDocumentName} setFileValue={setDocument} />
											<Spacer height={1} />
										</Col>
										<Col>
											<InputField
												type="textarea"
												label="Task Details"
												placeholder="insert your notes here..."
												value={taskDetails}
												handleChange={(e: any) => {
													setTaskDetails(e)
												}}
											/>
											<Spacer height={1} />
										</Col>
									</Row>
								</Wrapper>
							</Col>
							<Col lg={4}>
								<Row>
									<Col>
										<MultiSelect
											label="Assigned to"
											value={employeeOption?.find((e: any) => e?.value?.id == employeeId)}
											required
											options={employeeOption}
											onChange={(e: any) => {
												setEmployeeError('')
												setEmployeeId(e?.value?.id)
											}}
											error={employeeError}
										/>
										<Spacer height={1} />
									</Col>
									<Col>
										<Title direction="row" gap={0.4}>
											<JobIcon>
												<TbClockHour9 />
											</JobIcon>

											<Text type="normal" fontWeight={400} color="black" fontSize={0.8}>
												Time
											</Text>
										</Title>
										<Col>
											<Text type="normal" fontWeight={400} color="blue" fontSize={0.8}>
												{dueDate ? moment(dueDate).format('DD-MM-YYYY') : ''}
											</Text>
										</Col>

										<Spacer height={1} />
									</Col>
									<Col>
										<DropDown
											label="Status"
											firstSelected={status}
											options={statusOption}
											hasChanged={(value: any) => {
												setStatus(value)
											}}
											dropDownListHeight="100px"
										/>
										<Spacer height={1} />
									</Col>
								</Row>
							</Col>
						</Row>
					</Body>
					<Footer isDarkTheme={_isDarkTheme}>
						<TaskButton
							label={editTaskData?.id ? 'Edit' : 'Create'}
							width="100%"
							ifClicked={() => {
								editTaskData?.id ? updateTtask() : createTask()
							}}
						/>
					</Footer>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	position: relative;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
`

const Head = styled(Flexed)`
	padding: 0.938rem;
	width: 100%;
	background-color: ${palette.white};
	z-index: 4;
`

const Body = styled.div`
	max-height: 32rem;
	overflow-y: scroll;
	overflow-x: hidden;
`

const Wrapper = styled(Container)``

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 2rem;
	padding: 0.8rem;
`
const Footer = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	border-top: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	padding: 1rem;
`

const Title = styled(Flexed)``

const TaskButton = styled(Button)`
	background: ${palette.purple_3};
`

const JobIcon = styled.div`
	max-width: 14px;
`

export default TaskModal
