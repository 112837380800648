import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {BiCheck, BiDotsHorizontal} from 'react-icons/bi'
import {colorsList} from '../../utils'
import BoardBackgroundsModel from './BoardBackgroundsModel'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import MultiSelect from '../common/MultiSelect'
import {GiPrivate} from 'react-icons/gi'
import {FaUserGroup} from 'react-icons/fa6'
import {RiChatPrivateFill} from 'react-icons/ri'
import {MdOutlineLock} from 'react-icons/md'
import InputField from '../common/InputField'
import Loader from '../common/Loader'

interface ICategoriesModalProps {
	onCloseModal: any
	onCreateBoard?: any
}

const CreateNewBoard = ({onCloseModal, onCreateBoard}: ICategoriesModalProps) => {
	const [boardName, setBoardName] = useState('')
	const [selectedColor, setSelectedColor] = useState('')
	const [boardNameError, setBoardNameError] = useState('')
	const [selectedWorkspace, setSelectedWorkspace] = useState('workspace')
	const [backgroundImages, setBackgroudImages] = useState<any[]>([])
	const [selectedBackground, setSelectedBackground] = useState<any>('/images/boardImages/image1.avif')
	const [selectedBackgroundColor, setSelectedBackgroundColor] = useState<any>('')
	const [backgroundsModel, setBackgroundsModel] = useState<any>(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		let arr = []
		for (let i = 1; i <= 32; i++) {
			arr.push(`/images/boardImages/image${i}.avif`)
		}

		setBackgroudImages(arr)
	}, [])

	// Math.floor(Math.random() * 360)
	const createNewBoard = async () => {
		if (formValidation()) {
			setLoading(true)

			await api
				.post('/trello/create_board', {name: boardName, backgroundColor: selectedBackgroundColor, backgroundImage: selectedBackground, visibility: selectedWorkspace})
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							onCreateBoard && onCreateBoard()
							onCloseModal()
						}
						setLoading(false)
					}
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	let visibilityOptions: any = [
		{
			label: (
				<span style={{display: 'flex', gap: '0.2rem', alignItems: 'center'}}>
					<MdOutlineLock />
					&nbsp;
					{`Private`}
				</span>
			),
			value: 'private'
		},
		{
			label: (
				<span style={{display: 'flex', gap: '0.2rem', alignItems: 'center'}}>
					<FaUserGroup />
					&nbsp;
					{`Workspace`}
				</span>
			),
			value: 'workspace'
		}
	]

	const formValidation = () => {
		let isValid = true
		if (!boardName) {
			setBoardNameError('Board name is required')
			isValid = false
		}
		return isValid
	}

	return (
		<>
			{loading && <Loader />}
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black">
							Create a New Board
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Container>
							<Flexed direction="row" flexWrap="wrap" align={'center'} gap={1} justify={'center'}>
								<ImageContainer
									backgroundColor={selectedBackgroundColor}
									imageUrl={
										selectedBackgroundColor
											? ''
											: selectedBackground
												? selectedBackground
												: 'https://images.unsplash.com/photo-1711636418389-1ee93ebd56fb?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w3MDY2fDB8MXxjb2xsZWN0aW9ufDF8MzE3MDk5fHx8fHwyfHwxNzEyMTE1MDYzfA&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=400'
									}>
									<Image src="/boardListImages.svg" alt="" role="presentation" />
								</ImageContainer>
							</Flexed>
							<Flexed direction="column" flexWrap="wrap" align={'flex-start'} gap={0.3}>
								<Text type="medium" lineHeight="1.438" fontWeight="300" color="black">
									Background
								</Text>

								<Flexed direction="column" flexWrap="wrap" align={'center'} gap={1} justify={'center'}>
									<Flexed direction="row" flexWrap="wrap" align={'center'} gap={1} justify={'center'}>
										{backgroundImages?.map(
											(e: any, i: any) =>
												i <= 6 && (
													<React.Fragment key={i}>
														{' '}
														<SmallImageContainer
															imageUrl={e}
															onClick={() => {
																setSelectedBackground(e)
																setSelectedBackgroundColor('')
															}}>
															<SelectedImage display={e == selectedBackground}>
																<BiCheck color="white" />
															</SelectedImage>
														</SmallImageContainer>{' '}
													</React.Fragment>
												)
										)}
									</Flexed>
									<Flexed direction="row" flexWrap="wrap" align={'center'} gap={1} justify={'center'}>
										{colorsList?.map(
											(color: any, i: any) =>
												i <= 5 && (
													<React.Fragment key={i}>
														{' '}
														<ColorButton
															color={color}
															onClick={() => {
																setSelectedBackgroundColor(color)
																setSelectedBackground('')
															}}>
															<SelectedImage display={color == selectedBackgroundColor}>
																<BiCheck color="white" />
															</SelectedImage>
														</ColorButton>
													</React.Fragment>
												)
										)}

										<ColorButton>
											<SelectedImage display={true} onClick={() => setBackgroundsModel(true)}>
												<BiDotsHorizontal color={palette.gray} />
											</SelectedImage>
										</ColorButton>
									</Flexed>
								</Flexed>
							</Flexed>
							<Form>
								<InputField
									type="text"
									label="Board title"
									required
									value={boardName}
									handleChange={(e: any) => {
										setBoardNameError('')
										setBoardName(e)
									}}
									error={boardNameError}
								/>
								<Spacer height={'0.5'} />
								<VisibilityLabel htmlFor="create-board-select-visibility">Visibility</VisibilityLabel>
								<MultiSelect
									options={visibilityOptions}
									value={visibilityOptions.find((e: any) => e?.value == selectedWorkspace)}
									enterToAdd
									isSearchable
									noOptionsMessage={'No option found'}
									onChange={(e: any) => setSelectedWorkspace(e?.value)}
								/>
								<SelectVisibility>{/* Select visibility options */}</SelectVisibility>
								{/* Submit button */}
							</Form>
							{/* <TemplateButton>Start with a template</TemplateButton> */}
							<Footer>
								{/* <LicenseInfo>
									By using images from Unsplash, you agree to their{' '}
									<UnsplashLink href="https://unsplash.com/license" target="_blank">
										license
									</UnsplashLink>{' '}
									and{' '}
									<UnsplashLink href="https://unsplash.com/terms" target="_blank">
										Terms of Service
									</UnsplashLink>
								</LicenseInfo> */}
								<Footer direction="row" align="center" justify="space-between" gap={1}>
									<Button onClick={onCloseModal} border="silver_6" color="black_3" bg="white" bg_hover="silver_2">
										Cancel
									</Button>
									<Button color="white" bg="purple_1" bg_hover="purple_2" onClick={() => createNewBoard()}>
										Create Board
									</Button>
								</Footer>
							</Footer>
						</Container>
					</Body>
				</ModalWrapper>
				{backgroundsModel && (
					<BoardBackgroundsModel
						onCloseModal={() => setBackgroundsModel(false)}
						selectedBackground={selectedBackground}
						setSelectedBackground={(e: any) => {
							setSelectedBackground(e)
							setBackgroundsModel(false)
						}}
						selectedBackgroundColor={selectedBackground}
						setSelectedBackgroundColor={(e: any) => {
							setSelectedBackgroundColor(e)
							setBackgroundsModel(false)
						}}
					/>
				)}
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;
`

const Footer = styled(Flexed)`
	padding: 1rem 1.5rem;
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({border}) => palette[border]};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

const Container = styled.div`
	/* max-height: 372px; */
`

const ImageContainer = styled.div<any>`
	background-color: ${(props) => props.backgroundColor};
	background-image: url(${(props) => props.imageUrl});
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 120px;
	background-position: center center;
	border-radius: 3px;
`

const SmallImageContainer = styled.div<any>`
	background-color: ${(props) => props.backgroundColor};
	background-image: url(${(props) => props.imageUrl});
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.3rem;
	height: 40px;
	background-position: center center;
	border-radius: 3px;
	cursor: pointer;
`

const Image = styled.img`
	/* display: none; */
`

const BackgroundPicker = styled.div`
	display: flex;
	margin-top: 10px;
`

const ColorButton = styled.button`
	background-color: ${(props) => props.color};
	border: none;
	width: 3rem;
	height: 3rem;
	margin-right: 5px;
	cursor: pointer;
	width: 64px;
	height: 40px;
	background-position: center center;
	border-radius: 3px;
`

const EmojiButton = styled.button`
	font-size: 20px;
	background: none;
	border: none;
	cursor: pointer;
`

const Form = styled.form`
	margin-top: 20px;
`

const TitleInput = styled.input`
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
`

const VisibilityLabel = styled.label`
	display: block;
	margin-bottom: 5px;
`

const SelectVisibility = styled.div`
	margin-bottom: 10px;
`

const SubmitButton = styled.button`
	background-color: #0079bf;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
`

const TemplateButton = styled.button`
	background-color: transparent;
	border: 1px solid #0079bf;
	color: #0079bf;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin-top: 10px;
`

const LicenseInfo = styled.div`
	color: #666;
`

const UnsplashLink = styled.a`
	color: #0079bf;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`

const SelectedImage = styled.span<any>`
	position: relative;
	z-index: 1;
	flex-basis: 100%;
	font-size: 1.2rem;
	font-weight: 700;
	height: 24px;
	line-height: 24px;
	display: ${({display}) => (display ? 'flex' : 'none')};
	justify-content: center;
`

export default CreateNewBoard
