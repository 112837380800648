import React, {useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import InputFile from '../common/InputFile'
import {api, doGetOwnerProfile} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {loginAdminDetailsAction} from '../../actions/authActions'
import FileUpload from '../common/FileUpload'

interface ICategoriesModalProps {
	onCloseModal: any
	documentDetail: any
	refreshTable: any
	setIsLoading: any
}

const CompanyProfileModal = ({onCloseModal, documentDetail, refreshTable, setIsLoading}: ICategoriesModalProps) => {
	let _navigate = useNavigate()
	let _dispatch = useDispatch()
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const [companyType, setCompanyType] = useState(_loginAdminDetails ? _loginAdminDetails?.companyType : '')
	const [companyTypeOption, setCompanyTypeOption] = useState([
		{label: 'Private Limited Company', value: 'Private Limited Company'},
		{label: 'Joint Venture', value: 'Joint Venture'},
		{label: 'Sole Proprietorship', value: 'Sole Proprietorship'},
		{label: 'Public Listed Company', value: 'Public Listed Company'},
		{label: 'Venture', value: 'Venture'}
	])

	const [companyName, setCompanyName] = useState(_loginAdminDetails ? _loginAdminDetails?.companyName : '')
	const [logoName, setLogoName] = useState(_loginAdminDetails ? _loginAdminDetails?.logo : '')
	const [logo, setLogo] = useState(_loginAdminDetails ? _loginAdminDetails?.logo : '')

	const updateCompanyProfile = () => {
		setIsLoading(true)
		api.put(`/admin/update/`, {
			companyType: companyType,
			companyName: companyName,
			logo: logo
		})
			.then((response) => {
				if (response?.data?.success == true) {
					toast.success('Profile updated successfully')
					_dispatch(loginAdminDetailsAction(response?.data?.data?.data))
					onCloseModal()
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const handleCapture = (event: any) => {
		const _reader: any = new FileReader()
		_reader.readAsDataURL(event.target.files[0])
		_reader.onload = () => {
			if (_reader.readyState === 2) {
				setLogo(_reader?.result)
			}
		}
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
							Company Profile
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Spacer height={1} />
					<Body>
						<Container>
							<Row>
								{logo && <Col>
									<LogoContainer>
										<LogoImage src={logo && logo.startsWith('data:') ? logo : logo ? process.env.REACT_APP_IMAGE_URL + logo : null} alt="" />
									</LogoContainer>
									<Spacer height={1} />
								</Col>}
								<Col>
									<FileInput
										multiple
										id="faceImage"
										accept="image/*"
										type="file"
										onChange={(e) => {
											handleCapture(e)
										}}
									/>
									<FileUpload allow='image/*' label="Company Logo" value={logoName} setFileName={setLogoName} setFileValue={setLogo} />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Company Name"
										value={companyName}
										handleChange={(e: any) => {
											setCompanyName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Company Type" firstSelected={companyType} options={companyTypeOption} hasChanged={(value: any) => setCompanyType(value)} dropDownListHeight="130px" />
									<Spacer height={1} />
								</Col>

								
							</Row>
						</Container>
					</Body>
					<Footer direction="row" align="center" justify="flex-end" gap={1}>
						<Button
							border="red"
							color="white"
							bg="red"
							bg_hover="red_hover"
							onClick={() => {
								onCloseModal()
							}}>
							Cancel
						</Button>
						<Button
							border="purple_2"
							color="white"
							bg="purple_1"
							bg_hover="purple_2"
							onClick={() => {
								updateCompanyProfile()
							}}>
							Save
						</Button>
					</Footer>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
	background-color: ${palette.gray_1};
	border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 0.5rem 1.5rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 600px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Footer = styled(Flexed)`
	border-top: 1px solid ${palette.silver};
	padding: 1rem 1.5rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({border}) => palette[border]};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`
const FileInput = styled.input`
	display: none !important;
`
const LogoContainer = styled.div`
	width: 100px;
	height: 100px;
	overflow: hidden;
	border: 1px dashed ${palette.silver_13};
	border-radius: 0.375rem;
`

const LogoImage = styled.img`
	width: 100px;
	height: 100px;
	object-fit:cover;
`

export default CompanyProfileModal
