import React, {useEffect, useRef, useState} from 'react'
import styled, {keyframes} from 'styled-components'
import 'react-responsive-modal/styles.css'
import Button from './Button'
import DropDown from './DropDown'
import InputDate from './InputDate'

import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'

import {BsXLg} from 'react-icons/bs'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'

const CandidateViewFilter = ({data, show, onClick, onClose}: any) => {
	const myRef: any = useRef()
	const [candidateData, setCandidateData] = useState<any>('')

	// eslint-disable-next-line
	const [sortBy, setSortBy] = useState('normal')
	// eslint-disable-next-line
	const [fairoption, setFairOption] = useState([
		{value: 'byname', label: 'By Name'},
		{value: 'bydate', label: 'By Date'}
	])

	const handleClickOutside = (e: any) => {
		if (!myRef?.current?.contains(e.target)) {
			onClick()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	})
	useEffect(() => {
		getCandidate(data)
	}, [])
	const getCandidate = (data: any) => {
		api.get(`/candidate/get_candidate/${data}`)
			.then((response) => {
				if (response?.data?.success == true) {
					setCandidateData(response?.data?.data?.data)
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}
	return (
		<>
			<OverLay show={show} />
			<SideMenuWrapper show={show} ref={myRef}>
				<SideMenuHeader gap={1}>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={1.625} color="heading_color" fontWeight="500">
							{candidateData?.firstName} {candidateData?.lastName}
						</Text>
						<Close onClick={onClose} />
					</Flexed>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Mobile No: {candidateData?.phone}
						</Text>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Email: {candidateData?.email}
						</Text>
					</Flexed>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Current Job Title: {candidateData?.currentJobTitle}
						</Text>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Current Employer: {candidateData?.currentEmployee}
						</Text>
					</Flexed>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Experience: {candidateData?.experience}
						</Text>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Highest Qualification: {candidateData?.qualification}
						</Text>
					</Flexed>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Address: {candidateData?.additionalInfo}
						</Text>
					</Flexed>
				</SideMenuHeader>

				<Body justify="space-between" gap={1}>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							First Name: {candidateData?.firstName}
						</Text>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Last Name: {candidateData?.lastName}
						</Text>
					</Flexed>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Email: {candidateData?.email}
						</Text>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Website : {candidateData?.website}
						</Text>
					</Flexed>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Expected Salary: {candidateData?.expectedSalary}
						</Text>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Current Salary: {candidateData?.currentSalary}
						</Text>
					</Flexed>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Skills: {candidateData?.skillSet}
						</Text>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Additional Info: {candidateData?.additionalInfo}
						</Text>
					</Flexed>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Skype ID: {candidateData?.skypeID}
						</Text>
						<Text fontSize={0.9} color="heading_color" fontWeight="200">
							Twitter: {candidateData?.twitter}
						</Text>
					</Flexed>
				</Body>
			</SideMenuWrapper>
		</>
	)
}

const OverLay = styled.div<any>`
	background: #00000014;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: fixed;
	display: ${({show}) => (show ? 'block' : 'none')};
`
const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`

const SideMenuWrapper = styled.div<any>`
	overflow: auto;
	width: ${({show}) => (show ? '40rem' : '0rem')};
	top: 0;
	right: 0;
	height: 100%;
	background: ${palette.white};
	box-shadow: ${palette.shadow};
	position: fixed;
	transform-origin: right left;
	transition: width 10s ease 0s;
	animation: ${slideInFromRight} 1s ease;
	z-index: 10;
`

const SideMenuHeader = styled(Flexed)`
	background: ${palette.silver_3};
	height: 13rem;
	padding: 2rem 3rem;
	position: sticky;
	top: 0;
	z-index: 13;
`

const Close = styled(BsXLg)`
	cursor: pointer;
`

const Body = styled(Flexed)`
	padding: 2rem 3rem;
`
const Status = styled.span`
	margin-left: 0.5rem;
	font-size: 0.8rem;
	background: #e5f9de;
	padding: 2px 3px;
	border-radius: 6px;
`

export default CandidateViewFilter
