import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {toast} from 'react-toastify'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {media} from 'styled-bootstrap-grid'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {LuEye} from 'react-icons/lu'
import {ImAttachment} from 'react-icons/im'
import {IoIosSettings} from 'react-icons/io'
import {IoMdArrowForward} from 'react-icons/io'
import {MdContentCopy} from 'react-icons/md'
import {FaRegUser} from 'react-icons/fa6'
import {IoIosInformationCircleOutline} from 'react-icons/io'
import {BiArchive, BiChevronDown, BiLabel} from 'react-icons/bi'
import {FiCheckSquare} from 'react-icons/fi'
import {GoDotFill, GoPlus} from 'react-icons/go'
import {FaRegClock} from 'react-icons/fa'
import {IoShareSocialOutline} from 'react-icons/io5'
import {GoProjectTemplate} from 'react-icons/go'
import {HiMiniWindow} from 'react-icons/hi2'
import {HiOutlineBars3BottomLeft} from 'react-icons/hi2'
import {RxActivityLog} from 'react-icons/rx'
import {FaCheck} from 'react-icons/fa6'
import {IoLocationSharp} from 'react-icons/io5'
import {IoMdAdd} from 'react-icons/io'
import {RiArchiveLine} from 'react-icons/ri'
import {FaArrowRotateLeft, FaMinus} from 'react-icons/fa6'
import AddMemberPop, {ProfileImg} from '../trelloTaskModalComponents/AddMemberPop'
import LabelsPop from '../trelloTaskModalComponents/LabelsPop'
import AddChecklistPop from '../trelloTaskModalComponents/AddChecklistPop'
import CheckListsView from '../trelloTaskModalComponents/CheckListsView'
import DatePop from '../trelloTaskModalComponents/DatePop'
import AttachmentList from '../trelloTaskModalComponents/AttachmentList'
import AttachmentsPop from '../trelloTaskModalComponents/AttachmentsPop'
import LocationPop from '../trelloTaskModalComponents/LocationPop'
import LocationList from '../trelloTaskModalComponents/LocationList'
import {api} from '../api/callAxios'
import Loader from '../common/Loader'
import {useSelector} from 'react-redux'
import DeleteModal from './DeleteModal'
import MoveCard from '../trelloTaskModalComponents/MoveCard'
import CopyCard from '../trelloTaskModalComponents/CopyCard'
import ShareCard from '../trelloTaskModalComponents/ShareCard'
import DeleteCard from '../trelloTaskModalComponents/DeleteCard'
import {useDispatch} from 'react-redux'
import {updateBoardList, updatedList} from '../../actions/authActions'
import {getInitials} from '../../utils'
import useRouter from '../../hooks/router'
import {Checked, UnChecked} from '../../pages/Auth/SignUp'
import moment from 'moment'
import TrelloMemberProfile from './UserProfileModal'
import ActivityLog from './ActivityLogs'

declare global {
	interface Window {
		jQuery: any
	}
}
window.jQuery = require('jquery')
const TextEditor = require('../common/TextEditor').default

const TrelloTaskModal = ({onCloseModal, quote}: any) => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)

	const router: any = useRouter()

	const _addCheckListPopRef: any = useRef(null)
	const _datePopRef: any = useRef(null)
	const _dateCustomPopRef: any = useRef(null)
	const _addMemberPopRef: any = useRef(null)
	const _addLabelsPopRef: any = useRef(null)
	const _labelsPopRef: any = useRef(null)
	const _memberPopRef: any = useRef(null)
	const _attachmentsPopRef: any = useRef(null)
	const _locationPopRef: any = useRef(null)
	const _moveCardPopRef: any = useRef(null)
	const _copyCardPopRef: any = useRef(null)
	const _shareCardPopRef: any = useRef(null)
	const _openDeleteCardPopRef: any = useRef(null)
	const [location, setLocation] = useState('')
	const [isWatched, setIsWatched] = useState(false)
	const [viewDescriptionEditor, setViewDescriptionEditor] = useState(false)
	const [loading, setLoading] = useState(false)
	const [userComment, setUserComment] = useState<any>('')
	const [cardDetails, setCardDetails] = useState<any>('')
	const [employees, setEmployees] = useState<any>([])
	const [description, setDescription] = useState<any>('')
	const [viewEditCommentEditor, setViewEditCommentEditor] = useState(false)
	const [deleteModel, setDeleteModel] = useState(false)
	const [deletedItemId, setDeletedItemId] = useState<any>('')
	const [editCommentId, setEditCommentId] = useState<any>('')
	const dispatch = useDispatch()
	const [viewCommentEditor, setViewCommentEditor] = useState(false)
	const [isReply, setIsReply] = useState(false)
	const [openAddMemberPop, setOpenAddMemberPop] = useState(false)
	const [openMemberPop, setOpenMemberPop] = useState(false)
	const [openAddLabelsPop, setOpenAddLabelsPop] = useState(false)
	const [openLabelsPop, setOpenLabelsPop] = useState(false)
	const [openAddCheckListPop, setOpenAddCheckListPop] = useState(false)
	const [showChecklistContent, setShowChecklistContent] = useState(false)
	const [openDatePop, setOpenDatePop] = useState(false)
	const [openDateCustomPop, setOpenDateCustomPop] = useState(false)
	const [openAttachmentsPop, setOpenAttachmentsPop] = useState(false)
	const [openLocationPop, setOpenLocationPop] = useState(false)
	const [openMoveCardPop, setOpenMoveCardPop] = useState(false)
	const [openCopyCardPop, setOpenCopyCardPop] = useState(false)
	const [openShareCardPop, setOpenShareCardPop] = useState(false)
	const [openArchiveCardLayout, setOpenArchiveCardLayout] = useState(false)
	const [openDeleteCard, setOpenDeleteCard] = useState(false)
	const [boardMembers, setBoardMembers] = useState<any>([])
	const [isLabelUpdated, setIsLabelUpdated] = useState<any>(false)
	const [mentiondUser, setMentiondUser] = useState('')
	const [openUserProfile, setOpenUserProfile] = useState(false)
	const [userProfile, setUserProfile] = useState<any>('')
	const [showActivityDetails, setShowActivityDetails] = useState(false)

	const [colorList, setColorList] = useState([
		{color: '#baf3db', onHover: '#7ee2b8', title: 'subtle green'},
		{color: '#f8e6a0', onHover: '#f5cd47', title: 'subtle yellow'},
		{color: '#fedec8', onHover: '#fec195', title: 'subtle orange'},
		{color: '#ffd5d2', onHover: '#fd9891', title: 'subtle red'},
		{color: '#dfd8fd', onHover: '#b8acf6', title: 'subtle purple'},
		{color: '#4bce97', onHover: '#7ee2b8', title: 'green'},
		{color: '#f5cd47', onHover: '#e2b203', title: 'yellow'},
		{color: '#fea362', onHover: '#fec195', title: 'orange'},
		{color: '#f87168', onHover: '#fd9891', title: 'red'},
		{color: '#9f8fef', onHover: '#b8acf6', title: 'purple'},
		{color: '#1f845a', onHover: '#216e4e', title: 'bold green'},
		{color: '#946f00', onHover: '#7f5f01', title: 'bold yellow'},
		{color: '#c25100', onHover: '#a54800', title: 'bold orange'},
		{color: '#c9372c', onHover: '#ae2e24', title: 'bold red'},
		{color: '#6e5dc6', onHover: '#5e4db2', title: 'bold purple'},
		{color: '#cce0ff', onHover: '#85b8ff', title: 'subtle blue'},
		{color: '#c6edfb', onHover: '#9dd9ee', title: 'subtle sky'},
		{color: '#d3f1a7', onHover: '#b3df72', title: 'subtle lime'},
		{color: '#fdd0ec', onHover: '#f797d2', title: 'subtle pink'},
		{color: '#dcdfe4', onHover: '#b3b9c4', title: 'subtle black'},
		{color: '#579dff', onHover: '#85b8ff', title: 'blue'},
		{color: '#6cc3e0', onHover: '#9dd9ee', title: 'sky'},
		{color: '#94c748', onHover: '#b3df72', title: 'lime'},
		{color: '#e774bb', onHover: '#f797d2', title: 'pink'},
		{color: '#8590a2', onHover: '#b3b9c4', title: 'black'},
		{color: '#0c66e4', onHover: '#0055cc', title: 'bold blue'},
		{color: '#227d9b', onHover: '#206a83', title: 'bold sky'},
		{color: '#5b7f24', onHover: '#4c6b1f', title: 'bold lime'},
		{color: '#ae4787', onHover: '#943d73', title: 'bold pink'},
		{color: '#626f86', onHover: '#44546f', title: 'bold black'}
	])

	const [image, setImage] = useState('')
	const handleCapture = (event: any) => {
		const _reader: any = new FileReader()
		_reader.readAsDataURL(event.target.files[0])
		let _file = event.target.files?.[0]

		_reader.onload = () => {
			if (_reader.readyState === 2) {
				setImage(_reader?.result)
				addAttachment(_reader?.result, _file?.name)
			}
		}
	}
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_addCheckListPopRef?.current && !_addCheckListPopRef?.current?.contains(event.target)) {
				setOpenAddCheckListPop(false)
			}
			if (_datePopRef?.current && !_datePopRef?.current?.contains(event.target)) {
				setOpenDatePop(false)
			}
			if (_dateCustomPopRef?.current && !_dateCustomPopRef?.current?.contains(event.target)) {
				setOpenDateCustomPop(false)
			}

			if (_addMemberPopRef?.current && !_addMemberPopRef?.current?.contains(event.target)) {
				setOpenAddMemberPop(false)
			}
			if (_addLabelsPopRef?.current && !_addLabelsPopRef?.current?.contains(event.target)) {
				setOpenAddLabelsPop(false)
			}
			if (_labelsPopRef?.current && !_labelsPopRef?.current?.contains(event.target)) {
				setOpenLabelsPop(false)
			}
			if (_memberPopRef?.current && !_memberPopRef?.current?.contains(event.target)) {
				setOpenMemberPop(false)
			}
			if (_attachmentsPopRef?.current && !_attachmentsPopRef?.current?.contains(event.target)) {
				setOpenAttachmentsPop(false)
			}
			if (_locationPopRef?.current && !_locationPopRef?.current?.contains(event.target)) {
				setOpenLocationPop(false)
			}
			if (_moveCardPopRef?.current && !_moveCardPopRef?.current?.contains(event.target)) {
				setOpenMoveCardPop(false)
			}
			if (_copyCardPopRef?.current && !_copyCardPopRef?.current?.contains(event.target)) {
				setOpenCopyCardPop(false)
			}
			if (_shareCardPopRef?.current && !_shareCardPopRef?.current?.contains(event.target)) {
				setOpenShareCardPop(false)
			}
			if (_openDeleteCardPopRef?.current && !_openDeleteCardPopRef?.current?.contains(event.target)) {
				setOpenDeleteCard(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		if (quote?.card_id) {
			getCardDetails()
		}
		allMembers()
	}, [quote])

	const getCardDetails = async (loading = true) => {
		try {
			setLoading(loading)
			await api
				.get(`/trello/get_card?id=${quote?.card_id}`)
				.then((response) => {
					if (response?.data?.success == true) {
						setCardDetails(response?.data?.data)
						setLocation(response?.data?.data?.location)
						setLoading(false)
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const updateCardDetails = async (isCompleted = false) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_card/${quote?.card_id}`, {description, isCompleted})
				.then((response) => {
					if (response?.data?.success == true) {
						getCardDetails()
						setDescription('')
						setViewDescriptionEditor(false)
						setLoading(false)
						setIsLabelUpdated(true)
						// toast.success(response?.data?.message)
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const addComment = async (replyToEmployee?: any, replyToAdmin?: any) => {
		try {
			setLoading(true)
			await api
				.post(`/trello/add_comment`, {
					text: userComment.replace(/<a.*?<\/a>/, ''),
					mentiondUser,
					cardId: quote?.card_id,
					listId: cardDetails?.listId,
					userId: _loginAdminDetails?.id,
					employeeId: _loginUserDetails?.id,
					replyToEmployee,
					replyToAdmin
				})
				.then((response) => {
					if (response?.data?.success == true) {
						getCardDetails()
						setUserComment('')
						setViewEditCommentEditor(false)
						setViewCommentEditor(false)
						setIsLabelUpdated(true)
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const addAttachment = async (base64String = '', filename = '') => {
		try {
			toast.info('Uploading file', {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 3000
			})
			setLoading(true)
			await api
				.post(`/trello/add_attachment`, {cardId: quote?.card_id, base64String: base64String, filename})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success(response?.data?.message, {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 3000
						})
						getCardDetails()
						setOpenAttachmentsPop(false)
						setIsLabelUpdated(true)
					} else {
						toast.error(response?.data?.message, {
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 3000
						})
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const updateComment = async (id: any) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_comment/${id}`, {text: userComment.replace(/<a.*?<\/a>/, ''), mentiondUser, cardId: quote?.card_id, listId: cardDetails?.listId, userId: _loginAdminDetails?.id, employeeId: _loginUserDetails?.id})
				.then((response) => {
					if (response?.data?.success == true) {
						getCardDetails()
						setUserComment('')
						setViewEditCommentEditor(false)
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const deleteComment = async (id: any) => {
		try {
			setLoading(true)
			await api
				.delete(`/trello/delete_comment?id=${id}`)
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success(response?.data?.message)
						setDeleteModel(false)
						setDeletedItemId('')
						getCardDetails()
						setIsLabelUpdated(true)
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const allMembers = async () => {
		await api
			.post('/trello/get_members', {boardId: router?.query?.id, cardId: quote?.card_id})
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						const employeeData = response?.data?.data
						setBoardMembers(employeeData?.boardMembers)
						setEmployees(employeeData?.cardMembers)
						setIsLabelUpdated(true)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const removeCard = async () => {
		try {
			setLoading(true)
			await api
				.delete(`/trello/delete_card?id=${quote?.card_id}`)
				.then((response) => {
					if (response?.data?.success == true) {
						dispatch(updateBoardList(true))
						// dispatch(updatedList({type: 'delete card', quote: quote}))
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	function formatDateTime(dateString: any, timeString: any) {
		const inputDate = moment(dateString)
		const now = moment()
		const tomorrow = moment().add(1, 'day')
		const time = moment(timeString, 'h:mm A').format('h:mm A')

		if (inputDate.isSame(now, 'day')) {
			return timeString ? 'Today at ' + time : 'Today'
		} else if (inputDate.isSame(tomorrow, 'day')) {
			return timeString ? 'Tomorrow at ' + time : 'Tomorrow'
		} else {
			let date = timeString ? inputDate.format(timeString ? 'MMM D [at]' : 'MMM D') + ' ' + moment(time, 'h:mm A').format('h:mm A') : inputDate.format('MMM D')
			return date
		}
	}

	const addCardMember = async (invitationId?: any, employeeId?: any, cardId?: any, taskBoardId?: any, cardMember?: any, isAdmin?: any) => {
		try {
			await api
				.post(`/trello/add_card_member`, {employeeId, cardId, invitationId, taskBoardId, cardMember, isAdmin})
				.then((res: any) => {
					if (res?.data?.success == true) {
						allMembers()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const isMemberWatch = async (adminId?: any, employeeId?: any, isWatch?: any) => {
		try {
			await api
				.post(`/trello/watch_card`, {cardId: cardDetails?.id, adminId, employeeId})
				.then((res: any) => {
					if (res?.data?.success == true) {
						getCardDetails()
						setIsLabelUpdated(true)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const sortedActivityLogs = cardDetails?.ActivityLogs?.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
	return (
		<>
			{loading && <Loader />}
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal(isLabelUpdated)
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					{cardDetails?.taskListCardAttachments?.some((e: any) => e?.makeCover == true) ? (
						<Cover img={process.env.REACT_APP_IMAGE_URL + cardDetails?.taskListCardAttachments?.find((e: any) => e?.makeCover == true)?.url}>
							<CloseCover direction="row" align="center" justify="center">
								<CrossIcon
									onClick={() => {
										onCloseModal(isLabelUpdated)
									}}
								/>
							</CloseCover>
						</Cover>
					) : (
						''
					)}
					<Body>
						<Container>
							<Row>
								<Col>
									{cardDetails?.isArchive ? (
										<ArchivedCardDiv>
											<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem" align={'center'} gap={'0.3'}>
												<BiArchive /> This card is archived.
											</Flexed>
										</ArchivedCardDiv>
									) : (
										''
									)}

									<Flexed direction="row" justify={'space-between'} margin="0.625rem 0rem 0rem 0rem">
										<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem" align={'center'} gap={'0.3'}>
											<Icon>
												<HiMiniWindow />
											</Icon>
											<Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
												{cardDetails?.title}
											</Text>
											<Text type="medium" lineHeight="1.438" fontWeight="700" color="black_3">
												&nbsp;
												{cardDetails?.taskCardWatches?.some((e: any) => (e?.employeeId ? e?.employeeId == _loginUserDetails?.id && e?.isWatch : e?.adminId == _loginAdminDetails?.id && e?.isWatch)) && <LuEye />}
											</Text>
										</Flexed>
										{cardDetails?.taskListCardAttachments?.length == 0 ? (
											<CrossIcon
												position={'relative'}
												color={'grey'}
												onClick={() => {
													onCloseModal(isLabelUpdated)
												}}
											/>
										) : cardDetails?.taskListCardAttachments?.map((e: any) => !e?.makeCover)?.every((value: boolean) => value === true) ? (
											<CrossIcon
												position={'relative'}
												color={'grey'}
												onClick={() => {
													onCloseModal(isLabelUpdated)
												}}
											/>
										) : (
											''
										)}
									</Flexed>
								</Col>
							</Row>
						</Container>
						<Container>
							<Row>
								<Col lg={9}>
									<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem">
										<Icon />
										<MemberSection direction="row" margin="0rem 0.5rem 0rem 0rem"></MemberSection>
									</Flexed>
									<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem" flexWrap="wrap" align={'center'}>
										<Icon />
										{cardDetails?.taskLabels?.length ? (
											<LabelsSection direction="row" margin="0rem 1rem 0rem 0rem" s>
												<Flexed gap={0.5}>
													<Text type="xsmall" fontWeight="600" color="black_3">
														Labels
													</Text>
													<Flexed
														direction="row"
														gap={0.3}
														ref={_addLabelsPopRef}
														onClick={() => {
															setOpenAddLabelsPop(true)
														}}>
														{cardDetails?.taskLabels?.map((res: any) => (
															<ColorWrpper color={res?.color?.color} onHoverColor={res?.color?.onHover} title={res?.color?.title} colorDetails={res}>
																{res?.name}
															</ColorWrpper>
														))}
														<AddLable>
															<IoMdAdd color="black" />
															{openAddLabelsPop && (
																<LabelsPop
																	colorList={colorList}
																	top="60px"
																	left="0"
																	right="auto"
																	onCloseModal={() => setOpenAddLabelsPop(false)}
																	loadAgain={() => {
																		setIsLabelUpdated(true)
																		getCardDetails()
																	}}
																	cardDetails={cardDetails}
																/>
															)}
														</AddLable>
													</Flexed>
												</Flexed>
											</LabelsSection>
										) : (
											''
										)}
										{employees?.length ? (
											<MemberSection direction="row" margin="0rem 1rem 0rem 0rem" s>
												<Flexed gap={0.5}>
													<Text type="xsmall" fontWeight="600" color="black_3">
														Members
													</Text>
													<Flexed direction="row" gap={0.5}>
														{employees?.length
															? employees?.map((e: any) =>
																	e?.employee ? (
																		<>
																			{e?.employee?.image ? (
																				<ProfileImg>
																					<img src={process.env.REACT_APP_IMAGE_URL + e?.employee?.image} alt={e?.employee?.firstName + ' ' + e?.employee?.lastName} />
																				</ProfileImg>
																			) : (
																				<TagUser width="1.8rem" height="1.8rem">
																					{e?.employee?.firstName + ' ' + e?.employee?.lastName && getInitials(e?.employee?.firstName + ' ' + e?.employee?.lastName)}
																				</TagUser>
																			)}
																		</>
																	) : e?.adminId ? (
																		<>
																			{e?.admin?.logo ? (
																				<ProfileImg>
																					<img src={process.env.REACT_APP_IMAGE_URL + e?.admin?.logo} alt={e?.admin?.name} />
																				</ProfileImg>
																			) : (
																				<TagUser width="1.8rem" height="1.8rem">
																					{e?.admin?.name && getInitials(e?.admin?.name)}
																				</TagUser>
																			)}
																		</>
																	) : (
																		''
																	)
																)
															: ''}

														<AddMember
															ref={_addMemberPopRef}
															onClick={() => {
																setOpenAddMemberPop(!openAddMemberPop)
															}}>
															<IoMdAdd color="black" />
														</AddMember>
														{openAddMemberPop && (
															<AddMemberPop employees={employees} top="60px" onCloseModal={() => setOpenAddMemberPop(false)} loadAgain={allMembers} cardDetails={cardDetails} boardMembers={boardMembers} />
														)}
													</Flexed>
												</Flexed>
											</MemberSection>
										) : (
											''
										)}
										<Flexed direction="row">
											<Flexed gap={0.5}>
												<Text type="xsmall" fontWeight="600" color="black_3">
													Notifications
												</Text>
												<WatchedContent
													pointer
													direction="row"
													align="center"
													gap={0.5}
													onClick={() => {
														isMemberWatch(_loginAdminDetails?.id ? _loginAdminDetails?.id : null, _loginUserDetails?.id ? _loginUserDetails?.id : null)
														setIsWatched(!isWatched)
													}}>
													<LuEye />
													<Text fontWeight={600}>Watch</Text>
													{cardDetails?.taskCardWatches?.some((e: any) => (e?.employeeId ? e?.employeeId == _loginUserDetails?.id && e?.isWatch : e?.adminId == _loginAdminDetails?.id && e?.isWatch)) && (
														<CheckCover direction="row" align="center" justify="center">
															<FaCheck fontSize={12} />
														</CheckCover>
													)}
												</WatchedContent>
											</Flexed>
										</Flexed>

										{cardDetails?.dueDate ? (
											<Flexed direction="column" margin={'0 1rem'}>
												<Text type="xsmall" fontWeight="600" color="black_3">
													Due Date
												</Text>
												<Flexed gap={0.5} direction="row" align={'center'} style={{height: '2rem'}}>
													{cardDetails?.isCompleted ? (
														<Checked
															onClick={() => {
																updateCardDetails(false)
															}}
														/>
													) : (
														<UnChecked
															color="rgb(221 223 229)"
															onClick={() => {
																updateCardDetails(true)
															}}
														/>
													)}
													<CustomFlexed gap={0.5} direction="row" align={'center'}>
														{formatDateTime(cardDetails?.dueDate, cardDetails?.time)}
														{cardDetails?.isCompleted ? (
															<Text type="xsmall" fontWeight="600" color="white" style={{background: palette.greenDark, padding: '0.13rem', borderRadius: '0.2rem'}}>
																Completed
															</Text>
														) : moment(cardDetails?.time ? moment(`${moment(cardDetails?.dueDate).format('YYYY-MM-DD')} ${cardDetails?.time}`, 'YYYY-MM-DD HH:mm').format() : moment(cardDetails?.dueDate)).isSame(
																moment(),
																'day'
														  ) ||
														  moment(cardDetails?.time ? moment(`${moment(cardDetails?.dueDate).format('YYYY-MM-DD')} ${cardDetails?.time}`, 'YYYY-MM-DD HH:mm').format() : moment(cardDetails?.dueDate)).isBetween(
																moment(),
																moment().add(3, 'days'),
																null,
																'[]'
														  ) ? (
															<Text type="xsmall" fontWeight="600" color="white" style={{background: '#d2d20e', padding: '0.13rem', borderRadius: '0.2rem'}}>
																Due Soon
															</Text>
														) : (
															''
														)}
														<ContentSidebar
															ref={_dateCustomPopRef}
															width={'auto !important'}
															height={'auto !important'}
															margin="0"
															bg={'transparent !important'}
															direction="row"
															padding={'0'}
															onClick={() => setOpenDateCustomPop(!openDateCustomPop)}>
															<BiChevronDown fontSize={20} cursor={'pointer'} />
															{openDateCustomPop && (
																<DatePop
																	mainDateModal={true}
																	left={'2rem'}
																	bottom="-25rem"
																	onCloseModal={() => {
																		setOpenDateCustomPop(false)
																		setIsLabelUpdated(true)
																	}}
																	cardDetails={cardDetails}
																	isCard={true}
																	loadAgain={getCardDetails}
																/>
															)}
														</ContentSidebar>
													</CustomFlexed>
												</Flexed>
											</Flexed>
										) : (
											''
										)}
									</Flexed>

									<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem">
										<Wrapper gap={0.5}>
											<Flexed direction="row" align={'center'} justify={'space-between'} margin="0.625rem 0rem 0rem 0rem">
												<Flexed direction="row" align={'center'}>
													<Icon>
														<HiOutlineBars3BottomLeft style={{marginBottom: '2px', marginRight: '10px'}} />
													</Icon>
													<Text type="normal" fontWeight="700" color="black_3">
														Description
													</Text>
												</Flexed>

												{cardDetails?.description && !viewDescriptionEditor ? <Button onClick={() => setViewDescriptionEditor(!viewDescriptionEditor)}>Edit</Button> : ''}
											</Flexed>
											{cardDetails?.description && !viewDescriptionEditor ? (
												<Text
													type="small"
													fontWeight="500"
													color="black_3"
													margin="0 2rem"
													style={{cursor: 'pointer'}}
													dangerouslySetInnerHTML={{__html: cardDetails?.description}}
													onClick={() => setViewDescriptionEditor(!viewDescriptionEditor)}></Text>
											) : (
												''
											)}
											{viewDescriptionEditor ? (
												<>
													<TextEditor value={cardDetails?.description} onChange={(e: any) => setDescription(e)} />
													<Flexed direction="row" gap={0.5}>
														<Button onClick={() => description && updateCardDetails()}>Save</Button>
														<Button onClick={() => setViewDescriptionEditor(false)} cancel>
															Cancel
														</Button>
													</Flexed>
												</>
											) : (
												!cardDetails?.description && (
													<DescriptionContent
														pointer
														onClick={() => {
															setViewDescriptionEditor(!viewDescriptionEditor)
														}}>
														<Text fontWeight={600}>Add a more detailed description…</Text>
													</DescriptionContent>
												)
											)}
										</Wrapper>
									</Flexed>

									{location && (
										<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem">
											<Icon>
												<IoLocationSharp fontSize={16} style={{marginRight: '10px'}} />
											</Icon>
											<Wrapper gap={0.5}>
												<Flexed direction="row" justify="space-between" align="center">
													<Text type="normal" fontWeight="700" color="black_3">
														Location
													</Text>
												</Flexed>
												<LocationList cardDetails={cardDetails} loadAgain={getCardDetails} />
											</Wrapper>
										</Flexed>
									)}
									{cardDetails.taskListCardAttachments?.length ? (
										<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem">
											<Icon>
												<ImAttachment fontSize={16} style={{marginRight: '10px'}} />
											</Icon>

											<Wrapper gap={0.5}>
												<Flexed direction="row" justify="space-between" align="center">
													<Text type="normal" fontWeight="700" color="black_3">
														Attachments
													</Text>
													<AddFileButton>
														Add
														<FileInput
															multiple
															id="faceImage"
															accept="image/*"
															type="file"
															onChange={(e: any) => {
																handleCapture(e)
															}}
														/>
													</AddFileButton>
												</Flexed>
												{cardDetails.taskListCardAttachments?.map((res: any, i: any) => <AttachmentList callBack={() => getCardDetails()} quote={res} key={i} />)}
											</Wrapper>
										</Flexed>
									) : (
										''
									)}
									{cardDetails?.taskChecklists?.length
										? cardDetails?.taskChecklists?.map((checklist: any) => (
												<Flexed margin="0.625rem 0rem 0rem 0rem">
													<CheckListsView
														onDeleteList={() => setShowChecklistContent(false)}
														checklist={checklist}
														loadAgain={() => getCardDetails(false)}
														cardMembers={employees}
														boardMembers={boardMembers}
														cardDetails={cardDetails}
													/>
												</Flexed>
											))
										: ''}
									<Flexed direction="row" justify={'space-between'} align={'center'}>
										<Flexed direction="row" margin="1rem 0rem 0rem 0rem">
											<Icon>
												<RxActivityLog fontSize={16} style={{marginBottom: '10px', marginRight: '10px'}} />
											</Icon>
											<Text type="normal" fontWeight="700" color="black_3">
												Activity
											</Text>
										</Flexed>
										<ShowDetailsButton
											onClick={(e: any) => {
												setShowActivityDetails(!showActivityDetails)
											}}>
											{showActivityDetails ? 'Hide' : 'Show Details'}
										</ShowDetailsButton>
									</Flexed>
									{cardDetails?.ActivityLogs?.length && showActivityDetails ? (
										<Flexed>
											<ActivityLog activities={sortedActivityLogs} isCard={true} />
										</Flexed>
									) : (
										''
									)}

									<Flexed direction="row" align={viewCommentEditor ? 'flex-start' : 'center'} margin="0.4rem 0rem 0rem 0rem">
										<ProfileCover>
											<TagUser title={_loginAdminDetails?.name}>
												{_loginAdminDetails?.name ? getInitials(_loginAdminDetails?.name) : _loginUserDetails?.firstName ? getInitials(_loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName) : ''}
											</TagUser>
										</ProfileCover>
										<Wrapper>
											{viewCommentEditor ? (
												<>
													<TextEditor onChange={(e: any) => setUserComment(e)} />
													<Spacer height={0.5} />
													<Flexed direction="row" gap={0.5}>
														<Button onClick={() => userComment?.trim()?.length && addComment()}>Save</Button>
														<Button onClick={() => setViewCommentEditor(false)} cancel>
															Cancel
														</Button>
													</Flexed>
												</>
											) : (
												<CommentSection
													onClick={() => {
														setViewCommentEditor(!viewCommentEditor)
													}}>
													Write a comment
												</CommentSection>
											)}
										</Wrapper>
									</Flexed>
									<Flexed direction="column" margin="1rem 0rem 0rem 0rem">
										{cardDetails?.taskListCardcomments?.map((res: any) => {
											return (
												<Flexed gap="1" style={{width: '100%'}} direction="row" align={'flex-start'} margin="0.5rem 0rem 0rem 0rem">
													{res?.employee ? (
														<TagUser>{res?.employee?.firstName ? getInitials(res?.employee?.firstName + ' ' + res?.employee?.lastName) : ''}</TagUser>
													) : (
														<TagUser>{res?.admin?.name ? getInitials(res?.admin?.name) : _loginUserDetails?.firstName ? getInitials(_loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName) : ''}</TagUser>
													)}
													<Flexed style={{width: '100%'}} direction="column" align={'flex-start'}>
														<Text margin="0 0 0 0.5rem" type="small" color="black_3" fontWeight={700}>
															{res?.employee ? res?.employee?.firstName + ' ' + res?.employee?.lastName : res?.admin?.name}
														</Text>

														<CommentSection style={{cursor: 'default', padding: '8px 19px', display: 'flex'}}>
															{res?.replyToOwner || res?.replyTo ? (
																<div
																	style={{color: 'blue', cursor: 'pointer'}}
																	onClick={() => {
																		setUserProfile(res?.replyToOwner ? res?.replyToOwner : res?.replyTo)
																		setOpenUserProfile(true)
																	}}>
																	{res?.replyToOwner ? '@' + res?.replyToOwner?.name : res?.replyTo ? '@' + res?.replyTo?.firstName : ''}
																</div>
															) : (
																''
															)}
															<span dangerouslySetInnerHTML={{__html: res?.text}}></span>
														</CommentSection>

														{viewEditCommentEditor && res?.id == editCommentId ? (
															<>
																<Spacer height={0.4} />
																<TextEditor value={userComment} onChange={(e: any) => setUserComment(e)} />
																<Spacer height={0.5} />
																<Flexed style={{width: '100%'}} direction="row" gap={0.5}>
																	<Button
																		onClick={() => {
																			if (isReply) {
																				userComment?.trim()?.length && addComment(res?.employee ? res?.employeeId : null, res?.admin?.id ? res?.admin?.id : null)
																			} else {
																				userComment?.trim()?.length && updateComment(res?.id)
																			}
																		}}>
																		Save
																	</Button>
																	<Button
																		style={{width: 'auto'}}
																		onClick={() => {
																			setUserComment('')
																			setViewEditCommentEditor(false)
																		}}
																		cancel>
																		Discard Changes
																	</Button>
																</Flexed>
															</>
														) : (
															<>
																{_loginAdminDetails?.id == res?.admin?.id || _loginUserDetails?.id == res?.employeeId ? (
																	<Flexed style={{width: '100%'}} direction="row" gap="0.2" align={'center'} margin="0.4rem 0rem 0rem 0rem">
																		<Text
																			style={{textDecoration: 'underline', cursor: 'pointer'}}
																			type="small"
																			color="black_3"
																			fontWeight={600}
																			onClick={() => {
																				setUserComment(`<div style="display: flex;">${mentiondUser + res?.text}</div>`)
																				setEditCommentId(res?.id)
																				setViewEditCommentEditor(true)
																			}}>
																			Edit
																		</Text>
																		<GoDotFill fontSize={7} />
																		<Text
																			style={{textDecoration: 'underline', cursor: 'pointer'}}
																			type="small"
																			color="black_3"
																			fontWeight={600}
																			onClick={() => {
																				setDeletedItemId(res?.id)
																				setDeleteModel(true)
																			}}>
																			Delete
																		</Text>
																	</Flexed>
																) : (
																	<Flexed style={{width: '100%'}} direction="row" gap="0.2" align={'center'} margin="0.4rem 0rem 0rem 0rem">
																		<GoDotFill fontSize={7} />
																		<Text
																			style={{textDecoration: 'underline', cursor: 'pointer'}}
																			type="small"
																			color="black_3"
																			fontWeight={600}
																			onClick={() => {
																				setUserComment(`<a href='#'>@${res?.admin?.id ? res?.admin?.name : res?.employeeId ? res?.employee?.firstName : ''} </a>`)
																				setMentiondUser(`<a href='#'>@${res?.admin?.id ? res?.admin?.name : res?.employeeId ? res?.employee?.firstName : ''} </a>`)

																				setIsReply(true)
																				setEditCommentId(res?.id)
																				setViewEditCommentEditor(true)
																			}}>
																			Reply
																		</Text>
																	</Flexed>
																)}
															</>
														)}
													</Flexed>
												</Flexed>
											)
										})}
									</Flexed>
								</Col>

								<Col lg={3}>
									{(_loginAdminDetails?.id && employees?.some((e: any) => e?.adminId == _loginAdminDetails?.id)) || (_loginUserDetails?.id && employees?.some((e: any) => e?.employeeId == _loginUserDetails?.id)) ? (
										''
									) : (
										<>
											<Flexed justify="space-between" margin="0.625rem 0rem 0rem 0rem" align="center" gap={3} direction="row">
												<Text type="xsmall" color="black_3" fontWeight={700}>
													SideBar
												</Text>
												<IoIosSettings />
											</Flexed>

											<ContentSidebar
												justify="flex-start"
												align="center"
												gap={0.5}
												direction="row"
												onClick={() => {
													if (_loginUserDetails?.id) {
														let invitationId = boardMembers?.find((e: any) => e?.receiverUserId == _loginUserDetails?.id)
														console.log('=====cardDetails', cardDetails)
														addCardMember(invitationId?.id, _loginUserDetails?.id, cardDetails?.id, cardDetails?.boardId, true, false)
													} else {
														addCardMember('', '', cardDetails?.id, cardDetails?.boardId, true, true)
													}
												}}>
												<FaRegUser />
												<Text fontWeight={600}>Join</Text>
											</ContentSidebar>
										</>
									)}
									<Spacer height={1} />
									<Text type="xsmall" margin="0rem 0rem 0.3rem 0rem " color="black_3" fontWeight={700}>
										Add to cart
									</Text>
									<ContentSidebar ref={_memberPopRef} justify="flex-start" align="center" direction="row" gap={0.5} onClick={() => setOpenMemberPop(!openMemberPop)}>
										<FaRegUser />
										<Text fontWeight={600}>Members</Text>
										{openMemberPop && <AddMemberPop top="40px" left="auto" onCloseModal={() => setOpenMemberPop(false)} loadAgain={allMembers} employees={employees} cardDetails={cardDetails} boardMembers={boardMembers} />}
									</ContentSidebar>
									<ContentSidebar ref={_labelsPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenLabelsPop(!openLabelsPop)}>
										<BiLabel />
										<Text fontWeight={600}>Labels</Text>
										{openLabelsPop && (
											<LabelsPop
												colorList={colorList}
												top="40px"
												left="auto"
												onCloseModal={() => setOpenLabelsPop(false)}
												loadAgain={() => {
													setIsLabelUpdated(true)
													getCardDetails()
												}}
												cardDetails={cardDetails}
											/>
										)}
									</ContentSidebar>
									<ContentSidebar ref={_addCheckListPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenAddCheckListPop(!openAddCheckListPop)}>
										<FiCheckSquare />
										<Text fontWeight={600}>Checklist</Text>
										{openAddCheckListPop && (
											<AddChecklistPop
												top="40px"
												left="auto"
												addCheckList={() => {
													setShowChecklistContent(true)
													setOpenAddCheckListPop(false)
												}}
												onCloseModal={() => setOpenAddCheckListPop(false)}
												cardDetails={cardDetails}
												loadAgain={getCardDetails}
											/>
										)}
									</ContentSidebar>
									<ContentSidebar ref={_datePopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenDatePop(!openDatePop)}>
										<FaRegClock />
										<Text fontWeight={600}>Dates</Text>
										{openDatePop && <DatePop mainDateModal={true} bottom="-13rem" onCloseModal={() => setOpenDatePop(false)} cardDetails={cardDetails} isCard={true} loadAgain={getCardDetails} />}
									</ContentSidebar>
									<ContentSidebar ref={_attachmentsPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenAttachmentsPop(!openAttachmentsPop)}>
										<ImAttachment />
										<Text fontWeight={600}>Attachments</Text>
										{openAttachmentsPop && <AttachmentsPop onCloseModal={() => setOpenAttachmentsPop(false)} onSelect={(e: any) => e && addAttachment(e?.string, e?.fileName)} />}
									</ContentSidebar>
									<ContentSidebar ref={_locationPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenLocationPop(!openLocationPop)}>
										<IoLocationSharp />
										<Text fontWeight={600}>Location</Text>
										{openLocationPop && <LocationPop location={location} setLocation={setLocation} onCloseModal={() => setOpenLocationPop(false)} cardDetails={cardDetails} loadAgain={getCardDetails} />}
									</ContentSidebar>
									{/* <ContentSidebar justify="flex-start" align="center" gap={0.5} direction="row">
										<svg width="21" height="24" role="presentation" focusable="false" viewBox="0 0 24 24">
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6H3ZM4 16V12H20V16H4ZM2 12C2 10.8954 2.89543 10 4 10H20C21.1046 10 22 10.8954 22 12V16C22 17.1046 21.1046 18 20 18H4C2.89543 18 2 17.1046 2 16V12Z"
												fill="currentColor"></path>
										</svg>
										<Text fontWeight={600}>Custom Field</Text>
									</ContentSidebar> */}
									<Spacer height={1.5} />
									{/* <AddContent>
										<Text type="xsmall" margin="0.7rem 0rem 0.3rem 0rem " color="black_3" fontWeight={700}>
											Power-Ups
										</Text>
										<Flexed justify="flex-start" align="center" gap={0.5} direction="row">
											<GoPlus />
											<Text fontWeight={600}>Add Power-Ups</Text>
										</Flexed>
									</AddContent>
									<AddAutomation>
										<Flexed justify="space-between" align="center" gap={0.5} direction="row" margin="1rem 0rem 0.3rem 0rem">
											<Text type="xsmall"  color="black_3" fontWeight={700}>
											Automation
										</Text>

											<IoIosInformationCircleOutline />
										</Flexed>
										<Flexed justify="flex-start" align="center" gap={0.5} direction="row">
											<GoPlus />
											<Text fontWeight={600}>Add Button</Text>
										</Flexed>
									</AddAutomation>
									<Spacer height={2} /> */}
									<Text type="xsmall" margin="0rem 0rem 0.3rem 0rem" color="black_3" fontWeight={700}>
										Actions
									</Text>
									<ContentSidebar ref={_moveCardPopRef} justify="flex-start" align="center" direction="row" gap={0.5} onClick={() => setOpenMoveCardPop(!openMoveCardPop)}>
										<IoMdArrowForward />
										<Text fontWeight={600}>Move</Text>
										{openMoveCardPop && <MoveCard onCloseModal={() => setOpenMoveCardPop(false)} cardDetails={cardDetails} />}
									</ContentSidebar>
									<ContentSidebar ref={_copyCardPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenCopyCardPop(!openCopyCardPop)}>
										<MdContentCopy />
										<Text fontWeight={600}>Copy</Text>
										{openCopyCardPop && <MoveCard onCloseModal={() => setOpenCopyCardPop(false)} cardDetails={cardDetails} isCopy={true} />}
										{/* {openCopyCardPop && <CopyCard onCloseModal={() => setOpenCopyCardPop(false)} cardDetails={cardDetails} />} */}
									</ContentSidebar>
									{/* <ContentSidebar justify="space-between" align="center" gap={0.5} direction="row" onClick={() => setMakeTemplate(!makeTemplate)}>
										<Flexed direction="row" gap={0.5} align="center">
											<GoProjectTemplate />
											<Text fontWeight={600}>{!makeTemplate ? 'Make template' : 'Templates'} </Text>
										</Flexed>
										{makeTemplate && (
											<MakeTemplateCheck direction="row" align="center" justify="center">
												<FaCheck fontSize={12} />
											</MakeTemplateCheck>
										)}
									</ContentSidebar> */}
									{!openArchiveCardLayout ? (
										<ContentSidebar justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenArchiveCardLayout(true)}>
											<RiArchiveLine />
											<Text fontWeight={600}>Archive</Text>
										</ContentSidebar>
									) : (
										<>
											<Divider margin="0.5rem 0rem 0rem 0rem" />
											<ContentSidebar justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenArchiveCardLayout(false)}>
												<FaArrowRotateLeft />
												<Text fontWeight={600}>Send to board</Text>
											</ContentSidebar>
											<DeleteSidebarContent ref={_openDeleteCardPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenDeleteCard(!openDeleteCard)}>
												<FaMinus color="white" />
												<Text fontWeight={600} color="white">
													Delete
												</Text>
												{openDeleteCard && <DeleteCard onCloseModal={() => setOpenDeleteCard(false)} onDelete={removeCard} />}
											</DeleteSidebarContent>
										</>
									)}
									<ContentSidebar ref={_shareCardPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenShareCardPop(!openShareCardPop)}>
										<IoShareSocialOutline />
										<Text fontWeight={600}>Share</Text>
										{openShareCardPop && <ShareCard onCloseModal={() => setOpenShareCardPop(false)} />}
									</ContentSidebar>
								</Col>
							</Row>
						</Container>
					</Body>
					<Spacer height={1} />
				</ModalWrapper>

				{openUserProfile && (
					<TrelloMemberProfile
						onCloseModal={() => setOpenUserProfile(false)}
						profileImageUrl={userProfile?.logo ? process.env.REACT_APP_IMAGE_URL + userProfile?.logo : userProfile?.image ? process.env.REACT_APP_IMAGE_URL + userProfile?.image : ''}
						name={userProfile?.name ? userProfile?.name : userProfile?.firstName ? userProfile?.firstName + ' ' + userProfile?.lastName : ''}
						isCard={true}
					/>
				)}
				{deleteModel && (
					<DeleteModal
						open={deleteModel}
						onCloseModal={() => {
							setDeleteModel(false)
							setDeletedItemId('')
						}}
						title={'Comment'}
						body={'Deleting a comment is forever. There is no undo.'}
						deletePost={() => deletedItemId && deleteComment(deletedItemId)}
						modalStatus={''}
					/>
				)}
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Cover = styled.div<any>`
	position: relative;
	background-color: rgb(119, 119, 120);
	background-image: ${({img}) => (img ? `url(${img})` : '')};
	height: 160px;
	min-height: 160px;
	background-size: contain;
	background-origin: content-box;
	padding: 0px;
	box-sizing: border-box;
	background-position: center center;
	// border-radius: 12px 12px 0 0;
	background-repeat: no-repeat;
`

const Body = styled.div`
	padding: 0.5rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 800px;
	`}
`

const CloseCover = styled(Flexed)`
	width: 2rem;
	height: 2rem;
	position: absolute;
	border-radius: 100%;
	top: 0.1rem;
	right: 0.2rem;
	cursor: pointer;
	&:hover {
		background: #a6c5e229;
	}
`

const CrossIcon = styled(BsXLg)<any>`
	position: ${({position}) => (position ? position : 'absolute')};
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({color}) => (color ? color : palette.white)};
	font-size: 1.3rem;
`

const Icon = styled.span`
	font-size: 1.3rem;
	color: black;
	width: 2rem;
`

const TagUser = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({width}) => (width ? width : '2rem')};
	height: ${({height}) => (height ? height : '2rem')};
	border-radius: 100%;
	font-size: 0.875rem;
	font-weight: 600;
	color: ${palette.white};
	background-color: ${palette.purple_1};
`

export const AddLable = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 2rem;
	height: 2rem;
	border-radius: 0.3rem;
	font-size: 0.875rem;
	font-weight: 600;
	color: ${palette.white};
	background-color: ${palette.silver_14};
	&:hover {
		background: ${palette.silver_1};
	}
`

const AddMember = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
	font-size: 0.875rem;
	font-weight: 600;
	color: ${palette.white};
	background-color: ${palette.silver_14};
	color: ${palette.white};
	background-color: ${palette.silver_14};
	&:hover {
		background: ${palette.silver_1};
	}
`

const WatchedContent = styled(Flexed)`
	border-radius: 3px;
	width: fit-content;
	padding: 0rem 0.6rem;
	background-color: ${palette.silver_14};
	min-height: ${({height}) => (height ? height : '32px')};
	position: relative;
	&:hover {
		background: ${palette.silver_1};
	}
`

const ContentSidebar = styled(WatchedContent)<any>`
	background-color: ${({bg}) => (bg ? bg : palette.silver_14)};
	padding: ${({padding}) => (padding ? padding : '5px')};
	margin-top: ${({margin}) => (margin ? margin : '0.5rem')};
	width: ${({width}) => (width ? width : '100%')};
	cursor: pointer;
`
const DeleteSidebarContent = styled(WatchedContent)`
	background-color: ${palette.red};
	padding: 5px;
	margin-top: 0.5rem;
	width: 100%;
	cursor: pointer;
	&:hover {
		background-color: ${palette.red_hover};
	}
`

const AddContent = styled.div``
const AddAutomation = styled.div``

const ProfileCover = styled.div`
	position: relative;
	left: -0.5rem;
`

const CommentSection = styled.div`
	border-radius: 8px;
	outline: none;
	border: none;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
	border-radius: 8px;
	padding: 8px 12px;
	border: 1px solid gray;
	background-color: ${palette.white};
	width: 100%;
	margin-bottom: 0;
	cursor: pointer;
	&:hover {
		background-color: ${palette.silver_15};
	}
`

const CheckCover = styled(Flexed)`
	background: #626f86;
	color: #ffffff;
	width: 28px;
	height: 24px;
	border-radius: 0.2rem;
`

const MakeTemplateCheck = styled(CheckCover)`
	background: ${palette.green};
`

const Wrapper = styled(Flexed)`
	width: 100%;
`

const DescriptionContent = styled(Flexed)`
	border-radius: 3px;
	padding: 0.5rem 0.6rem;
	background-color: ${palette.silver_14};
	min-height: 56px;
	width: 100%;
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({cancel}) => (cancel ? palette.black : palette.white)};
	background: ${({cancel}) => (cancel ? palette.white : palette.purple_1)};
	font-weight: 600;
	cursor: pointer;
	width: 5rem;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		color: ${({cancel}) => (cancel ? palette.black : palette.white)};
		background: ${({cancel}) => (cancel ? palette.silver_14 : palette.purple_2)};
	}
`

export const AddFileButton = styled.label<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.black};
	background: ${palette.silver_14};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	margin-bottom: 0;
	&:hover {
		background: ${palette.silver_1};
	}
`

export const ShowDetailsButton = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.black};
	background: ${palette.silver_14};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	margin-bottom: 0;
	&:hover {
		background: ${palette.silver_1};
	}
`

const FileInput = styled.input`
	display: none !important;
`

export const Popup = styled.div<any>`
	position: absolute;
	right: 0;
	top: ${({top}) => top};
	left: ${({left}) => left};
	z-index: 5;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

const LabelsSection = styled(Flexed)`
	position: relative;
`

const MemberSection = styled(Flexed)`
	position: relative;
`

const Input = styled.input<any>`
	width: 100%;
	padding: 6px 12px;
	border: none;
	border-radius: 3px;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
	box-sizing: border-box;
	outline: none;
	font-size: 14px;
	&:focus {
		box-shadow: inset 0 0 0 2px var(--ds-border-input, ${palette.purple_1});
	}
`

const ColorWrpper = styled(Flexed)<any>`
	border: none;
	padding: 0rem 0.5rem;
	border-radius: 0.25rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.black};
	background: ${({color}) => (color ? color : palette.silver_14)};
	font-weight: 500;
	cursor: pointer;
	line-height: 32px;
	position: relative;
	width: 3.23rem;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	&:hover {
		background: ${({onHoverColor}) => (onHoverColor ? onHoverColor : palette.color)};
	}
`

const CustomFlexed = styled(Flexed)<any>`
	padding: 0.1rem 0.3rem;
	border-radius: 0.2rem;
	cursor: pointer;
	background: ${({onHoverColor}) => (onHoverColor ? onHoverColor : palette.silver_3)};
	&:hover {
		background: ${({onHoverColor}) => (onHoverColor ? onHoverColor : palette.silver_2)};
	}
`

const ArchivedCardDiv = styled.div`
	background-color: var(--ds-background-warning, #fff7d6);
	background-image: linear-gradient(
		to bottom right,
		var(--ds-background-neutral, rgba(0, 0, 0, 0.05)) 25%,
		transparent 25%,
		transparent 50%,
		var(--ds-background-neutral, rgba(0, 0, 0, 0.05)) 50%,
		var(--ds-background-neutral, rgba(0, 0, 0, 0.05)) 75%,
		transparent 75%,
		transparent
	);
	background-size: 14px 14px;
	min-height: 32px;
	padding: 16px 16px 16px 56px;
	position: relative;
	border-radius: 12px 12px 0 0;
	overflow: hidden;
`
export default TrelloTaskModal
