import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {DocxType, DocxName, DocxDescription, DocxExpiryDate, DocxDoumentShow, DocxAction} from './DocumentsTable'
import {FaMinus} from 'react-icons/fa'
import {IoEye} from 'react-icons/io5'

const DocumentsTableList = ({data, onDelete}: any) => {
	return (
		<>
			<Main>
				<Wrapper>
					<Flexed direction="row" gap={1} align="center">
						<DocxType gap={0.2}>
							<Text>{data?.documentType}</Text>
						</DocxType>
						<DocxName direction="row">
							<Text>{data?.documentName}</Text>
						</DocxName>
						<DocxDescription direction="row">
							<Text>{data?.description}</Text>
						</DocxDescription>
						<DocxExpiryDate direction="row">
							<Text>{data?.expiryDate === 'Invalid date' ? 'DD/MM/YYYY' : data?.expiryDate}</Text>
						</DocxExpiryDate>

						<DocxAction direction="row" gap={1} align="center" justify="flex-end">
							<IoEye
								cursor="pointer"
								fontSize="1.2rem"
								onClick={() => {
									const imageUrl = process.env.REACT_APP_IMAGE_URL + data?.document
									window.open(imageUrl, '_blank')
								}}
							/>
							<Delete onClick={() => onDelete()}>
								<FaMinus />
							</Delete>
						</DocxAction>
					</Flexed>
				</Wrapper>
			</Main>
		</>
	)
}
const HeadWrapper = styled.div<any>`
	width: 38rem;
	background: #eef0f1;
	padding: 0.5rem;
	transition: all ease 0.25s;
	position: relative;
	${media.sm`
	width: 42rem;
	`}
	${media.md`
	width: 100%;
	`}
`

const Main = styled.div<any>`
	border: 1px solid #ddd;
`

const Wrapper = styled.div<any>`
	margin: 0 0 0.5rem 0;
	width: 38rem;
	padding: 0.5rem;
	border-radius: 0.39rem;
	transition: all ease 0.25s;
	position: relative;
	${media.sm`
	width: 42rem;
	`}
	${media.md`
	width: 100%;
	`}
`

const Delete = styled.div`
	font-size: 11px;
	font-weight: 400;
	border-radius: 4px;
	background: ${palette.green_1};
	padding: 1px 4px;
	width: fit-content;
	height: auto;
	position: relative;
	color: ${palette.white};
	text-align: center;
	cursor: pointer;
`

export default DocumentsTableList
