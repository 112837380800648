import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'
import LabelsList from './LabelsList'
import {IoIosArrowBack} from 'react-icons/io'
import {relative} from 'path'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'

const LabelsPop = ({top, left, right, onCloseModal, colorList, loadAgain, cardDetails}: any) => {
	const [createNewLabel, setCreateNewLabel] = useState(false)
	const [editLabel, setEditLabel] = useState(false)
	const [deleteLabel, setDeleteLabel] = useState(false)
	const [customTitle, setCustomTitle] = useState('')
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)

	const [colorView, setColorView] = useState<any>({
		color: colorList[0]?.color,
		title: colorList[0]?.title
	})
	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const addNewLabel = async (val = {}, isEdit = false) => {
		try {
			await api
				.post(`/trello/add_card_label`, {
					color: colorView,
					...val,
					name: customTitle,
					cardId: cardDetails?.id,
					adminId: _loginAdminDetails ? _loginAdminDetails?.id : undefined,
					employeeId: _loginUserDetails ? _loginUserDetails?.id : undefined,
					isEdit
				})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
						setCustomTitle('')
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const deleteCardLabel = async (id: any) => {
		try {
			await api
				.delete(`/trello/remove_card_label?id=${id}`)
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
						setCustomTitle('')
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	return (
		<>
			<Popup top={top} left={left} right={right} onClick={handleModalClick}>
				<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
					{createNewLabel || editLabel || deleteLabel ? (
						<CloseCover
							onClick={() => {
								setCreateNewLabel(false)
								setEditLabel(false)
								setDeleteLabel(false)
							}}>
							<IoIosArrowBack cursor="pointer" color="black" />
						</CloseCover>
					) : (
						<div></div>
					)}
					<Text fontWeight={600}>{createNewLabel ? 'Create label' : editLabel ? 'Edit label' : deleteLabel ? 'Delete label' : 'Labels'} </Text>
					<CloseCover onClick={onCloseModal}>
						<IoMdClose cursor="pointer" color="black" />
					</CloseCover>
				</Flexed>
				<Spacer height={0.5} />
				{!createNewLabel && !editLabel && !deleteLabel && (
					<>
						<Input placeholder="Search labels..." />
						<div>
							<Text type="xsmall" margin="1rem 0rem 0.3rem 0rem " color="black_3" fontWeight={700}>
								Labels
							</Text>
						</div>
						<Flexed align="center" gap={0.2} margin="0.5rem 0rem 1rem 0rem">
							{colorList?.map(
								(res: any, i: any) =>
									i <= 10 && (
										<LabelsList
											editLabel={() => {
												let name = cardDetails?.taskLabels?.find((e: any) => e?.color?.color == colorList[i]?.color)
												if (name) {
													setCustomTitle(name?.name)
												}
												setColorView(colorList[i])
												setEditLabel(true)
											}}
											color={colorList[i]}
											cardDetails={cardDetails}
											onAdd={(e: any) => addNewLabel(e)}
										/>
									)
							)}
						</Flexed>
						<Button onClick={() => setCreateNewLabel(true)}>Create a new label</Button>
					</>
				)}
				{(createNewLabel || editLabel) && (
					<>
						<ReviewWrapper>
							<ColorWrpper title={colorView?.title} color={colorView?.color}>
								{customTitle}
							</ColorWrpper>
						</ReviewWrapper>
						<Text type="xsmall" margin="0.5rem 0rem 0.3rem 0rem " color="black_3" fontWeight={700}>
							Title
						</Text>
						<Input placeholder="" value={customTitle} onChange={(e: any) => setCustomTitle(e.target.value)} />
						<div>
							<Text type="xsmall" margin="1rem 0rem 0.3rem 0rem " color="black_3" fontWeight={700}>
								Select a color
							</Text>
						</div>
						<Grid>
							{colorList?.map((val: any) => {
								return <ColorWrpper title={val?.title} color={val?.color} onHoverColor={val?.onHover} onClick={() => setColorView(val)} />
							})}
						</Grid>
						<Button
							onClick={() => {
								setCreateNewLabel(false)
								setEditLabel(false)
							}}>
							<IoMdClose cursor="pointer" color="black" /> Remove color
						</Button>
						<Divider margin="0.5rem 0rem" />
						<Flexed direction="row" justify="space-between">
							<CreateButton
								onClick={() => {
									setCreateNewLabel(false)
									setEditLabel(false)
									if (editLabel) {
										addNewLabel({}, true)
									} else {
										addNewLabel()
									}
								}}>
								{editLabel ? 'Save' : 'Create'}
							</CreateButton>
							{editLabel && (
								<DeleteButton
									onClick={() => {
										setCreateNewLabel(false)
										setEditLabel(false)
										setDeleteLabel(true)
									}}>
									Delete
								</DeleteButton>
							)}
						</Flexed>
					</>
				)}
				{deleteLabel && (
					<>
						<Text type="xsmall" margin="0.5rem 0rem" fontWeight={600}>
							This will remove this label from all cards. There is no undo.
						</Text>
						<DeleteButton
							width="100%"
							onClick={() => {
								let colors = cardDetails?.taskLabels?.find((e: any) => e?.color?.color == colorView?.color)
								if (colors?.id) {
									deleteCardLabel(colors?.id)
								}
								setCreateNewLabel(false)
								setEditLabel(false)
								setDeleteLabel(false)
							}}>
							Delete
						</DeleteButton>
					</>
				)}
			</Popup>
		</>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({top}) => top};
	z-index: 5;
	left: ${({left}) => (left ? left : '0')};
	right: ${({right}) => (right ? right : '0')};
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

const Input = styled.input<any>`
	width: 100%;
	padding: 6px 12px;
	border: none;
	border-radius: 3px;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
	box-sizing: border-box;
	outline: none;
	font-size: 14px;
	&:focus {
		box-shadow: inset 0 0 0 2px var(--ds-border-input, ${palette.purple_1});
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.black};
	background: ${palette.silver_14};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	&:hover {
		background: ${palette.silver_1};
	}
`

const CreateButton = styled(Button)<any>`
	width: fit-content;
	color: ${palette.white};
	background: ${palette.purple_1};
	&:hover {
		background: ${palette.purple_2};
	}
`

const DeleteButton = styled(Button)<any>`
	width: ${({width}) => (width ? width : 'fit-content')};
	color: ${palette.white};
	background: ${palette.red};
	&:hover {
		background: ${palette.red_hover};
	}
`

const ReviewWrapper = styled.div`
	padding: 1rem;
	background: ${palette.silver_14};
`

export const ColorWrpper = styled(Flexed)<any>`
	border: none;
	padding: ${({padding}) => (padding ? padding : '0rem 0.5rem')};
	border-radius: 0.25rem;
	font-size: 0.875rem;
	height: ${({height}) => (height ? height : '2rem')};
	color: ${palette.black};
	background: ${({color}) => (color ? color : palette.silver_14)};
	font-weight: 500;
	cursor: pointer;
	line-height: 32px;
	position: relative;
	width: 100%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	&:hover {
		background: ${({onHoverColor}) => (onHoverColor ? onHoverColor : palette.color)};
	}
`

export const Grid = styled.div`
	display: grid;
	gap: 0.3rem;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	margin: 0.5rem 0rem;
`

export default LabelsPop
