import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { Divider, Flexed, Spacer, Text } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { Checked, CheckIconWrapper, UnChecked } from '../../pages/Auth/SignUp'
import { IoClose } from "react-icons/io5";
import InputField from '../common/InputField'
import DeductionsTableList from './DeductionsTableList'
import AddDeductionModal from '../modals/AddDeductionModal'

const DeductionsTable = ({baseSalary,setBaseSalaryError,deductionList, setDeductionList,taxableDeductionCheck,editDeductionAmount,removeDataFromDeduction}:any) => {
    const [openAddModal,setOpenAddModal] = useState(false)
    return (<>
        <HeadWrapper>
            <Flexed direction="row" gap={1} align="center">
                <DeductionsName
                    gap={0.2}
                >
                    <Text type='small' fontWeight={400} color='black_1'>
                        Name
                    </Text>
                </DeductionsName>
                <DeductionsPercentage gap={0.1}>
                    <Text type='small' fontWeight={400} color='black_1'>
                        Percentage
                    </Text>
                </DeductionsPercentage>
                <DeductionsAmount gap={0.2}>
                    <Text type="small" color='black_1'>
                        Amount
                    </Text>
                </DeductionsAmount>
                {/* <DeductionsStartingBalance gap={0.2}>
                    <Text type="small" color='black_1'>
                    Starting Balance
                    </Text>
                </DeductionsStartingBalance> */}
                <DeductionsTaxable direction="row" gap={1}>
                    <Text type='small' fontWeight={400} color='black_1'>
                        Taxable
                    </Text>
                </DeductionsTaxable>
                <DeductionsAction direction="row" gap={0.5} align="center" justify="flex-end">
                    <Add onClick={()=>{setOpenAddModal(true)}}>
                        Add
                    </Add>
                </DeductionsAction>
            </Flexed>
        </HeadWrapper>
        {deductionList?.map((data:any) => {
        return <DeductionsTableList baseSalary={baseSalary} data={data} editDeductionAmount={(amount:any)=>{editDeductionAmount(amount,data?.deductionCategory)}} taxableDeductionCheck={(taxable:any)=>taxableDeductionCheck(taxable,data?.deductionCategory)} onRemove={()=>removeDataFromDeduction(data?.deductionCategory)} />
    })}
        {openAddModal && <AddDeductionModal deductionList={deductionList} setDeductionList={setDeductionList} onCloseModal={()=>{setOpenAddModal(false)}}/>}
    </>
    )
}
const HeadWrapper = styled.div<any>`
	width: 38rem;
	background: #eef0f1;
	padding: 0.5rem;
	transition: all ease 0.25s;
	position: relative;
	${media.sm`
	width: 42rem;
	`}
	${media.md`
	width: 100%;
	`}
`

export const DeductionsName = styled(Flexed)`
	width: 15%;
`

export const DeductionsPercentage = styled(Flexed)`
    width: 30%;
    & #inputWrapper{
        width: 100%;
    }
    & input{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        line-height: 1.5rem !important;
    }
`

export const DeductionsAmount = styled(Flexed)`
	width: 30%;
    & #inputWrapper{
        width: 100%;
    }
    & input{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        line-height: 1.5rem !important;
    }
`

export const  DeductionsStartingBalance = styled(Flexed)`
	width: 30%;
    & #inputWrapper{
        width: 100%;
    }
    & input{
        line-height: 1.5rem !important;
    }
`

export const DeductionsTaxable = styled(Flexed)`
	width: 15%;
`

export const DeductionsAction = styled(Flexed)`
	position: relative;
	width: 10%;
`

const Add = styled(Text)`
    font-size: 11px;
    font-weight: 400;
    border-radius: 4px;
    background: ${palette.green_1};
    padding: 1px 4px;
    width: 50px;
    height: auto;
    position: relative;
    color: ${palette.white};
    text-align: center;
    cursor: pointer;
`

export default DeductionsTable
