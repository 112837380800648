import React, { useState } from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import { Flexed, Text, Divider, Spacer } from '../../styled/shared';
import { palette } from '../../styled/colors'
import { IoMdClose } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";

const EmojiPop = ({ onCloseModal }: any) => {
    const [search, setSearch] = useState('');
    const [openUploadEmojiView, setOpenUploadEmojiView] = useState(false);
    const [emoji, setEmoji] = useState('');
    const [emojiName, setEmojiName] = useState('');

    const handleModalClick = (event: any) => {
        event.stopPropagation();
    };

    const handleCapture = (event: any) => {
        const _files = event.target.files;
        let _fileName: any = ''
        if (_files && _files.length > 0) {
            let _file = event.target.files?.[0]
            if (_file) {
                _fileName = _file?.name
            }
            const _reader: any = new FileReader()
            _reader.readAsDataURL(event.target.files[0])
            _reader.onload = () => {
                if (_reader.readyState === 2) {
                    setEmoji(_reader?.result)
                    setEmojiName(_fileName.split('.')[0])
                }
            }
        }
    }
    return (
        <Popup top='2.3rem' onClick={handleModalClick}>
            <Flexed direction='row' margin='0rem 0.5rem' align='center' justify='space-between' gap={0.2}>
                {openUploadEmojiView ? <CloseCover onClick={() => setOpenUploadEmojiView(false)}>
                    <IoIosArrowBack cursor='pointer' />
                </CloseCover> : <div></div>}
                <Text fontWeight={600}>{openUploadEmojiView ? 'Upload emoji' : 'Emoji'}</Text>
                <CloseCover onClick={onCloseModal}>
                    <IoMdClose cursor='pointer' />
                </CloseCover>
            </Flexed>
            {!openUploadEmojiView ? <Flexed margin='0.5rem 0rem 0rem 0rem'>
                <div>
                    <Input placeholder='Search for emoji...' value={search} onChange={(e: any) => { setSearch(e?.target?.value) }} />
                </div>
                <Spacer height={0.5} />
                <List direction='row' align='center' gap={0.5}>
                    <img src='./icons/thumbsup.png' alt='thumbsup' />
                    <Text type='small' fontWeight={500}>thumbsup</Text>
                </List>
                <List direction='row' align='center' gap={0.5}>
                    <img src='./icons/smile.png' alt='smile' />
                    <Text type='small' fontWeight={500}>smile</Text>
                </List>
                <List direction='row' align='center' gap={0.5}>
                    <img src='./icons/warning.png' alt='warning' />
                    <Text type='small' fontWeight={500}>warning</Text>
                </List>
                <List direction='row' align='center' gap={0.5}>
                    <img src='./icons/sunglasses.png' alt='sunglasses' />
                    <Text type='small' fontWeight={500}>sunglasses</Text>
                </List>
                <List direction='row' align='center' gap={0.5}>
                    <img src='./icons/check_box.png' alt='check_box' />
                    <Text type='small' fontWeight={500}>ballot_box_with_check</Text>
                </List>
                <List direction='row' align='center' gap={0.5}>
                    <img src='./icons/facepalm.png' alt='facepalm' />
                    <Text type='small' fontWeight={500}>facepalm</Text>
                </List>
                <Divider margin='0.5rem 0rem' />
                <List onClick={() => setOpenUploadEmojiView(true)}>
                    <Text type='small' margin='0.2rem 0rem' fontWeight={600}>Upload emoji</Text>
                </List>
            </Flexed>
                :
                <Flexed margin='0.5rem 0rem 0rem 0rem' gap={0.5}>
                    <Text type='xsmall' fontWeight={600}>Upload image</Text>
                    <input type='file' accept="image/*"
                        onChange={(e: any) => {
                            handleCapture(e)
                        }} />
                    <div>
                        <Text type='xsmall' margin='0rem 0rem 0.2rem 0rem' fontWeight={600}>Emoji name</Text>
                        <Input placeholder='' value={emojiName} onChange={(e: any) => setEmojiName(e.target.value)} />
                    </div>
                    <Text type='xsmall' fontWeight={500}>The name will default to the name of the uploaded file. You can change it here, though.</Text>
                    <Text type='xsmall' fontWeight={800} color='black'>Preview</Text>
                    <Flexed direction='row' align='center' gap={0.2}>
                        <EmojiName type='xsmall' fontWeight={600} >:{emojiName ? emojiName : '???'}:</EmojiName>
                        <FaArrowRightLong />
                        <div>
                            <Box>
                                {emoji && <img src={emoji} alt='img' />}
                            </Box>
                        </div>
                    </Flexed>

                    <Button active={emoji && emojiName}>Create custom emoji</Button>
                </Flexed>
            }

        </Popup>
    )
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({ top }) => top};
	z-index:5;
	right:0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding:0.5rem 0.7rem;
	width: 300px;
`

const Input = styled.input <any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
    outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
`

const List = styled(Flexed)`
	width: 100%;
	padding: 0.4rem 0.313rem;
    border-radius: 0.3rem;
	background: ${({ active }) => active ? palette.silver_1 : palette.white};
	position: relative;
	cursor:pointer;
	&:hover {
		background: ${palette.silver_1};
	}
    & img{
        width: 1.125rem;
        height: 1.125rem;
    }
`

const Button = styled.div <any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({ active }) => active ? palette.white : palette.silver_8};
	background: ${({ active }) => active ? palette.purple_1 : palette.silver_14};
	font-weight: 600;
	cursor: ${({ active }) => active ? 'pointer' : 'no-drop'};
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
    &:hover {
		background: ${({ active }) => active ? palette.purple_2 : ''};
	}
`

const Box = styled.div`
    height: 20px;
    width: 20px;
    border: 1px solid ${palette.black};
    overflow: hidden;
    display: flex;
    & img{
        height: 20px;
        width: 20px;
    }
`;

const EmojiName = styled(Text)`
    word-break: break-all;
`;

export default EmojiPop
