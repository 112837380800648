import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {borderRadius, grid} from './constants'
import {ImAttachment} from 'react-icons/im'
import {HiOutlineBars3BottomLeft} from 'react-icons/hi2'
import {Flexed, Text} from '../../styled/shared'
import {VscEdit} from 'react-icons/vsc'
import TrelloTaskModal, {AddLable} from '../../components/modals/TrelloTaskModal'
import {FaRegUser} from 'react-icons/fa6'
import {HiMiniWindow} from 'react-icons/hi2'
import {BiComment, BiLabel} from 'react-icons/bi'
import {FaRegWindowMaximize} from 'react-icons/fa'
import {FaRegClock} from 'react-icons/fa'
import {IoMdAdd, IoMdArrowForward, IoMdCheckboxOutline} from 'react-icons/io'
import {MdContentCopy} from 'react-icons/md'
import {RiArchiveLine} from 'react-icons/ri'
import MoveCard from '../../components/trelloTaskModalComponents/MoveCard'
import CopyCard from '../../components/trelloTaskModalComponents/CopyCard'
import DatePop from '../../components/trelloTaskModalComponents/DatePop'
import AddMemberPop from '../../components/trelloTaskModalComponents/AddMemberPop'
import {useSelector} from 'react-redux'
import {colorsList, getInitials} from '../../utils'
import {useDispatch} from 'react-redux'
import {updateBoardList} from '../../actions/authActions'
import LabelsPop, {ColorWrpper, Grid} from '../../components/trelloTaskModalComponents/LabelsPop'
import {DueDateLink} from '../../components/trelloTaskModalComponents/CheckBoxList'
import moment from 'moment'
import {IoLocation} from 'react-icons/io5'
import {ProfileImg} from '../../pages/Team'
import {LuEye} from 'react-icons/lu'
import TrelloMemberProfile from '../../components/modals/UserProfileModal'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'

const getBackgroundColor = (isDragging: any, isGroupedOver: any, authorColors: any) => {
	if (isDragging) {
		return authorColors.soft
	}
	if (isGroupedOver) {
		return '#EBECF0'
	}

	return '#FFFFFF'
}

const getBorderColor = (isDragging: any, authorColors: any) => (isDragging ? authorColors.hard : 'transparent')

const imageSize = 40

function getStyle(provided: any, style: any) {
	if (!style) {
		return provided.draggableProps.style
	}

	return {
		...provided.draggableProps.style,
		...style
	}
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function QuoteItem(props: any) {
	const [openTaskModal, setOpenTaskModal] = useState(false)
	const {quote, isDragging, isGroupedOver, provided, style, isClone, index, listIndex} = props
	const _moveCardPopRef: any = useRef(null)
	const _copyCardPopRef: any = useRef(null)
	const _datePopRef: any = useRef(null)
	const _memberPopRef: any = useRef(null)
	const _addLabelsPopRef: any = useRef(null)
	const [modalData, setModalData] = useState('')
	const [openMemberPop, setOpenMemberPop] = useState(false)
	const [openDatePop, setOpenDatePop] = useState(false)
	const [openEditPanal, setOpenEditPanal] = useState(false)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [openUserProfile, setOpenUserProfile] = useState(false)
	const [userProfile, setUserProfile] = useState<any>('')
	const [memberDetails, setMemberDetails] = useState<any>('')
	const [boardMembers, setBoardMembers] = useState<any>([])
	const [cardMembers, setCardMembers] = useState<any>([])
	const [openMemberPopup, setOpenMemberPopup] = useState(false)
	const [openAddLabelsPop, setOpenAddLabelsPop] = useState(false)
	const [cardDetails, setCardDetails] = useState<any>('')
	const [colorList, setColorList] = useState([
		{color: '#baf3db', onHover: '#7ee2b8', title: 'subtle green'},
		{color: '#f8e6a0', onHover: '#f5cd47', title: 'subtle yellow'},
		{color: '#fedec8', onHover: '#fec195', title: 'subtle orange'},
		{color: '#ffd5d2', onHover: '#fd9891', title: 'subtle red'},
		{color: '#dfd8fd', onHover: '#b8acf6', title: 'subtle purple'},
		{color: '#4bce97', onHover: '#7ee2b8', title: 'green'},
		{color: '#f5cd47', onHover: '#e2b203', title: 'yellow'},
		{color: '#fea362', onHover: '#fec195', title: 'orange'},
		{color: '#f87168', onHover: '#fd9891', title: 'red'},
		{color: '#9f8fef', onHover: '#b8acf6', title: 'purple'},
		{color: '#1f845a', onHover: '#216e4e', title: 'bold green'},
		{color: '#946f00', onHover: '#7f5f01', title: 'bold yellow'},
		{color: '#c25100', onHover: '#a54800', title: 'bold orange'},
		{color: '#c9372c', onHover: '#ae2e24', title: 'bold red'},
		{color: '#6e5dc6', onHover: '#5e4db2', title: 'bold purple'},
		{color: '#cce0ff', onHover: '#85b8ff', title: 'subtle blue'},
		{color: '#c6edfb', onHover: '#9dd9ee', title: 'subtle sky'},
		{color: '#d3f1a7', onHover: '#b3df72', title: 'subtle lime'},
		{color: '#fdd0ec', onHover: '#f797d2', title: 'subtle pink'},
		{color: '#dcdfe4', onHover: '#b3b9c4', title: 'subtle black'},
		{color: '#579dff', onHover: '#85b8ff', title: 'blue'},
		{color: '#6cc3e0', onHover: '#9dd9ee', title: 'sky'},
		{color: '#94c748', onHover: '#b3df72', title: 'lime'},
		{color: '#e774bb', onHover: '#f797d2', title: 'pink'},
		{color: '#8590a2', onHover: '#b3b9c4', title: 'black'},
		{color: '#0c66e4', onHover: '#0055cc', title: 'bold blue'},
		{color: '#227d9b', onHover: '#206a83', title: 'bold sky'},
		{color: '#5b7f24', onHover: '#4c6b1f', title: 'bold lime'},
		{color: '#ae4787', onHover: '#943d73', title: 'bold pink'},
		{color: '#626f86', onHover: '#44546f', title: 'bold black'}
	])

	// console.log('=====listIndex', props?.quote?.listData?.taskListCards)

	const dispatch = useDispatch()

	const [openMoveCardPop, setOpenMoveCardPop] = useState(false)
	const [openCopyCardPop, setOpenCopyCardPop] = useState(false)

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_memberPopRef?.current && !_memberPopRef?.current?.contains(event.target)) {
				setOpenMemberPop(false)
			}
			if (_datePopRef?.current && !_datePopRef?.current?.contains(event.target)) {
				setOpenDatePop(false)
			}
			if (_moveCardPopRef?.current && !_moveCardPopRef?.current?.contains(event.target)) {
				setOpenMoveCardPop(false)
			}
			if (_copyCardPopRef?.current && !_copyCardPopRef?.current?.contains(event.target)) {
				setOpenCopyCardPop(false)
			}
			// if (_addLabelsPopRef?.current && !_addLabelsPopRef?.current?.contains(event.target)) {
			// 	setOpenAddLabelsPop(false)
			// }
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		let _query: any = document.querySelector(`#scrollContainer${listIndex}`)
		if (openEditPanal) {
			_query.style.overflowX = 'visible'
			_query.style.overflowY = 'visible'
		} else {
			_query.style.overflowX = 'hidden'
			_query.style.overflowY = 'auto'
		}
	}, [openEditPanal])

	const countChecklist = quote?.taskChecklists?.filter((e: any) => e?.parentId)
	const countCheckedlist = quote?.taskChecklists?.filter((e: any) => e?.parentId && e?.isCheck)

	useEffect(() => {
		if (openMemberPop) {
			allMembers()
		}
	}, [openMemberPop])

	const allMembers = async () => {
		await api
			.post('/trello/get_members', {boardId: quote?.boardId, cardId: quote?.card_id})
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						const employeeData = response?.data?.data
						setBoardMembers(employeeData?.boardMembers)
						setCardMembers(employeeData?.cardMembers)
						setOpenMemberPopup(true)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const getCardDetails = async (loading = true) => {
		try {
			await api
				.get(`/trello/get_card?id=${quote?.card_id}`)
				.then((response) => {
					if (response?.data?.success == true) {
						setCardDetails(response?.data?.data)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	let cardMembersData = cardMembers && openMemberPopup ? cardMembers : quote?.taskCardMembers
	let cardLabels = cardDetails ? cardDetails : quote

	const updateCardDetails = async (isArchive = false) => {
		try {
			await api
				.put(`/trello/update_card/${quote?.card_id}`, {isArchive})
				.then((response) => {
					if (response?.data?.success == true) {
						dispatch(updateBoardList(true))
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	let check = props?.quote?.listData?.taskListCards?.filter(
		(res: any) => res?.location || res?.taskCardMembers?.length || res?.taskChecklists?.length || res?.taskLabels?.length || res?.taskListCardAttachments?.length || res?.taskListCardcomments?.length
	)

	return (
		<Wrapper>
			{openEditPanal && (
				<Overlay
					onClick={() => {
						setOpenEditPanal(false)
					}}
				/>
			)}
			{!openEditPanal && (
				<EditCover
					align="center"
					justify="center"
					onClick={() => {
						setOpenEditPanal(true)
					}}>
					<VscEdit />
				</EditCover>
			)}
			{/* {openEditPanal && (
				<Overlay
					onClick={() => {
						setOpenEditPanal(false)
					}}
				/>
			)}
			{!openEditPanal && (
				<EditCover
					align="center"
					justify="center"
					onClick={() => {
						setOpenEditPanal(true)
					}}>
					<VscEdit />
				</EditCover>
			)} */}
			<Container
				openEditPanal={openEditPanal}
				onClick={() => {
					setModalData(quote)
					// setOpenTaskModal(true)
				}}
				// href={quote.author.url}
				isDragging={isDragging}
				isGroupedOver={isGroupedOver}
				isClone={isClone}
				colors={quote?.author?.colors}
				ref={provided?.innerRef}
				{...provided?.draggableProps}
				{...provided?.dragHandleProps}
				style={getStyle(provided, style)}
				data-is-dragging={isDragging}
				data-testid={quote.id}
				data-index={index}
				aria-label={`${quote?.author?.name} quote ${quote?.content}`}>
				{quote?.coverPhoto?.url ? (
					<CoverImage
						onClick={() => {
							setModalData(quote)
							setOpenTaskModal(true)
						}}
						src={process.env.REACT_APP_IMAGE_URL + quote?.coverPhoto?.url}
						alt="img"
					/>
				) : (
					''
				)}
				{isClone ? <CloneBadge>Clone</CloneBadge> : null}
				<Content>
					<Grid
						onClick={() => {
							setModalData(quote)
							setOpenTaskModal(true)
						}}>
						{cardLabels?.taskLabels?.map((res: any) => (
							<ColorWrpper title={res?.color?.title} color={res?.color?.color} onHoverColor={res?.color?.onHover} padding={'0rem 0.1rem'}>
								{res?.name}
							</ColorWrpper>
						))}
					</Grid>
					<Heading
						// contentEditable={openEditPanal}
						onClick={() => {
							setModalData(quote)
							setOpenTaskModal(true)
						}}>
						{quote.content}
					</Heading>
					<Footer>
						<Flexed
							direction="row"
							align={'center'}
							flexWrap="wrap"
							gap={0.5}
							onClick={() => {
								setModalData(quote)
								setOpenTaskModal(true)
							}}>
							{cardLabels?.dueDate && (
								<DueDateLink
									direction="row"
									gap={0.2}
									align="center"
									justify="center"
									small={true}
									dueDate={cardLabels?.dueDate}
									isSameDay={moment().isSame(cardLabels?.dueDate, 'day')}
									isComplete={cardLabels?.isCompleted}
									style={{width: '5rem', height: '1.5rem'}}>
									<FaRegClock fontSize={14} />
									<div style={{fontSize: '0.7rem'}}> {moment(cardLabels?.dueDate).format('DD MMM')}</div>
								</DueDateLink>
							)}
							{cardLabels?.description ? <HiOutlineBars3BottomLeft cursor="pointer" fontSize={12} /> : ''}
							{cardLabels?.taskListCardAttachments?.length ? (
								<Flexed direction="row" align={'center'} gap={0.1}>
									{' '}
									<ImAttachment cursor="pointer" fontSize={12} />
									{cardLabels?.taskListCardAttachments?.length}
								</Flexed>
							) : (
								''
							)}
							{cardLabels?.taskCardWatches?.some((e: any) => (e?.employeeId ? e?.employeeId == _loginUserDetails?.id && e?.isWatch : e?.adminId == _loginAdminDetails?.id && e?.isWatch)) ? <LuEye /> : ''}
							{cardLabels?.taskListCardcomments?.length ? (
								<Flexed direction="row" align={'center'} gap={0.1}>
									<BiComment />
									{cardLabels?.taskListCardcomments?.length}
								</Flexed>
							) : (
								''
							)}

							{cardLabels?.location ? (
								<Flexed direction="row" align={'center'} gap={0.1}>
									<IoLocation />
								</Flexed>
							) : (
								''
							)}
							{countChecklist?.length ? (
								<Flexed
									direction="row"
									align={'center'}
									gap={0.1}
									style={{
										background: countCheckedlist?.length == countChecklist?.length ? palette.greenDark : '',
										color: countCheckedlist?.length == countChecklist?.length ? palette.white : '',
										padding: '0.2rem',
										borderRadius: '0.2rem'
									}}>
									<IoMdCheckboxOutline />
									<Text fontSize={'small'} style={{color: countCheckedlist?.length == countChecklist?.length ? palette.white : ''}}>
										{countCheckedlist?.length}/{countChecklist?.length}
									</Text>
								</Flexed>
							) : (
								''
							)}
						</Flexed>
						<Flexed direction="row" align={'center'} flexWrap="wrap" gap={0.2}>
							{cardMembersData?.map((e: any) =>
								e?.employee ? (
									<>
										{e?.employee?.image ? (
											<ProfileImg
												style={{width: '1.7rem', height: '1.7rem'}}
												onClick={() => {
													setMemberDetails(e)
													setOpenUserProfile(true)
													setUserProfile(e?.employee)
												}}>
												<img style={{width: '1.7rem', height: '1.7rem'}} src={process.env.REACT_APP_IMAGE_URL + e?.employee?.image} alt={e?.employee?.firstName + ' ' + e?.employee?.lastName} />
											</ProfileImg>
										) : (
											<TagUser
												width="1.7rem"
												height="1.7rem"
												onClick={() => {
													setMemberDetails(e)
													setOpenUserProfile(true)
													setUserProfile(e?.employee)
												}}>
												{e?.employee?.firstName + ' ' + e?.employee?.lastName && getInitials(e?.employee?.firstName + ' ' + e?.employee?.lastName)}
											</TagUser>
										)}
									</>
								) : e?.adminId ? (
									<>
										{e?.admin?.logo ? (
											<ProfileImg
												style={{width: '1.7rem', height: '1.7rem'}}
												onClick={() => {
													setMemberDetails(e)
													setOpenUserProfile(true)
													setUserProfile(e?.admin)
												}}>
												<img style={{width: '1.7rem', height: '1.7rem'}} src={process.env.REACT_APP_IMAGE_URL + e?.admin?.logo} alt={e?.admin?.name} />
											</ProfileImg>
										) : (
											<TagUser
												width="1.7rem"
												height="1.7rem"
												onClick={() => {
													setMemberDetails(e)
													setOpenUserProfile(true)
													setUserProfile(e?.admin)
												}}>
												{e?.admin?.name && getInitials(e?.admin?.name)}
											</TagUser>
										)}
									</>
								) : (
									''
								)
							)}
						</Flexed>

						{openUserProfile && (
							<TrelloMemberProfile
								onCloseModal={() => setOpenUserProfile(false)}
								profileImageUrl={userProfile?.logo ? process.env.REACT_APP_IMAGE_URL + userProfile?.logo : userProfile?.image ? process.env.REACT_APP_IMAGE_URL + userProfile?.image : ''}
								name={userProfile?.name ? userProfile?.name : userProfile?.firstName ? userProfile?.firstName + ' ' + userProfile?.lastName : ''}
								memberDetails={memberDetails}
								// isCard={true}
							/>
						)}
					</Footer>
				</Content>
			</Container>
			{openTaskModal && (
				<TrelloTaskModal
					quote={modalData}
					onCloseModal={(isLabelUpdated = false) => {
						if (isLabelUpdated) {
							getCardDetails()
							allMembers()
						}

						setOpenTaskModal(false)
					}}
				/>
			)}
			{openEditPanal && (
				<EditPanalContent totalCards={props?.quote?.position >= 5}>
					<Contentsidebar
						justify="flex-start"
						align="center"
						gap={0.5}
						direction="row"
						onClick={() => {
							setModalData(quote)
							setOpenTaskModal(true)
						}}>
						<HiMiniWindow />
						<Text fontWeight={600}>Open card</Text>
					</Contentsidebar>
					<Contentsidebar justify="flex-start" align="center" gap={0.5} direction="row">
						<Flexed
							direction="row"
							gap={0.3}
							ref={_addLabelsPopRef}
							onClick={() => {
								setOpenAddLabelsPop(true)
							}}>
							<BiLabel />
							<Text fontWeight={600}>Edit labels</Text>
							{openAddLabelsPop && (
								<LabelsPop
									colorList={colorList}
									top="60px"
									left="0"
									right="auto"
									onCloseModal={() => setOpenAddLabelsPop(false)}
									loadAgain={() => {
										getCardDetails()
									}}
									cardDetails={cardDetails ? cardDetails : quote}
								/>
							)}
						</Flexed>
					</Contentsidebar>
					<Contentsidebar ref={_memberPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenMemberPop(!openMemberPop)}>
						<FaRegUser />
						<Text fontWeight={600}>Change members</Text>
						{openMemberPop && <AddMemberPop top="40px" left="auto" onCloseModal={() => setOpenMemberPop(false)} loadAgain={allMembers} cardDetails={cardLabels} boardMembers={boardMembers} employees={cardMembers} />}
					</Contentsidebar>
					<Contentsidebar justify="flex-start" align="center" gap={0.5} direction="row">
						<FaRegWindowMaximize />
						<Text fontWeight={600}>Change cover</Text>
					</Contentsidebar>
					<Contentsidebar ref={_datePopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenDatePop(!openDatePop)}>
						<FaRegClock />
						<Text fontWeight={600}>Edit dates</Text>
						{openDatePop && <DatePop mainDateModal={true} bottom="-25rem" loadAgain={getCardDetails} onCloseModal={() => setOpenDatePop(false)} isCard={true} cardDetails={cardLabels} />}
					</Contentsidebar>
					<Contentsidebar ref={_moveCardPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenMoveCardPop(!openMoveCardPop)}>
						<IoMdArrowForward />
						<Text fontWeight={600}>Move</Text>
						{openMoveCardPop && <MoveCard onCloseModal={() => setOpenMoveCardPop(false)} cardDetails={cardLabels} />}
					</Contentsidebar>
					<Contentsidebar ref={_copyCardPopRef} justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => setOpenCopyCardPop(!openCopyCardPop)}>
						<MdContentCopy />
						<Text fontWeight={600}>Copy</Text>
						{openCopyCardPop && <MoveCard onCloseModal={() => setOpenCopyCardPop(false)} cardDetails={cardLabels} isCopy={true} />}
					</Contentsidebar>
					<Contentsidebar justify="flex-start" align="center" gap={0.5} direction="row" onClick={() => updateCardDetails(true)}>
						<RiArchiveLine />
						<Text fontWeight={600}>Archive</Text>
					</Contentsidebar>
				</EditPanalContent>
			)}
			{openEditPanal && (
				<SaveButtonContent>
					<Button>Save</Button>
				</SaveButtonContent>
			)}
		</Wrapper>
	)
}
const EditCover = styled(Flexed)`
	display: none;
	width: 2rem;
	height: 2rem;
	background: ${palette.white};
	position: absolute;
	border-radius: 100%;
	top: 0.1rem;
	right: 0.2rem;
	cursor: pointer;
	z-index: 2;
`

const Wrapper = styled.div`
	position: relative;
	&:hover ${EditCover} {
		display: flex;
	}
`

const Overlay = styled.div`
	position: fixed;
	background: #00000099;
	/* width: 100%;
	height: 100%; */
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 7;
	overflow: scroll;
`

const CloneBadge = styled.div`
	background: #79f2c0;
	bottom: ${grid / 2}px;
	border: 2px solid #57d9a3;
	border-radius: 50%;
	box-sizing: border-box;
	font-size: 10px;
	position: absolute;
	right: -${imageSize / 3}px;
	top: -${imageSize / 3}px;
	transform: rotate(40deg);
	height: ${imageSize}px;
	width: ${imageSize}px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Container = styled.div<any>`
	display: block;
	position: relative;
	border-radius: ${borderRadius}px;
	border: 2px solid transparent;
	border-color: ${(props) => getBorderColor(props.isDragging, props.colors)};
	background-color: ${(props) => getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)};
	box-shadow: ${({isDragging}) => (isDragging ? `2px 2px 1px #A5ADBA` : 'none')};
	z-index: ${({openEditPanal}) => (openEditPanal ? '8' : '')};
	box-sizing: border-box;
	min-height: ${imageSize}px;
	margin-bottom: ${grid}px;
	user-select: none;
	overflow: hidden;
	color: #091e42;
	box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
	&:hover,
	&:active {
		color: #091e42;
		text-decoration: none;
		border: 2px solid ${palette.purple_1};
	}
	&:focus {
		outline: none;
		border-color: ${(props) => props.colors.hard};
		box-shadow: none;
	}
`

const CoverImage = styled.img`
	width: 100%;
	height: 7rem;
	margin-right: ${grid}px;
	flex-shrink: 0;
	flex-grow: 0;
	object-fit: cover;
`

const Content = styled.div`
	/* flex child */
	flex-grow: 1;
	/*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
	flex-basis: 100%;
	/* flex parent */
	display: flex;
	flex-direction: column;
	padding: ${grid}px;
`

const Heading = styled.div`
	font-size: 14px;
`

const Footer = styled.div`
	display: flex;
	margin-top: ${grid}px;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
`

const Author = styled.small<any>`
	color: ${(props) => props.colors.hard};
	flex-grow: 0;
	margin: 0;
	background-color: ${(props) => props.colors.soft};
	border-radius: ${borderRadius}px;
	font-weight: normal;
	padding: ${grid / 2}px;
`

const QuoteId = styled.small`
	flex-grow: 1;
	flex-shrink: 1;
	margin: 0;
	font-weight: normal;
	text-overflow: ellipsis;
	text-align: right;
`

const EditPanalContent = styled.div<any>`
	position: absolute;
	top: ${({totalCards}) => (totalCards ? 'auto' : 0)};
	right: -10rem;
	bottom: ${({totalCards}) => (totalCards ? '5rem' : 'auto')};
	z-index: 8;
`

const SaveButtonContent = styled.div`
	position: absolute;
	bottom: -2.5rem;
	left: 0rem;
	z-index: 7;
`

const TagUser = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 100%;
	font-size: 0.75rem;
	font-weight: 600;
	color: ${palette.white};
	background-color: ${palette.purple_1};
`

const Contentsidebar = styled(Flexed)`
	border-radius: 3px;
	width: fit-content;
	padding: 0rem 0.6rem;
	background-color: ${palette.white};
	min-height: 32px;
	position: relative;
	padding: 5px;
	margin-top: 0.5rem;
	cursor: pointer;
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.purple_1};
	font-weight: 600;
	cursor: pointer;
	width: 5.5rem;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
`

export default React.memo(QuoteItem)
