import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoIosArrowBack} from 'react-icons/io'
import {IoMdClose} from 'react-icons/io'

const CopyList = ({goBack, onCloseModal, onAdd}: any) => {
	const [listName, setListName] = useState('')
	return (
		<>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				<CloseCover onClick={goBack}>
					<IoIosArrowBack cursor="pointer" />
				</CloseCover>
				<Text fontWeight={600}>Copy list</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			<Flexed margin="0.5rem 0.8rem" gap={0.5}>
				<div>
					<Text type="xsmall" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
						Name
					</Text>
					<Input
						placeholder=""
						type="textarea"
						rows={2}
						value={listName}
						onChange={(e: any) => {
							setListName(e?.target?.value)
						}}
					/>
				</div>
				<Button onClick={() => listName?.trim()?.length > 0 && onAdd(listName)}>Create list</Button>
			</Flexed>
		</>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Input = styled.textarea<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
	outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.purple_1};
	font-weight: 600;
	cursor: pointer;
	width: fit-content;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
`

export default CopyList
