import {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {MDBDataTableV5} from 'mdbreact'
import {Flexed, Loading, Text} from '../styled/shared'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../styled/colors'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import {LiaBarcodeSolid} from 'react-icons/lia'
import {IoEye} from 'react-icons/io5'
import {Checked, CheckIconWrapper, UnChecked} from './Auth/SignUp'
import QRAScanModal from '../components/modals/QRAScanModal'
import EmployeeDetails, {Back} from '../components/employee/EmployeeDetails'
import {TiArrowSortedDown} from 'react-icons/ti'
import {HiDotsHorizontal} from 'react-icons/hi'
import DeleteEmployeeModal from '../components/modals/DeleteEmployeeModal'
import ImportEmployeesModal from '../components/modals/ImportEmployeesModal'
import EmbededCodeModel from '../components/modals/EmbededCodeModel'
import React from 'react'

const Team = () => {
	const {pathname} = useLocation()
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')
	let _navigate = useNavigate()
	const _ref: any = useRef(null)
	const [showActive, setShowActive] = useState(false)
	const [viewEmployeeDetails, setViewEmployeeDetails] = useState('')
	const [openQRAScanModal, setOpenQRAScanModal] = useState(false)
	const [openCreateNewDrop, setOpenCreateNewDrop] = useState(false)
	const [openDeleteEmployeeModal, setOpenDeleteEmployeeModal] = useState(false)
	const [openImportEmployeeModal, setOpenImportEmployeeModal] = useState(false)
	const [deleteEmployeeId, setDeleteEmployeeId] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [designation, setDesignation] = useState('')
	const [department, setDepartment] = useState('')
	const [workLocation, setWorkLocation] = useState('')
	const [employeeDetail, setEmployeeDetail] = useState('')
	const [activeEmployee, setActiveEmployee] = useState(0)
	const [inActiveEmployee, setInActiveEmployee] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [openEmbedModel, setOpenEmbedModel] = useState(false)

	const [users, setUsers] = useState({
		columns: [
			{label: 'Emp Code', field: 'empCode', width: 200},
			{label: 'Employee', field: 'employee', width: 200},
			{label: 'Job Title', field: 'jobTitle', width: 50},
			{label: 'Department', field: 'department', width: 50},
			{label: 'Office Location', field: 'officeLocation', width: 50},
			{label: 'Status', field: 'status', width: 50},
			{label: '', field: 'view', sort: String('disabled'), width: 30}
		],
		rows: []
	})

	useEffect(() => {
		allPostings()
		if (_loginUserStatus === 'employee') {
			employeeProfile()
		}
		const handleClickOutside = (event: any) => {
			if (_ref?.current && !_ref?.current?.contains(event.target)) {
				setOpenCreateNewDrop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const allPostings = async (inActive = false) => {
		setIsLoading(true)
		await api
			.get(`/employee/all_employees?inActive=${inActive}&allTeam=${true}`)
			.then((response) => {
				if (response?.data?.success == true) {
					setActiveEmployee(response?.data?.data?.data?.active)
					setInActiveEmployee(response?.data?.data?.data?.inActive)
					if (response?.data?.data?.data?.employees?.length > 0) {
						setUsers((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response?.data?.data?.data?.employees?.map((data: any, index: any) => {
							createUserTable(data)
						})
					} else {
						setActiveEmployee(response?.data?.data?.data?.active)
						setInActiveEmployee(response?.data?.data?.data?.inActive)
						setUsers((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
					}
				} else if (response?.data?.success == false) {
					setActiveEmployee(0)
					setInActiveEmployee(0)
					setUsers((preValue) => {
						return {
							columns: [...preValue.columns],
							rows: []
						}
					})
				}

				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const employeeProfile = async () => {
		setIsLoading(true)
		await api
			.get('/employee/employee_Profile')
			.then((response) => {
				if (response?.data?.success == true) {
					const profileData = response?.data?.data?.data
					setEmployeeDetail(profileData)
					setViewEmployeeDetails(profileData)
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const createUserTable = (data: any) => {
		let _obj = {
			empCode: data?.employeeCode,
			employee: (
				<Flexed direction="row" align="center" gap={0.5}>
					<div>
						<ProfileImg>
							<img
								src={
									data?.image
										? process.env.REACT_APP_IMAGE_URL + data?.image
										: data?.gender == 'male'
											? '/images/default_user_profile_img.png'
											: data?.gender == 'female'
												? '/images/default_girl_profile_img.png'
												: '/images/default_user_profile_img.png'
								}
								alt="i"
							/>
						</ProfileImg>
					</div>
					{data?.firstName} {data?.lastName}
				</Flexed>
			),
			_employeeName: data?.firstName + ' ' + data?.lastName,
			jobTitle: data?.designation,
			department: data?.department,
			officeLocation: data?.workLocation,
			status: (
				<Flexed direction="row" align="center" gap={0.5}>
					<Status status={data?.status} />
					{data?.status}
				</Flexed>
			),
			view: (
				<Flexed direction="row" align="center" justify="center" gap={1}>
					<BarCodeWrapper
						onClick={() => {
							setOpenQRAScanModal(true)
							setFirstName(data?.firstName)
							setLastName(data?.lastName)
							setDesignation(data?.designation)
							setDepartment(data?.department)
							setWorkLocation(data?.workLocation)
						}}
						pointer
						direction="row"
						align="center"
						gap={0.5}>
						<LiaBarcodeSolid cursor="pointer" fontSize="1.5rem" />
					</BarCodeWrapper>
					<IoEye
						cursor="pointer"
						fontSize="1.2rem"
						onClick={() => {
							setViewEmployeeDetails(data)
						}}
					/>
					<Action>
						<HiDotsHorizontal fontSize="1.2rem" />
						<DropContent>
							{_loginUserStatus === 'admin' ? (
								<>
									{data?.status != 'active' && <DropMenu onClick={() => changeStatus(data?.id, 'active')}>Active</DropMenu>}
									{data?.status != 'inActive' && <DropMenu onClick={() => changeStatus(data?.id, 'inActive')}>InActive</DropMenu>}
									<DropMenu
										onClick={() => {
											setDeleteEmployeeId(data?.id)
											setOpenDeleteEmployeeModal(true)
										}}>
										Delete
									</DropMenu>
								</>
							) : null}
						</DropContent>
					</Action>
				</Flexed>
			)
		}
		setUsers((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	const changeStatus = async (id: any, status: any) => {
		setIsLoading(true)
		await api
			.put(`employee/update_status/${id}`, {
				status: status
			})
			.then((response) => {
				if (response?.data?.success == true) {
					allPostings()
				}
				setIsLoading(false)
				toast.success('Status updated successfully.')
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const removeEmployee = (id: any) => {
		setIsLoading(true)
		api.delete(`employee/remove/${id}`)
			.then((response) => {
				if (response?.data?.success == true) {
					setOpenDeleteEmployeeModal(false)
					setDeleteEmployeeId('')
					allPostings()
					toast.success('Employee deleted successfully.')
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}
	return (
		<>
			{viewEmployeeDetails === '' ? (
				<>
					<Wrapper direction="row" align="center">
						<Content direction="row" gap={1} align="center" justify="space-between" pointer="pointer" flexWrap="wrap">
							<div>
								<Text type="normal" fontWeight={500} color="black">
									Team Detail
								</Text>
							</div>
							<Flexed direction="row" gap={1}>
								<DashboardMenu to="/team" active={pathname === '/team'}>
									<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/team'}>
										{_loginUserStatus === 'admin' ? 'Team' : 'Profile'}
									</StyledText>
								</DashboardMenu>
								<DashboardMenu to="/doc" active={pathname === '/doc'}>
									<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/doc'}>
										Docs
									</StyledText>
								</DashboardMenu>
								<DashboardMenu to="/tasks" active={pathname === '/tasks'}>
									<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/tasks'}>
										Tasks
									</StyledText>
								</DashboardMenu>
							</Flexed>
							<ButtonFlexed direction="row" align="center" gap={0.5}>
								<EmbededCodeButton
									onClick={() => {
										setOpenEmbedModel(true)
									}}>
									See Embeded code
								</EmbededCodeButton>
								{_loginUserStatus === 'admin' ? (
									<GroupButton direction="row" align="center">
										<Button
											onClick={() => {
												_navigate('/team/create-team')
											}}>
											{' '}
											Create a New
										</Button>
										<Drop
											ref={_ref}
											onClick={() => {
												setOpenCreateNewDrop(!openCreateNewDrop)
											}}>
											<TiArrowSortedDown fontSize="13px" />
										</Drop>
										{openCreateNewDrop && (
											<DropDown>
												<DropList>Export Employee(s)</DropList>
												<DropList
													onClick={() => {
														setOpenImportEmployeeModal(true)
													}}>
													Import Employee(s)
												</DropList>
											</DropDown>
										)}
									</GroupButton>
								) : null}
								<Back>
									<svg _ngcontent-kxy-c151="" width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00acac">
										<path
											_ngcontent-kxy-c151=""
											d="M116 121v260c0 13.785 11.215 25 25 25s25-11.215 25-25V121c0-13.785-11.215-25-25-25S116 107.215 116 121zM211.98 243.107c-5.985 3.555-5.996 12.224 0 15.785l219.648 130.416c7.391 4.39 16.269-2.86 13.317-11.014l-42.561-117.712c-1.455-4.026-1.564-8.549-.078-12.823 45.595-131.625 43.871-124.776 43.871-127.686 0 0 0 0 0-.001 0-6.511-7.428-11.61-13.906-7.763L211.98 243.107zM346 451c0 8.272 6.728 15 15 15 8.271 0 15-6.728 15-15 0-8.271-6.729-15-15-15C352.728 436 346 442.729 346 451zM66 396c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 5.515 4.486 10 10 10S66 401.515 66 396zM226 71c0 13.785 11.215 25 25 25 13.785 0 25-11.215 25-25s-11.215-25-25-25C237.215 46 226 57.215 226 71zM250.998 371.003A10 10 0 10250.998 391.003 10 10 0 10250.998 371.003zM350.998 80.997A10 10 0 10350.998 100.997 10 10 0 10350.998 80.997zM51.003 151.003A10 10 0 1051.003 171.003 10 10 0 1051.003 151.003zM460.998 261.003A10 10 0 10460.998 281.003 10 10 0 10460.998 261.003z"></path>
									</svg>
									Back
								</Back>
							</ButtonFlexed>
						</Content>
					</Wrapper>
					<Section>
						<Container fluid>
							<Row>
								<Col>
									<TableWraper>
										<Flexed flexWrap="wrap" direction="row" align="center" justify="space-between" gap={0.2} margin="0rem 0rem 0rem 0rem">
											<Flexed direction="row" align="center" gap={0.2}>
												<CheckIconWrapper>
													{showActive ? (
														<Checked
															onClick={() => {
																allPostings(false)
																setShowActive(!showActive)
															}}
														/>
													) : (
														<UnChecked
															onClick={() => {
																allPostings(true)
																setShowActive(!showActive)
															}}
														/>
													)}
												</CheckIconWrapper>
												<Text fontSize={0.813} fontWeight={500} color="black_3">
													InActive
												</Text>
											</Flexed>
											<Flexed direction="row" align="center" gap={2}>
												<Flexed direction="row" align="center" gap={1}>
													<Text fontSize={0.813} fontWeight={500} color="black_3">
														Active Employees
													</Text>
													<Text type="medium" fontWeight={700} color="black_3">
														{activeEmployee}
													</Text>
												</Flexed>
												<Flexed direction="row" align="center" gap={1}>
													<Text fontSize={0.813} fontWeight={500} color="black_3">
														InActive Employees
													</Text>
													<Text type="medium" fontWeight={700} color="black_3">
														{inActiveEmployee}
													</Text>
												</Flexed>
											</Flexed>
										</Flexed>

										<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={users} fullPagination searchTop searchBottom={false} />
									</TableWraper>
								</Col>
							</Row>
						</Container>
					</Section>
				</>
			) : (
				<EmployeeDetails
					employeeData={viewEmployeeDetails}
					onClose={() => {
						if (_loginUserStatus === 'admin') {
							setViewEmployeeDetails('')
							allPostings()
						} else {
							setViewEmployeeDetails(employeeDetail)
						}
					}}
				/>
			)}
			{openQRAScanModal && (
				<QRAScanModal
					data={{firstName, lastName, designation, department, workLocation}}
					onCloseModal={() => {
						setOpenQRAScanModal(false)
					}}
					userDetails={''}
				/>
			)}
			{openImportEmployeeModal && (
				<ImportEmployeesModal
					onCloseModal={() => {
						setOpenImportEmployeeModal(false)
						setIsLoading(false)
						allPostings()
					}}
					setIsLoading={() => {
						setIsLoading(true)
					}}
				/>
			)}
			{openDeleteEmployeeModal && <DeleteEmployeeModal onCloseModal={() => setOpenDeleteEmployeeModal(false)} deleteAction={() => removeEmployee(deleteEmployeeId)} />}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}

			{openEmbedModel && <EmbededCodeModel open={openEmbedModel} onCloseModal={() => setOpenEmbedModel(false)} title={'Embeded code for team'} parent={'teams'} />}
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	min-height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`

const Content = styled(Flexed)`
	width: 100%;
`
const DashboardMenu = styled(NavLink)<any>`
	border-bottom: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
`

const StyledText = styled(Text)`
	padding: 10px 0px 10px 0px;
	color: ${({active}) => (active ? `${palette.purple_1}` : 'black')};
`

const Section = styled.div`
	padding: 25px 5px 20px 5px;
`
const TableWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	padding: 15px;
	border-radius: 5px;
`
export const ProfileImg = styled.div<any>`
	position: relative;
	width: 1.9rem;
	height: 1.9rem;
	border-radius: 100%;
	overflow: hidden;
	background: ${palette.purple_1};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	border: 1px solid ${palette.purple_1};
	& > img {
		width: 1.9rem;
		height: 1.9rem;
		border-radius: 3rem;
		object-fit: cover;
	}
`

const Status = styled.div<any>`
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 100%;
	background: ${({status}) => (status === 'active' ? palette.green : palette.red)};
`

const BarCodeWrapper = styled.div<any>`
	width: fit-content;
`

const GroupButton = styled(Flexed)`
	position: relative;
	width: 124.07px;
`

const ButtonFlexed = styled(Flexed)`
	padding: 0.5rem 0rem;
	justify-content: flex-end;
	width: 100%;
	${media.md`width: fit-content;`}
`

const Button = styled.div<any>`
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	height: 34px;
	display: flex;
	align-items: center;
	padding: 10px 0px 10px 10px;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	font-weight: 500;
	font-size: 0.813rem;
	line-height: 1.5rem;
	color: ${palette.white};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
`

const EmbededCodeButton = styled.div<any>`
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	height: 34px;
	display: flex;
	align-items: center;
	padding: 10px 10px 10px 10px;
	border-radius: 3px;
	font-weight: 500;
	font-size: 0.813rem;
	line-height: 1.5rem;
	color: ${palette.white};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
`

const Drop = styled.div<any>`
	width: 26px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	color: ${palette.white};
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	cursor: pointer;
	&:hover {
		background-color: ${palette.purple_2};
	}
`
const DropDown = styled.div<any>`
	position: absolute;
	top: 2.2rem;
	background: ${palette.white};
	right: 0;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
	box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
`

const DropList = styled.div`
	display: block;
	padding: 8px 20px;
	clear: both;
	font-weight: 400;
	line-height: 1.42857143;
	color: ${palette.black_3};
	cursor: pointer;
	white-space: nowrap;
	font-size: 13px;
	&:hover {
		background-color: ${palette.purple_2};
		color: ${palette.white};
	}
`

const DropContent = styled.div<any>`
	display: none;
	top: 1.7rem;
	position: absolute;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	min-width: 6.5rem;
	right: -2rem;
	box-shadow: ${palette.shadow};
	z-index: 1;
	border-radius: 0.5rem;
	overflow: hidden;
`

const Action = styled.div`
	position: relative;
	&:hover ${DropContent} {
		display: block;
	}
`

const DropMenu = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-size: 0.8rem;
	padding: 0rem 0.5rem 0rem 0.5rem;
	text-decoration: none;
	display: block;
	&:hover {
		background-color: ${palette.purple_1};
		color: ${palette.white};
	}
	z-index: 1;
	cursor: pointer;
	text-align: left;
`

export default Team
