import axios from 'axios'
import {toast} from 'react-toastify'
// import {toast} from 'react-toastify'
export const api = axios.create({
	baseURL: process.env.REACT_APP_API,
	headers: {
		'Content-Type': 'application/json'
	}
})

api.interceptors.request.use(
	(request: any) => {
		let token = sessionStorage.getItem('authorization')
		if (token) {
			request.headers.common.Authorization = `Bearer ${token}`
		}
		return request
	},
	(error) => {
		return Promise.reject(error)
	}
)

api.interceptors.response.use(
	(response) => {
		return response
	},
	function (error) {
		return Promise.reject(error)
	}
)

export const doGetOwnerProfile = async () => {
	let response: any = []
	await api
		.get('/admin/details')
		.then((res) => {
			if (res?.data?.success) {
				response = res?.data?.data?.data
			}
		})
		.catch((e) => {
			response = e
			if (e?.response?.data?.message) {
				toast.error(e?.response?.data?.message)
			}
		})
	return response
}

export const doGetCustomerProfile = async (employeeId: any) => {
	let response: any = []
	await api
		.get(`/employee/get_employee/${employeeId}`)
		.then((res) => {
			if (res?.data?.success) {
				response = res?.data?.data?.data
			}
		})
		.catch((e) => {
			console.error(e)
			// toast.error(e?.response?.data?.message)
		})
	return response
}
