import React, {useState} from 'react'
import styled from 'styled-components'
import {MemberList, ProfileImg, TagUser} from '../trelloTaskModalComponents/AddMemberPop'
import {Text} from '../../styled/shared'
import {getInitials} from '../../utils'
import moment from 'moment'
import TrelloTaskModal from './TrelloTaskModal'

const ActivityLogContainer = styled.div`
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	background-color: #f9f9f9;
	overflow: scroll;
	max-height: 80vh;
`

const ActivityItem = styled.li`
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const ActivityDetails = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`

const ActivityUser = styled.span`
	font-weight: bold;
	margin-right: 10px;
`

const ActivityAction = styled.span`
	color: #007bff;
	margin-right: 10px;
`

const ActivityTime = styled.span`
	color: #6c757d;
`

const ActivityLog = ({activities, isCard}: any) => {
	const [openCard, setOpenCard] = useState(false)
	const [cardDetails, setCardDetails] = useState<any>('')
	return (
		<ActivityLogContainer>
			<ul style={{listStyle: 'none'}}>
				{activities.map((activity: any, index: any) => (
					<ActivityItem key={index}>
						<ActivityDetails
							onClick={() => {
								if (activity?.taskListCard && !isCard) {
									setCardDetails(activity?.taskListCard)
									setOpenCard(true)
								}
							}}>
							{activity.admin ? (
								<>
									<MemberList direction="row" align="center" gap={0.5} hover={true}>
										{activity.admin?.logo ? (
											<ProfileImg>
												<img src={process.env.REACT_APP_IMAGE_URL + activity.admin?.logo} alt={activity.admin?.name} />
											</ProfileImg>
										) : (
											<TagUser width="2rem" height="2rem">
												{activity.admin?.name ? getInitials(activity.admin?.name) : ''}
											</TagUser>
										)}
										<Text type="small" color="black_3" fontWeight={500}>
											{activity.admin?.name ? activity.admin?.name : ''}
										</Text>
									</MemberList>
								</>
							) : (
								<MemberList margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" gap={0.5} hover={true}>
									{activity.employee?.image ? (
										<ProfileImg>
											<img src={process.env.REACT_APP_IMAGE_URL + activity.employee?.image} alt={activity.employee?.firstName} />
										</ProfileImg>
									) : (
										<TagUser width="2rem" height="2rem">
											{activity.employee?.firstName ? getInitials(activity.employee?.firstName + ' ' + activity.employee?.lastName) : ''}
										</TagUser>
									)}
									<Text type="small" color="black_3" fontWeight={500}>
										{activity.employee?.firstName ? activity.employee?.firstName + ' ' + activity.employee?.lastName : activity.employee?.email}
									</Text>
								</MemberList>
							)}

							{/* <ActivityUser>{activity.admin?.name}</ActivityUser> */}
							<ActivityAction>{activity?.action}</ActivityAction>
						</ActivityDetails>
						<ActivityTime>{moment(activity.createdAt).fromNow(true)}</ActivityTime>
					</ActivityItem>
				))}
			</ul>

			{openCard && (
				<TrelloTaskModal
					quote={{...cardDetails, card_id: cardDetails?.id}}
					onCloseModal={(isLabelUpdated = false) => {
						setOpenCard(false)
					}}
				/>
			)}
		</ActivityLogContainer>
	)
}

export default ActivityLog
