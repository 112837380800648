import React, {useState, useEffect, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Text, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {loginUserDetailsAction, loginAdminDetailsAction, updateBoardListFav, filterBoardCards} from '../../actions/authActions'
import {FaAngleDown} from 'react-icons/fa'
import {api, doGetCustomerProfile, doGetOwnerProfile} from '../api/callAxios'
import useWindowSize from '../../hooks/useWindowSize'
import {BsThreeDots} from 'react-icons/bs'
import useRouter from '../../hooks/router'
import {FaRegStar, FaStar} from 'react-icons/fa'
import {IoIosArrowDown} from 'react-icons/io'
import {FiSearch} from 'react-icons/fi'
import {toast} from 'react-toastify'
import {BiArrowBack} from 'react-icons/bi'
import {LuEyeIcon} from './SideMenuBar'
import BoardFiltersModel from '../modals/BoardFiltersModel'
import {TagUser} from '../trelloTaskModalComponents/AddMemberPop'
import {getInitials} from '../../utils'
import TrelloMemberProfile from '../modals/UserProfileModal'
import {Button} from '../modals/ClosedBoardModel'
import ShareBoardModel from '../modals/ShareBoardModel'

interface IProps {
	scroll: number
	path?: boolean
	active?: boolean
	isDarkTheme?: boolean
}

const SubNavBar = ({openSideMenu, filters}: any) => {
	const {pathname} = useLocation()
	const windowSize = useWindowSize()
	const _ref: any = useRef(null)
	const _userProfileRef: any = useRef(null)
	let _navigate = useNavigate()
	let _dispatch = useDispatch()
	const [faviroute, setFaviroute] = useState(false)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const updateBoard: any = useSelector<any>((state: any) => state.auth.updateBoard)
	const getFavBoardDetails = useSelector((state: any) => state.auth?.updateTrelloListFav)
	const _filterOptions: any = useSelector<any>((state: any) => state.auth.filterCards)

	const _authToken: any = sessionStorage.getItem('authorization')
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')
	const [scrollPosition, setScrollPosition] = useState(0)
	const [openNotificationList, setOpenNotificationList] = useState(false)
	const [openUserProfileDrop, setOpenUserProfileDrop] = useState(false)
	const employeeId: any = sessionStorage.getItem('employeeId')
	const [boardDetails, setBoardDetails] = useState<any>('')
	const [openFilterPopup, setOpenFiltersPopup] = useState(false)
	const [openUserProfile, setOpenUserProfile] = useState(false)
	const [userProfile, setUserProfile] = useState<any>('')
	const [inviteDetails, setInviteDetails] = useState<any>('')
	const [adminDetails, setAdminDetails] = useState<any>('')

	const [shareModel, setShareModel] = useState(false)
	const router: any = useRouter()
	const dispatch = useDispatch()

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}

	useEffect(() => {
		if (router.query?.id) getBoardById(router.query?.id)
	}, [router.query, getFavBoardDetails, updateBoard])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, {passive: true})

		const handleClickOutside = (event: any) => {
			if (_ref?.current && !_ref?.current?.contains(event.target)) {
				setOpenNotificationList(false)
			}
			if (_userProfileRef?.current && !_userProfileRef?.current?.contains(event.target)) {
				setOpenUserProfileDrop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const getProfile = async (status: string) => {
		if (status === 'admin') {
			let response = await doGetOwnerProfile()
			if (response?.response?.status == 401) {
				router.navigate('/login')
			} else if (response) {
				_dispatch(loginAdminDetailsAction(response))
			}
		} else if (status === 'employee') {
			let response = await doGetCustomerProfile(employeeId)
			if (response) {
				_dispatch(loginUserDetailsAction(response))
			}
		}
	}

	const getBoardById = async (id: string, callDispatch = true) => {
		try {
			await api
				.get(`/trello/get_board?id=${id}`)
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							setBoardDetails(response?.data?.data)
							if (response?.data?.data?.taskBoardInvites?.length) {
								setAdminDetails(response?.data?.data?.taskBoardInvites[0]?.sender)
							}
							if (callDispatch) _dispatch(updateBoardListFav(false))
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	useEffect(() => {
		if (_authToken) {
			getProfile(_loginUserStatus)
		} else {
			if (!_authToken && pathname == '/create-candidate') {
			} else {
				_navigate('/login')
			}
		}
	}, [_authToken])

	const addBoardIntoFav = async (id: string) => {
		try {
			await api
				.post(`/trello/add_fav_board`, {boardId: id, adminId: _loginAdminDetails ? _loginAdminDetails?.id : undefined, employeeId: _loginUserDetails ? _loginUserDetails?.id : undefined})
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							_dispatch(updateBoardListFav(response?.data?.data ? response?.data?.data : {}))
							getBoardById(id, false)
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const isMemberWatch = async (adminId?: any, employeeId?: any, isWatch?: any) => {
		try {
			await api
				.post(`/trello/watch_card`, {cardId: null, listId: null, boardId: boardDetails?.id, adminId, employeeId})
				.then((res: any) => {
					if (res?.data?.success == true) {
						getBoardById(boardDetails?.id)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	return (
		<Main>
			<Container fluid>
				<Row>
					<CustomCol>
						<Flexed direction="row" align="center" gap={0.5}>
							<Flexed direction="row" align="center" gap={0.5}>
								<BiArrowBack color="white" cursor={'pointer'} onClick={() => router.navigate('/boards')} />
								<BoardName pointer type="medium" fontWeight={700} color="white">
									{boardDetails?.name}
								</BoardName>
							</Flexed>

							<Star
								direction="row"
								align="center"
								justify="center"
								onClick={() => {
									addBoardIntoFav(router?.query?.id)
									setFaviroute(!faviroute)
								}}>
								{boardDetails?.isFav ? <FaStar color="white" /> : <FaRegStar color="white" />}
							</Star>
							{/* <List direction="row" align="center" justify="center">
								<svg width="16" height="16" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M12.5048 5.67168C11.9099 5.32669 11.2374 5.10082 10.5198 5.0267C11.2076 3.81639 12.5085 3 14 3C16.2092 3 18 4.79086 18 7C18 7.99184 17.639 8.89936 17.0413 9.59835C19.9512 10.7953 22 13.6584 22 17C22 17.5523 21.5523 18 21 18H18.777C18.6179 17.2987 18.3768 16.6285 18.0645 16H19.917C19.4892 13.4497 17.4525 11.445 14.8863 11.065C14.9608 10.7218 15 10.3655 15 10C15 9.58908 14.9504 9.18974 14.857 8.80763C15.5328 8.48668 16 7.79791 16 7C16 5.89543 15.1046 5 14 5C13.4053 5 12.8711 5.25961 12.5048 5.67168ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12ZM14 10C14 10.9918 13.639 11.8994 13.0412 12.5984C15.9512 13.7953 18 16.6584 18 20C18 20.5523 17.5523 21 17 21H3C2.44772 21 2 20.5523 2 20C2 16.6584 4.04879 13.7953 6.95875 12.5984C6.36099 11.8994 6 10.9918 6 10C6 7.79086 7.79086 6 10 6C12.2091 6 14 7.79086 14 10ZM9.99999 14C12.973 14 15.441 16.1623 15.917 19H4.08295C4.55902 16.1623 7.02699 14 9.99999 14Z"
										fill="currentColor"></path>
								</svg>
								Workspace visible
							</List> */}
							<ListButton direction="row" align="center" justify="center">
								<svg width="16" height="16" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M2 7V15C2 16.1046 2.89543 17 4 17H6C7.10457 17 8 16.1046 8 15V7C8 5.89543 7.10457 5 6 5H4C2.89543 5 2 5.89543 2 7ZM4 7V15H6V7L4 7Z" fill="currentColor"></path>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M9 7V13C9 14.1046 9.89543 15 11 15H13C14.1046 15 15 14.1046 15 13V7C15 5.89543 14.1046 5 13 5H11C9.89543 5 9 5.89543 9 7ZM11 7V13H13V7L11 7Z"
										fill="currentColor"></path>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M16 17V7C16 5.89543 16.8954 5 18 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H18C16.8954 19 16 18.1046 16 17ZM18 17V7L20 7V17H18Z"
										fill="currentColor"></path>
								</svg>
								Board
							</ListButton>
						</Flexed>
						<ActionMenu direction="row" align="center">
							{boardDetails?.isWatchBoard ? (
								<List direction="row" align="center" justify="space-between" onClick={() => isMemberWatch(_loginAdminDetails?.id ? _loginAdminDetails?.id : null, _loginUserDetails?.id ? _loginUserDetails?.id : null)}>
									<Flexed direction="row" align="center" gap={0.5}>
										<LuEyeIcon fontSize={16} />
										<Text type="small" fontWeight={500} color="white">
											Watch
										</Text>
									</Flexed>
								</List>
							) : (
								''
							)}
							<Flexed direction="row" align="center" justify="space-between">
								<List direction="row" align="center" justify="center" onClick={() => setOpenFiltersPopup(!openFilterPopup)}>
									<svg width="16" height="16" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M4.61799 6C3.87461 6 3.39111 6.78231 3.72356 7.44721L3.99996 8H20L20.2763 7.44721C20.6088 6.78231 20.1253 6 19.3819 6H4.61799ZM10.8618 17.7236C10.9465 17.893 11.1196 18 11.309 18H12.6909C12.8803 18 13.0535 17.893 13.1382 17.7236L14 16H9.99996L10.8618 17.7236ZM17 13H6.99996L5.99996 11H18L17 13Z"
											fill="currentColor"></path>
									</svg>
									Filters
								</List>
								{Object.keys(_filterOptions)?.length ? (
									<Button
										onClick={() => {
											dispatch(filterBoardCards({}))
										}}
										border="silver_6"
										removedBorder={true}
										color="white"
										// bg="white"
										bg_hover="silver_8">
										Clear All
									</Button>
								) : (
									''
								)}
								{/* <Button type={'discard'} label="Clear All" /> */}
							</Flexed>
							{boardDetails?.taskBoardInvites?.map((res: any) =>
								res?.receiverUserId ? (
									<UserProfile
										direction="row"
										align="center"
										gap={0.5}
										onClick={() => {
											setInviteDetails(res)
											setUserProfile(res?.receiver)
											setOpenUserProfile(true)
										}}>
										<ProfileImg>
											{res?.receiver?.image ? (
												<>{res?.receiver?.image ? <img src={process.env.REACT_APP_IMAGE_URL + res?.receiver?.image} alt="i" /> : <img src={'/images/default_user_profile_img.png'} alt="i" />}</>
											) : (
												<>
													<TagUser width="1.8rem" height="1.8rem">
														{res?.receiver?.firstName ? res?.receiver?.firstName + ' ' + res?.receiver?.lastName && getInitials(res?.receiver?.firstName + ' ' + res?.receiver?.lastName) : getInitials(res?.receiver?.email)}
													</TagUser>
												</>
											)}
										</ProfileImg>
										<Arrow src="/icons/double_profile_arrow.png" />
									</UserProfile>
								) : (
									''
								)
							)}

							{_loginUserStatus === 'admin' ? (
								<UserProfile
									direction="row"
									align="center"
									gap={0.5}
									onClick={() => {
										setUserProfile(_loginAdminDetails)
										setOpenUserProfile(true)
									}}>
									<ProfileImg>
										{_loginUserStatus === 'admin' ? (
											<>{_loginAdminDetails?.logo ? <img src={process.env.REACT_APP_IMAGE_URL + _loginAdminDetails?.logo} alt="i" /> : <img src={'/images/default_user_profile_img.png'} alt="i" />}</>
										) : (
											<></>
										)}
									</ProfileImg>
									<Arrow src="/icons/double_profile_arrow.png" />
								</UserProfile>
							) : adminDetails ? (
								<UserProfile
									direction="row"
									align="center"
									gap={0.5}
									onClick={() => {
										setUserProfile(adminDetails)
										setOpenUserProfile(true)
									}}>
									<ProfileImg>
										{_loginUserStatus === 'admin' ? (
											<>{adminDetails?.logo ? <img src={process.env.REACT_APP_IMAGE_URL + adminDetails?.logo} alt="i" /> : <img src={'/images/default_user_profile_img.png'} alt="i" />}</>
										) : (
											<TagUser width="1.8rem" height="1.8rem">
												{adminDetails.name ? getInitials(adminDetails?.name) : ''}
											</TagUser>
										)}
									</ProfileImg>
									<Arrow src="/icons/double_profile_arrow.png" />
								</UserProfile>
							) : (
								''
							)}

							<ListButton direction="row" align="center" justify="center" onClick={() => setShareModel(true)}>
								<svg width="16" height="16" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 9.44777 7.61532 10.7518 8.59871 11.6649C5.31433 13.0065 3 16.233 3 20C3 20.5523 3.44772 21 4 21H12C12.5523 21 13 20.5523 13 20C13 19.4477 12.5523 19 12 19H5.07089C5.55612 15.6077 8.47353 13 12 13ZM15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8Z"
										fill="currentColor"></path>
									<path
										d="M17 14C17 13.4477 17.4477 13 18 13C18.5523 13 19 13.4477 19 14V16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H19V20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20V18H15C14.4477 18 14 17.5523 14 17C14 16.4477 14.4477 16 15 16H17V14Z"
										fill="currentColor"></path>
								</svg>
								Share
							</ListButton>
							<List direction="row" align="center" justify="center" onClick={openSideMenu}>
								<BsThreeDots fontSize={18} />
							</List>
						</ActionMenu>
					</CustomCol>
				</Row>
			</Container>
			{shareModel && <ShareBoardModel onCloseModal={() => setShareModel(false)} boardDetails={boardDetails} />}
			{openFilterPopup && <BoardFiltersModel filters={filters} onCloseModal={() => setOpenFiltersPopup(false)} boardDetails={boardDetails} />}

			{openUserProfile && (
				<TrelloMemberProfile
					onCloseModal={() => {
						setInviteDetails('')
						setOpenUserProfile(false)
					}}
					profileImageUrl={userProfile?.logo ? process.env.REACT_APP_IMAGE_URL + userProfile?.logo : userProfile?.image ? process.env.REACT_APP_IMAGE_URL + userProfile?.image : ''}
					name={userProfile?.name ? userProfile?.name : userProfile?.firstName ? userProfile?.firstName + ' ' + userProfile?.lastName : userProfile?.email ? userProfile?.email : ''}
					isCard={false}
					memberDetails={inviteDetails}
					isNav={true}
					adminDetails={adminDetails}
				/>
			)}
		</Main>
	)
}
const Main = styled.div<any>`
	background: ${palette.transparent_1};
	backdrop-filter: blur(4px);
	position: sticky;
	top: 0;
	z-index: 0;
	width: 100%;
`

const CustomCol = styled(Col)`
	display: flex;
	height: 3.6rem;
	justify-content: space-between;
	align-items: center;
`

const BoardName = styled(Text)`
	white-space: nowrap;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0.625rem;
	border-radius: 0.188rem;
	&:hover {
		background: ${palette.transparent_2};
	}
`

const Star = styled(Flexed)`
	padding: 0 0.625rem;
	height: 2rem;
	cursor: pointer;
	border-radius: 0.188rem;
	&:hover {
		background: ${palette.transparent_2};
	}
	&:hover svg {
		transform: scale(1.25);
	}
`

const List = styled(Flexed)`
	font-size: 0.875rem;
	padding: 0 0.625rem;
	color: ${palette.white};
	font-weight: 500;
	gap: 0.5rem;
	height: 2rem;
	cursor: pointer;
	border-radius: 0.188rem;
	&:hover {
		background: ${palette.transparent_2};
	}
`

const ListButton = styled(List)`
	color: ${palette.blue_2};
	font-weight: 600;
	background: ${palette.silver_16};
	&:hover {
		background: ${palette.white};
	}
`

const ActionMenu = styled(Flexed)`
	${media.xs`gap:0.7rem`};
	${media.sm`gap:0.7rem`};
	${media.md`gap:0.7rem`};
`

export const UserProfile = styled(Flexed)`
	cursor: pointer;
	position: relative;
`

export const ProfileImg = styled.div<any>`
	position: relative;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 100%;
	overflow: hidden;
	background: ${palette.purple_1};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	& > img {
		width: 1.75rem;
		height: 1.75rem;
		border-radius: 3rem;
		object-fit: cover;
	}
`

export const SearchWapper = styled.div`
	position: relative;
`

export const SearchIcon = styled(FiSearch)`
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0.5rem;
`

export const SearchInput = styled.input`
	box-shadow: none;
	height: 32px;
	/* width: 100%; */
	padding: 0px var(--ds-space-150, 12px) 0px 10px;
	box-sizing: border-box;
	outline: none;
	border-radius: 5px;
	font-size: 14px;
	line-height: 20px;
	border: 2px solid gray;
	/* box-shadow: none; */
	background-color: rgba(255, 255, 255, 0.2);
	color: ${palette.black};
	&::placeholder {
		color: ${palette.gray};
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${palette.gray};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: ${palette.gray};
	}
`

const Arrow = styled.img`
	height: 0.563rem;
	width: 0.563rem;
	position: absolute;
	right: 0;
	bottom: 0;
`

export default SubNavBar
