import React, {useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import styled from 'styled-components'
import Header from './components/common/Header'
import Home from './pages/Home'
import Recruitment from './pages/Recruitment'
import Post from './pages/Post'
import EditCandidate from './pages/EditCandidate'
import Candidate from './pages/Candidate'
import CreateCandidate from './pages/CreateCandidate'
import CreateInterview from './pages/CreateInterview'
import EditInterview from './pages/EditInterview'
import EditPost from './pages/EditPost'
import Requests from './pages/Requests'
import Interview from './pages/Interview'
import Sidebar from './components/common/Sidebar'
import TaskManagementSideBar from './components/common/TaskManagementSideBar'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {palette} from './styled/colors'
import {Loading} from './styled/shared'
import LogIn from './pages/Auth/Login'
import SignUp from './pages/Auth/SignUp'
import ForgotPassword from './pages/Auth/ForgotPassword'
import ResetPassword from './pages/Auth/ResetPassword'
import Team from './pages/Team'
import Document from './pages/Document'
import ForgotPasswordConfirmation from './pages/Auth/ForgotPasswordConfirmation'
import CreateTeam from './pages/CreateTeam'
import Tasks from './pages/Tasks'
import LeaveRequest from './pages/LeaveRequest'
import ExpenseRequest from './pages/ExpenseRequest'
import LoanRequest from './pages/LoanRequest'
import DocumentRequest from './pages/DocumentRequest'
import Organization from './components/settings/Organization'
import ChangePassword from './pages/Auth/ChangePassword'
import TaskManagement from './pages/TaskManagement'
import Time from './pages/Time'
import AddAttendance from './pages/AddAttendance'
import AddHoliday from './pages/AddHoliday'
import JobPostWidget from './pages/JobPostWidget'
import {media} from 'styled-bootstrap-grid'
import FrontPage from './pages/FrontPage'
import Boards from './pages/Boards'
import UserProfile from './dnd/UserProfile'

interface IProps {
	active: boolean
	isDarkTheme: boolean
}

const App = () => {
	const {pathname} = useLocation()
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [isLoading, setIsLoading] = useState<any>(false)
	const [openTaskManagementSideBar, setOpenTaskManagementSideBar] = useState(true)
	const [activeTimeTab, setActiveTimeTab] = useState('liveAttendance')
	const _authToken: any = sessionStorage.getItem('authorization')
	let chechCandidate = _authToken && pathname == '/create-candidate' ? true : false
	return (
		<div>
			{pathname != '/' &&
				pathname != '/feature' &&
				pathname != '/login' &&
				pathname != '/register' &&
				pathname != '/forgot-password' &&
				pathname != '/forgotpassword-confirmation' &&
				pathname != '/reset-password' &&
				pathname != '/create-candidate' && (
					<>
						<Sidebar />
					</>
				)}
			{/* {(pathname == '/task-management' || pathname == '/boards' || pathname == '/members') && (
				<>
					<TaskManagementSideBar openTaskManagementSideBar={openTaskManagementSideBar} setOpenTaskManagementSideBar={setOpenTaskManagementSideBar} />
				</>
			)} */}

			{chechCandidate && (
				<>
					<Sidebar />
				</>
			)}
			<Wrapper
				// activeBigMargin={pathname == '/task-management'}
				activeMargin={pathname != '/' && pathname != '/feature' && pathname != '/login' && pathname != '/register' && pathname != '/forgot-password' && pathname != '/forgotpassword-confirmation' && pathname != '/reset-password'}>
				{pathname != '/forgot-password' && pathname != '/forgotpassword-confirmation' && pathname != '/reset-password' && pathname != '/create-candidate' && (
					<>
						<Header />
					</>
				)}
				{chechCandidate && (
					<>
						<Header />
					</>
				)}
				<Main>
					<Routes>
						<Route path="/feature" element={<FrontPage />}></Route>
						<Route path="/dashboard" element={<Home />}></Route>
						<Route path="/recruitment" element={<Recruitment />}></Route>
						<Route path="/post" element={<Post />}></Route>
						<Route path="/candidate" element={<Candidate />}></Route>
						<Route path="/interview" element={<Interview />}></Route>
						<Route path="/edit-interview" element={<EditInterview />}></Route>
						<Route path="/create-interview" element={<CreateInterview />}></Route>
						<Route path="/create-candidate" element={<CreateCandidate />}></Route>
						<Route path="/edit-post" element={<EditPost />}></Route>
						<Route path="/edit-candidate" element={<EditCandidate />}></Route>
						<Route path="/login" element={<LogIn />}></Route>
						<Route path="/register" element={<SignUp />}></Route>
						<Route path="/forgot-password" element={<ForgotPassword />}></Route>
						<Route path="/forgotpassword-confirmation" element={<ForgotPasswordConfirmation />}></Route>
						<Route path="/reset-password" element={<ResetPassword />}></Route>
						<Route path="/change-password" element={<ChangePassword />}></Route>
						<Route path="/team" element={<Team />}></Route>
						<Route path="/doc" element={<Document />}></Route>
						<Route path="/team/create-team" element={<CreateTeam />}></Route>
						<Route path="/tasks" element={<Tasks />}></Route>
						<Route path="/requests" element={<Requests />}></Route>
						<Route path="/leave-request" element={<LeaveRequest />}></Route>
						<Route path="/loan-request" element={<LoanRequest />}></Route>
						<Route path="/document-request" element={<DocumentRequest />}></Route>
						<Route path="/organization" element={<Organization />}></Route>
						<Route path="/task-management" element={<TaskManagement />}></Route>
						<Route path="/boards" element={<Boards />}></Route>
						<Route path="/task-board-user" element={<UserProfile />}></Route>
						<Route path="/career" element={<JobPostWidget />}></Route>
						<Route path="/time" element={<Time activeTimeTab={activeTimeTab} setActiveTimeTab={setActiveTimeTab} />}></Route>
						<Route path="/time/add-attendance" element={<AddAttendance setActiveTimeTab={setActiveTimeTab} />}></Route>
						<Route path="/time/add-holiday" element={<AddHoliday setActiveTimeTab={setActiveTimeTab} />}></Route>
					</Routes>
				</Main>

				{isLoading && (
					<Loading>
						<div className="dots-loading"></div>
					</Loading>
				)}
			</Wrapper>
		</div>
	)
}
const Wrapper = styled.div<any>`
	@media screen and (min-width: 992px) and (max-width: 9999px) {
		margin-left: ${({activeMargin, openTaskManagementSideBar, activeBigMargin}) => (activeBigMargin ? (openTaskManagementSideBar ? '260px' : '0.75rem') : activeMargin ? '70px' : '')};
	}
`

const Main = styled.div<any>`
	background: ${palette.silver_5};
	min-height: calc(100vh - 57.59px);
`

export default App
