import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text, Divider, Spacer} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'
import {IoIosArrowBack} from 'react-icons/io'
import {GoDotFill} from 'react-icons/go'

const AttachmentsPop = ({onCloseModal, onSelect}: any) => {
	const [search, setSearch] = useState('')
	const [openUploadEmojiView, setOpenUploadEmojiView] = useState(false)
	const [emoji, setEmoji] = useState('')
	const [displayText, setDisplayText] = useState('')

	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const handleCapture = (event: any) => {
		const _files = event.target.files
		let _fileName: any = ''
		if (_files && _files.length > 0) {
			let _file = event.target.files?.[0]
			if (_file) {
				_fileName = _file?.name
			}
			const _reader: any = new FileReader()
			_reader.readAsDataURL(event.target.files[0])
			_reader.onload = () => {
				if (_reader.readyState === 2) {
					setEmoji(_reader?.result)
					onSelect && onSelect({string: _reader?.result, fileName: _fileName})
				}
			}
		}
	}
	return (
		<Popup top="-13rem" onClick={handleModalClick}>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				{openUploadEmojiView ? (
					<CloseCover onClick={() => setOpenUploadEmojiView(false)}>
						<IoIosArrowBack cursor="pointer" />
					</CloseCover>
				) : (
					<div></div>
				)}
				<Text fontWeight={600}>Attach</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			<Flexed margin="0.5rem 0rem 0rem 0rem" gap={0.5}>
				<Text type="small" fontWeight={600}>
					Attach a file from your computer
				</Text>
				<Text type="xsmall" fontWeight={600}>
					You can also drag and drop files to upload them.
				</Text>
				<ChooseFile>
					Choose a file
					<InputFile
						type="file"
						accept="image/*"
						onChange={(e: any) => {
							handleCapture(e)
						}}
					/>
				</ChooseFile>
			</Flexed>
			<Divider margin="0.5rem 0rem" />
			{/* <Flexed margin="0.5rem 0rem 0rem 0rem">
				<div>
					<Text type="xsmall" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
						Search or paste a link
					</Text>
					<Input placeholder="Find recent links or paste a new link" value={search} onChange={(e: any) => setSearch(e.target.value)} />
				</div>
				<Spacer height={0.5} />
				<div>
					<Text type="xsmall" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
						Display text (optional)
					</Text>
					<Input placeholder="Text to dispaly" value={displayText} onChange={(e: any) => setDisplayText(e.target.value)} />
				</div>
				<Spacer height={0.5} />
				<Text type="xsmall" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
					RECENTLY VIEWED
				</Text>
				<List direction="row" gap={0.5}>
					<img src="./icons/colum_icon.svg" alt="column" />
					<div>
						<Text type="small" fontWeight={500}>
							Project name
						</Text>
						<Flexed direction="row" align="center" gap={0.2}>
							<Text type="xsmall" fontWeight={500}>
								SmartSoftStudios
							</Text>
							<GoDotFill fontSize={8} />
							<Text type="xsmall" fontWeight={500}>
								Viewed 2 hours ago
							</Text>
						</Flexed>
					</div>
				</List>
				<List direction="row" gap={0.5}>
					<img src="./icons/card.svg" alt="column" />
					<div>
						<Text type="small" fontWeight={500}>
							Card Name
						</Text>
						<Flexed direction="row" align="center" gap={0.2}>
							<Text type="xsmall" fontWeight={500}>
								SmartSoftStudios
							</Text>
							<GoDotFill fontSize={8} />
							<Text type="xsmall" fontWeight={500}>
								Viewed 2 hours ago
							</Text>
						</Flexed>
					</div>
				</List>
			</Flexed>
			<Flexed margin="0.5rem 0rem 0rem 0rem" direction="row" gap={0.5} justify="flex-end">
				<Button onClick={onCloseModal}>Cancel</Button>
				<Button active>Insert</Button>
			</Flexed> */}
		</Popup>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({top}) => top};
	z-index: 5;
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

const Input = styled.input<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
	outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
`

const InputFile = styled.input<any>`
	display: none !important;
`

const List = styled(Flexed)`
	width: 100%;
	padding: 0.4rem 0.313rem;
	border-radius: 0.3rem;
	background: ${({active}) => (active ? palette.silver_1 : palette.white)};
	position: relative;
	cursor: pointer;
	&:hover {
		background: ${palette.silver_1};
	}
	& img {
		width: 1.125rem;
		height: 1.125rem;
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({active}) => (active ? palette.white : palette.black)};
	background: ${({active}) => (active ? palette.purple_1 : '')};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		background: ${({active}) => (active ? palette.purple_2 : palette.silver_14)};
	}
`

const ChooseFile = styled.label<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.black};
	background: ${palette.silver_14};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		background: ${palette.silver_11};
	}
`

export default AttachmentsPop
