import {useState, useEffect} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../styled/shared'
import {useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../styled/colors'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import InputField from '../components/common/InputField'
import InputDate from '../components/common/InputDate'
import FileUpload from '../components/common/FileUpload'
import {Loading} from '../styled/shared'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {Back} from '../components/employee/EmployeeDetails'
import {Logo} from '../components/modals/AddEmployeeAttendanceModal'
import MultiSelect from '../components/common/MultiSelect'

const LeaveRequest = () => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')
	let _navigate = useNavigate()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const requestId = queryParams.get('id')
	const [isLoading, setIsLoading] = useState<any>(false)
	const [dateFrom, setDateFrom] = useState('')
	const [dateFromError, setDateFromError] = useState('')
	const [dateTo, setDateTo] = useState('')
	const [dateToError, setDateToError] = useState('')
	const [description, setDescription] = useState('')
	const [attachmentName, setAttachmentName] = useState('')
	const [attachment, setAttachment] = useState('')
	const [employee, setEmployee] = useState({
		label: _loginUserStatus === 'employee' ? _loginUserDetails?.firstName : '',
		value: _loginUserStatus === 'employee' ? _loginUserDetails?.id : '',
		image: _loginUserStatus === 'employee' && _loginUserDetails?.image ? _loginUserDetails?.image : ''
	})
	const [employeeError, setEmployeeError] = useState('')
	const [employeeId, setEmployeeId] = useState<any>(_loginUserStatus === 'employee' ? _loginUserDetails?.id : '')
	const [employeeOption, setEmployeeOption] = useState<any>([])

	useEffect(() => {
		allEmployee()
	}, [])
	useEffect(() => {
		api.get(`/request/get_request/${requestId}`)
			.then((response) => {
				if (response?.data?.success == true) {
					const data = response?.data?.data?.data
					setEmployee({label: data?.employeeName, value: data?.employeeId, image: data?.employeeImage})
					setEmployeeId(data?.employeeId)
					setDateFrom(data?.from)
					setDateTo(data?.to)
					setAttachmentName(data?.document)
					setAttachment(data?.document)
					setDescription(data?.description)
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}, [requestId])

	const allEmployee = () => {
		api.get('/employee/all_employees')
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data?.data?.data.length > 0) {
						const employeeData = response?.data?.data?.data

						const arr = employeeData.map((employee: any) => ({
							label: (
								<span style={{display: 'flex', gap: '0.2rem'}}>
									{' '}
									<Logo src={employee?.image ? `${process.env.REACT_APP_IMAGE_URL + employee?.image}` : '/images/default_user_profile_img.png'}></Logo>
									{`${employee.firstName}`}
									&nbsp;
									{`${employee.id}`}
								</span>
							),
							value: employee
						}))
						setEmployeeOption(arr)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const createRequest = (e: any) => {
		e?.preventDefault()
		const compareFromDate = moment(dateFrom).format('YYYY-MM-DD')
		const compareToDate = moment(dateTo).format('YYYY-MM-DD')
		if (moment(compareFromDate, 'YYYY-MM-DD').isAfter(moment(compareToDate, 'YYYY-MM-DD'))) {
			toast.error('From date should be smaller')
			return
		}
		if (formValidation()) {
			setIsLoading(true)
			api.post(`/request/create`, {
				requestType: 'Leave Request',
				from: moment(dateFrom).format('YYYY-MM-DD'),
				to: moment(dateTo).format('YYYY-MM-DD'),
				document: attachment,
				description: description,
				employeeId: employeeId
			})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Request created successfully')
						_navigate('/requests')
					}
					if (response?.data?.success == false) {
						toast.error(response?.data?.message ? response?.data?.message : 'Request creation failed')
					}
					setIsLoading(false)
				})
				.catch(function (error) {
					setIsLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}
	const updateRequest = (e: any) => {
		e?.preventDefault()
		const compareFromDate = moment(dateFrom).format('YYYY-MM-DD')
		const compareToDate = moment(dateTo).format('YYYY-MM-DD')
		if (moment(compareFromDate, 'YYYY-MM-DD').isAfter(moment(compareToDate, 'YYYY-MM-DD'))) {
			toast.error('From date should be smaller')
			return
		}
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/request/update_request/${requestId}`, {
				requestType: 'Leave Request',
				employeeName: employee.label,
				from: moment(dateFrom).format('YYYY-MM-DD'),
				to: moment(dateTo).format('YYYY-MM-DD'),
				document: attachment,
				description: description,
				employeeId: employeeId,
				employeeImage: employee.image
			})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Request updated successfully')
						_navigate('/requests')
					}
					setIsLoading(false)
				})
				.catch(function (error) {
					setIsLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (!employeeId) {
			setEmployeeError('Field is required')
			isValid = false
		}
		if (dateFrom === '') {
			setDateFromError('Date required')
			isValid = false
		}
		if (dateTo === '') {
			setDateToError('Date required')
			isValid = false
		}

		return isValid
	}

	return (
		<>
			<Section>
				<Container>
					<Row justifyContent="center">
						<Col lg={11}>
							<Container>
								<Title direction="row" gap={1}>
									<HeadingWrapper>
										<JobIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="25px">
											<path d="M 12 0 C 10.90625 0 10 0.90625 10 2 L 10 4 L 4 4 C 2.839844 4 2 4.839844 2 6 L 2 13 L 48 13 L 48 6 C 48 4.839844 47.160156 4 46 4 L 40 4 L 40 2 C 40 0.90625 39.09375 0 38 0 L 36 0 C 34.90625 0 34 0.90625 34 2 L 34 4 L 16 4 L 16 2 C 16 0.90625 15.09375 0 14 0 Z M 12 2 L 14 2 L 14 8 L 12 8 Z M 36 2 L 38 2 L 38 8 L 36 8 Z M 2 15 L 2 46 C 2 47.105469 2.894531 48 4 48 L 46 48 C 47.105469 48 48 47.105469 48 46 L 48 15 Z M 23.902344 20.167969 L 26.007813 20.167969 C 26.191406 20.167969 26.375 20.265625 26.375 20.550781 L 26.375 34.445313 C 26.375 34.636719 26.28125 34.828125 26.007813 34.828125 L 23.902344 34.828125 C 23.71875 34.828125 23.53125 34.730469 23.53125 34.445313 L 23.53125 20.550781 C 23.53125 20.359375 23.625 20.167969 23.902344 20.167969 Z M 23.898438 37.992188 L 26.097656 37.992188 L 26.097656 38.089844 C 26.28125 38.089844 26.46875 38.183594 26.46875 38.46875 L 26.46875 40.964844 C 26.46875 41.15625 26.375 41.34375 26.097656 41.34375 L 23.898438 41.34375 C 23.714844 41.34375 23.53125 41.25 23.53125 40.964844 L 23.53125 38.375 C 23.53125 38.183594 23.625 37.992188 23.898438 37.992188 Z"></path>
										</JobIcon>

										<Text type="normal" fontWeight={500} color="black" fontSize={1.4}>
											Leave Request
										</Text>
									</HeadingWrapper>
									<div>
										<Back onClick={() => _navigate('/requests')}>
											<svg _ngcontent-kxy-c151="" width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00acac">
												<path
													_ngcontent-kxy-c151=""
													d="M116 121v260c0 13.785 11.215 25 25 25s25-11.215 25-25V121c0-13.785-11.215-25-25-25S116 107.215 116 121zM211.98 243.107c-5.985 3.555-5.996 12.224 0 15.785l219.648 130.416c7.391 4.39 16.269-2.86 13.317-11.014l-42.561-117.712c-1.455-4.026-1.564-8.549-.078-12.823 45.595-131.625 43.871-124.776 43.871-127.686 0 0 0 0 0-.001 0-6.511-7.428-11.61-13.906-7.763L211.98 243.107zM346 451c0 8.272 6.728 15 15 15 8.271 0 15-6.728 15-15 0-8.271-6.729-15-15-15C352.728 436 346 442.729 346 451zM66 396c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 5.515 4.486 10 10 10S66 401.515 66 396zM226 71c0 13.785 11.215 25 25 25 13.785 0 25-11.215 25-25s-11.215-25-25-25C237.215 46 226 57.215 226 71zM250.998 371.003A10 10 0 10250.998 391.003 10 10 0 10250.998 371.003zM350.998 80.997A10 10 0 10350.998 100.997 10 10 0 10350.998 80.997zM51.003 151.003A10 10 0 1051.003 171.003 10 10 0 1051.003 151.003zM460.998 261.003A10 10 0 10460.998 281.003 10 10 0 10460.998 261.003z"></path>
											</svg>
											Back
										</Back>
									</div>
								</Title>
								<Row>
									<Col lg={8}>
										<form onSubmit={requestId ? updateRequest : createRequest}>
											<Row>
												<Col>
													<MultiSelect
														label="Employee"
														value={employeeOption?.find((e: any) => e?.value?.id == employeeId)}
														required
														options={employeeOption}
														onChange={(e: any) => {
															setEmployeeId(e?.value?.id)
															e?.value?.id && setEmployeeError('')
														}}
														error={employeeError}
													/>
													<Spacer height={1} />
												</Col>
												<Col lg={6}>
													<InputDate
														label="From"
														required
														value={dateFrom}
														handleChange={(e: any) => {
															setDateFromError('')
															if (e === '') {
																setDateFromError('Date is required')
															}
															setDateFrom(e)
														}}
														error={dateFromError}
													/>
													<Spacer height={1} />
												</Col>
												<Col lg={6}>
													<InputDate
														label="To"
														required
														value={dateTo}
														handleChange={(e: any) => {
															setDateToError('')
															if (e === '') {
																setDateToError('Date is required')
															}

															setDateTo(e)
														}}
														error={dateToError}
													/>
													<Spacer height={1} />
												</Col>
												<Col>
													<FileUpload label="Attachment" value={attachmentName} setFileName={setAttachmentName} setFileValue={setAttachment} />
													<Spacer height={1} />
												</Col>

												<Col>
													<InputField
														type="textarea"
														label="Reason for Leave"
														value={description}
														handleChange={(e: any) => {
															setDescription(e)
														}}
													/>
													<Spacer height={1} />
												</Col>
											</Row>

											<ButtonRow direction="row" gap={1}>
												<CancelButton onClick={() => _navigate('/requests')}>Cancel</CancelButton>
												<SaveButton
													type="submit"
													onClick={(e: any) => {
														requestId ? updateRequest(e) : createRequest(e)
													}}>
													{requestId ? 'Save' : 'Create'}
												</SaveButton>
											</ButtonRow>
										</form>
									</Col>
									<Col lg={4}></Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>
			</Section>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Section = styled.div`
	margin-top: 16px;
`
const Title = styled(Flexed)`
	top: 57px;
	width: 100%;
	padding: 2rem 0rem;
	z-index: 2;
	display: flex;
	justify-content: space-between;
`
const ButtonRow = styled(Flexed)`
	top: 57px;
	width: 100%;
	padding: 2rem 0rem;
	z-index: 2;
	display: flex;
	justify-content: flex-end;
`
const JobIcon = styled.svg`
	max-width: 25px;
	fill: ${palette.purple_1};
	stroke-width: 2px;
	stroke-linecap: round;
`
const HeadingWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 1rem;
`

const CancelButton = styled.div`
	font-size: 0.9rem;
	height: 25px;
	padding: 20px;
	border-radius: 25px;
	color: ${palette.white};
	font-weight: 400;
	cursor: pointer;
	min-width: 3rem;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
	font-family: 'Montserrat', sans-serif;
	background: #fb7979;
	border-color: #fb7979;
	margin-left: 3px;
	transition: all 0.3s ease;
	min-width: 85px;
	cursor: pointer;
	&:hover {
		background: #fb7979;
		border-color: #fb7979;
		box-shadow: 0 0 0 3px #fb79794d;
	}
`
const SaveButton = styled.button`
	font-size: 0.9rem;
	height: 25px;
	padding: 20px;
	border-radius: 25px;
	color: ${palette.white};
	font-weight: 400;
	cursor: pointer;
	min-width: 3rem;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
	font-family: 'Montserrat', sans-serif;
	background: ${palette.purple_1};
	margin-left: 3px;
	transition: all 0.3s ease;
	min-width: 85px;
	cursor: pointer;
	border: none;
`

export default LeaveRequest
