import React, {useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {media} from 'styled-bootstrap-grid'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import DropDown from '../common/DropDown'
import InputField from '../common/InputField'
import {toast} from 'react-toastify'
import moment from 'moment-timezone'
import InputDate from '../common/InputDate'
import {api} from '../api/callAxios'

interface ICategoriesModalProps {
	onCloseModal: any
	setIsLoading: any
	editHoliday: any
	refreashTable: any
}

const AddPublicHoliday = ({editHoliday, refreashTable, setIsLoading, onCloseModal}: ICategoriesModalProps) => {
	const [holidayName, setHolidayName] = useState(editHoliday?.holidayName ? editHoliday?.holidayName : '')
	const [fromDate, setFromDate] = useState(editHoliday?.holidayFrom ? new Date(moment(editHoliday?.holidayFrom, 'DD-MM-YYYY').format()) : new Date())
	const [toDate, setToDate] = useState(editHoliday?.holidayTo ? new Date(moment(editHoliday?.holidayTo, 'DD-MM-YYYY').format()) : new Date())

	const [holidayNameError, setHolidayNameError] = useState('')
	const [fromDateError, setFromDateError] = useState('')
	const [toDateError, setToDateError] = useState('')

	const addHoliday = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.post('/holiday/add_holiday', {
				holidayName: holidayName,
				holidayFrom: moment(fromDate).format('DD-MM-YYYY'),
				holidayTo: moment(toDate).format('DD-MM-YYYY')
			})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Holiday added successfully')
						onCloseModal()
						refreashTable()
					}
					if (response?.data?.success == false) {
						toast.error(response?.data?.message ? response?.data?.message : 'Failed to add holiday')
					}
					setIsLoading(false)
				})
				.catch(function (error) {
					setIsLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	const updateHoliday = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/holiday/update_holiday/${editHoliday?.id}`, {
				holidayName: holidayName,
				holidayFrom: moment(fromDate).format('DD-MM-YYYY'),
				holidayTo: moment(toDate).format('DD-MM-YYYY')
			})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Holiday updated successfully')
						onCloseModal()
						refreashTable()
					}
					if (response?.data?.success == false) {
						toast.error(response?.data?.message ? response?.data?.message : 'Failed to update holiday')
					}
					setIsLoading(false)
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (holidayName == '') {
			setHolidayNameError('This value is required.')
			isValid = false
		}
		return isValid
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
							{editHoliday ? 'Edit Public Holiday' : 'Add Public Holiday'}
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Spacer height={1} />
					<Body>
						<Container>
							<Row>
								<Col>
									<InputField
										label="Holiday Name"
										required
										placeholder=""
										value={holidayName}
										error={holidayNameError}
										handleChange={(e: any) => {
											setHolidayNameError('')
											if (e === '') {
												setHolidayNameError('This value is required.')
											}
											setHolidayName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>

								<Col lg={6}>
									<InputDate
										label="From Date"
										error={fromDateError}
										required
										value={fromDate}
										handleChange={(e: any) => {
											setFromDateError('')
											if (moment(e).isAfter(toDate)) {
												setFromDateError('The From date should be less than or equal to To date')
												setFromDate(toDate)
											} else {
												if (e === '') {
													setFromDateError('This value is required.')
												}
												setFromDate(e)
											}
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="To Date"
										error={toDateError}
										required
										value={toDate}
										handleChange={(e: any) => {
											setToDateError('')
											if (!moment(e).isAfter(fromDate)) {
												setToDate(toDate)
											}
											if (e === '') {
												setToDateError('This value is required.')
											}
											setToDate(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Body>
					<Spacer height={1} />
					<Footer direction="row" align="center" justify="flex-end" gap={1}>
						<Button
							border="red"
							color="white"
							bg="red"
							bg_hover="red_hover"
							onClick={() => {
								onCloseModal()
							}}>
							Close
						</Button>
						<Button
							border="purple_2"
							color="white"
							bg="purple_1"
							bg_hover="purple_2"
							onClick={() => {
								if (editHoliday) {
									updateHoliday()
								} else {
									addHoliday()
								}
							}}>
							{editHoliday ? 'Update' : 'Save'}
						</Button>
					</Footer>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
	background-color: ${palette.gray_1};
	border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 800px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Footer = styled(Flexed)`
	border-top: 1px solid ${palette.silver};
	padding: 1rem 1.5rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({border}) => palette[border]};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	cursor: pointer;
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

export default AddPublicHoliday
