import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'

const AddChecklistPop = ({addCheckList, top, left, onCloseModal, cardDetails, loadAgain}: any) => {
	const [listName, setListName] = useState('')
	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const addNewCheckList = async () => {
		try {
			await api
				.post(`/trello/create_checklist`, {name: listName, cardId: cardDetails?.id})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
						addCheckList && addCheckList()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	return (
		<Popup top={top} left={left} onClick={handleModalClick}>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				<div></div>
				<Text fontWeight={600}>Add checklist</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			<Flexed margin="0.5rem 0rem" gap={0.5}>
				<div>
					<Text type="xsmall" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
						Title
					</Text>
					<Input
						placeholder=""
						value={listName}
						onChange={(e: any) => {
							setListName(e?.target?.value)
						}}
					/>
				</div>
				<Button onClick={addNewCheckList}>Add</Button>
			</Flexed>
		</Popup>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({top}) => top};
	z-index: 5;
	left: ${({left}) => (left ? left : '0')};
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

const Input = styled.input<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
	outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.purple_1};
	font-weight: 600;
	cursor: pointer;
	width: 5rem;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
`

export default AddChecklistPop
