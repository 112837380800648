import * as types from '../actions/types'

const initialState = {
	isDarkTheme: false,
	loginUserDetails: {},
	loginAdminDetails: {},
	checkOutDetail: null,
	search: null,
	updateTrelloList: false,
	updateTrelloListFav: false,
	updateBoard: false,
	filterCards: {},
	updatedTrelloList: {}
}

export default function authReducer(state = initialState, action: any) {
	switch (action.type) {
		case types.SWITCH_THEME: {
			return {
				...state,
				isDarkTheme: action.value
			}
		}
		case types.LOGIN_USER_DETAILS:
			return {
				...state,
				loginUserDetails: action.value
			}
		case types.LOGIN_ADMIN_DETAILS:
			return {
				...state,
				loginAdminDetails: action.value
			}
		case types.CHECK_OUT_DETAIL:
			return {
				...state,
				checkOutDetail: action.value
			}
		case types.SEARCH:
			return {
				...state,
				search: action.value
			}
		case types.UPDATE_LIST:
			return {
				...state,
				updateTrelloList: action.value
			}
		case types.UPDATE_BOARD_LIST:
			return {
				...state,
				updateTrelloListFav: action.value
			}
		case types.UPDATE_BOARD:
			return {
				...state,
				updateBoard: action.value
			}
		case types.FILTER_BOARD_CARDS:
			return {
				...state,
				filterCards: action.value
			}
		case types.UPDATED_LIST:
			return {
				...state,
				updatedTrelloList: action.value
			}

		default:
			return state
	}
}
