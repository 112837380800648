import React, { useState,useRef,useEffect } from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import { Divider, Flexed, Spacer, Text } from '../../styled/shared';
import { palette } from '../../styled/colors'
import { HiOutlineUsers } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { GoDotFill } from 'react-icons/go';
import DeleteCard from './DeleteCard'

const ShareCard = ({ onCloseModal }: any) => {
    const _openDeleteCardPopRef: any = useRef(null)
    const [showQRCode, setShowQRCode] = useState(false);
    const [openDeleteCard, setOpenDeleteCard] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (_openDeleteCardPopRef?.current && !_openDeleteCardPopRef?.current?.contains(event.target)) {
                setOpenDeleteCard(false)
            }
        }
        window.addEventListener('click', handleClickOutside)
        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const handleModalClick = (event: any) => {
        event.stopPropagation();
    };
    return (
        <>
            <Popup onClick={handleModalClick}>
                <Flexed direction='row' margin='0rem 0.5rem' align='center' justify='space-between' gap={0.2}>
                    <div></div>
                    <Text fontWeight={600}>Share and more…</Text>
                    <CloseCover onClick={onCloseModal}>
                        <IoMdClose cursor='pointer' />
                    </CloseCover>
                </Flexed>
                <Flexed margin='0.5rem 0rem'>
                    <List direction='row'>
                        <Text type='small' fontWeight={500}>Print…</Text>
                    </List>
                    <List direction='row'>
                        <Text type='small' fontWeight={500}>Export JSON</Text>
                    </List>
                    <Divider margin='0.2rem 0rem 0.5rem 0rem' />
                    <div>
                        <Flexed direction='row' gap={0.3}>
                            <Text type='xsmall' margin='0rem 0rem 0.2rem 0rem' fontWeight={600}>Link to this card</Text>
                            <HiOutlineUsers />
                        </Flexed>
                        <Input placeholder=''/>
                    </div>
                    <Spacer height={0.5} />
                    <TextHover pointer type='small' color='purple_1' fontWeight={500} onClick={()=>setShowQRCode(!showQRCode)}>{showQRCode ? 'Hide QR Code' : 'Show QR Code'}</TextHover>
                    {showQRCode && <Flexed margin='0.5rem 0rem 0rem 0rem' gap={0.5} direction='row'>
                        <QraCode src='/images/qra.png' alt='qra'/>
                        <Flexed gap={0.5}>
                        <Text type='small' fontWeight={500}>Link anyone to this card by sending them this QR code:</Text>
                        <Button>Download</Button>
                        </Flexed>
                    </Flexed>}
                    <Spacer height={0.5} />
                    <div>
                        <Text type='xsmall' margin='0rem 0rem 0.2rem 0rem' fontWeight={600}>Embed this card</Text>
                        <Input placeholder=''/>
                    </div>
                    <Spacer height={0.5} />
                    <div>
                        <Text type='xsmall' margin='0rem 0rem 0.2rem 0rem' fontWeight={600}>Email for this card</Text>
                        <Input placeholder=''/>
                    </div>
                    <Spacer height={0.5} />
                    <Text type='xsmall' fontWeight={600}>Emails sent to this address will appear as a comment by you on the card</Text>
                    <Divider margin='0.5rem 0rem' />

                    <Flexed direction='row' align='center' gap={0.2}>
                        <Text type='xsmall' fontWeight={600}>Card #0</Text>
                        <Text type='xsmall' fontWeight={500}>Added 21 Mar at 12:44</Text>
                        <GoDotFill fontSize={8} />
                        <Actions>
                        <TextHover pointer type='small' color='purple_1' fontWeight={600} onClick={()=> setOpenDeleteCard(!openDeleteCard)}>Delete</TextHover>
                        {openDeleteCard && <DeleteCard onCloseModal={()=> setOpenDeleteCard(false)}/>}
                        </Actions>
                    </Flexed>
                </Flexed>
            </Popup>
        </>
    )
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const List = styled(Flexed)`
	width: 100%;
	padding: 0.4rem 0.313rem;
    border-radius: 0.3rem;
	background: ${({ active }) => active ? palette.silver_1 : palette.white};
	position: relative;
	cursor:pointer;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Button = styled.div <any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.purple_1};
	font-weight: 600;
	cursor: pointer;
	width: fit-content;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
`

const Popup = styled.div<any>`
	position: absolute;
	bottom: 2.5rem;
	z-index:5;
	right:0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding:0.5rem 0.7rem;
	width: 300px;
`

const Input = styled.input <any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
    outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
`

const TextHover = styled(Text) <any>`
    width: fit-content;
	&:hover{
        text-decoration: underline;s
    }
`

const QraCode = styled.img <any>`
    width: 6.25rem;
    height: 6.25rem;
`

const Actions = styled.div`
    position: relative;
`;

export default ShareCard
