import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text, Spacer} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BiUserPlus} from 'react-icons/bi'
import {FaRegClock} from 'react-icons/fa'
import {MdAlternateEmail} from 'react-icons/md'
import {BsEmojiSmile} from 'react-icons/bs'
import {Popup} from '../modals/TrelloTaskModal'
import {FiCheckSquare} from 'react-icons/fi'
import {IoMdClose} from 'react-icons/io'
import AssignPop from './AssignPop'
import MentionMemberPop from './MentionMemberPop'
import EmojiPop from './EmojiPop'
import DatePop from './DatePop'
import CheckBoxList from './CheckBoxList'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {ProfileImg, TagUser} from './AddMemberPop'
import {getInitials} from '../../utils'

const CheckListsView = ({onDeleteList, checklist, loadAgain, cardMembers, boardMembers, cardDetails}: any) => {
	const _deletePopRef: any = useRef(null)
	const _assignPopRef: any = useRef(null)
	const _datePopRef: any = useRef(null)
	const _assignMentionMemberRef: any = useRef(null)
	const _assignEmojiRef: any = useRef(null)
	const [showAddItemContent, setShowAddItemContent] = useState(true)
	const [openDeletePop, setOpenDeletePop] = useState(false)
	const [openAssignPop, setOpenAssignPop] = useState(false)
	const [openDatePop, setOpenDatePop] = useState(false)
	const [openMentionMemberPop, setOpenMentionMemberPop] = useState(false)
	const [openEmojiPop, setOpenEmojiPop] = useState(false)
	const [name, setName] = useState<any>('')
	const [opendListId, setOpendListId] = useState<any>('')

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_deletePopRef.current && !_deletePopRef.current.contains(event.target) && openDeletePop) {
				setOpenDeletePop(false)
			}
			if (_assignPopRef.current && !_assignPopRef.current.contains(event.target)) {
				setOpenAssignPop(false)
			}
			if (_datePopRef.current && !_datePopRef.current.contains(event.target)) {
				setOpenDatePop(false)
			}
			if (_assignMentionMemberRef.current && !_assignMentionMemberRef.current.contains(event.target)) {
				setOpenMentionMemberPop(false)
			}
			if (_assignEmojiRef.current && !_assignEmojiRef.current.contains(event.target)) {
				setOpenEmojiPop(false)
			}
		}

		document.addEventListener('click', handleClickOutside)

		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const addNewCheckList = async (id = '', cardId = '') => {
		try {
			await api
				.post(`/trello/create_checklist`, {name: name, cardId: cardId, parentId: id})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
						setName('')
						setOpendListId('')
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const updateCheckList = async (id = '', hideChecklist = false, isCheck = false, name?: any) => {
		try {
			await api
				.put(`/trello/update_checklist/${id}`, {name: name, hideChecklist: hideChecklist, isCheck})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
						setOpendListId('')
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const deleteCheckList = async (id = '') => {
		try {
			await api
				.delete(`/trello/delete_checklist/${id}`)
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
						setOpendListId('')
						setOpenDeletePop(false)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const subChecklists = checklist?.subChecklists || []

	const checkedSubChecklists = subChecklists.filter((e: any) => e?.isCheck).length

	const totalSubChecklists = subChecklists.length
	let percentage = (checkedSubChecklists / totalSubChecklists) * 100
	percentage = percentage ? percentage : 0

	return (
		<>
			<Flexed direction="row">
				<Icon>
					<FiCheckSquare fontSize={16} style={{marginRight: '10px'}} />
				</Icon>
				<Wrapper gap={0.5}>
					<Flexed direction="row" justify="space-between" align="center" key={checklist?.id}>
						<Text type="normal" fontWeight="700" color="black_3">
							{checklist?.name}
						</Text>
						<Flexed direction="row" align="center" justify="flex-end" gap={0.5}>
							<HideCheckButton
								onClick={() => {
									updateCheckList(checklist?.id, !checklist?.hideChecklist, checklist?.isCheck, checklist?.name)
								}}>
								{checklist?.hideChecklist ? `Show checked items (${checkedSubChecklists})` : 'Hide checked items'}
							</HideCheckButton>
							<Cover
								ref={_deletePopRef}
								onClick={() => {
									setOpendListId(checklist?.id)
									setShowAddItemContent(false)
									setOpenDeletePop(true)
								}}>
								<CheckListDeleteButton>Delete</CheckListDeleteButton>
								{openDeletePop && opendListId == checklist?.id && (
									<Popup top="2.3rem" onClick={handleModalClick}>
										<Flexed direction="row" align="center" justify="space-between" gap={0.2}>
											<div></div>
											<Text fontWeight={600}>Delete Checklist?</Text>
											<IoMdClose cursor="pointer" onClick={() => setOpenDeletePop(false)} />
										</Flexed>
										<Spacer height={0.5} />
										Deleting a checklist is permanent and there is no way to get it back.
										<Spacer height={0.5} />
										<Delete
											onClick={() => {
												deleteCheckList(checklist?.id)
												setOpenDeletePop(false)
											}}>
											Delete checklist
										</Delete>
									</Popup>
								)}
							</Cover>
						</Flexed>
					</Flexed>
				</Wrapper>
			</Flexed>
			<Flexed direction="row" align="center" margin="0.5rem 0rem">
				<Icon>
					<Text type="xsmall">{percentage?.toFixed(0)}%</Text>
				</Icon>
				<ProgressContent>
					<ProgressBar width={percentage?.toFixed(0)} />
				</ProgressContent>
			</Flexed>
			{checklist?.subChecklists?.map((subList: any) =>
				subList?.isCheck && checklist?.hideChecklist ? '' : <CheckBoxList subList={subList} loadAgain={loadAgain} cardMembers={cardMembers} boardMembers={boardMembers} cardDetails={cardDetails} />
			)}

			{showAddItemContent && opendListId == checklist?.id ? (
				<Flexed margin="0rem 0rem 0rem 2rem">
					<Input placeholder="Add an item" type="textarea" rows={2} onChange={(e: any) => setName(e.target?.value)} />
					<Flexed margin="0.5rem 0rem 0rem 0rem" direction="row" justify="space-between" align="center" gap={0.5}>
						<Flexed direction="row" gap={0.5} align="center">
							<Button
								onClick={() => {
									if (name?.trim()?.length) {
										addNewCheckList(checklist?.id, checklist?.cardId)
									}
								}}>
								Add
							</Button>
							<Button
								cancel
								onClick={() => {
									setOpendListId(checklist?.id)
									setShowAddItemContent(false)
								}}>
								Cancel
							</Button>
						</Flexed>
						<CustomFlex direction="row" align="center">
							{checklist?.taskChecklistMembers?.map((e: any) => (
								<Flexed direction="row" gap={0.5}>
									{e?.employee?.image ? (
										<ProfileImg>
											<img src={process.env.REACT_APP_IMAGE_URL + e?.employee?.image} alt={e?.employee?.firstName + ' ' + e?.employee?.lastName} />
										</ProfileImg>
									) : (
										<TagUser width="1.8rem" height="1.8rem">
											{e?.employee?.firstName + ' ' + e?.employee?.lastName && getInitials(e?.employee?.firstName + ' ' + e?.employee?.lastName)}
										</TagUser>
									)}
								</Flexed>
							))}
							<div ref={_assignPopRef} onClick={() => setOpenAssignPop(!openAssignPop)}>
								<Link direction="row" gap={0.1} align="center">
									<BiUserPlus fontSize={18} />
									Assign
								</Link>
								{openAssignPop && <AssignPop onCloseModal={() => setOpenAssignPop(false)} />}
							</div>
							<div ref={_datePopRef} onClick={() => setOpenDatePop(!openDatePop)}>
								<Link direction="row" gap={0.2} align="center">
									<FaRegClock fontSize={14} />
									Due date
								</Link>
								{openDatePop && <DatePop bottom="-7rem" onCloseModal={() => setOpenDatePop(false)} />}
							</div>
							<div ref={_assignMentionMemberRef} onClick={() => setOpenMentionMemberPop(!openMentionMemberPop)}>
								<Link direction="row" align="center">
									<MdAlternateEmail fontSize={14} />
								</Link>
								{openMentionMemberPop && <MentionMemberPop onCloseModal={() => setOpenMentionMemberPop(false)} />}
							</div>
							<div ref={_assignEmojiRef} onClick={() => setOpenEmojiPop(!openEmojiPop)}>
								<Link direction="row" align="center">
									<BsEmojiSmile fontSize={14} />
								</Link>
								{openEmojiPop && <EmojiPop onCloseModal={() => setOpenEmojiPop(false)} />}
							</div>
						</CustomFlex>
					</Flexed>
				</Flexed>
			) : (
				<AddItemButton
					onClick={() => {
						setOpendListId(checklist?.id)
						setShowAddItemContent(true)
					}}>
					Add an item
				</AddItemButton>
			)}
			<Spacer height={0.5} />
		</>
	)
}

const Icon = styled.span`
	font-size: 1.3rem;
	color: black;
	width: 2rem;
`

const Wrapper = styled(Flexed)`
	width: 100%;
`

const ProgressContent = styled.div`
	background: ${palette.silver_14};
	border-radius: 0.25rem;
	width: 100%;
	height: 0.5rem;
	overflow: hidden;
	position: relative;
`

const ProgressBar = styled.div<any>`
	background: ${palette.purple_1};
	border-radius: 0.25rem;
	width: ${({width}) => `${width}%`};
	height: 0.5rem;
	overflow: hidden;
	position: relative;
`

const Input = styled.textarea<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
	outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
	&:focus {
		box-shadow: inset 0 0 0 2px var(--ds-border-input, ${palette.purple_1});
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({cancel}) => (cancel ? palette.black : palette.white)};
	background: ${({cancel}) => (cancel ? palette.white : palette.purple_1)};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		color: ${({cancel}) => (cancel ? palette.black : palette.white)};
		background: ${({cancel}) => (cancel ? palette.silver_14 : palette.purple_2)};
	}
`

const Delete = styled(Button)`
	width: 100%;
	background: ${palette.red};
	&:hover {
		background: ${palette.red_hover};
	}
`

const HideCheckButton = styled(Button)`
	width: 100%;
	color: ${palette.black};
	background: ${palette.silver_14};
	&:hover {
		color: ${palette.black};
		background: ${palette.silver_1};
	}
`

export const Link = styled(Flexed)<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.black};
	background: ${palette.white};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	position: relative;
	&:hover {
		background: ${palette.silver_14};
	}
`

const CheckListDeleteButton = styled.label<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.black};
	background: ${palette.silver_14};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	margin-bottom: 0;
	&:hover {
		background: ${palette.silver_1};
	}
`

const AddItemButton = styled(CheckListDeleteButton)`
	width: fit-content;
	margin-left: 2rem;
`

const Cover = styled.div`
	position: relative;
`

const CustomFlex = styled(Flexed)`
	position: relative;
`

export default CheckListsView
