import React, {useState} from 'react'
import styled from 'styled-components'
import {AuthFilledButton, AuthUnFilledButton, CopyRight, Flexed, Loading, Spacer, Text} from '../../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector, useDispatch} from 'react-redux'
import InputFieldAuth from '../../components/common/InputFieldAuth'
import {CommonEnum} from '../../utils'
import {useNavigate} from 'react-router-dom'
import InputPhoneNo from '../../components/common/InputPhoneNo'
import {palette} from '../../styled/colors'
import {BsCheckSquareFill} from 'react-icons/bs'
import {ImCheckboxUnchecked} from 'react-icons/im'
import useWindowSize from '../../hooks/useWindowSize'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'

const SignUp = () => {
	let _navigate = useNavigate()
	const _regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
	const _windowSize = useWindowSize()
	const [isLoading, setIsLoading] = useState(false)
	const [name, setName] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [companyNameError, setCompanyNameError] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [phone, setPhone] = useState('')
	const [agreeWithPolicy, setAgreeWithPolicy] = useState(false)

	const [nameError, setNameError] = useState('')
	const [emailError, setEmailError] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const [phoneError, setPhoneError] = useState('')
	const [agreeWithPolicyError, setAgreeWithPolicyError] = useState('')

	const doSignUp = async (e: any) => {
		e.preventDefault()
		if (formValidation()) {
			setIsLoading(true)
			await api
				.post(`/admin/register`, {
					name: name,
					companyName: companyName,
					email: email,
					password: password,
					phone: phone,
					agreeWithPolicy: agreeWithPolicy
				})
				.then(async (res) => {
					setIsLoading(false)
					if (res?.data?.success) {
						toast.success(res?.data?.message)
						_navigate('/login')
					} else {
						toast.error(res?.data?.message)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (name === '') {
			setNameError('Name is required.')
			isValid = false
		}
		if (companyName === '') {
			setCompanyNameError('Company name is required.')
			isValid = false
		}
		if (!_regex.test(email)) {
			setEmailError('Invalid email.')
			isValid = false
		}
		if (email === '') {
			setEmailError('Email is required.')
			isValid = false
		}
		if (password === '') {
			setPasswordError('Password is required.')
			isValid = false
		}
		if (phone === '') {
			setPhoneError('Phone number is required.')
			isValid = false
		}
		if (agreeWithPolicy === false) {
			setAgreeWithPolicyError('This value is required.')
			isValid = false
		}
		return isValid
	}

	return (
		<>
			<MainWrapper>
				<Flexed direction="row" justify="center">
					<ImageContent>
						<CoverImage src="/images/logo.png" alt="login_bg" />
					</ImageContent>
					<LoginContent>
						<Wrapper>
							<InnerWrapper>
								<Row justifyContent="center">
									<Col lg={10}>
										<form onSubmit={doSignUp}>
											<Text fontSize={_windowSize?.width < 600 ? 1.5 : 1.875} lineHeight={2} fontWeight={600} color="black_3">
												Modernize your HR operations
											</Text>
											<Spacer height={1} />
											<Text fontSize={0.813} color="black_7">
												Welcome to {CommonEnum.AppName} , Please fill the form below and enjoy your free trial
											</Text>
											<Spacer height={1.8} />
											<Grid>
												<div>
													<InputFieldAuth
														label="Name"
														required
														type="text"
														value={name}
														error={nameError}
														handleChange={(e: any) => {
															setNameError('')
															if (e === '') {
																setNameError('Name is required.')
															}
															setName(e)
														}}
													/>
												</div>
												<div>
													<InputFieldAuth
														label="Company Name"
														type="text"
														required
														value={companyName}
														error={companyNameError}
														handleChange={(e: any) => {
															setCompanyNameError('')
															if (e === '') {
																setCompanyNameError('Company name is required.')
															}
															setCompanyName(e)
														}}
													/>
												</div>
												<div>
													<InputFieldAuth
														label="Email"
														required
														type="text"
														value={email}
														error={emailError}
														handleChange={(e: any) => {
															setEmailError('')
															if (e === '') {
																setEmailError('Email is required.')
															} else if (!_regex.test(e)) {
																setEmailError('Invalid email.')
															}
															setEmail(e)
														}}
													/>
												</div>
												<div>
													<InputFieldAuth
														label="Password"
														required
														type="password"
														value={password}
														error={passwordError}
														handleChange={(e: any) => {
															setPasswordError('')
															if (e.length < 8) {
																setPasswordError('Password length should be minimum eight.')
															}
															if (e === '') {
																setPasswordError('Password is required.')
															}
															setPassword(e)
														}}
													/>
												</div>
												<div>
													<InputPhoneNo
														error={phoneError}
														label="Phone"
														required
														value={phone}
														handleChange={(e: any) => {
															setPhoneError('')
															if (e?.value == '') {
																setPhoneError('Phone number is required.')
															}
															setPhone(e?.value)
														}}
													/>
												</div>
											</Grid>
											<Spacer height={1} />
											<Flexed gap={0.2}>
												<Flexed direction="row" align="center" gap={0.2}>
													<CheckIconWrapper>
														{agreeWithPolicy ? (
															<Checked
																onClick={() => {
																	setAgreeWithPolicy(!agreeWithPolicy)
																	setAgreeWithPolicyError('')
																}}
															/>
														) : (
															<UnChecked
																onClick={() => {
																	setAgreeWithPolicy(!agreeWithPolicy)
																	setAgreeWithPolicyError('')
																}}
															/>
														)}
													</CheckIconWrapper>
													<CustomText fontSize={0.813} fontWeight={500} color="black_3">
														I agree to the <span>Privacy Policy</span> and <span>Terms of Service</span>
													</CustomText>
												</Flexed>
												{agreeWithPolicyError && (
													<Text fontSize={0.7} type="small" color="danger">
														{agreeWithPolicyError}
													</Text>
												)}
											</Flexed>

											<Spacer height={2} />
											<Flexed direction="row" gap={1}>
												<AuthFilledButton type="submit" onClick={doSignUp}>
													Sign Up
												</AuthFilledButton>
												<AuthUnFilledButton
													onClick={() => {
														_navigate('/login')
													}}>
													Login
												</AuthUnFilledButton>
											</Flexed>
										</form>
									</Col>
								</Row>
							</InnerWrapper>
							<CopyRight>© 2024 {CommonEnum.AppName}, All rights reserved</CopyRight>
						</Wrapper>
					</LoginContent>
				</Flexed>
			</MainWrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled.div`
	height: calc(100vh - 57px);
`

const LoginContent = styled.div`
	width: 100%;
	height: calc(100vh - 57px);
	background: url(/images/white_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	${media.sm`width: 80%;`};
	${media.md`width: 60%;`};
	${media.lg`width: 50%;`};
`

const Wrapper = styled.div`
	position: relative;
	background: #ffffffcc;
	height: 100%;
	display: flex;
	${media.lg` align-items: center;`};
`

const InnerWrapper = styled(Container)`
	margin-top: 2rem;
	margin-bottom: 0rem;
	${media.lg`margin-bottom: 6rem; margin-top: 0rem;`}
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.8rem;
	${media.lg`  grid-template-columns: 1fr 1fr;`};
`

const ImageContent = styled.div`
	width: 50%;
	display: none;
	${media.lg`
		display: flex;
		justify-content: center;
		align-items: center;
	`};
`

const CoverImage = styled.img`
	// height: 100vh;
	// width: 100%;
	width: 20rem;
	object-fit: cover;
	object-position: bottom;
`

const CustomText = styled(Text)`
	bottom: 3rem;
	left: 0;
	right: 0;
	text-align: center;
	& span {
		color: ${palette.purple_1};
		cursor: pointer;
	}
	& span:hover {
		color: ${palette.blue_1};
		cursor: pointer;
		text-decoration: underline;
		text-decoration-thickness: 1px;
		text-underline-offset: 1px;
	}
`

export const CheckIconWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	cursor: pointer;
	width: 1.25rem;
`

export const Checked = styled(BsCheckSquareFill)<any>`
	color: ${palette.purple_1};
	pointer-events: ${({disabled}) => (disabled ? 'none' : '')};
	cursor: pointer;
`
export const UnChecked = styled(ImCheckboxUnchecked)<any>`
	color: ${palette.black_7};
	pointer-events: ${({disabled}) => (disabled ? 'none' : '')};
	cursor: pointer;
`

export default SignUp
