import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {Checked, CheckIconWrapper, UnChecked} from '../../pages/Auth/SignUp'

const LabelsList = ({editLabel, color, onAdd, onRemove, cardDetails, isEdit, checked}: any) => {
	const [isChecked, setIsChecked] = useState(false)

	useEffect(() => {
		if (checked) {
			setIsChecked(checked)
		} else {
			setIsChecked(cardDetails?.taskLabels?.some((e: any) => e?.color?.color == color?.color))
		}
	}, [cardDetails?.taskLabels?.length, checked])
	return (
		<Wrapper direction="row" align="center" gap={0.5} justify="space-between">
			<CheckIconCover>
				{cardDetails?.taskLabels?.some((e: any) => e?.color?.color == color?.color) || isChecked ? (
					<Checked
						onClick={() => {
							onAdd && onAdd({name: editLabel, color})
							setIsChecked(!isChecked)
						}}
					/>
				) : (
					<UnChecked
						color="rgb(221 223 229)"
						onClick={() => {
							setIsChecked(!isChecked)
							onAdd && onAdd({name: editLabel, color})
						}}
					/>
				)}
			</CheckIconCover>
			<ColorWrpper
				direction="row"
				align="center"
				color={color?.color}
				onHoverColor={color?.onHover}
				title={color?.title}
				onClick={() => {
					setIsChecked(!isChecked)
				}}>
				{cardDetails?.taskLabels?.some((e: any) => e?.color?.color == color?.color) ? cardDetails?.taskLabels?.find((e: any) => e?.color?.color == color?.color)?.name : ''}
			</ColorWrpper>
			{isEdit ? (
				''
			) : (
				<ChooseColor onClick={editLabel}>
					<svg width="18" height="18" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M7.82034 14.4893L9.94134 16.6103L18.4303 8.12131L16.3093 6.00031H16.3073L7.82034 14.4893ZM17.7233 4.58531L19.8443 6.70731C20.6253 7.48831 20.6253 8.7543 19.8443 9.53531L10.0873 19.2933L5.13734 14.3433L14.8943 4.58531C15.2853 4.19531 15.7973 4.00031 16.3093 4.00031C16.8203 4.00031 17.3323 4.19531 17.7233 4.58531ZM5.20094 20.4097C4.49794 20.5537 3.87694 19.9327 4.02094 19.2297L4.80094 15.4207L9.00994 19.6297L5.20094 20.4097Z"
							fill="currentColor"></path>
					</svg>
				</ChooseColor>
			)}
		</Wrapper>
	)
}

const Wrapper = styled(Flexed)`
	width: 100%;
`

const ChooseColor = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	color: ${palette.black};
	&:hover {
		background: ${palette.silver_1};
	}
`

const CheckIconCover = styled(CheckIconWrapper)`
	width: 2.125rem;
`

const ColorWrpper = styled(Flexed)<any>`
	border: none;
	padding: 0rem 0.5rem;
	border-radius: 0.25rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.black};
	background: ${({color}) => (color ? color : palette.silver_14)};
	font-weight: 500;
	cursor: pointer;
	line-height: 32px;
	position: relative;
	width: 100%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	&:hover {
		background: ${({onHoverColor}) => (onHoverColor ? onHoverColor : palette.color)};
	}
`

export default LabelsList
