import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import axios from 'axios'
import Button from '../common/Button'
import {media} from 'styled-bootstrap-grid'

interface ICategoriesModalProps {
	onCloseModal: any
	deleteAction: any
}

interface IProps {
	isDarkTheme: boolean
}

const DeleteAttendanceModal = ({onCloseModal, deleteAction}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                        Delete Attendance
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Spacer height={0.5} />
						<Text type="normal" fontWeight={500} isCentered isDarkTheme={_isDarkTheme}>
                        Are you sure you want to delete this attendance?
						</Text>
						<Spacer height={2} />
						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Button
								label="Cancel"
								small
								type="cancel"
								width="100%"
								ifClicked={() => {
									onCloseModal()
								}}
							/>
							<Button
								label="Delete"
								type="red"
								small
								width="100%"
								ifClicked={() => {
									deleteAction()
								}}
							/>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

export default DeleteAttendanceModal
