import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Text, Flexed} from '../../styled/shared'
import {MdOutlineCloudUpload} from 'react-icons/md'
import {RiDeleteBinLine} from 'react-icons/ri'

const FileUpload = ({label, value, allow, setFileName, setFileValue, required, placeholder, disabled, error, errorMsg, bgTransparent}: any) => {
	const inputId = `fileInput_${Math.random().toString(36).substring(2, 15)}`
	const [inputKey, setInputKey] = useState(Date.now()) // State for key

	const uploadFile = (event: any) => {
		let _fileType = ''
		let _fileName: any = ''
		const file = event.target.files?.[0]
		if (file != undefined) {
			if (file) {
				_fileName = file.name
				_fileType = getFileType(file.name)
			}
			if (_fileType === 'pdf') {
				handlePdf(event)
			} else {
				const _reader: any = new FileReader()
				_reader.readAsDataURL(event.target.files[0])
				_reader.onload = () => {
					if (_reader.readyState === 2) {
						setFileName(_fileName)
						setFileValue(_reader?.result)
					}
				}
			}
		}
		setInputKey(Date.now())
	}

	const getFileType = (fileName: string): string => {
		const _extension = fileName.split('.').pop()?.toLowerCase() || ''
		switch (_extension) {
			case 'pdf':
				return 'pdf'
			case 'jpg':
			case 'jpeg':
			case 'png':
			case 'gif':
				return 'image/' + _extension
			default:
				return ''
		}
	}
	const handlePdf = (event: React.ChangeEvent<HTMLInputElement>) => {
		const _file: any = event.target.files?.[0]
		let _fileName = _file?.name
		if (_file && _file.type === 'application/pdf' && _file.size <= 10 * 1024 * 1024) {
			const _reader = new FileReader()
			_reader.onload = () => {
				const base64String = _reader.result?.toString()
				if (base64String) {
					setFileName(_fileName)
					setFileValue(base64String)
				}
			}

			_reader.readAsDataURL(_file)
		} else {
			// Handle invalid file or exceeding size limit
			console.error('Invalid PDF file or exceeds size limit.')
		}
	}

	const removeFile = () => {
		setFileName('')
		setFileValue('')
		setInputKey(Date.now()) // Update key when file is removed
	}

	return (
		<>
			<Label type="small" margin="0rem 0rem 0.19rem 0rem">
				{label} {required ? <Mandatory>*</Mandatory> : ''}
			</Label>
			<InputWrapper>
				<TextInput value={value}>{value ? value : 'Please Select file'}</TextInput>
				<Cover direction="row" align="center" justify="center">
					{value ? (
						<>
							<Remove onClick={removeFile}>
								Remove &nbsp;
								<RiDeleteBinLine onClick={removeFile} cursor="pointer" fontSize={16} color={palette.red} />
							</Remove>
						</>
					) : (
						<>
							<Browse htmlFor={inputId} aria-label="upload picture">
								Upload &nbsp;
								<MdOutlineCloudUpload cursor="pointer" fontSize={20} color={palette.purple_1} />
							</Browse>
						</>
					)}
				</Cover>
				<FileInput key={inputKey} id={inputId} accept={allow ? allow : '.pdf, image/*'} type="file" onChange={uploadFile} />
			</InputWrapper>
			{required && error && (
				<Text fontSize={0.7} type="small" color="danger">
					{error}
				</Text>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

const InputWrapper = styled.div`
	position: relative;
	background: ${palette.gray_1};
	border: 1px dashed ${palette.silver_13};
	border-radius: 0.375rem;
`

const TextInput = styled.div<any>`
	font-family: 'Roboto';
	height: 52px;
	width: 100%;
	line-height: 2.4rem;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	padding: 0.5rem 7rem 0.5rem 0.8rem;
	color: ${({value}) => (value ? palette.black : palette.black_7)};
	width: 100%;
	overflow: hidden;
	background: ${palette.gray_2};
`

const Browse = styled.label`
	color: ${palette.purple_1};
	border-bottom-right-radiuss: 0.375rem;
	border-top-right-radius: 0.375rem;
	font-family: 'Roboto';
	font-weight: 500;
	font-size: 13px;
	line-height: 32px;
	text-transform: capitalize;
	cursor: pointer;
	margin-bottom: 0rem;
`

const Remove = styled.div`
	color: ${palette.red};
	border-bottom-right-radiuss: 0.375rem;
	border-top-right-radius: 0.375rem;
	font-family: 'Roboto';
	font-weight: 500;
	font-size: 13px;
	line-height: 32px;
	text-transform: capitalize;
	cursor: pointer;
	margin-bottom: 0rem;
	display: flex;
	align-items: center;
`

const Cover = styled(Flexed)`
	position: absolute;
	width: 8rem;
	top: 0rem;
	bottom: 0rem;
	margin: auto;
	right: 0rem;
	padding: 0rem 1rem;
	background: ${palette.gray_1};
	border-bottom-left-radius: 0.375rem;
	border-top-left-radius: 0.375rem;
	border-left: 1px dashed ${palette.silver_13};
`

const FileInput = styled.input`
	display: none !important;
`

const InfoText = styled(Text)`
	text-transform: capitalize;
	font-weight: 400;
`

export default FileUpload
