import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoIosArrowBack} from 'react-icons/io'
import {IoMdClose} from 'react-icons/io'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import useRouter from '../../hooks/router'
import Loader from '../common/Loader'
import {updateBoardList} from '../../actions/authActions'

const MoveAllCardsInList = ({goBack, onCloseModal, listDetails}: any) => {
	const [currentSelectedList, setCurrentSelectedList] = useState('')
	const dispatch = useDispatch()
	const [boardDetailsList, setBoardLists] = useState<any[]>([])
	const router = useRouter()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setCurrentSelectedList(listDetails?.id)
		if (router.query?.id) {
			getBoardById(router.query?.id)
		}
	}, [router.query, listDetails])

	const getBoardById = async (id?: any) => {
		try {
			setLoading(true)
			await api
				.post(`/trello/get_all_lists?id=${router?.query?.id}`)
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							setBoardLists(response?.data?.data)
						}
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const moveAllListCards = async (listId?: any) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_list?id=${listDetails?.id}`, {listId, isMoveCards: true})
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							dispatch(updateBoardList(true))
						}
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	return (
		<>
			{loading && <Loader></Loader>}
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				<CloseCover onClick={goBack}>
					<IoIosArrowBack cursor="pointer" />
				</CloseCover>
				<Text fontWeight={600}>Move all cards in list</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			{boardDetailsList?.map((e: any) => {
				return (
					<PopupList
						active={currentSelectedList === e?.id}
						onClick={() => {
							moveAllListCards(e?.id)
							setCurrentSelectedList(e?.id)
						}}>
						{e?.title} {listDetails?.id === e?.id && '(current)'}
					</PopupList>
				)
			})}

			{/* <PopupList
				active={currentSelectedList === 'Doing'}
				onClick={() => {
					setCurrentSelectedList('Doing')
				}}>
				Doing {currentSelectedList === 'Doing' && '(current)'}
			</PopupList>
			<PopupList
				active={currentSelectedList === 'Done'}
				onClick={() => {
					setCurrentSelectedList('Done')
				}}>
				Done {currentSelectedList === 'Done' && '(current)'}
			</PopupList> */}
		</>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const PopupList = styled(Flexed)<any>`
	width: 100%;
	padding: 0.313rem 0.8rem;
	background-color: ${palette.white};
	position: relative;
	cursor: ${({active}) => (active ? '' : 'pointer')};
	opacity: ${({active}) => (active ? '0.5' : '1')};
	&:hover {
		background: ${({active}) => (active ? '' : palette.silver_1)};
	}
`

export default MoveAllCardsInList
