import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import {Checked, CheckIconWrapper, UnChecked} from '../../pages/Auth/SignUp'
import {BsCheckSquareFill} from 'react-icons/bs'
import {ImCheckboxUnchecked} from 'react-icons/im'
import {Back, Next} from './PersonalIz'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'

const Attendance = ({doBack, setIsLoading, employeeId, setEmployeeId, setEmployeeName, isEdit}: any) => {
	let _navigate = useNavigate()
	const [attendanceRuleApplied, setAttendanceRuleApplied] = useState(true)
	const [missingAttendance, setMissingAttendance] = useState(true)
	const [lateArrival, setLateArrival] = useState(true)
	const [hoursInADay, setHoursInADay] = useState(true)
	const [leaveRestrictions, setLeaveRestrictions] = useState(true)
	const [overTimePolicy, setOverTimePolicy] = useState(false)
	const [earlyOut, setEarlyOut] = useState(true)
	const [notificationEmail, setNotificationEmail] = useState('')

	const [checkoutAccessOnWeb, setCheckoutAccessOnWeb] = useState(false)
	const [checkoutAccessOnMobile, setCheckoutAccessOnMobile] = useState(false)
	const [attendanceThroughQRCode, setAttendanceThroughQRCode] = useState(false)
	const [enableEmployeeTracking, setEnableEmployeeTracking] = useState(false)
	const [eMailNotificationChecksInChecksOut, setEMailNotificationChecksInChecksOut] = useState(false)
	const [checkInNotification, setCheckInNotification] = useState(false)
	const [checkOutNotification, setCheckOutNotification] = useState(false)
	const [permissionsAlloweMailPasswordMobileApp, setPermissionsAlloweMailPasswordMobileApp] = useState(false)
	const [permissionsAllowOtherEmployees, setPermissionsAllowOtherEmployees] = useState(false)

	useEffect(() => {
		if (employeeId) {
			doGetEmployeeDetails(employeeId)
		}
	}, [employeeId])

	const doGetEmployeeDetails = (id: any) => {
		setIsLoading(true)
		api.get(`/employee/get_employee/${id}`)
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					let _data = response?.data?.data?.data
					setEmployeeName(_data?.firstName)
					setNotificationEmail(_data?.notificationEmail)
					setCheckoutAccessOnWeb(_data?.checkoutAccessOnWeb)
					setCheckoutAccessOnMobile(_data?.checkoutAccessOnMobile)
					setAttendanceThroughQRCode(_data?.attendanceThroughQRCode)
					setEnableEmployeeTracking(_data?.enableEmployeeTracking)
					setEMailNotificationChecksInChecksOut(_data?.eMailNotificationChecksInChecksOut)
					setCheckInNotification(_data?.checkInNotification)
					setCheckOutNotification(_data?.checkOutNotification)
					setPermissionsAlloweMailPasswordMobileApp(_data?.permissionsAlloweMailPasswordMobileApp)
					setPermissionsAllowOtherEmployees(_data?.permissionsAllowOtherEmployees)
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const doUpdate = () => {
		setIsLoading(true)
		api.put(`/employee/update_employee/${employeeId}`, {
			notificationEmail: notificationEmail,
			checkoutAccessOnWeb: checkoutAccessOnWeb,
			checkoutAccessOnMobile: checkoutAccessOnMobile,
			attendanceThroughQRCode: attendanceThroughQRCode,
			enableEmployeeTracking: enableEmployeeTracking,
			eMailNotificationChecksInChecksOut: eMailNotificationChecksInChecksOut,
			checkInNotification: checkInNotification,
			checkOutNotification: checkOutNotification,
			permissionsAlloweMailPasswordMobileApp: permissionsAlloweMailPasswordMobileApp,
			permissionsAllowOtherEmployees: permissionsAllowOtherEmployees
		})
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					let _data = response?.data?.data?.data
					setEmployeeName(_data?.firstName)
					toast.success(response?.data?.message ? response?.data?.message : 'employee updated successfully')
					_navigate('/team')
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	return (
		<>
			<Row>
				<Col>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Time rules,Attendance and ESS Access
						</Text>
						<Text type="small" color="black_3">
							Workplace attendance is whether an employee has shown up for work at the appointed hour and time.
						</Text>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col>
					<FilledBox>
						<Text type="small" color="white" fontWeight={500}>
							Time Rule :
						</Text>
						<Spacer height={1} />
						<Container>
							<Row>
								<Col lg={4}>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{attendanceRuleApplied ? (
												<InversChecked
													disabled
													onClick={() => {
														setAttendanceRuleApplied(!attendanceRuleApplied)
													}}
												/>
											) : (
												<InversUnChecked
													disabled
													onClick={() => {
														setAttendanceRuleApplied(!attendanceRuleApplied)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="white">
											Attendance Rule Applied
										</Text>
									</Flexed>
									<Spacer height={1} />
								</Col>
								<Col lg={4}>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{missingAttendance ? (
												<InversChecked
													disabled
													onClick={() => {
														setMissingAttendance(!missingAttendance)
													}}
												/>
											) : (
												<InversUnChecked
													disabled
													onClick={() => {
														setMissingAttendance(!missingAttendance)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="white">
											Missing Attendance
										</Text>
									</Flexed>
									<Spacer height={1} />
								</Col>
								<Col lg={4}>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{lateArrival ? (
												<InversChecked
													disabled
													onClick={() => {
														setLateArrival(!lateArrival)
													}}
												/>
											) : (
												<InversUnChecked
													disabled
													onClick={() => {
														setLateArrival(!lateArrival)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="white">
											Attendance Rule Applied
										</Text>
									</Flexed>
									<Spacer height={1} />
								</Col>
								<Col lg={4}>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{hoursInADay ? (
												<InversChecked
													disabled
													onClick={() => {
														setHoursInADay(!hoursInADay)
													}}
												/>
											) : (
												<InversUnChecked
													disabled
													onClick={() => {
														setHoursInADay(!hoursInADay)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="white">
											Hours in a day
										</Text>
									</Flexed>
									<Spacer height={1} />
								</Col>
								<Col lg={4}>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{leaveRestrictions ? (
												<InversChecked
													disabled
													onClick={() => {
														setLeaveRestrictions(!leaveRestrictions)
													}}
												/>
											) : (
												<InversUnChecked
													disabled
													onClick={() => {
														setLeaveRestrictions(!leaveRestrictions)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="white">
											Leave Restrictions
										</Text>
									</Flexed>
									<Spacer height={1} />
								</Col>
								<Col lg={4}>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{overTimePolicy ? (
												<InversChecked
													disabled
													onClick={() => {
														setOverTimePolicy(!overTimePolicy)
													}}
												/>
											) : (
												<InversUnChecked
													disabled
													onClick={() => {
														setOverTimePolicy(!overTimePolicy)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="white">
											Over Time Policy
										</Text>
									</Flexed>
									<Spacer height={1} />
								</Col>
								<Col lg={4}>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{earlyOut ? (
												<InversChecked
													disabled
													onClick={() => {
														setEarlyOut(!earlyOut)
													}}
												/>
											) : (
												<InversUnChecked
													disabled
													onClick={() => {
														setEarlyOut(!earlyOut)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="white">
											Early Out
										</Text>
									</Flexed>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</FilledBox>
					<Spacer height={1} />
				</Col>
				<Col>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={7}>
									<Flexed direction="row" gap={0.2}>
										<Label type="medium" color="black_3" fontWeight={500}>
											Attendance:
										</Label>
										<Flexed direction="row" align="center" gap={0.2}>
											<CheckIconWrapper>
												{checkoutAccessOnWeb ? (
													<Checked
														onClick={() => {
															setCheckoutAccessOnWeb(!checkoutAccessOnWeb)
														}}
													/>
												) : (
													<UnChecked
														onClick={() => {
															setCheckoutAccessOnWeb(!checkoutAccessOnWeb)
														}}
													/>
												)}
											</CheckIconWrapper>
											<Text fontSize={0.813} fontWeight={500} color="black_3">
												Enable CheckIn CheckOut Access on Web
											</Text>
										</Flexed>
									</Flexed>
									<Spacer height={1.5} />
								</Col>
								<Col lg={5}>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{checkoutAccessOnMobile ? (
												<Checked
													onClick={() => {
														setCheckoutAccessOnMobile(!checkoutAccessOnMobile)
													}}
												/>
											) : (
												<UnChecked
													onClick={() => {
														setCheckoutAccessOnMobile(!checkoutAccessOnMobile)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="black_3">
											Enable CheckIn CheckOut Access on Mobile
										</Text>
									</Flexed>
									<Spacer height={1.5} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Flexed direction="row" gap={0.2}>
										<Label type="medium" color="black_3" fontWeight={500}>
											QR Code:
										</Label>
										<Flexed direction="row" align="center" gap={0.2}>
											<CheckIconWrapper>
												{attendanceThroughQRCode ? (
													<Checked
														onClick={() => {
															setAttendanceThroughQRCode(!attendanceThroughQRCode)
														}}
													/>
												) : (
													<UnChecked
														onClick={() => {
															setAttendanceThroughQRCode(!attendanceThroughQRCode)
														}}
													/>
												)}
											</CheckIconWrapper>
											<Text fontSize={0.813} fontWeight={500} color="black_3">
												Allow mark others attendance through QR Code.
											</Text>
										</Flexed>
									</Flexed>
									<Spacer height={1.5} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Flexed direction="row" gap={0.2}>
										<Label type="medium" color="black_3" fontWeight={500}>
											Employee Tracking:
										</Label>
										<Flexed direction="row" align="center" gap={0.2}>
											<CheckIconWrapper>
												{enableEmployeeTracking ? (
													<Checked
														onClick={() => {
															setEnableEmployeeTracking(!enableEmployeeTracking)
														}}
													/>
												) : (
													<UnChecked
														onClick={() => {
															setEnableEmployeeTracking(!enableEmployeeTracking)
														}}
													/>
												)}
											</CheckIconWrapper>
											<Text fontSize={0.813} fontWeight={500} color="black_3">
												Enable Employee Tracking
											</Text>
										</Flexed>
									</Flexed>
									<Spacer height={1.5} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Flexed direction="row" gap={0.2}>
										<div>
											<Label type="medium" color="black_3" fontWeight={500}>
												Notifications:
											</Label>
										</div>
										<div style={{width: '100%'}}>
											<Flexed direction="row" align="center" gap={0.2}>
												<CheckIconWrapper>
													{eMailNotificationChecksInChecksOut ? (
														<Checked
															onClick={() => {
																setEMailNotificationChecksInChecksOut(!eMailNotificationChecksInChecksOut)
															}}
														/>
													) : (
														<UnChecked
															onClick={() => {
																setEMailNotificationChecksInChecksOut(!eMailNotificationChecksInChecksOut)
															}}
														/>
													)}
												</CheckIconWrapper>
												<Text fontSize={0.813} fontWeight={500} color="black_3">
													Send eMail notification when employee checks-in or out.
												</Text>
											</Flexed>
											<Spacer height={1} />
											{eMailNotificationChecksInChecksOut && (
												<Box>
													<InputField
														label="Email"
														value={notificationEmail}
														handleChange={(e: any) => {
															setNotificationEmail(e)
														}}
													/>
													<Flexed direction="row" justify="flex-end" margin="0.2rem 0rem 0rem 0rem">
														<Text type="xsmall">Notifications will be send to this eMail Address, Multiple eMail addresses must be comma-separated</Text>
													</Flexed>
													<Flexed direction="row" align="center" gap={2}>
														<Flexed direction="row" align="center" gap={0.2}>
															<Text fontSize={0.813} fontWeight={500} color="black_3">
																Send Check-In Notification
															</Text>
															<CheckIconWrapper>
																{checkInNotification ? (
																	<Checked
																		onClick={() => {
																			setCheckInNotification(!checkInNotification)
																		}}
																	/>
																) : (
																	<UnChecked
																		onClick={() => {
																			setCheckInNotification(!checkInNotification)
																		}}
																	/>
																)}
															</CheckIconWrapper>
														</Flexed>

														<Flexed direction="row" align="center" gap={0.2}>
															<Text fontSize={0.813} fontWeight={500} color="black_3">
																Send Check-Out Notification
															</Text>
															<CheckIconWrapper>
																{checkOutNotification ? (
																	<Checked
																		onClick={() => {
																			setCheckOutNotification(!checkOutNotification)
																		}}
																	/>
																) : (
																	<UnChecked
																		onClick={() => {
																			setCheckOutNotification(!checkOutNotification)
																		}}
																	/>
																)}
															</CheckIconWrapper>
														</Flexed>
													</Flexed>
												</Box>
											)}
										</div>
									</Flexed>
									<Spacer height={1.5} />
								</Col>
							</Row>
							{/* <Row>
                            <Col lg={7}>
                                <Flexed direction="row" gap={0.2}>
                                    <div>
                                        <Label type='medium' color='black_3' fontWeight={500}>Multi Locations:</Label>
                                    </div>
                                    <DropDown />
                                </Flexed>
                                <Spacer height={1.5} />
                            </Col>
                        </Row> */}
							<Row>
								<Border>
									<BorderText type="small" lineHeight={2} color="black_1">
										Attendance Access
									</BorderText>
								</Border>
							</Row>
							<Row>
								<Col>
									<Flexed direction="row" gap={0.2}>
										<Label type="medium" color="black_3" fontWeight={500}>
											Permissions:
										</Label>
										<div>
											<Flexed direction="row" align="center" gap={0.2}>
												<CheckIconWrapper>
													{permissionsAlloweMailPasswordMobileApp ? (
														<Checked
															onClick={() => {
																setPermissionsAlloweMailPasswordMobileApp(!permissionsAlloweMailPasswordMobileApp)
															}}
														/>
													) : (
														<UnChecked
															onClick={() => {
																setPermissionsAlloweMailPasswordMobileApp(!permissionsAlloweMailPasswordMobileApp)
															}}
														/>
													)}
												</CheckIconWrapper>
												<Text fontSize={0.813} fontWeight={500} color="black_3">
													Login to the HR-App, Employee can login with their eMail and Password to the HR-App Mobile App
												</Text>
											</Flexed>
											<Spacer height={0.5} />
											<Flexed direction="row" align="center" gap={0.2}>
												<CheckIconWrapper>
													{permissionsAllowOtherEmployees ? (
														<Checked
															onClick={() => {
																setPermissionsAllowOtherEmployees(!permissionsAllowOtherEmployees)
															}}
														/>
													) : (
														<UnChecked
															onClick={() => {
																setPermissionsAllowOtherEmployees(!permissionsAllowOtherEmployees)
															}}
														/>
													)}
												</CheckIconWrapper>
												<Text fontSize={0.813} fontWeight={500} color="black_3">
													Allow Other Employees
												</Text>
											</Flexed>
										</div>
									</Flexed>
									<Spacer height={1.5} />
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			<Row>
				<Col>
					<Divider margin="1rem 0rem" />
					<Flexed direction="row" gap={1} align="center" justify="flex-end">
						<Back onClick={doBack}>{isEdit ? 'Cancel' : 'Back'}</Back>
						<Next onClick={doUpdate}>{isEdit ? 'Save' : 'Done'}</Next>
					</Flexed>
				</Col>
			</Row>
		</>
	)
}

const Label = styled(Text)`
	text-transform: capitalize;
	width: 12rem;
`

const Form = styled.div`
	background: ${palette.white};
	border-radius: 4px;
	margin-bottom: 10px;
`

const Border = styled.div<any>`
	display: block;
	width: 100%;
	text-align: center;
	border-bottom: 2px dashed ${palette.silver_6};
	margin-bottom: 2rem;
`

const BorderText = styled(Text)`
	text-align: center;
	position: relative;
	top: 0.938rem;
	padding: 0 0.938rem;
	display: inline-block;
	background: ${palette.white};
	color: ${palette.opacity.black};
	text-transform: capitalize;
`

const FilledBox = styled.div`
	background: ${palette.purple_1};
	padding: 1.5rem;
	border-radius: 0.3rem;
`

const Box = styled.div`
	border: 1px solid ${palette.silver_1};
	padding: 1.5rem;
	border-radius: 0.3rem;
`

export const InversChecked = styled(BsCheckSquareFill)<any>`
	color: #9a96cc;
	pointer-events: ${({disabled}) => (disabled ? 'none' : '')};
`
export const InversUnChecked = styled(ImCheckboxUnchecked)<any>`
	color: #9a96cc;
	pointer-events: ${({disabled}) => (disabled ? 'none' : '')};
`

export default Attendance
