import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoIosArrowBack} from 'react-icons/io'
import {IoMdClose} from 'react-icons/io'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {updateBoardList} from '../../actions/authActions'

const MoveList = ({goBack, onCloseModal, isCopy, listDetails}: any) => {
	const [allBoards, setAllBoards] = useState<any[]>([])
	const [userBoards, setUserBoards] = useState<any[]>([])
	const [listPosition, setListPosition] = useState<any[]>([])
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const [cardPosition, setCardPosition] = useState<any>('')
	const [boardId, setBoardId] = useState<any>('')
	const [optionsList, setOptionsList] = useState<any[]>([])
	const dispatch = useDispatch()

	useEffect(() => {
		getAllBoards()
	}, [])

	const getAllBoards = async () => {
		await api
			.get('/trello/get_all_boards')
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						if (response?.data?.data?.userboards?.length && !isCopy) {
							setListPosition(response?.data?.data?.userboards[0]?.taskBoardLists)
							setBoardId(response?.data?.data?.userboards[0]?.id)
							if (response?.data?.data?.userboards[0]?.taskBoardLists?.length) {
								setCardPosition(1)
							} else {
								setCardPosition('')
							}
						}
						if (response?.data?.data?.userboards?.length && isCopy) {
							let findBoard = response?.data?.data?.userboards.find((e: any) => e?.id == listDetails?.boardId)
							let findList = findBoard?.taskBoardLists?.find((e: any) => e?.id == listDetails?.listId)
							setListPosition(findBoard?.taskBoardLists)
							setBoardId(findBoard?.id)
							if (findBoard?.taskBoardLists?.length) {
								setCardPosition(listDetails?.position)
							}
						}

						const groupedData = response?.data?.data?.memberBoards.reduce((acc: any, curr: any) => {
							const adminName = curr.admin.name
							if (!acc[adminName]) {
								acc[adminName] = []
							}
							acc[adminName].push(curr)
							return acc
						}, {})
						setUserBoards(response?.data?.data?.userboards)
						setAllBoards(groupedData)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	function handleSelectChange(event: any) {
		const selectedOption = event.target.value
		let optgroupLabel: any = null

		for (let childNode of event.target.childNodes) {
			if (childNode.tagName === 'OPTGROUP') {
				for (let option of childNode.childNodes) {
					if (option.tagName === 'OPTION' && option.selected) {
						optgroupLabel = childNode.label
						break
					}
				}
				if (optgroupLabel) break
			}
		}

		let employeeName = _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName
		if (_loginAdminDetails?.name && _loginAdminDetails?.name == optgroupLabel) {
			let _optionsList: any = userBoards?.find((board: any) => board?.id == selectedOption)
			setListPosition(_optionsList?.taskBoardLists)
			if (_optionsList?.taskBoardLists?.length) {
				setCardPosition(1)
			} else {
				setCardPosition('')
			}
		} else if (!_loginAdminDetails?.name && employeeName != optgroupLabel) {
			let _optionsList: any = allBoards[optgroupLabel]?.find((board: any) => board?.id == selectedOption)
			setListPosition(_optionsList?.taskBoardLists)
			if (_optionsList?.taskBoardLists?.length) {
				setCardPosition(1)
			} else {
				setCardPosition('')
			}
		}

		if (_loginUserDetails?.firstName && employeeName == optgroupLabel) {
			let _optionsList = userBoards?.find((board: any) => board?.id == selectedOption)
			setListPosition(_optionsList?.taskBoardLists)
			if (_optionsList?.taskBoardLists?.length) {
				setCardPosition(1)
			} else {
				setCardPosition('')
			}
		} else if (!_loginUserDetails?.name && _loginAdminDetails?.name != optgroupLabel) {
			let _optionsList: any = allBoards[optgroupLabel]?.find((board: any) => board?.id == selectedOption)
			setListPosition(_optionsList?.taskBoardLists)
			if (_optionsList?.taskBoardLists?.length) {
				setCardPosition(1)
			} else {
				setCardPosition('')
			}
		}

		setBoardId(selectedOption)
	}

	const moveListAndCArds = async () => {
		if (listDetails?.id && boardId && listPosition) {
			await api
				.put(`/trello/update_list?id=${listDetails?.id}`, {boardId, position: cardPosition, isMove: true})
				.then((response) => {
					if (response?.data?.success === true) {
						dispatch(updateBoardList(true))
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	return (
		<>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				<CloseCover onClick={goBack}>
					<IoIosArrowBack cursor="pointer" />
				</CloseCover>
				<Text fontWeight={600}>Move list</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			<Flexed margin="0.5rem 0.8rem" gap={0.5}>
				<Content>
					<Text type="xsmall" fontWeight={600}>
						Board
					</Text>
					<Select onChange={(e: any) => handleSelectChange(e)} value={boardId}>
						<optgroup label={_loginAdminDetails?.name ? _loginAdminDetails?.name : _loginUserDetails?.firstName ? _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName : ''}>
							{userBoards?.map((board: any, i: any) => (
								<option key={i} value={board?.id}>
									{board?.name}
								</option>
							))}
						</optgroup>
						{Object.keys(allBoards).map((board: any) => (
							<optgroup label={board}>
								{allBoards[board]?.map((res: any, i: any) => (
									<option key={i} value={res?.id}>
										{res?.name}
									</option>
								))}
							</optgroup>
						))}
					</Select>
				</Content>
				<Content>
					<Text type="xsmall" fontWeight={600}>
						Position
					</Text>
					<Select
						onChange={(e) => {
							if (e.target.value) {
								setCardPosition(e.target.value)
							}
						}}
						value={cardPosition}>
						{listPosition?.length ? (
							listPosition?.map((list: any, i: any) => (
								<option key={i} value={i + 1}>
									{i + 1}
								</option>
							))
						) : (
							<option>N/A</option>
						)}
					</Select>
				</Content>
				<Button onClick={() => moveListAndCArds()}>Move</Button>
			</Flexed>
		</>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Content = styled.div<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem 0.8rem;
	background: ${palette.silver_1};
	&:hover {
		background: ${palette.silver_2};
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.purple_1};
	font-weight: 600;
	cursor: pointer;
	width: fit-content;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
`

const Select = styled.select`
	border: none;
	cursor: pointer;
	background: transparent;
	width: 100%;
	max-height: 300px;
	outline: none;
	font-weight: 600;
	font-size: 0.75rem;
`

export default MoveList
