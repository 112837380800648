export const SWITCH_THEME = 'SWITCH_THEME'
export const LOGIN_USER_DETAILS = 'LOGIN_USER_DETAILS'
export const LOGIN_ADMIN_DETAILS = 'LOGIN_ADMIN_DETAILS'
export const CHECK_OUT_DETAIL = 'CHECK_OUT_DETAIL'
export const SEARCH = 'SEARCH'
export const UPDATE_LIST = 'UPDATE_LIST'
export const UPDATE_BOARD_LIST = 'UPDATE_BOARD_LIST'
export const UPDATE_BOARD = 'UPDATE_BOARD'
export const FILTER_BOARD_CARDS = 'FILTER_BOARD_CARDS'
export const UPDATED_LIST = 'UPDATED_LIST'
