import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Container, media} from 'styled-bootstrap-grid'
import {Flexed, Loading, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import PersonalIz from '../components/employee/PersonalIz'
import Official from '../components/employee/Official'
import Salary from '../components/employee/Salary'
import Dependents from '../components/employee/Dependents'
import TimeOffPolicy from '../components/employee/TimeOffPolicy'
import Attendance from '../components/employee/Attendance'
import Documents from '../components/employee/Documents'
import {FaCheck} from 'react-icons/fa'

const CreateTeam = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [activeStep, setActiveStep] = useState(0)
	const [moveToClikedStep, setMoveToClikedStep] = useState(0)
	const [employeeId, setEmployeeId] = useState('')
	const [employeeName, setEmployeeName] = useState('')

	return (
		<>
			<Wrapper>
				<Flex direction="row" align="center" gap={0.6}>
					<Text type="normal" fontWeight={500} color="black_3">
						Employee
					</Text>
					<Text type="xsmall" color="black_3">
						{employeeName}
					</Text>
				</Flex>
				<Stepper direction="row" align="center" justify="space-between">
					<Step>
						<Line />
						<StepBox
							active={activeStep >= 0}
							onClick={() => {
								setMoveToClikedStep(0)
							}}>
							<StepLabel left={1} type="small">
								Personal
							</StepLabel>
							<StepCheck active={activeStep > 0}>
								<FaCheck fontSize="12px" />
							</StepCheck>
						</StepBox>
					</Step>
					<Step>
						<Line />
						<StepBox
							active={activeStep >= 1}
							onClick={() => {
								setMoveToClikedStep(1)
							}}>
							<StepLabel left={0.7} type="small">
								Official
							</StepLabel>
							<StepCheck active={activeStep > 1}>
								<FaCheck fontSize="12px" />
							</StepCheck>
						</StepBox>
					</Step>
					<Step>
						<Line />
						<StepBox
							active={activeStep >= 2}
							onClick={() => {
								setMoveToClikedStep(2)
							}}>
							<StepLabel left={0.7} type="small">
								Salary
							</StepLabel>
							<StepCheck active={activeStep > 2}>
								<FaCheck fontSize="12px" />
							</StepCheck>
						</StepBox>
					</Step>

					<Step>
						<Line />
						<StepBox
							active={activeStep >= 3}
							onClick={() => {
								setMoveToClikedStep(3)
							}}>
							<StepLabel left={0.4} type="small">
								Docs
							</StepLabel>
							<StepCheck active={activeStep > 3}>
								<FaCheck fontSize="12px" />
							</StepCheck>
						</StepBox>
					</Step>
					<Step>
						<Line />
						<StepBox
							active={activeStep >= 4}
							onClick={() => {
								setMoveToClikedStep(4)
							}}>
							<StepLabel left={1.8} type="small">
								Dependents
							</StepLabel>
							<StepCheck active={activeStep > 4}>
								<FaCheck fontSize="12px" />
							</StepCheck>
						</StepBox>
					</Step>
					<Step>
						<Line />
						<StepBox
							active={activeStep >= 5}
							onClick={() => {
								setMoveToClikedStep(5)
							}}>
							<StepLabel left={1.8} type="small">
								Attendance
							</StepLabel>
							<StepCheck active={activeStep > 5}>
								<FaCheck fontSize="12px" />
							</StepCheck>
						</StepBox>
					</Step>
				</Stepper>
				<Section>
					<Container fluid>
						{moveToClikedStep === 0 && (
							<PersonalIz
								isEdit={false}
								setIsLoading={setIsLoading}
								employeeId={employeeId}
								setEmployeeId={setEmployeeId}
								setEmployeeName={setEmployeeName}
								doBack={() => {}}
								doNext={() => {
									setMoveToClikedStep(1)
									if (activeStep === 0) {
										setActiveStep(1)
									}
								}}
							/>
						)}
						{moveToClikedStep === 1 && (
							<Official
								isEdit={false}
								doBack={() => {
									setMoveToClikedStep(0)
								}}
								setIsLoading={setIsLoading}
								employeeId={employeeId}
								setEmployeeId={setEmployeeId}
								setEmployeeName={setEmployeeName}
								doNext={() => {
									setMoveToClikedStep(2)
									if (activeStep === 1) {
										setActiveStep(2)
									}
								}}
							/>
						)}
						{moveToClikedStep === 2 && (
							<Salary
								isEdit={false}
								doBack={() => {
									setMoveToClikedStep(1)
								}}
								setIsLoading={setIsLoading}
								employeeId={employeeId}
								setEmployeeId={setEmployeeId}
								setEmployeeName={setEmployeeName}
								doNext={() => {
									setMoveToClikedStep(3)
									if (activeStep === 2) {
										setActiveStep(3)
									}
								}}
							/>
						)}

						{moveToClikedStep === 3 && (
							<Documents
								isEdit={false}
								doBack={() => {
									setMoveToClikedStep(2)
								}}
								setIsLoading={setIsLoading}
								employeeId={employeeId}
								setEmployeeId={setEmployeeId}
								setEmployeeName={setEmployeeName}
								doNext={() => {
									setMoveToClikedStep(4)
									if (activeStep === 3) {
										setActiveStep(4)
									}
								}}
							/>
						)}
						{moveToClikedStep === 4 && (
							<Dependents
								isEdit={false}
								doBack={() => {
									setMoveToClikedStep(3)
								}}
								setIsLoading={setIsLoading}
								employeeId={employeeId}
								setEmployeeId={setEmployeeId}
								setEmployeeName={setEmployeeName}
								doNext={() => {
									setMoveToClikedStep(5)
									if (activeStep === 4) {
										setActiveStep(5)
									}
								}}
							/>
						)}
						{moveToClikedStep === 5 && (
							<Attendance
								isEdit={false}
								doBack={() => {
									setMoveToClikedStep(4)
								}}
								setIsLoading={setIsLoading}
								employeeId={employeeId}
								setEmployeeId={setEmployeeId}
								setEmployeeName={setEmployeeName}
							/>
						)}
					</Container>
				</Section>
			</Wrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}
const Wrapper = styled.div`
	padding: 25px 20px;
`

const Flex = styled(Flexed)`
	height: 50px;
`

const Stepper = styled(Flexed)`
	position: relative;
	height: 3rem;
	margin: 0.5rem 0rem 2rem 0rem;
	padding: 0rem 1rem 0rem 1rem;
	display: none;
	${media.md`
    display: flex;
     padding:0rem 3rem 0rem 3rem;
    `};
	${media.lg`
     padding:0rem 6rem 0rem 4rem;
    `};
`
const Line = styled.div`
	width: 100%;
	height: 2px;
	background: ${palette.purple_1};
	position: absolute;
	bottom: 0.63rem;
`

const Section = styled.div`
	padding: 30px 15px;
	background: ${palette.white};
	border-radius: 5px;
`

const Step = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 0.5rem;
	&:not(:last-child) {
		width: 100%;
	}
`

const StepBox = styled.div<any>`
	cursor: ${({active}) => (active ? 'pointer' : '')};
	pointer-events: ${({active}) => (active ? 'pointer' : 'none')};
	z-index: 1;
`

const StepLabel = styled(Text)`
	position: absolute;
	top: -1.5rem;
	left: -${({left}) => `${left}`}rem;
`

const StepCheck = styled.div<any>`
	height: 22px;
	width: 22px;
	border: 2px solid ${palette.purple_1};
	border-radius: 50%;
	background: ${({active}) => (active ? palette.purple_1 : '#f2f4f5')};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	& svg {
		display: ${({active}) => (active ? 'block' : 'none')};
		color: ${({active}) => (active ? palette.white : '#f2f4f5')};
	}
`

export default CreateTeam
