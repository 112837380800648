import React, {useEffect, useRef, useState} from 'react'
import styled, {keyframes} from 'styled-components'
import 'react-responsive-modal/styles.css'
import Button from './Button'
import DropDown from './DropDown'
import InputDate from './InputDate'

import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'

import {BsXLg} from 'react-icons/bs'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {NavLink, useNavigate} from 'react-router-dom'

const AddRequestSideBar = ({data, show, onClick, onClose}: any) => {
	const myRef: any = useRef()
	let _navigate = useNavigate()
	const [postdata, setPostdata] = useState<any>('')

	// eslint-disable-next-line
	const [sortBy, setSortBy] = useState('normal')
	// eslint-disable-next-line
	const [fairoption, setFairOption] = useState([
		{value: 'byname', label: 'By Name'},
		{value: 'bydate', label: 'By Date'}
	])

	const handleClickOutside = (e: any) => {
		if (!myRef?.current?.contains(e.target)) {
			onClick()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	})
	useEffect(() => {
		// getJobPost(data)
	}, [])
	// const getJobPost = (data: any) => {
	// 	api.get(`/recruitment/get_post/${data}`)
	// 		.then((response) => {
	// 			if (response?.data?.success == true) {
	// 				setPostdata(response?.data?.data?.data)
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			toast.error('Something went wrong. Please try again later.')
	// 		})
	// }
	return (
		<>
			<OverLay show={show} />
			<SideMenuWrapper show={show} ref={myRef}>
				<SideMenuHeader gap={1}>
					<Flexed direction="row" gap={1} justify="space-between" style={{width: '100%'}}>
						<Text fontSize={1.625} color="heading_color" fontWeight="500">
							Add New Request
						</Text>
						<Close onClick={onClose} />
					</Flexed>
				</SideMenuHeader>

				<SideMenuBody gap={1}>
					<SidebarContent onClick={() => _navigate('/leave-request')}>
						<RequestMenu>
							<IconContainer>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="22px" height="22px">
									<path fill="#FFCDD2" d="M5,38V14h38v24c0,2.2-1.8,4-4,4H9C6.8,42,5,40.2,5,38z"></path>
									<path fill="#F44336" d="M43,10v6H5v-6c0-2.2,1.8-4,4-4h30C41.2,6,43,7.8,43,10z"></path>
									<path fill="#B71C1C" d="M33 7A3 3 0 1 0 33 13 3 3 0 1 0 33 7zM15 7A3 3 0 1 0 15 13 3 3 0 1 0 15 7z"></path>
									<path fill="#BDBDBD" d="M33 3c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2s2-.9 2-2V5C35 3.9 34.1 3 33 3zM15 3c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2s2-.9 2-2V5C17 3.9 16.1 3 15 3z"></path>
									<g>
										<path
											fill="#F44336"
											d="M22.2,35.3c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.5s0.3-0.3,0.5-0.3c0.2-0.1,0.5-0.1,0.7-0.1s0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.3s0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0.3-0.6,0.3S24.3,37,24,37s-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5C22.3,35.8,22.2,35.6,22.2,35.3z M25.3,31h-2.6l-0.4-11h3.3L25.3,31z"></path>
									</g>
								</svg>
								<Text fontSize={0.9}>Leave Request</Text>
							</IconContainer>
						</RequestMenu>
					</SidebarContent>
					<SidebarContent onClick={() => _navigate('/loan-request')}>
						<RequestMenu>
							<IconContainer>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="22px" height="22px">
									<g data-name="Capital-Finance-Hand-Revenue-Money Bag">
										<path fill="#d8d7da" d="M27,17.66V32a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V17.66l10-10Z"></path>
										<rect width="6" height="8" x="14" y="25" fill="#acabb1"></rect>
										<circle cx="17" cy="18" r="3" fill="#e6e7e8"></circle>
										<polygon fill="#ee8700" points="27 4 27 12 23 8 23 4 27 4"></polygon>
										<path fill="#c6c5ca" d="M19,9.66,7.69,20.97a2.057,2.057,0,0,1-.69.45V17.66l10-10Z"></path>
										<path fill="#ee8700" d="M27,17.66l-10-10-10,10L5.69,18.97a2,2,0,0,1-2.83-2.83L16.29,2.71a1.008,1.008,0,0,1,1.42,0L23,8l4,4,4.14,4.14a2,2,0,1,1-2.83,2.83Z"></path>
										<path fill="#3d9ae2" d="M43,22a2.006,2.006,0,0,1,2,2,2.015,2.015,0,0,1-2,2H39a2.006,2.006,0,0,1-2-2,2.015,2.015,0,0,1,2-2Z"></path>
										<path fill="#5aaae7" d="M38,16c2,0,1,1,3,1s1-1,3-1,1,1,3,1l-4,5H39l-4-5C37,17,36,16,38,16Z"></path>
										<path
											fill="#5aaae7"
											d="M54,43.39A2.595,2.595,0,0,1,51.39,46H30.61a2.6,2.6,0,0,1-1.45-4.77l.95-.64A1.981,1.981,0,0,0,31,38.93V34a8,8,0,0,1,8-8h4a8,8,0,0,1,8,8v4.93a1.981,1.981,0,0,0,.89,1.66l.95.64A2.6,2.6,0,0,1,54,43.39Z"></path>
										<path
											fill="#ffc477"
											d="M44,52h.51a3.925,3.925,0,0,0,2-.54L57.18,45.3a3,3,0,0,1,3,5.2L46.55,58.37a15.968,15.968,0,0,1-9.77,2.05L24,59,2,61V47H21l2.62-1.57A9.973,9.973,0,0,1,28.77,44h1.3a9.853,9.853,0,0,1,3.72.72L37,46h7a3,3,0,0,1,0,6Z"></path>
										<path fill="#1e81ce" d="M42,35H40a1,1,0,0,1,0-2h2a1,1,0,0,1,1,1h2a3,3,0,0,0-3-3V30H40v1a3,3,0,0,0,0,6h2a1,1,0,0,1,0,2H40a1,1,0,0,1-1-1H37a3,3,0,0,0,3,3v1h2V41a3,3,0,0,0,0-6Z"></path>
										<rect width="10" height="2" x="34" y="51" fill="#ffb655" rx="1"></rect>
									</g>
								</svg>

								<Text fontSize={0.9}>Loan Request</Text>
							</IconContainer>
						</RequestMenu>
					</SidebarContent>
					<SidebarContent onClick={() => _navigate('/document-request')}>
						<RequestMenu>
							<IconContainer>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="22px" height="22px">
									<path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path>
									<path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path>
									<path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
								</svg>
								<Text fontSize={0.9}>Document Request</Text>
							</IconContainer>
						</RequestMenu>
					</SidebarContent>
				</SideMenuBody>
			</SideMenuWrapper>
		</>
	)
}

const OverLay = styled.div<any>`
	background: #00000014;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	position: fixed;
	display: ${({show}) => (show ? 'block' : 'none')};
`
const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`

const SideMenuWrapper = styled.div<any>`
	overflow: auto;
	width: ${({show}) => (show ? '30rem' : '0rem')};
	top: 0;
	left: 0; /* Change right to left */
	height: 100%;
	background: ${palette.white};
	box-shadow: ${palette.shadow};
	position: fixed;
	transform-origin: left; /* Remove 'right' */
	transition: width 1s ease; /* Change duration if needed */
	animation: ${slideInFromLeft} 1s ease; /* Add appropriate keyframes for sliding in from left */
	z-index: 10;
`

const SideMenuHeader = styled(Flexed)`
	background: ${palette.silver_3};
	height: auto;
	padding: 2rem 3rem;
	position: sticky;
	top: 0;
	z-index: 13;
	margin-bottom: 10px;
`
const SideMenuBody = styled(Flexed)`
	height: auto;
	padding: 0.4rem 3rem;
	position: sticky;
	top: 0;
	z-index: 13;
`

const Close = styled(BsXLg)`
	cursor: pointer;
`

const Body = styled(Flexed)`
	padding: 2rem 3rem;
`
const Status = styled.span`
	margin-left: 0.5rem;
	font-size: 0.8rem;
	background: #e5f9de;
	padding: 2px 3px;
	border-radius: 6px;
`
const SidebarContent = styled.div`
	flex-direction: row;
	&:hover {
		border-color: #f4f4f4 !important;
		box-shadow: 0 8px 16px 0 #0000001a;
		transition: border-color 0.167s linear, box-shadow 0.167s linear;
		background-color: #f3f2f1;
	}
`
const IconContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	padding: 10px;
	svg {
		width: 24px;
		height: 24px;
	}
`

const RequestMenu = styled(NavLink)<any>`
	border-left: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
`

export default AddRequestSideBar
