/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import QuoteItem from './item'
import {grid} from './constants'
import Title from './title'

const InnerQuoteList = React.memo(function InnerQuoteList(props: any) {
	return props?.quotes?.map((quote: any, index: any) => (
		<Draggable key={quote.id} draggableId={quote.id} index={index}>
			{(dragProvided, dragSnapshot) => <QuoteItem key={quote.id} listIndex={props?.listIndex} quote={quote} isDragging={dragSnapshot.isDragging} isGroupedOver={Boolean(dragSnapshot.combineTargetFor)} provided={dragProvided} />}
		</Draggable>
	))
})

function InnerList(props: any) {
	const {quotes, dropProvided, listIndex} = props
	const title = props.title ? <Title>{props.title}</Title> : null

	return (
		<Container>
			{title}
			<DropZone ref={dropProvided.innerRef}>
				<InnerQuoteList quotes={quotes} listIndex={listIndex} />
				{dropProvided.placeholder}
			</DropZone>
		</Container>
	)
}

export default function QuoteList(props: any) {
	const {ignoreContainerClipping, internalScroll, scrollContainerStyle, isDropDisabled, isCombineEnabled, listId = 'LIST', listType, style, quotes, title, useClone, listIndex} = props

	return (
		<Droppable
			droppableId={listId}
			type={listType}
			ignoreContainerClipping={ignoreContainerClipping}
			isDropDisabled={isDropDisabled}
			isCombineEnabled={isCombineEnabled}
			renderClone={useClone ? (provided, snapshot, descriptor) => <QuoteItem listIndex={listIndex} quote={quotes[descriptor.source.index]} provided={provided} isDragging={snapshot.isDragging} isClone /> : undefined}>
			{(dropProvided, dropSnapshot) => (
				<Wrapper style={style} isDraggingOver={dropSnapshot.isDraggingOver} isDropDisabled={isDropDisabled} isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)} {...dropProvided.droppableProps}>
					{internalScroll ? (
						<ScrollContainer id={`scrollContainer${listIndex}`} height={props?.showAddListContent} style={scrollContainerStyle}>
							<InnerList quotes={quotes} listIndex={listIndex} title={title} dropProvided={dropProvided} />
						</ScrollContainer>
					) : (
						<InnerList quotes={quotes} title={title} dropProvided={dropProvided} />
					)}
				</Wrapper>
			)}
		</Droppable>
	)
}

export const getBackgroundColor = (isDraggingOver: any, isDraggingFrom: any) => {
	if (isDraggingOver) {
		return '#FFEBE6'
	}
	if (isDraggingFrom) {
		return '#E6FCFF'
	}
	return '#EBECF0'
}

const Wrapper = styled.div<any>`
	background-color: ${(props) => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
	display: flex;
	flex-direction: column;
	opacity: ${({isDropDisabled}) => (isDropDisabled ? 0.5 : 'inherit')};
	padding: ${grid}px;
	border: ${grid}px;
	padding-bottom: 0;
	transition:
		background-color 0.2s ease,
		opacity 0.1s ease;
	user-select: none;
	width: 250px;
`

const scrollContainerHeight = 550

const DropZone = styled.div`
	/* stop the list collapsing when empty */
	// min-height: ${scrollContainerHeight}px;
	min-height: auto;
	/*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
	padding-bottom: ${grid}px;
`

const ScrollContainer = styled.div<any>`
	overflow-x: hidden;
	overflow-y: auto;
	// max-height: ${({height}) => (height ? 'calc(100vh - 250px)' : 'calc(100vh - 170px)')};
	max-height: ${({height}) => (height ? 'calc(100vh - 312px)' : 'calc(100vh - 238px)')};
`

/* stylelint-disable block-no-empty */
const Container = styled.div``
/* stylelint-enable */
