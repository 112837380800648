import React, {useState} from 'react'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {Flexed, Text} from '../../styled/shared'
import {Mandatory} from '../common/InputField'
import AddDeductionModal from '../modals/AddDeductionModal'
import DocumentsTableList from './DocumentsTableList'

const DocumentsTable = ({documentsList, removeData}: any) => {
	const [openAddModal, setOpenAddModal] = useState(false)
	return (
		<>
			<HeadWrapper>
				<Flexed direction="row" gap={1} align="center">
					<DocxType gap={0.2}>
						<Text type="small" fontWeight={400} color="black_1">
							Document Type <Mandatory>*</Mandatory>
						</Text>
					</DocxType>
					<DocxName gap={0.1}>
						<Text type="small" fontWeight={400} color="black_1">
							Document Name <Mandatory>*</Mandatory>
						</Text>
					</DocxName>
					<DocxDescription gap={0.2}>
						<Text type="small" color="black_1">
							Description <Mandatory>*</Mandatory>
						</Text>
					</DocxDescription>
					<DocxExpiryDate gap={0.2}>
						<Text type="small" color="black_1">
							Expiry Date
						</Text>
					</DocxExpiryDate>
					{/* <DocxDoumentShow direction="row" gap={1}>
                    <Text type='small' fontWeight={400} color='black_1'>
                    Doument Show
                    </Text>
                </DocxDoumentShow> */}
					<DocxAction direction="row" gap={0.5} align="center" justify="flex-end">
						<Text type="small" fontWeight={400} color="black_1">
							Action
						</Text>
					</DocxAction>
				</Flexed>
			</HeadWrapper>
			{documentsList?.map((data: any) => {
				return (
					<DocumentsTableList
						data={data}
						onDelete={() => {
							removeData(data?.id)
						}}
					/>
				)
			})}
			{openAddModal && (
				<AddDeductionModal
					onCloseModal={() => {
						setOpenAddModal(false)
					}}
				/>
			)}
		</>
	)
}
const HeadWrapper = styled.div<any>`
	width: 38rem;
	background: #eef0f1;
	padding: 0.5rem;
	transition: all ease 0.25s;
	position: relative;
	${media.sm`
	width: 42rem;
	`}
	${media.md`
	width: 100%;
	`}
`

export const DocxType = styled(Flexed)`
	width: 15%;
	& button {
		line-height: 1.5rem !important;
	}
`

export const DocxName = styled(Flexed)`
	width: 30%;
	& #inputWrapper {
		width: 100%;
	}
	& input {
		line-height: 1.5rem !important;
	}
`

export const DocxDescription = styled(Flexed)`
	width: 30%;
	& #inputWrapper {
		width: 100%;
	}
	& textarea {
		line-height: 1.5rem !important;
	}
`

export const DocxExpiryDate = styled(Flexed)`
	width: 30%;
	& #inputWrapper {
		width: 100%;
	}
	& div {
		line-height: 1.5rem !important;
	}
`

export const DocxDoumentShow = styled(Flexed)`
	width: 15%;
`

export const DocxAction = styled(Flexed)`
	position: relative;
	width: 10%;
`

export default DocumentsTable
