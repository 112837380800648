import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Spacer, Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {getInitials} from '../../utils'
import {TagUser} from '../trelloTaskModalComponents/AddMemberPop'
import {api} from '../api/callAxios'
import {useDispatch} from 'react-redux'
import {updateBoard, updateBoardList, updatedList} from '../../actions/authActions'
import {toast} from 'react-toastify'
import useRouter from '../../hooks/router'
import {useSelector} from 'react-redux'

interface IProps {
	isDarkTheme?: boolean
}

const TrelloMemberProfile = ({onCloseModal, name, username, profileImageUrl, isCard, memberDetails, isNav, adminDetails}: any) => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)

	const dispatch = useDispatch()
	const router = useRouter()

	const removeBoardMember = async () => {
		try {
			await api
				.delete(`/trello/delete_invitation?inviteId=${memberDetails?.id}`)
				.then((res: any) => {
					if (res?.data?.success == true) {
						dispatch(updateBoard(true))

						dispatch(updateBoardList(true))
						onCloseModal()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const removeCardMember = async () => {
		try {
			await api
				.delete(`/trello/remove_card_member/${memberDetails?.id}`)
				.then((res: any) => {
					if (res?.data?.success == true) {
						dispatch(updateBoardList(true))
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: ''
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between">
						<Flexed direction="row" align={'center'} flexWrap="wrap" gap={0.5}>
							{profileImageUrl ? (
								<ProfileImage src={profileImageUrl} alt="Profile" />
							) : (
								<TagUser width="4.8rem" height="4.8rem">
									{name && getInitials(name)}
								</TagUser>
							)}

							<div>
								<Text type="small" lineHeight="1.438" fontWeight="700">
									{name}
								</Text>
							</div>
						</Flexed>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<ProfileSection>
							<StyledUl onClick={() => router.navigate(`/task-board-user?id=${memberDetails ? memberDetails?.receiver?.id : adminDetails ? `admin&adminId=${adminDetails?.id}` : 'admin'}`)}>
								<ProfileLink>View profile</ProfileLink>
							</StyledUl>

							{!isCard && memberDetails ? (
								<>
									<hr style={{margin: '8px 0px'}} />
									<StyledUl onClick={() => (isNav ? removeBoardMember() : removeCardMember())}>
										{memberDetails?.receiverUserId == _loginUserDetails?.id ? <ProfileLink>{'Leave board'} </ProfileLink> : <ProfileLink>{isNav ? 'Remove from board' : 'Remove from card'} </ProfileLink>}
									</StyledUl>
								</>
							) : (
								''
							)}
						</ProfileSection>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	position: relative;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
`

const Head = styled(Flexed)`
	width: 100%;
	background-color: ${palette.white};
	z-index: 4;
`

const Body = styled.div`
	max-height: 32rem;
	overflow-y: scroll;
	overflow-x: hidden;
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 2rem;
	padding: 0.8rem;
`

const ProfileSection = styled.section`
	inset: auto auto 71px 1044px;
	width: 304px;
	border-radius: 8px;
	padding: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const StyledUl = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
`

const ProfileImage = styled.img`
	width: 88px;
	height: 88px;
	border-radius: 50%;
	margin-bottom: 10px;
`

const ProfileName = styled.div`
	font-size: 1.2rem;
	margin-bottom: 5px;
`

const ProfileUsername = styled.div`
	color: #666;
`

const ProfileLink = styled.li`
	cursor: pointer;
	margin-top: 8px;
	color: #0079bf;
	&:hover {
		text-decoration: underline;
	}
`
export default TrelloMemberProfile
