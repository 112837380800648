import React from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'

const CheckBoxItemActionPop = ({onCloseModal, onDelete, onAddCard}: any) => {
	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}
	return (
		<>
			<Popup onClick={handleModalClick}>
				<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
					<div></div>
					<Text fontWeight={600}>Item actions</Text>
					<CloseCover onClick={onCloseModal}>
						<IoMdClose cursor="pointer" />
					</CloseCover>
				</Flexed>
				<Flexed margin="0.5rem 0rem">
					<List direction="row">
						<Text type="small" fontWeight={500} onClick={() => onAddCard && onAddCard()}>
							Convert to card
						</Text>
					</List>
					<List direction="row" onClick={() => onDelete && onDelete()}>
						<Text type="small" fontWeight={500}>
							Delete
						</Text>
					</List>
				</Flexed>
			</Popup>
		</>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const List = styled(Flexed)`
	width: 100%;
	padding: 0.4rem 0.313rem;
	border-radius: 0.3rem;
	background: ${({active}) => (active ? palette.silver_1 : palette.white)};
	position: relative;
	cursor: pointer;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	top: 2.3rem;
	z-index: 5;
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

export default CheckBoxItemActionPop
