import React, {useState, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {MdOutlineCloudUpload} from 'react-icons/md'
import {RiDeleteBinLine} from 'react-icons/ri'
import moment from 'moment-timezone'
import countryList from 'react-select-country-list'
import {Country, State, City} from 'country-state-city'

const PersonalIz = ({doNext, setIsLoading, employeeId, setEmployeeId, setEmployeeName, isEdit, doBack}: any) => {
	const _regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
	const optionsForCountry = useMemo(() => Country.getAllCountries(), [])
	const optionsForState = useMemo(() => State.getAllStates(), [])
	const [gender, setGender] = useState('male')
	const [image, setImage] = useState('')
	const [email, setEmail] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [fatherName, setFatherName] = useState('')
	const [motherName, setMotherName] = useState('')
	const [bloodGroup, setBloodGroup] = useState({label: '', value: ''})
	const [qualification, setQualification] = useState({label: '', value: ''})
	const [dob, setDob] = useState('')
	const [maritalStatus, setMaritalStatus] = useState({label: '', value: ''})
	const [religion, setReligion] = useState({label: '', value: ''})
	const [employeeCode, setEmployeeCode] = useState('')
	const [attendanceMachineCode, setAttendanceMachineCode] = useState('')
	const [nationalID, setNationalID] = useState('')
	const [passportNo, setPassportNo] = useState('')
	const [eobiNo, setEobiNo] = useState('')
	const [ntnNo, setNtnNo] = useState('')
	const [country, setCountry] = useState({label: '', value: ''})
	const [state, setState] = useState({label: '', value: ''})
	const [city, setCity] = useState({label: '', value: ''})
	const [zipCode, setZipCode] = useState('')
	const [nationality, setNationality] = useState({label: '', value: ''})
	const [permanentAddress, setPermanentAddress] = useState('')
	const [temporaryAddress, setTemporaryAddress] = useState('')
	const [homePhone, setHomePhone] = useState('')
	const [mobile, setMobile] = useState('')
	const [emergencyContact, setEmergencyContact] = useState('')
	const [emergencyName, setEmergencyName] = useState('')
	const [bloodGroupOption, setBloodGroupOption] = useState([
		{value: '', label: 'Select an option'},
		{value: 'AB Negative', label: 'AB Negative'},
		{value: 'AB Positive', label: 'AB Positive'},
		{value: 'A Negative', label: 'A Negative'},
		{value: 'A Positive', label: 'A Positive'},
		{value: 'B Negative', label: 'B Negative'},
		{value: 'B Positive', label: 'B Positive'},
		{value: 'O Negative', label: 'O Negative'},
		{value: 'O Positive', label: 'O Positive'}
	])
	const [qualificationOption, setQualificationOption] = useState([
		{value: '', label: 'Select an option'},
		{value: 'Masters', label: 'Masters'},
		{value: 'Middle School', label: 'Middle School'},
		{value: 'Matriculation', label: 'Matriculation'}
	])
	const [maritalStatusOption, setMaritalStatusOption] = useState([
		{value: '', label: 'Select an option'},
		{value: 'Single', label: 'Single'},
		{value: 'Married', label: 'Married'},
		{value: 'Divorced or Widow', label: 'Divorced or Widow'},
		{value: 'N/A', label: 'N/A'}
	])
	const [religionOption, setReligionOption] = useState([
		{value: '', label: 'Select an option'},
		{value: 'Muslim', label: 'Muslim'},
		{value: 'Non-Muslim', label: 'Non-Muslim'},
		{value: 'Hindu', label: 'Hindu'},
		{value: 'Christian', label: 'Christian'}
	])

	const [countryOption, setCountryOption] = useState([])
	const [stateOption, setStateOption] = useState([])
	const [cityOption, setCityOption] = useState([])

	const [nationalityOption, setNationalityOption] = useState([
		{value: '', label: 'Select an option'},
		{value: 'Pakistani', label: 'Pakistani'},
		{value: 'USA', label: 'USA'},
		{value: 'Saudia', label: 'Saudia'},
		{value: 'UK', label: 'UK'}
	])

	const [firstNameError, setFirstNameError] = useState('')
	const [lastNameError, setLastNameError] = useState('')
	const [employeeCodeError, setEmployeeCodeError] = useState('')
	const [countryError, setCountryError] = useState('')
	const [stateError, setStateError] = useState('')
	const [cityError, setCityError] = useState('')
	const [emailError, setEmailError] = useState('')
	const [isCities, setIsCities] = useState(false)
	const [isStates, setIsStates] = useState(false)

	useEffect(() => {
		const _countries: any = []
		for (let i = 0; i < optionsForCountry.length; i++) {
			_countries.push({value: optionsForCountry[i].name, label: optionsForCountry[i].name})
		}
		setCountryOption(_countries)
	}, [optionsForCountry])

	useEffect(() => {
		if (employeeId) {
			doGetEmployeeDetails(employeeId)
		}
	}, [employeeId])

	const formValidation = () => {
		let isValid = true
		if (firstName === '') {
			setFirstNameError('This value is required.')
			isValid = false
		}
		if (lastName === '') {
			setLastNameError('This value is required.')
			isValid = false
		}
		if (email === '') {
			setEmailError('Email is required.')
			isValid = false
		}
		if (employeeCode === '') {
			setEmployeeCodeError('This value is required.')
			isValid = false
		}
		if (country?.value === '') {
			setCountryError('This value is required.')
			isValid = false
		}
		if (city?.value === '') {
			setCityError('This value is required.')
			isValid = false
		}

		return isValid
	}

	const doGetEmployeeDetails = (id: any) => {
		setIsLoading(true)
		api.get(`/employee/get_employee/${id}`)
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					let _data = response?.data?.data?.data
					setEmployeeName(_data?.firstName)
					setGender(_data?.gender)
					setImage(_data?.image)
					setEmail(_data?.email)
					setFirstName(_data?.firstName)
					setLastName(_data?.lastName)
					setFatherName(_data?.fatherName)
					setMotherName(_data?.motherName)
					setBloodGroup({label: _data?.bloodGroup, value: _data?.bloodGroup})
					setQualification({label: _data?.qualification, value: _data?.qualification})
					setDob(_data?.dob)
					setMaritalStatus({label: _data?.maritalStatus, value: _data?.maritalStatus})
					setReligion({label: _data?.religion, value: _data?.religion})
					setEmployeeCode(_data?.employeeCode)
					setAttendanceMachineCode(_data?.attendanceMachineCode)
					setNationalID(_data?.nationalID)
					setPassportNo(_data?.passportNo)
					setEobiNo(_data?.eobiNo)
					setNtnNo(_data?.ntnNo)
					setCountry({label: _data?.country, value: _data?.country})
					setState({label: _data?.state, value: _data?.state})
					setCity({label: _data?.city, value: _data?.city})
					setZipCode(_data?.zipCode)
					setNationality({label: _data?.nationality, value: _data?.nationality})
					setPermanentAddress(_data?.permanentAddress)
					setTemporaryAddress(_data?.temporaryAddress)
					setHomePhone(_data?.homePhone)
					setMobile(_data?.mobile)
					setEmergencyContact(_data?.emergencyContact)
					setEmergencyName(_data?.emergencyName)
					setStateAndCityDrops(_data?.country, _data?.state, _data?.city)
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const doSave = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.post(`/employee/create`, {
				gender: gender,
				image: image,
				email: email,
				firstName: firstName,
				lastName: lastName,
				fatherName: fatherName,
				motherName: motherName,
				bloodGroup: bloodGroup?.value,
				qualification: qualification?.value,
				dob: dob,
				maritalStatus: maritalStatus?.value,
				religion: religion?.value,
				employeeCode: employeeCode,
				attendanceMachineCode: attendanceMachineCode,
				nationalID: nationalID,
				passportNo: passportNo,
				eobiNo: eobiNo,
				ntnNo: ntnNo,
				country: country?.value,
				state: state?.value,
				city: city?.value,
				zipCode: zipCode,
				nationality: nationality?.value,
				permanentAddress: permanentAddress,
				temporaryAddress: temporaryAddress,
				homePhone: homePhone,
				mobile: mobile,
				emergencyContact: emergencyContact,
				emergencyName: emergencyName
			})
				.then(async (response) => {
					setIsLoading(false)
					if (response?.data?.success == true) {
						let _data = response?.data?.data?.data
						setEmployeeName(_data?.firstName)
						setEmployeeId(_data?.id)
						doNext()
					} else {
						toast.error(response?.data?.message)
					}
				})
				.catch((e: any) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const doUpdate = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/employee/update_employee/${employeeId}`, {
				gender: gender,
				image: image,
				email: email,
				firstName: firstName,
				lastName: lastName,
				fatherName: fatherName,
				motherName: motherName,
				bloodGroup: bloodGroup?.value,
				qualification: qualification?.value,
				dob: dob,
				maritalStatus: maritalStatus?.value,
				religion: religion?.value,
				employeeCode: employeeCode,
				attendanceMachineCode: attendanceMachineCode,
				nationalID: nationalID,
				passportNo: passportNo,
				eobiNo: eobiNo,
				ntnNo: ntnNo,
				country: country?.value,
				state: state?.value,
				city: city?.value,
				zipCode: zipCode,
				nationality: nationality?.value,
				permanentAddress: permanentAddress,
				temporaryAddress: temporaryAddress,
				homePhone: homePhone,
				mobile: mobile,
				emergencyContact: emergencyContact,
				emergencyName: emergencyName
			})
				.then(async (response) => {
					setIsLoading(false)
					if (response?.data?.success == true) {
						let _data = response?.data?.data?.data
						setEmployeeName(_data?.firstName)
						doNext()
					}
				})
				.catch((e: any) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const handleCapture = (event: any) => {
		const _reader: any = new FileReader()
		_reader.readAsDataURL(event.target.files[0])
		_reader.onload = () => {
			if (_reader.readyState === 2) {
				setImage(_reader?.result)
			}
		}
	}

	const findStates = (selectedCountry: any) => {
		setStateOption([])
		setCityOption([])
		setState({label: '', value: ''})
		setCity({label: '', value: ''})
		let countryCode = ''
		const _setState: any = []

		optionsForCountry?.map((country: any) => {
			if (country?.name === selectedCountry) {
				countryCode = country?.isoCode
			}
		})
		optionsForState?.map((state: any) => {
			if (state?.countryCode === countryCode) {
				_setState.push({value: state.name, label: state.name})
			}
		})
		if (_setState?.length == 0) {
			setIsStates(true)
		} else {
			setIsStates(false)
		}
		setStateOption(_setState)
	}

	const findCities = (selectedCountry: any, selectedState: any) => {
		setCityOption([])
		setCity({label: '', value: ''})
		let countryCode = ''
		let stateCode = ''
		const _setCities: any = []

		optionsForCountry?.map((country: any) => {
			if (country?.name === selectedCountry) {
				countryCode = country?.isoCode
			}
		})
		optionsForState?.map((state: any) => {
			if (state?.name === selectedState) {
				stateCode = state?.isoCode
			}
		})
		if (countryCode && stateCode) {
			City.getCitiesOfState(countryCode, stateCode)?.forEach((city: any) => {
				_setCities.push({value: city.name, label: city.name})
			})
		}
		if (_setCities?.length == 0) {
			setIsCities(true)
		} else {
			setIsCities(false)
		}
		setCityOption(_setCities)
	}

	const setStateAndCityDrops = (countryName: any, stateName: any, cityName: any) => {
		let countryCode = ''
		let stateCode = ''
		const _setState: any = []
		const _setCities: any = []

		optionsForCountry?.map((country: any) => {
			if (country?.name === countryName) {
				countryCode = country?.isoCode
			}
		})
		optionsForState?.map((state: any) => {
			if (state?.countryCode === countryCode) {
				_setState.push({value: state.name, label: state.name})
			}
			if (state?.name === stateName) {
				stateCode = state?.isoCode
			}
		})

		setStateOption(_setState)
		if (countryCode && stateCode) {
			City.getCitiesOfState(countryCode, stateCode)?.forEach((city: any) => {
				_setCities.push({value: city.name, label: city.name})
			})
		}
		setCityOption(_setCities)
	}
	return (
		<>
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="user" viewBox="0 0 32 32" color="#00acac">
							<path _ngcontent-sio-c152="" d="M16 15a7 7 0 1 0 0-14 7 7 0 0 0 0 14zM3 31a13 13 0 0 1 26 0"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							General Information
						</Text>
						<TagLine type="normal" color="silver_8">
							Fill in the employee's personal data.
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Flexed direction="row" justify="center">
							<ProfileCover>
								{gender && (
									<img
										src={
											image
												? image.startsWith('data:')
													? image
													: process.env.REACT_APP_IMAGE_URL + image
												: gender === 'male'
													? '/images/default_user_profile_img.png'
													: gender === 'female'
														? '/images/default_girl_profile_img.png'
														: ''
										}
										alt=""
									/>
								)}
							</ProfileCover>
						</Flexed>
						<Divider margin="1rem 0rem 1.5rem 0rem" />
						<Container fluid>
							<Row>
								<Col>
									{image ? (
										<Delete
											onClick={() => {
												image && setImage('')
											}}>
											<RiDeleteBinLine color={palette.red} fontSize={24} />
											<Text type="xsmall" color="red">
												Delete Image
											</Text>
										</Delete>
									) : (
										<Upload
											onClick={() => {
												image && setImage('')
											}}>
											<MdOutlineCloudUpload fontSize={24} />
											<Text type="xsmall">Upload Image</Text>
											<FileInput
												multiple
												id="faceImage"
												accept="image/*"
												type="file"
												onChange={(e) => {
													handleCapture(e)
												}}
											/>
										</Upload>
									)}
								</Col>
								<Col>
									<Label type="small" margin="0rem 0rem 0.19rem 0rem">
										Gender
									</Label>
									<GroupButton direction="row" align="center">
										<Box direction="row" align="center" gap={0.5} onClick={() => setGender('male')}>
											<input name="male" type="radio" checked={gender === 'male'} />
											<Label type="small">Male</Label>
										</Box>
										<Box direction="row" align="center" gap={0.5} onClick={() => setGender('female')}>
											<input name="female" type="radio" checked={gender === 'female'} />
											<Label type="small">Female</Label>
										</Box>
										<Box direction="row" align="center" gap={0.5} onClick={() => setGender('others')}>
											<input name="others" type="radio" checked={gender === 'others'} />
											<Label type="small">Others</Label>
										</Box>
									</GroupButton>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="First Name"
										required
										placeholder="First Name"
										value={firstName}
										error={firstNameError}
										handleChange={(e: any) => {
											setFirstNameError('')
											if (e === '') {
												setFirstNameError('This value is required.')
											}
											setFirstName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Last Name"
										required
										placeholder="Last Name"
										value={lastName}
										error={lastNameError}
										handleChange={(e: any) => {
											setLastNameError('')
											if (e === '') {
												setLastNameError('This value is required.')
											}
											setLastName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Father Name"
										placeholder="Father Name"
										value={fatherName}
										handleChange={(e: any) => {
											setFatherName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Mother Name"
										placeholder="Mother Name"
										value={motherName}
										handleChange={(e: any) => {
											setMotherName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Work Email"
										error={emailError}
										required
										placeholder="Email"
										value={email}
										handleChange={(e: any) => {
											setEmailError('')
											if (e === '') {
												setEmailError('Email is required.')
											} else if (!_regex.test(e)) {
												setEmailError('Invalid email.')
											}
											setEmail(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Blood Group" firstSelected={bloodGroup.label} options={bloodGroupOption} hasChanged={(value: any) => setBloodGroup({label: value, value: value})} dropDownListHeight="200px" />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Qualification" firstSelected={qualification.label} options={qualificationOption} hasChanged={(value: any) => setQualification({label: value, value: value})} dropDownListHeight="200px" />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="DOB"
										value={dob}
										handleChange={(e: any) => {
											const today = moment()
											const age = today.diff(moment(e), 'years')
											if (age >= 9) {
												setDob(e)
											} else {
												setDob('')
												toast.error('The employee age should be greater than 9 year')
											}
										}}
										maxDate={new Date()}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Marital Status" firstSelected={maritalStatus.label} options={maritalStatusOption} hasChanged={(value: any) => setMaritalStatus({label: value, value: value})} dropDownListHeight="200px" />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Religion" firstSelected={religion.label} options={religionOption} hasChanged={(value: any) => setReligion({label: value, value: value})} dropDownListHeight="200px" />
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			<Spacer height={4} />
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="" viewBox="0 0 32 33">
							<path
								_ngcontent-sio-c152=""
								d="M10 25V7.312C10 6.588 10.634 6 11.417 6h9.166C21.366 6 22 6.588 22 7.313V25M14 10h4M14 14h4M14 18h4M14 22h4M24 32H8v-5.5c0-.828.616-1.5 1.375-1.5h13.25c.76 0 1.375.672 1.375 1.5V32zM16 5V2"></path>
							<path _ngcontent-sio-c152="" d="M14 32v-4h4v4M1 32h30"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Identities
						</Text>
						<TagLine type="normal" color="silver_8">
							Fill in the employee's Identities.
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={6}>
									<InputField
										label="Employee Code"
										allowPhoneNumberOnly={true}
										required
										error={employeeCodeError}
										value={employeeCode}
										handleChange={(e: any) => {
											setEmployeeCodeError('')
											if (e === '') {
												setEmployeeCodeError('This value is required.')
											}
											setEmployeeCode(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Nationality" firstSelected={nationality.label} options={nationalityOption} hasChanged={(value: any) => setNationality({label: value, value: value})} dropDownListHeight="200px" />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<Flexed direction="row" margin="0rem 0rem 0.19rem 0rem" gap={0.8} justify="space-between">
										<Label type="small">National ID</Label>
									</Flexed>
									<InputField
										allowPhoneNumberOnly={true}
										placeholder="National ID"
										value={nationalID}
										min={8}
										maxLength={18}
										handleChange={(e: any) => {
											setNationalID(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<Flexed direction="row" margin="0rem 0rem 0.19rem 0rem" gap={0.8} justify="space-between">
										<Label type="small">Passport No.</Label>
									</Flexed>
									<InputField
										placeholder="Passport Number"
										value={passportNo}
										min={6}
										maxLength={9}
										handleChange={(e: any) => {
											setPassportNo(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<Label type="small" margin="0rem 0rem 0.19rem 0rem">
										EOBI No
									</Label>
									<InputField
										allowOnlyNumbers={true}
										placeholder="EOBI Number"
										value={eobiNo}
										min={9}
										maxLength={12}
										handleChange={(e: any) => {
											setEobiNo(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<Label type="small" margin="0rem 0rem 0.19rem 0rem">
										NTN No..
									</Label>
									<InputField
										allowOnlyNumbers={true}
										placeholder="NTN"
										value={ntnNo}
										min={6}
										maxLength={18}
										handleChange={(e: any) => {
											setNtnNo(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			<Spacer height={4} />
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="" viewBox="0 0 32 32">
							<path _ngcontent-sio-c152="" d="M27.333 13.333C27.333 22 16 30.667 16 30.667S4.667 22.042 4.667 13.333C4.667 7.074 9.74 2 16 2c6.26 0 11.333 5.074 11.333 11.333z"></path>
							<path _ngcontent-sio-c152="" d="M16 18a4.667 4.667 0 1 0 0-9.333A4.667 4.667 0 0 0 16 18z"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Address
						</Text>
						<TagLine type="normal" color="silver_8">
							Fill in the employee's address
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={6}>
									<DropDown
										label="Country"
										required
										error={countryError}
										firstSelected={country.label}
										options={countryOption}
										hasChanged={(value: any) => {
											setCountryError('')
											setCountry({label: value, value: value})
											findStates(value)
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown
										label="State"
										required
										error={stateError}
										isCities={isStates}
										placeholder={'No state found'}
										firstSelected={state.label}
										options={stateOption}
										hasChanged={(value: any) => {
											setStateError('')
											setState({label: value, value: value})
											findCities(country?.value, value)
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown
										label="City"
										required
										error={cityError}
										firstSelected={city.label}
										options={cityOption}
										placeholder={'No city found'}
										isCities={isCities}
										hasChanged={(value: any) => {
											setCityError('')
											setCity({label: value, value: value})
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Zip/Postal Code"
										value={zipCode}
										min={3}
										maxLength={10}
										handleChange={(e: any) => {
											setZipCode(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
							<Row>
								<Col lg={6}>
									<InputField
										type="textarea"
										label="Permanent Address"
										value={permanentAddress}
										placeholder="Address"
										handleChange={(e: any) => {
											setPermanentAddress(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										type="textarea"
										label="Temporary Address"
										value={temporaryAddress}
										placeholder="Temporary Address"
										handleChange={(e: any) => {
											setTemporaryAddress(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			<Spacer height={4} />
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="" viewBox="0 0 32 32">
							<path _ngcontent-sio-c152="" d="M27.333 13.333C27.333 22 16 30.667 16 30.667S4.667 22.042 4.667 13.333C4.667 7.074 9.74 2 16 2c6.26 0 11.333 5.074 11.333 11.333z"></path>
							<path _ngcontent-sio-c152="" d="M16 18a4.667 4.667 0 1 0 0-9.333A4.667 4.667 0 0 0 16 18z"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Contact
						</Text>
						<TagLine type="normal" color="silver_8">
							Fill in the employee's Contact
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={6}>
									<InputField
										label="Home Phone"
										placeholder="Home Phone (+XXXXXXX)"
										value={homePhone}
										min={10}
										maxLength={15}
										allowPhoneNumberOnly={true}
										handleChange={(e: any) => {
											setHomePhone(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Work Phone/Mobile"
										placeholder="Work Phone/Mobile (+XXXXXXX)"
										allowPhoneNumberOnly={true}
										value={mobile}
										min={10}
										maxLength={15}
										handleChange={(e: any) => {
											setMobile(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			<Row>
				<Col>
					<Divider margin="1rem 0rem" />
					<Flexed direction="row" gap={1} align="center" justify="flex-end">
						{isEdit && <Back onClick={doBack}>Cancel</Back>}
						<Next
							onClick={() => {
								employeeId ? doUpdate() : doSave()
							}}>
							{isEdit ? 'Save' : 'Next'}
						</Next>
					</Flexed>
				</Col>
			</Row>
		</>
	)
}
const Icons = styled.svg`
	max-width: 25px;
	fill: #0000;
	stroke: ${palette.purple_1};
	stroke-width: 2px;
	stroke-linecap: round;
`

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

export const TagLine = styled(Text)`
	${media.md`max-width: 620px;`}
	${media.lg`max-width: 310px;`}
`

const GroupButton = styled(Flexed)`
	border: 2px solid #dfe3e6;
	border-radius: 3px;
	width: fit-content;
`

const Box = styled(Flexed)`
	cursor: pointer;
	padding: 7px 9px 5px;
	&:not(:last-child) {
		border-right: 2px solid #dfe3e6;
	}
`

const Form = styled.div`
	background: ${palette.silver_9};
	padding: 30px;
	border-radius: 4px;
	margin-bottom: 10px;
	border: 1px solid ${palette.gray_300};
`

const ProfileCover = styled.div`
	overflow: hidden;
	width: 130px;
	height: 130px;
	border-radius: 100%;
	display: inline-block;
	border: 2px solid ${palette.purple_1};
	margin: 1rem 0rem;
	& img {
		width: 100%;
		object-fit: cover;
		height: 130px;
	}
`

const ExpiryTag = styled.div`
	height: 20px;
	background: #ebeff4;
	line-height: 10px;
	font-size: 11px;
	color: ${palette.black_3};
	border-radius: 0.15rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.15rem 0.25rem;
	cursor: pointer;
	&:hover {
		background: ${palette.purple_1};
		color: ${palette.white};
	}
`

export const Next = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	background: ${palette.purple_1};
	color: ${palette.white};
	width: 5rem;
	font-size: 16px;
	font-weight: 500;
	border-radius: 10rem;
	cursor: pointer;
	&:hover {
		background: ${palette.purple_2};
	}
`

export const Back = styled(Next)`
	background: #d7e0ea;
	color: #4d6575;
	&:hover {
		background: #d7e0ea;
		box-shadow: 0 0 0 3px #d7e0ea4d;
		color: #4d6575 !important;
	}
`

const Upload = styled.label`
	padding: 0.5rem 1rem;
	width: 100%;
	border: 0.063rem dashed ${palette.light_gray};
	border-radius: 0.375rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.1rem;
	flex-direction: column;
	cursor: pointer;
`

const Delete = styled.div`
	padding: 0.5rem 1rem;
	width: 100%;
	border: 0.063rem dashed ${palette.light_gray};
	border-radius: 0.375rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.1rem;
	flex-direction: column;
	cursor: pointer;
`

const FileInput = styled.input`
	display: none !important;
`

export default PersonalIz
