import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {media} from 'styled-bootstrap-grid'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import InputField from '../common/InputField'
import moment from 'moment-timezone'
import InputDate from '../common/InputDate'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import MultiSelect from '../common/MultiSelect'

interface ICategoriesModalProps {
	onCloseModal: any
	editAttendance: any
	setIsLoading: any
	refreashTable: any
}

const AddEmployeeAttendanceModal = ({editAttendance, setIsLoading, refreashTable, onCloseModal}: ICategoriesModalProps) => {
	const [employee, setEmployee] = useState({
		label: editAttendance?.employeeName,
		value: editAttendance?.employeeId,
		image: editAttendance?.employeeImage
	})
	const [employeeId, setEmployeeId] = useState(editAttendance?.employeeId ? editAttendance?.employeeId : '')
	const [attendanceDate, setAttendanceDate] = useState(editAttendance?.attendanceDate ? new Date(moment(editAttendance?.attendanceDate, 'DD-MM-YYYY').format()) : new Date())
	const [timeInDate, setTimeInDate] = useState(editAttendance?.timeIn ? new Date(moment(editAttendance?.timeIn, 'DD-MM-YYYY HH:mm A').format()) : new Date())
	const [timeOutDate, setTimeOutDate] = useState(editAttendance?.timeOutDate ? new Date(moment(editAttendance?.timeOutDate, 'DD-MM-YYYY HH:mm A').format()) : new Date())
	const [remarks, setRemarks] = useState(editAttendance?.remarks ? editAttendance?.remarks : '')
	const [employeeOption, setEmployeeOption] = useState<any>([])

	const [employeeError, setEmployeeError] = useState('')
	const [attendanceDateError, setAttendanceDateError] = useState('')
	const [timeInDateError, setTimeInDateError] = useState('')
	const [timeOutDateError, setTimeOutDateError] = useState('')

	useEffect(() => {
		allEmployee()
	}, [])

	const allEmployee = async () => {
		await api
			.get('/employee/all_employees')
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data?.data?.data.length > 0) {
						const employeeData = response?.data?.data?.data

						const arr = employeeData.map((employee: any) => ({
							label: (
								<span style={{display: 'flex', gap: '0.2rem'}}>
									{' '}
									<Logo src={employee?.image ? `${process.env.REACT_APP_IMAGE_URL + employee?.image}` : '/images/default_user_profile_img.png'}></Logo>
									{`${employee.firstName}`}
									&nbsp;
									{`${employee.id}`}
								</span>
							),
							value: employee,
							image: employee?.image
						}))
						setEmployeeOption(arr)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const addAttendance = async () => {
		if (formValidation()) {
			setIsLoading(true)
			await api
				.post('/attendance/add_attendance', {
					employeeName: employee?.label,
					employeeImage: employee?.image,
					employeeId: employeeId,
					attendanceDate: moment(attendanceDate).format('DD-MM-YYYY'),
					timeIn: moment(timeInDate).format('DD-MM-YYYY HH:mm A'),
					timeOut: moment(timeOutDate).format('DD-MM-YYYY HH:mm A'),
					remarks: remarks
				})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Attendance added successfully')
						onCloseModal()
						refreashTable()
					} else if (response?.data?.success == false) {
						toast.error(response?.data?.message ? response?.data?.message : 'Failed to add Attendance')
					}
					setIsLoading(false)
				})
				.catch(function (error) {
					setIsLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	const updateAttendance = async () => {
		if (formValidation()) {
			setIsLoading(true)
			await api
				.put(`/attendance/update_attendance/${editAttendance?.id}`, {
					employeeName: employee?.label,
					employeeImage: employee?.image,
					employeeId: employeeId,
					attendanceDate: moment(attendanceDate).format('DD-MM-YYYY'),
					timeIn: moment(timeInDate).format('DD-MM-YYYY HH:mm A'),
					timeOut: moment(timeOutDate).format('DD-MM-YYYY HH:mm A'),
					remarks: remarks
				})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Attendance updated successfully')
						onCloseModal()
						refreashTable()
					} else if (response?.data?.success == false) {
						toast.error(response?.data?.message ? response?.data?.message : 'Failed to add Attendance')
					}
					setIsLoading(false)
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (!employeeId) {
			setEmployeeError('This value is required.')
			isValid = false
		}
		return isValid
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
							{editAttendance ? 'Edit Attendance' : 'Add Attendance'}
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Spacer height={1} />
					<Body>
						<Container>
							<Row>
								<Col lg={6}>
									<MultiSelect label="Employee" value={employeeOption?.find((e: any) => e?.value?.id == employeeId)} required options={employeeOption} onChange={(e: any) => setEmployeeId(e?.value?.id)} error={employeeError} />

									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="Attendance Date"
										error={attendanceDateError}
										required
										value={attendanceDate}
										handleChange={(e: any) => {
											setAttendanceDateError('')
											if (e === '') {
												setAttendanceDateError('This value is required.')
											}
											setTimeInDate(e)
											setTimeOutDate(e)
											setAttendanceDate(e)
										}}
									/>
									<Spacer height={1} />
								</Col>

								<Col lg={6}>
									<InputDate
										label="Time In"
										format="dd-MM-yyyy HH:mm a"
										error={timeInDateError}
										required
										value={timeInDate}
										handleChange={(e: any) => {
											setTimeInDateError('')
											if (moment(e).isAfter(timeOutDate)) {
												setTimeInDateError('The From date should be less than or equal to To date')
												setTimeInDate(timeOutDate)
											} else {
												if (e === '') {
													setTimeInDateError('This value is required.')
												}
												setTimeInDate(e)
											}
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="Time Out"
										format="dd-MM-yyyy HH:mm a"
										error={timeOutDateError}
										required
										value={timeOutDate}
										handleChange={(e: any) => {
											setTimeOutDateError('')
											if (!moment(e).isAfter(timeInDate)) {
												setTimeOutDate(timeOutDate)
											}
											if (e === '') {
												setTimeOutDateError('This value is required.')
											}
											setTimeOutDate(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col>
									<InputField
										type="textarea"
										label="Remarks"
										value={remarks}
										handleChange={(e: any) => {
											setRemarks(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Body>
					<Spacer height={1} />
					<Footer direction="row" align="center" justify="flex-end" gap={1}>
						<Button
							border="red"
							color="white"
							bg="red"
							bg_hover="red_hover"
							onClick={() => {
								onCloseModal()
							}}>
							Close
						</Button>
						<Button
							border="purple_2"
							color="white"
							bg="purple_1"
							bg_hover="purple_2"
							onClick={() => {
								if (editAttendance?.employeeId) {
									updateAttendance()
								} else {
									addAttendance()
								}
							}}>
							{editAttendance ? 'Update' : 'Save'}
						</Button>
					</Footer>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
	background-color: ${palette.gray_1};
	border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 800px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Footer = styled(Flexed)`
	border-top: 1px solid ${palette.silver};
	padding: 1rem 1.5rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({border}) => palette[border]};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	cursor: pointer;
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

export const Logo = styled.img`
	width: 1.5rem;
	height: 1.5rem;
	object-fit: cover;
	border-radius: 100%;
`
export default AddEmployeeAttendanceModal
