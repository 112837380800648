import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import SyntaxHighlighter from '../common/syntaxHighlighter/SyntaxHighlighter'
import {getEmbededCode} from './embedCode'
import {useSelector} from 'react-redux'
import {palette} from '../../styled/colors'

const OnlineBooking = ({title}: any) => {
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)

	return (
		<Wrapper>
			<Row>
				<Col md={12} lg={12}>
					<Wrapper>
						<Row>
							<Flex>
								<Card>
									<div>
										<Text>{'A fully customizable widget.'}</Text>
										<PointsContent>
											<AlignFlex>
												<Points>1</Points>
												<div>{`Paste code into an HTML file where the ${title ? title : ''} should appear.`}</div>
											</AlignFlex>
											<AlignFlex>
												<Points>2</Points>
												<div style={{width: 'fit-content'}}>{'If you want to integrate it into ReactJs/NextJs/GatsbyJs, then please paste it within an HTML `iframe` tag using the srcDoc prop.'}</div>
											</AlignFlex>
										</PointsContent>
									</div>
									<SyntaxHighlighter language="javascript">
										{getEmbededCode(_loginAdminDetails?.id!, title == 'job posts' ? process.env.REACT_APP_WIDGET_BUILD_JOB_POST_URL : process.env.REACT_APP_WIDGET_BUILD_TEAM_URL)}
									</SyntaxHighlighter>
								</Card>
							</Flex>
						</Row>
					</Wrapper>
				</Col>
			</Row>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	padding: 0rem;
`

const Card = styled.div`
	background: ${palette.white};
	/* box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
	border-radius: 4.50296px;
	padding: 1.75rem 2rem; */
`
const Text = styled.p`
	font-size: 1rem !important;
	line-height: 1.5rem !important;
	color: rgba(157, 160, 164) !important;
	margin: 0rem;
`

const Flex = styled(Col)`
	margin-bottom: 1.2rem;
`

const PointsContent = styled.div`
	padding: 1.25rem 0rem !important;
`

const Points = styled.div`
	color: rgba(255, 255, 255) !important;
	background-color: ${palette.purple_1};
	border-radius: 9999px !important;
	justify-content: center !important;
	align-items: center !important;
	width: 2rem !important;
	height: 2rem !important;
	display: flex !important;
	margin-right: 0.5rem;
`

const AlignFlex = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem !important;
`

export default OnlineBooking
