import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Divider, Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'
import CopyList from './CopyList'
import MoveList from './MoveList'
import {LuCheck} from 'react-icons/lu'
import MoveAllCardsInList from './MoveAllCardsInList'
import ArchiveAllCards from './ArchiveAllCards'
import {api} from '../api/callAxios'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {updateBoardList} from '../../actions/authActions'
import useRouter from '../../hooks/router'

const ListActionPop = ({watch, isWatch, onAddCardClick, onCloseModal, archiveList, listId, listDetails}: any) => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const [loading, setLoading] = useState(false)
	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}
	const [currentView, setCurrentView] = useState('default')
	const dispatch = useDispatch()
	const router = useRouter()

	const isMemberWatch = async (adminId?: any, employeeId?: any, isWatch?: any) => {
		try {
			await api
				.post(`/trello/watch_card`, {cardId: null, listId: listId, adminId, employeeId})
				.then((res: any) => {
					if (res?.data?.success == true) {
						onCloseModal(true)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const addList = async (title = '') => {
		try {
			await api
				.post(`/trello/create_list`, {title: title, isCopy: true, listId, boardId: router?.query?.id})
				.then((response) => {
					if (response?.data?.success == true) {
						dispatch(updateBoardList(true))
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const archiveListCards = async () => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_list?id=${listDetails?.id}`, {isArchiveAllCards: true})
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							dispatch(updateBoardList(true))
						}
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}
	return (
		<>
			<Popup onClick={handleModalClick}>
				{currentView === 'default' && (
					<>
						<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
							<div></div>
							<Text fontWeight={600}>List actions</Text>
							<CloseCover onClick={onCloseModal}>
								<IoMdClose cursor="pointer" />
							</CloseCover>
						</Flexed>
						<PopupList
							onClick={() => {
								onAddCardClick()
								onCloseModal()
							}}>
							Add Card
						</PopupList>
						<PopupList
							onClick={() => {
								setCurrentView('copy_list')
							}}>
							Copy list
						</PopupList>
						<PopupList
							onClick={() => {
								setCurrentView('move_list')
							}}>
							Move list
						</PopupList>
						<PopupList
							onClick={() => {
								isMemberWatch(_loginAdminDetails?.id ? _loginAdminDetails?.id : null, _loginUserDetails?.id ? _loginUserDetails?.id : null)
								isWatch()
							}}
							direction="row"
							align="center"
							gap={0.2}>
							Watch {watch ? <LuCheck /> : ''}
						</PopupList>
						<CustomDivider />

						<PopupList
							onClick={() => {
								setCurrentView('move_all_cards')
							}}>
							Move all cards in this list
						</PopupList>
						<PopupList
							onClick={() => {
								setCurrentView('archive_all_cards')
							}}>
							Archive all cards in this list
						</PopupList>
						<Divider />
						<PopupList onClick={archiveList && archiveList}>Archive this list</PopupList>
					</>
				)}
				{currentView === 'copy_list' && (
					<CopyList
						goBack={() => {
							setCurrentView('default')
						}}
						onAdd={addList}
						onCloseModal={onCloseModal}
					/>
				)}
				{currentView === 'move_list' && (
					<MoveList
						goBack={() => {
							setCurrentView('default')
						}}
						onCloseModal={onCloseModal}
						listDetails={listDetails}
					/>
				)}
				{currentView === 'move_all_cards' && (
					<MoveAllCardsInList
						goBack={() => {
							setCurrentView('default')
						}}
						listDetails={listDetails}
						onCloseModal={onCloseModal}
					/>
				)}
				{currentView === 'archive_all_cards' && (
					<ArchiveAllCards
						goBack={() => {
							setCurrentView('default')
						}}
						onArchiveClick={() => listDetails?.id && archiveListCards()}
						onCloseModal={onCloseModal}
					/>
				)}
			</Popup>
		</>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const CustomDivider = styled(Divider)`
	padding: 0 0.8rem;
`

const Popup = styled.div<any>`
	position: absolute;
	top: 2rem;
	z-index: 6;
	left: 0;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0;
	width: 300px;
`

const PopupList = styled(Flexed)`
	width: 100%;
	padding: 0.313rem 0.8rem;
	background-color: ${palette.white};
	// min-height: 32px;
	position: relative;
	cursor: pointer;
	&:hover {
		background: ${palette.silver_1};
	}
`

export default ListActionPop
