import React, {useState} from 'react'
import FirstStep from '../components/frontPage/FirstStep'
import SecondStep from '../components/frontPage/SecondStep'
import ThirdStep from '../components/frontPage/ThirdStep'

const FrontPage = () => {
	return (
		<div>
			<FirstStep />
			<SecondStep />
			<ThirdStep />
		</div>
	)
}

export default FrontPage
