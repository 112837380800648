import React, {Component, RefObject} from 'react'
import ReactSummernote from 'react-summernote' // Assuming 'react-summernote' is the correct import
import 'react-summernote/dist/react-summernote.css'
import 'bootstrap/js/tooltip'
import 'bootstrap/js/modal'
import 'bootstrap/js/dropdown'
// import 'bootstrap/dist/css/bootstrap.css'
import {Text} from '../../styled/shared'
import styled from 'styled-components'
import $ from 'jquery'
interface TextEditorProps {
	label: string
	onChange?: any
	value?: any
}

export default class TextEditor extends Component<TextEditorProps> {
	private uniqueEditor: RefObject<any> = React.createRef()

	onImageUpload = (fileList: any) => {
		const reader = new FileReader()
		reader.onloadend = () => {
			if (this.uniqueEditor.current) {
				this.uniqueEditor.current.insertImage(reader.result as string)
			}
		}
		reader.readAsDataURL(fileList[0])
	}

	onChange = (content: any) => {
		this.props.onChange && this.props.onChange(content)
	}

	render() {
		return (
			<>
				{this.props.label && (
					<Label type="medium" color="black" margin="0rem 0rem 0.19rem 0rem">
						{this.props.label}
					</Label>
				)}
				<ReactSummernote
					children={<div dangerouslySetInnerHTML={{__html: this.props.value}}></div>}
					ref={this.uniqueEditor}
					onImageUpload={this.onImageUpload}
					// codeview={false}
					options={{
						height: 200,
						toolbar: [
							['style', ['style']],
							['font', ['bold', 'underline', 'clear']],
							['fontname', ['fontname', 'color']],
							['para', ['ul', 'ol', 'paragraph']],
							['table', ['table']],
							['insert', ['link', 'picture', 'video']],
							['view', ['fullscreen', 'codeview', 'help']]
						]
					}}
					value={this.props.value}
					onChange={this.onChange}
				/>
			</>
		)
	}
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`
