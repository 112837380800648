import {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {MDBDataTableV5} from 'mdbreact'
import {Flexed, Text} from '../styled/shared'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../styled/colors'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import {Back} from '../components/employee/EmployeeDetails'
import TaskModal from '../components/modals/TaskModal'
import {Loading} from '../styled/shared'
import {HiDotsHorizontal} from 'react-icons/hi'
import DeleteModal from '../components/modals/DeleteModal'
import { ProfileImg } from './Team'

const Tasks = () => {
	const {pathname} = useLocation()
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')

	const _navigate = useNavigate()
	const _ref: any = useRef(null)
	const [modalStatus, setModalStatus] = useState('deleteTask')
	const [taskData, setTaskData] = useState('')
	const [taskId, setTaskId] = useState('')
	const [openTaskModal, setOpenTaskModal] = useState(false)
	const [isLoading, setIsLoading] = useState<any>(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [tabName, setTabName] = useState<any>('all')

	const [task, setTasks] = useState({
		columns: [
			{label: 'Task Name', field: 'taskName', width: 200},
			{label: 'dueDate', field: 'dueDate', width: 200},
			{label: 'Employee', field: 'employee', width: 200},
			{label: 'Status', field: 'status', width: 200},
			{label: 'Action', field: 'action', sort: String('disabled'), width: 50}
		],
		rows: []
	})

	useEffect(() => {
		allTask()
	}, [tabName])

	useEffect(() => {
		allTask()
	}, [])

	const allTask = async () => {
		if (_loginUserStatus === 'admin') {
			await api
				.post(`/admin/task/get_tasks`, {
					tabName: tabName
				})
				.then((response) => {
					if (response?.data?.success == true) {
						setTasks((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						if (response?.data?.data?.data?.length > 0) {
							response?.data?.data?.data?.map((data: any, index: any) => {
								createTasksTable(data)
							})
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} else {
			await api
				.post(`/admin/task/employee_tasks`, {
					tabName: tabName
				})
				.then((response) => {
					if (response?.data?.success == true) {
						setTasks((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						if (response?.data?.data?.data?.length > 0) {
							response?.data?.data?.data?.map((data: any, index: any) => {
								createTasksTable(data)
							})
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	const createTasksTable = (data: any) => {
		let _obj = {
			taskName: data?.taskName,
			dueDate: data?.dueDate,
			employee: <Flexed direction="row" align="center" justify="center"  gap={0.5}>
			<div>
				<ProfileImg>
					<img src={data?.employee_task?.image ? process.env.REACT_APP_IMAGE_URL + data?.employee_task?.image : '/images/default_user_profile_img.png'} alt="i" />
				</ProfileImg>
			</div>
			{data?.employee_task?.firstName} {data?.employee_task?.lastName}
		</Flexed> ,
			status: data?.status,
			action: (
				<Action>
					<HiDotsHorizontal fontSize="1.2rem" />
					<DropContent>
						<DropMenu
							onClick={() => {
								setTaskId(data?.id)
								setOpenDeleteModal(true)
							}}>
							Delete
						</DropMenu>
						<DropMenu
							onClick={() => {
								setTaskData(data)
								setOpenTaskModal(true)
							}}>
							Edit
						</DropMenu>
					</DropContent>
				</Action>
			)
		}
		setTasks((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}
	const deleteTask = async (id: any) => {
		setIsLoading(true)
		await api
			.delete(`/admin/task/remove_task/${id}`)
			.then((response) => {
				if (response?.data?.success == true) {
					toast.success('Document deleted successfully')
					setOpenDeleteModal(false)
					allTask()
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	return (
		<>
			<Wrapper direction="row" align="center">
				<Content direction="row" gap={2} align="center" justify="space-between" pointer="pointer">
					<div>
						<Text type="normal" fontWeight={500} color="black">
							Team Detail
						</Text>
					</div>
					<Flexed direction="row" gap={1}>
						<DashboardMenu to="/team" active={pathname === '/team'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/team'}>
								{_loginUserStatus === 'admin' ? 'Team' : 'Profile'}
							</StyledText>
						</DashboardMenu>
						<DashboardMenu to="/doc" active={pathname === '/doc'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/doc'}>
								Docs
							</StyledText>
						</DashboardMenu>
						<DashboardMenu to="/tasks" active={pathname === '/tasks'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/tasks'}>
								Tasks
							</StyledText>
						</DashboardMenu>
					</Flexed>
					<Flexed direction="row" align="center" gap={0.5}>
						{_loginUserStatus === 'admin' ? (
							<GroupButton direction="row" align="center">
								<Button
									onClick={() => {
										setOpenTaskModal(true)
									}}>
									{' '}
									Create a Task
								</Button>
							</GroupButton>
						) : null}
						<Back onClick={() => _navigate('/doc')}>
							<svg _ngcontent-kxy-c151="" width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00acac">
								<path
									_ngcontent-kxy-c151=""
									d="M116 121v260c0 13.785 11.215 25 25 25s25-11.215 25-25V121c0-13.785-11.215-25-25-25S116 107.215 116 121zM211.98 243.107c-5.985 3.555-5.996 12.224 0 15.785l219.648 130.416c7.391 4.39 16.269-2.86 13.317-11.014l-42.561-117.712c-1.455-4.026-1.564-8.549-.078-12.823 45.595-131.625 43.871-124.776 43.871-127.686 0 0 0 0 0-.001 0-6.511-7.428-11.61-13.906-7.763L211.98 243.107zM346 451c0 8.272 6.728 15 15 15 8.271 0 15-6.728 15-15 0-8.271-6.729-15-15-15C352.728 436 346 442.729 346 451zM66 396c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 5.515 4.486 10 10 10S66 401.515 66 396zM226 71c0 13.785 11.215 25 25 25 13.785 0 25-11.215 25-25s-11.215-25-25-25C237.215 46 226 57.215 226 71zM250.998 371.003A10 10 0 10250.998 391.003 10 10 0 10250.998 371.003zM350.998 80.997A10 10 0 10350.998 100.997 10 10 0 10350.998 80.997zM51.003 151.003A10 10 0 1051.003 171.003 10 10 0 1051.003 151.003zM460.998 261.003A10 10 0 10460.998 281.003 10 10 0 10460.998 261.003z"></path>
							</svg>
							Back
						</Back>
					</Flexed>
				</Content>
			</Wrapper>
			<Section>
				<Container fluid>
					<Row>
						<Col>
							<HeadingWraper>
								<Flexed direction="row" align="center" justify="space-between" gap={0.2} margin="0rem 0rem 0rem 1rem">
									<Flexed direction="row" align="center" gap={1}>
										<SearchMenu active={tabName === 'all'} onClick={() => setTabName('all')}>
											<StyledButtons type="normal" fontWeight={500} fontSize={0.8} active={tabName === 'all'}>
												All
											</StyledButtons>
										</SearchMenu>
										<SearchMenu active={tabName === 'today'} onClick={() => setTabName('today')}>
											<StyledButtons type="normal" fontWeight={500} fontSize={0.8} active={tabName === 'today'}>
												Today
											</StyledButtons>
										</SearchMenu>
										<SearchMenu active={tabName === 'sevenDays'} onClick={() => setTabName('sevenDays')}>
											<StyledButtons type="normal" fontWeight={500} fontSize={0.8} active={tabName === 'sevenDays'}>
												Next 7 Days
											</StyledButtons>
										</SearchMenu>
										<SearchMenu active={tabName === 'completed'} onClick={() => setTabName('completed')}>
											<StyledButtons type="normal" fontWeight={500} fontSize={0.8} active={tabName === 'completed'}>
												Completed
											</StyledButtons>
										</SearchMenu>
										<SearchMenu active={tabName === 'inProgress'} onClick={() => setTabName('inProgress')}>
											<StyledButtons type="normal" fontWeight={500} fontSize={0.8} active={tabName === 'inProgress'}>
												In Progress
											</StyledButtons>
										</SearchMenu>
									</Flexed>
								</Flexed>
							</HeadingWraper>
						</Col>
					</Row>
					<Row>
						<Col>
							<TableWraper>
								<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={task} fullPagination searchTop searchBottom={false} />
							</TableWraper>
						</Col>
					</Row>
					{openTaskModal && (
						<TaskModal
							open={openTaskModal}
							onCloseModal={() => {
								setOpenTaskModal(false)
								setTaskData('')
								setIsLoading(false)
							}}
							refreshTable={() => {
								allTask()
							}}
							editTaskData={taskData}
							setIsLoading={() => {
								setIsLoading(true)
							}}
						/>
					)}
				</Container>
			</Section>
			{openDeleteModal && (
				<DeleteModal
					modalStatus={modalStatus}
					open={openDeleteModal}
					onCloseModal={() => {
						setOpenDeleteModal(false)
					}}
					deletePost={() => deleteTask(taskId)}
				/>
			)}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`

const Content = styled(Flexed)`
	width: 100%;
`
const DashboardMenu = styled(NavLink)<any>`
	border-bottom: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
`
const SearchMenu = styled(NavLink)<any>``

const StyledText = styled(Text)`
	padding: 10px 0px 10px 0px;
	color: ${({active}) => (active ? `${palette.purple_1}` : 'black')};
`
const StyledButtons = styled(Text)`
	background: ${({active}) => (active ? `${palette.purple_1}` : 'white')};
	color: ${({active}) => (active ? `${palette.white}` : `${palette.purple_1}`)};
	border: 1px solid ${palette.purple_1};
	padding: 1px 15px;
	border-radius: 5px;
`

const Section = styled.div`
	padding: 25px 5px 20px 5px;
`
const TableWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	padding: 15px;
	border-radius: 5px;
`
const HeadingWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	border-radius: 5px;
`

const GroupButton = styled(Flexed)`
	position: relative;
`

const Button = styled.div<any>`
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	height: 34px;
	display: flex;
	align-items: center;
	padding: 10px 10px 10px 10px;
	border-radius: 3px;
	border-bottom-left-radius: 3px;
	font-weight: 500;
	font-size: 0.813rem;
	line-height: 1.5rem;
	color: ${palette.white};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
`

const DropContent = styled.div<any>`
	display: none;
	top: 1rem;
	position: absolute;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	min-width: 6.5rem;
	right: 1.5rem;
	box-shadow: ${palette.shadow};
	z-index: 1;
	border-radius: 0.5rem;
	overflow: hidden;
`

const Action = styled.div`
	position: relative;
	&:hover ${DropContent} {
		display: block;
	}
`

const DropMenu = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-size: 0.8rem;
	padding: 0rem 0.5rem 0rem 0.5rem;
	text-decoration: none;
	display: block;
	&:hover {
		background-color: ${palette.purple_1};
		color: ${palette.white};
	}
	z-index: 1;
	cursor: pointer;
	text-align: left;
`

export default Tasks
