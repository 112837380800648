import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Text, Spacer, Flexed, Divider } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { media } from 'styled-bootstrap-grid'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import DropDown from '../common/DropDown'
import InputField from '../common/InputField'
import { toast } from 'react-toastify'

const AddDeductionModal = ({ onCloseModal,deductionList, setDeductionList }:any) => {
    const [deductionCategory, setDeductionCategory] = useState({ label: 'EOBI', value: 'EOBI' });
    const [calculateAmount, setCalculateAmount] = useState({ label: 'Fixed', value: 'Fixed' });
    const [deductionTaxPlan, setDeductionTaxPlan] = useState({ label: 'Taxable', value: 'Taxable' });
    const [amount, setAmount] = useState<any>(0);
    const [percentage, setPercentage] = useState<any>(0);

    const [amountError, setAmountError] = useState<any>('');
    const [percentageError, setPercentageError] = useState<any>('');

    const [deductionCategoryOption, setDeductionCategoryOption] = useState([
        { value: 'EOBI', label: 'EOBI' },
        { value: 'Income Tax', label: 'Income Tax' },
        { value: 'Late & Early Out Deduction', label: 'Late & Early Out Deduction' },
        { value: 'Loan', label: 'Loan' },
        { value: 'Provident Fund', label: 'Provident Fund' },
        { value: 'Other', label: 'Other' },
    ])
    const [calculateAmountOption, setCalculateAmountOption] = useState([
        { value: 'Fixed', label: 'Fixed' },
        { value: 'Salary', label: 'Salary' },
    ])
    const [deductionTaxPlanOption, setDeductionTaxPlanOption] = useState([
        { value: 'Taxable', label: 'Taxable' },
        { value: 'Non Taxable', label: 'Non Taxable' },
    ])

    const addList = () => {
        if(formValidation()){
            let _obj = {
                deductionCategory: deductionCategory?.value,
                calculateAmount: calculateAmount?.value,
                deductionTaxPlan: deductionTaxPlan?.value,
                amount: amount,
                percentage: percentage,
            }
            const isDuplicateName = deductionList.some((item: any) => item.deductionCategory === _obj.deductionCategory);
            if (!isDuplicateName) {
                setDeductionList((pre: any) => {
                    return [...pre, _obj]
                })
                setDeductionCategory({ label: 'EOBI', value: 'EOBI' });
                setCalculateAmount({ label: 'Fixed', value: 'Fixed' });
                setDeductionTaxPlan({ label: 'Taxable', value: 'Taxable' });
                setAmount(0);
                setPercentage(0)
                onCloseModal()
            }
            else {
                toast.error('Deduction Category with the same name already exists.');
            }
        }
    }

    const formValidation = () => {
        let isValid = true
        if (calculateAmount?.value === 'Fixed') {
            if (amount == 0) {
                setAmountError('This value is required.')
                isValid = false
            }
        }
        if (calculateAmount?.value === 'Salary') {
            if (percentage == 0) {
                setPercentageError('This value is required.')
                isValid = false
            }
        }
        return isValid
    }
    return (
        <>
            <Modal
                open={true}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'blockModal'
                }}
                showCloseIcon={false}>
                <ModalWrapper>
                    <Head direction="row" align="center" justify="space-between" gap={1}>
                        <Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
                            Add Deduction
                        </Text>
                        <CrossIcon
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Spacer height={1} />
                    <Body>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <DropDown label='Deduction Category' firstSelected={deductionCategory.label} options={deductionCategoryOption} hasChanged={(value: any) => setDeductionCategory({ label: value, value: value })} dropDownListHeight='160px' />
                                    <Spacer height={1} />
                                </Col>

                                <Col lg={6}>
                                    <DropDown label='Calculate Amount' firstSelected={calculateAmount.label} options={calculateAmountOption} hasChanged={(value: any) => setCalculateAmount({ label: value, value: value })} dropDownListHeight='150px' />
                                    <Spacer height={1} />
                                </Col>

                                <Col lg={6}>
                                    <DropDown label='Deduction tax plan' firstSelected={deductionTaxPlan.label} options={deductionTaxPlanOption} hasChanged={(value: any) => setDeductionTaxPlan({ label: value, value: value })} dropDownListHeight='150px' />
                                    <Spacer height={1} />
                                </Col>

                                {calculateAmount.label === 'Fixed' ? <Col lg={6}>
                                    <InputField error={amountError} required label='Amount' placeholder='' value={amount}
                                        allowOnlyNumbersAndDecimal={true}
                                        onBlur={() => {
                                            if (amount == '') {
                                                setAmount(0)
                                            }
                                        }}
                                        onFocus={() => {
                                            setPercentage(0)
                                            if (amount == 0) {
                                                setAmount('')
                                            }
                                        }}
                                        handleChange={(e: any) => {
                                            setAmountError('');
                                            setAmount(e)
                                        }} />
                                    <Spacer height={1} />
                                </Col>
                                    :
                                    <Col lg={6}>
                                        <InputField error={percentageError} required label='Amount %' placeholder='' value={percentage}
                                            allowOnlyNumbersAndDecimal={true}
                                            onBlur={() => {
                                                if (percentage == '') {
                                                    setPercentage(0)
                                                }
                                            }}
                                            onFocus={() => {
                                                setAmount(0)
                                                if (percentage == 0) {
                                                    setPercentage('')
                                                }
                                            }}
                                            handleChange={(e: any) => {
                                               setPercentageError('');
                                                setPercentage(e)
                                            }} />
                                        <Spacer height={1} />
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col>
                                    <Flexed margin='1rem 0rem 0rem 0rem' direction='row' justify='flex-end'>
                                        <Button onClick={() => { addList() }}>Add To List</Button>
                                    </Flexed>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col>
                                    <ListHead direction="row" align="center" justify="space-between">
                                        <Text type="small" fontWeight={500} color='black_1'>
                                            Name
                                        </Text>
                                        <Text type="small" fontWeight={500} color='black_1'>
                                            Amount
                                        </Text>
                                    </ListHead>
                                    <Divider />
                                    <Divider />
                                    {deductionList?.map((data: any) => {
                                        return (
                                            <>
                                                <List direction="row" align="center" justify="space-between" pointer>
                                                    <Text type="small" color='black_1'>
                                                        {data?.deductionCategory}
                                                    </Text>
                                                    <Text type="small" color='black_1'>
                                                        RS {data?.amount}
                                                    </Text>
                                                </List>
                                                <Divider />
                                            </>
                                        )
                                    })}
                                </Col>
                            </Row> */}
                        </Container>
                    </Body>
                    <Spacer height={1} />
                </ModalWrapper>
            </Modal>
        </>
    )
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
    background-color: ${palette.gray_1};
    border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;
    ${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 800px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const ListHead = styled(Flexed)`
    padding: 0.5rem;
    &:hover{
        background :${palette.silver}
    };
`

const List = styled(Flexed)`
    padding: 0.5rem;
    &:hover{
        background :${palette.silver}
    };
`

const Button = styled.div<any>`
    width: fit-content;
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	height: 40px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 3px;
	font-weight: 500;
	font-size: 14.5px;
	line-height: 1.5rem;
	color: ${palette.white};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
`

export default AddDeductionModal
