import React, {useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import InputFile from '../common/InputFile'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import FileUpload from '../common/FileUpload'

interface ICategoriesModalProps {
	onCloseModal: any
	documentDetail: any
	refreshTable: any
	setIsLoading: any
}

const CompanyDocumentModal = ({onCloseModal, documentDetail, refreshTable, setIsLoading}: ICategoriesModalProps) => {
	let _navigate = useNavigate()
	const [documentType, setDocumentType] = useState(documentDetail ? documentDetail?.documentType : '')
	const [documentTypeOption, setDocumentTypeOption] = useState([
		{label: 'HR Policies', value: 'HR Policies'},
		{label: 'Finance Policies', value: 'Finance Policies'},
		{label: 'Employment Contract', value: 'Employment Contract'}
	])
	const [accessRights, setAccessRights] = useState(documentDetail ? documentDetail?.accessRights : '')
	const [accessRightsOption, setAccessRightsOption] = useState([
		{label: 'Administrator', value: 'Administrator'},
		{label: 'Employees', value: 'Employees'}
	])
	const [expiryNotificationBeforeMonth, setExpiryNotificationBeforeMonth] = useState(documentDetail ? documentDetail?.expNotifyBeforeMonths : '')
	const [expiryNotificationBeforeMonthOption, setExpiryNotificationBeforeMonthOption] = useState([
		{label: '1', value: '1'},
		{label: '2', value: '2'},
		{label: '3', value: '3'}
	])
	const [documentName, setDocumentName] = useState(documentDetail ? documentDetail?.documentName : '')
	const [attachmentName, setAttachmentName] = useState(documentDetail ? documentDetail?.document : '')
	const [expiryDate, setExpiryDate] = useState(documentDetail ? documentDetail?.expiryDate : '')
	const [attachment, setAttachment] = useState(documentDetail ? documentDetail?.document : '')
	const [description, setDescription] = useState(documentDetail ? documentDetail?.description : '')
	const [documentNameError, setDocumentNameError] = useState('')
	const [documentTypeError, setDocumentTypeError] = useState('')

	const addDocument = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.post(`/admin/document/add`, {
				documentType: documentType,
				document: attachment,
				documentName: documentName,
				accessRights: accessRights,
				description: description,
				expiryDate: expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : '',
				expNotifyBeforeMonths: expiryNotificationBeforeMonth
			})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Document Added successfully')
						onCloseModal()
						_navigate('/doc')
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}
	const updateDocument = () => {
		setIsLoading(true)
		api.put(`/admin/document/update/${documentDetail?.id}`, {
			documentType: documentType,
			document: attachment,
			documentName: documentName,
			accessRights: accessRights,
			description: description,
			expiryDate: expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : '',
			expNotifyBeforeMonths: expiryNotificationBeforeMonth
		})
			.then((response) => {
				if (response?.data?.success == true) {
					toast.success('Document Added successfully')
					onCloseModal()
					refreshTable()
					_navigate('/doc')
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const formValidation = () => {
		let isValid = true
		if (!documentName) {
			setDocumentNameError('Document name is required')
			isValid = false
		}
		if (!documentType) {
			setDocumentTypeError('Document type is required')
			isValid = false
		}
		return isValid
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
							Add Company Document
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Spacer height={1} />
					<Body>
						<Container>
							<Row>
								<Col lg={6}>
									<DropDown
										label="Document Type"
										required
										firstSelected={documentType}
										options={documentTypeOption}
										hasChanged={(value: any) => {
											setDocumentTypeError('')
											setDocumentType(value)
										}}
										dropDownListHeight="130px"
										error={documentTypeError}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Document Name"
										value={documentName}
										required
										handleChange={(e: any) => {
											setDocumentNameError('')
											setDocumentName(e)
										}}
										error={documentNameError}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={12}>
									<FileUpload label="Attachment Type" value={attachmentName} setFileName={setAttachmentName} setFileValue={setAttachment} />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Access Rights" firstSelected={accessRights} options={accessRightsOption} hasChanged={(value: any) => setAccessRights(value)} dropDownListHeight="130px" />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="Expiry Date"
										value={expiryDate}
										handleChange={(e: any) => {
											setExpiryDate(e)
										}}
									/>

									<Spacer height={1} />
								</Col>
								<Col lg={12}>
									<DropDown
										label="Show document expiry notification before Month"
										firstSelected={expiryNotificationBeforeMonth}
										options={expiryNotificationBeforeMonthOption}
										hasChanged={(value: any) => setExpiryNotificationBeforeMonth(value)}
										dropDownListHeight="130px"
									/>
									<Spacer height={1} />
								</Col>

								<Col>
									<InputField
										label="Description"
										type="textarea"
										value={description}
										handleChange={(e: any) => {
											setDescription(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Body>
					<Footer direction="row" align="center" justify="flex-end" gap={1}>
						<Button
							border="red"
							color="white"
							bg="red"
							bg_hover="red_hover"
							onClick={() => {
								onCloseModal()
							}}>
							Cancel
						</Button>
						<Button
							border="purple_2"
							color="white"
							bg="purple_1"
							bg_hover="purple_2"
							onClick={() => {
								documentDetail ? updateDocument() : addDocument()
							}}>
							Save
						</Button>
					</Footer>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
	background-color: ${palette.gray_1};
	border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 0.5rem 1.5rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 600px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Footer = styled(Flexed)`
	border-top: 1px solid ${palette.silver};
	padding: 1rem 1.5rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({border}) => palette[border]};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

export default CompanyDocumentModal
