import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import {Checked, CheckIconWrapper, UnChecked} from '../../pages/Auth/SignUp'
import {RiAddFill} from 'react-icons/ri'
import {Back, Next, TagLine} from './PersonalIz'
import MedicalInsuranceModal from '../modals/MedicalInsuranceModal'
import AirTicketModal from '../modals/AirTicketModal'
import {toast} from 'react-toastify'
import {api} from '../api/callAxios'
import moment from 'moment-timezone'

const Official = ({doBack, doNext, setIsLoading, employeeId, setEmployeeId, setEmployeeName, isEdit}: any) => {
	const [openMedicalInsuranceModal, setOpenMedicalInsuranceModal] = useState(false)
	const [openAirTicketModalModal, setOpenAirTicketModalModal] = useState(false)
	const [joiningDate, setJoiningDate] = useState('')
	const [employeeType, setEmployeeType] = useState({label: 'Permanent(Confirmed)', value: 'Permanent(Confirmed)'})
	const [employeeTypeStartDate, setEmployeeTypeStartDate] = useState('')
	const [employeeTypeEndDate, setEmployeeTypeEndDate] = useState('')
	const [probationEndDate, setProbationEndDate] = useState('')
	const [confirmationDate, setConfirmationDate] = useState('')
	const [workLocation, setWorkLocation] = useState({label: '', value: ''})
	const [designation, setDesignation] = useState({label: '', value: ''})
	const [department, setDepartment] = useState({label: '', value: ''})
	const [subDepartment, setSubDepartment] = useState({label: '', value: ''})

	const [insuranceCardNo, setInsuranceCardNo] = useState('')
	const [insuranceClass, setInsuranceClass] = useState({label: 'VIP', value: 'VIP'})
	const [medicalInsuranceExpiryDate, setMedicalInsuranceExpiryDate] = useState('')
	const [medicalInsuranceAmountMonthly, setMedicalInsuranceAmountMonthly] = useState('')

	const [numberOfAirTicket, setNumberOfAirTicket] = useState('')
	const [airTicketFrequency, setAirTicketFrequency] = useState({label: '6 Months', value: '6 Months'})
	const [originCountry, setOriginCountry] = useState({label: '', value: ''})
	const [originCity, setOriginCity] = useState({label: '', value: ''})
	const [destinationCountry, setDestinationCountry] = useState({label: '', value: ''})
	const [destinationCity, setDestinationCity] = useState({label: '', value: ''})
	const [airTicketClass, setAirTicketClass] = useState({label: 'First Class', value: 'First Class'})
	const [remarks, setRemarks] = useState('')

	const [confirmation, setConfirmation] = useState(false)
	const [medicalInsurance, setMedicalInsurance] = useState(false)
	const [airTicket, setAirTicket] = useState(false)
	const [gratuity, setGratuity] = useState(false)
	const [gratuityDate, setGratuityDate] = useState('')
	const [paymentMethod, setPaymentMethod] = useState({label: 'Bank Transfer', value: 'Bank Transfer'})
	const [bankName, setBankName] = useState('')
	const [branch, setBranch] = useState('')
	const [branchCode, setBranchCode] = useState('')
	const [accountNo, setAccountNo] = useState('')
	const [swiftCode, setSwiftCode] = useState('')

	const [joiningDateError, setJoiningDateError] = useState('')
	const [employeeTypeError, setEmployeeTypeError] = useState('')
	const [employeeTypeStartDateError, setEmployeeTypeStartDateError] = useState('')
	const [employeeTypeEndDateError, setEmployeeTypeEndDateError] = useState('')
	const [workLocationError, setWorkLocationError] = useState('')
	const [designationError, setDesignationError] = useState('')
	const [departmentError, setDepartmentError] = useState('')
	const [subDepartmentError, setSubDepartmentError] = useState('')

	const [employeeTypeOption, setEmployeeTypeOption] = useState([
		{value: 'Permanent(Confirmed)', label: 'Permanent(Confirmed)'},
		{value: 'Contract', label: 'Contract'},
		{value: 'Permanent (Probation)', label: 'Permanent (Probation)'},
		{value: 'Retirement', label: 'Retirement'},
		{value: 'Resigned', label: 'Resigned'},
		{value: 'Terminated', label: 'Terminated'},
		{value: 'Other', label: 'Other'},
		{value: 'Internship', label: 'Internship'},
		{value: 'Probation', label: 'Probation'}
	])
	const [workLocationOption, setWorkLocationOption] = useState([
		{value: 'Lahore', label: 'Lahore'},
		{value: 'Karachi', label: 'Karachi'},
		{value: 'Multan', label: 'Multan'}
	])
	const [designationOption, setDesignationOption] = useState([
		{value: 'Front-end Developer', label: 'Front-end Developer'},
		{value: 'Back-end Developer', label: 'Back-end Developer'},
		{value: 'HR', label: 'HR'},
		{value: 'Manager', label: 'Manager'},
		{value: 'Quality Assurance', label: 'Quality Assurance'}
	])
	const [departmentOption, setDepartmentOption] = useState([
		{value: 'Web', label: 'Web'},
		{value: 'MobileApp', label: 'MobileApp'},
		{value: 'Game', label: 'Game'},
		{value: 'Manager', label: 'Manager'},
		{value: 'Quality Assurance', label: 'Quality Assurance'}
	])
	const [subDepartmentOption, setSubDepartmentOption] = useState([
		{value: 'Front-end Developer', label: 'Front-end Developer'},
		{value: 'Back-end Developer', label: 'Back-end Developer'},
		{value: 'HR', label: 'HR'},
		{value: 'Manager', label: 'Manager'},
		{value: 'Quality Assurance', label: 'Quality Assurance'},
		{value: 'Software Developer', label: 'Software Developer'},
		{value: 'Full Stack Developer', label: 'Full Stack Developer'},
		{value: 'Mobile App Developer', label: 'Mobile App Developer'},
		{value: 'Software Engineer', label: 'Software Engineer'},
		{value: 'UI/UX Designer', label: 'UI/UX Designer'},
		{value: 'DevOps Engineer', label: 'DevOps Engineer'}
	])

	const [salaryPaymentMethodOption, setSalaryPaymentMethodOption] = useState([
		{value: 'Bank Transfer', label: 'Bank Transfer'},
		{value: 'Cash', label: 'Cash'},
		{value: 'Cheque', label: 'Cheque'},
		{value: 'International Wire', label: 'International Wire'}
	])

	const [insuranceClassOption, setInsuranceClassOption] = useState([
		{value: 'VIP', label: 'VIP'},
		{value: 'A+', label: 'A+'},
		{value: 'A', label: 'A'},
		{value: 'B', label: 'B'},
		{value: 'C', label: 'C'}
	])

	const [airTicketFrequencyOption, setAirTicketFrequencyOption] = useState([
		{value: '6 Months', label: '6 Months'},
		{value: 'Annual', label: 'Annual'}
	])
	const [originCountryOption, setOriginCountryOption] = useState([
		{value: 'Pakistan', label: 'Pakistan'},
		{value: 'USA', label: 'USA'},
		{value: 'Canada', label: 'Canada'},
		{value: 'UK', label: 'UK'},
		{value: 'UAE', label: 'UAE'}
	])
	const [originCityOption, setOriginCityOption] = useState([
		{value: 'Lahore', label: 'Lahore'},
		{value: 'Karachi', label: 'Karachi'},
		{value: 'Multan', label: 'Multan'}
	])
	const [destinationCountryOption, setDestinationCountryOption] = useState([
		{value: 'Pakistan', label: 'Pakistan'},
		{value: 'USA', label: 'USA'},
		{value: 'Canada', label: 'Canada'},
		{value: 'UK', label: 'UK'},
		{value: 'UAE', label: 'UAE'}
	])
	const [destinationCityOption, setDestinationCityOption] = useState([
		{value: 'Lahore', label: 'Lahore'},
		{value: 'Karachi', label: 'Karachi'},
		{value: 'Multan', label: 'Multan'}
	])
	const [airTicketClassOption, setAirTicketClassOption] = useState([
		{value: 'First Class', label: 'First Class'},
		{value: 'Business Class', label: 'Business Class'},
		{value: 'Economy Class', label: 'Economy Class'}
	])

	useEffect(() => {
		if (employeeId) {
			doGetEmployeeDetails(employeeId)
		}
	}, [employeeId])

	const doGetEmployeeDetails = (id: any) => {
		setIsLoading(true)
		api.get(`/employee/get_employee/${id}`)
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					let _data = response?.data?.data?.data
					setEmployeeName(_data?.firstName)
					setJoiningDate(_data?.joiningDate)
					setEmployeeType({label: _data?.employeeType ? _data?.employeeType : 'Permanent(Confirmed)', value: _data?.employeeType ? _data?.employeeType : 'Permanent(Confirmed)'})
					setEmployeeTypeStartDate(_data?.employeeTypeStartDate)
					setEmployeeTypeEndDate(_data?.employeeTypeEndDate)
					setProbationEndDate(_data?.probationEndDate)
					setConfirmationDate(_data?.confirmationDate)
					setWorkLocation({label: _data?.workLocation, value: _data?.workLocation})
					setDesignation({label: _data?.designation, value: _data?.designation})
					setDepartment({label: _data?.department, value: _data?.department})
					setSubDepartment({label: _data?.subDepartment, value: _data?.subDepartment})
					setInsuranceCardNo(_data?.insuranceCardNo)
					setInsuranceClass({label: _data?.insuranceClass, value: _data?.insuranceClass})
					setMedicalInsuranceExpiryDate(_data?.medicalInsuranceExpiryDate)
					setMedicalInsuranceAmountMonthly(_data?.medicalInsuranceAmountMonthly)
					setNumberOfAirTicket(_data?.numberOfAirTicket)
					setAirTicketFrequency({label: _data?.airTicketFrequency, value: _data?.airTicketFrequency})
					setOriginCountry({label: _data?.originCountry, value: _data?.originCountry})
					setOriginCity({label: _data?.originCity, value: _data?.originCity})
					setDestinationCountry({label: _data?.destinationCountry, value: _data?.destinationCountry})
					setDestinationCity({label: _data?.destinationCity, value: _data?.destinationCity})
					setAirTicketClass({label: _data?.airTicketClass, value: _data?.airTicketClass})
					setRemarks(_data?.remarks)
					setConfirmation(_data?.confirmation)
					setMedicalInsurance(_data?.medicalInsurance)
					setAirTicket(_data?.airTicket)
					setGratuity(_data?.gratuity)
					setGratuityDate(_data?.gratuityDate)
					setPaymentMethod({label: _data?.paymentMethod ? _data?.paymentMethod : 'Bank Transfer', value: _data?.paymentMethod ? _data?.paymentMethod : 'Bank Transfer'})
					setBankName(_data?.bankName)
					setBranch(_data?.branch)
					setBranchCode(_data?.branchCode)
					setAccountNo(_data?.accountNo)
					setSwiftCode(_data?.swiftCode)
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const doUpdate = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/employee/update_employee/${employeeId}`, {
				joiningDate: joiningDate,
				employeeType: employeeType?.value,
				employeeTypeStartDate: employeeTypeStartDate,
				employeeTypeEndDate: employeeTypeEndDate,
				probationEndDate: probationEndDate,
				confirmationDate: confirmationDate,
				workLocation: workLocation?.value,
				designation: designation?.value,
				department: department?.value,
				subDepartment: subDepartment?.value,
				insuranceCardNo: insuranceCardNo,
				insuranceClass: insuranceClass?.value,
				medicalInsuranceExpiryDate: medicalInsuranceExpiryDate,
				medicalInsuranceAmountMonthly: medicalInsuranceAmountMonthly,
				numberOfAirTicket: numberOfAirTicket,
				airTicketFrequency: airTicketFrequency?.value,
				originCountry: originCountry?.value,
				originCity: originCity?.value,
				destinationCountry: destinationCountry?.value,
				destinationCity: destinationCity?.value,
				airTicketClass: airTicketClass?.value,
				remarks: remarks,
				confirmation: confirmation,
				medicalInsurance: medicalInsurance,
				airTicket: airTicket,
				gratuity: gratuity,
				gratuityDate: gratuityDate,
				paymentMethod: paymentMethod?.value,
				bankName: bankName,
				branch: branch,
				branchCode: branchCode,
				accountNo: accountNo,
				swiftCode: swiftCode
			})
				.then(async (response) => {
					setIsLoading(false)
					if (response?.data?.success == true) {
						let _data = response?.data?.data?.data
						setEmployeeName(_data?.firstName)
						doNext()
					}
				})
				.catch((e: any) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (joiningDate === '') {
			setJoiningDateError('This value is required.')
			isValid = false
		}
		if (moment().add(2, 'month').isBefore(moment(joiningDate))) {
			setJoiningDateError('Joining date should be within next 2 months.')
			isValid = false
		}
		if (employeeType?.value === '') {
			setEmployeeTypeError('This value is required.')
			isValid = false
		}
		if (employeeType?.value != 'Permanent(Confirmed)') {
			if (employeeTypeStartDate === '') {
				setEmployeeTypeStartDateError('This value is required.')
				isValid = false
			}
			if (employeeTypeEndDate === '') {
				setEmployeeTypeEndDateError('This value is required.')
				isValid = false
			}
		}

		if (workLocation?.value === '') {
			setWorkLocationError('This value is required.')
			isValid = false
		}
		if (designation?.value === '') {
			setDesignationError('This value is required.')
			isValid = false
		}
		return isValid
	}

	return (
		<>
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="user" viewBox="0 0 32 32" color="#00acac">
							<path _ngcontent-sio-c152="" d="M16 15a7 7 0 1 0 0-14 7 7 0 0 0 0 14zM3 31a13 13 0 0 1 26 0"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Contract
						</Text>
						<TagLine type="normal" color="silver_8">
							Fill in the employee's contract info.
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={6}>
									<InputDate
										label="Joining Date"
										error={joiningDateError}
										required
										value={joiningDate}
										handleChange={(e: any) => {
											setJoiningDateError('')
											if (e === '') {
												setJoiningDateError('This value is required.')
											} else if (moment().add(2, 'month').isBefore(moment(e))) {
												setJoiningDateError('Joining date should be within next 2 months.')
											} else {
												setJoiningDateError('')
											}
											setJoiningDate(e)
										}}
										// minDate={new Date(moment().subtract(2, 'week').format())}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown
										label="Employee Type"
										error={employeeTypeError}
										required
										firstSelected={employeeType.label}
										options={employeeTypeOption}
										hasChanged={(value: any) => {
											setEmployeeTypeError('')
											setEmployeeType({label: value, value: value})
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
								<Col>
									<Flexed direction="row" align="center" gap={0.2}>
										<CheckIconWrapper>
											{confirmation ? (
												<Checked
													onClick={() => {
														setConfirmation(!confirmation)
													}}
												/>
											) : (
												<UnChecked
													onClick={() => {
														setConfirmation(!confirmation)
													}}
												/>
											)}
										</CheckIconWrapper>
										<Text fontSize={0.813} fontWeight={500} color="black_3">
											Confirmation
										</Text>
									</Flexed>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="Probation End Date"
										value={probationEndDate}
										handleChange={(e: any) => {
											setProbationEndDate(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="Confirmation Date"
										value={confirmationDate}
										disabled={!confirmation}
										handleChange={(e: any) => {
											setConfirmationDate(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								{employeeType?.label != 'Permanent(Confirmed)' && (
									<>
										<Col lg={6}>
											<InputDate
												label={employeeType?.value === 'Resigned' || employeeType?.value === 'Terminated' || employeeType?.value === 'Retirement' ? employeeType?.value : 'Start Date'}
												error={employeeTypeStartDateError}
												required={employeeType?.label != 'Permanent(Confirmed)'}
												value={employeeTypeStartDate}
												handleChange={(e: any) => {
													setEmployeeTypeStartDateError('')
													if (moment(e).isAfter(employeeTypeEndDate)) {
														setEmployeeTypeStartDateError('The start date should be less than or equal to end date')
														setEmployeeTypeStartDate('')
													} else {
														setEmployeeTypeStartDate(e)
													}
												}}
											/>
											<Spacer height={1} />
										</Col>
										<Col lg={6}>
											<InputDate
												label={employeeType?.value === 'Resigned' || employeeType?.value === 'Terminated' || employeeType?.value === 'Retirement' ? 'Final Settlement Date' : 'End Date'}
												error={employeeTypeEndDateError}
												required={employeeType?.label != 'Permanent(Confirmed)'}
												value={employeeTypeEndDate}
												handleChange={(e: any) => {
													setEmployeeTypeEndDateError('')
													if (!moment(e).isAfter(employeeTypeStartDate)) {
														setEmployeeTypeStartDate('')
													}
													setEmployeeTypeEndDate(e)
												}}
											/>
											<Spacer height={1} />
										</Col>
									</>
								)}
								<Col lg={6}>
									<DropDown
										label="Work Location"
										error={workLocationError}
										required
										firstSelected={workLocation.label}
										options={workLocationOption}
										hasChanged={(value: any) => {
											setWorkLocationError('')
											setWorkLocation({label: value, value: value})
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown
										label="Designation"
										error={designationError}
										required
										firstSelected={designation.label}
										options={designationOption}
										hasChanged={(value: any) => {
											setDesignationError('')
											setDesignation({label: value, value: value})
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown
										label="Department"
										error={departmentError}
										required
										firstSelected={department.label}
										options={departmentOption}
										hasChanged={(value: any) => {
											setDepartmentError('')
											setDepartment({label: value, value: value})
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown
										label="Sub Department"
										error={subDepartmentError}
										firstSelected={subDepartment.label}
										options={subDepartmentOption}
										hasChanged={(value: any) => {
											setSubDepartmentError('')
											setSubDepartment({label: value, value: value})
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			<Spacer height={4} />
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="" viewBox="0 0 32 32">
							<path _ngcontent-sio-c152="" d="M27.333 13.333C27.333 22 16 30.667 16 30.667S4.667 22.042 4.667 13.333C4.667 7.074 9.74 2 16 2c6.26 0 11.333 5.074 11.333 11.333z"></path>
							<path _ngcontent-sio-c152="" d="M16 18a4.667 4.667 0 1 0 0-9.333A4.667 4.667 0 0 0 16 18z"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Eligibility
						</Text>
						<TagLine type="normal" color="silver_8">
							Fill in the employee's eligibility
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col>
									<Label type="small" margin="0rem 0rem 0.5rem 0rem">
										Medical Insurance
									</Label>
									<Flexed direction="row" align="center" gap={2} justify="space-between">
										<Flexed direction="row" align="center" gap={2}>
											<Flexed direction="row" align="center" gap={0.5} onClick={() => setMedicalInsurance(true)}>
												<input name="eligible" type="radio" checked={medicalInsurance === true} />
												<Label type="small">Eligible</Label>
											</Flexed>
											<Flexed direction="row" align="center" gap={0.5} onClick={() => setMedicalInsurance(false)}>
												<input name="nonEligible" type="radio" checked={medicalInsurance === false} />
												<Label type="small">Non-Eligible</Label>
											</Flexed>
										</Flexed>
										{medicalInsurance && (
											<Box
												onClick={() => {
													setOpenMedicalInsuranceModal(true)
												}}>
												<RiAddFill />
											</Box>
										)}
									</Flexed>
									<Spacer height={1.5} />
								</Col>
								<Col>
									<Label type="small" margin="0rem 0rem 0.5rem 0rem">
										Air Ticket
									</Label>
									<Flexed direction="row" align="center" gap={2} justify="space-between">
										<Flexed direction="row" align="center" gap={2}>
											<Flexed direction="row" align="center" gap={0.5} onClick={() => setAirTicket(true)}>
												<input name="eligibleAirTicket" type="radio" checked={airTicket === true} />
												<Label type="small">Eligible</Label>
											</Flexed>
											<Flexed direction="row" align="center" gap={0.5} onClick={() => setAirTicket(false)}>
												<input name="nonEligibleAirTicket" type="radio" checked={airTicket === false} />
												<Label type="small">Non-Eligible</Label>
											</Flexed>
										</Flexed>
										{airTicket && (
											<Box
												onClick={() => {
													setOpenAirTicketModalModal(true)
												}}>
												<RiAddFill />
											</Box>
										)}
									</Flexed>
									<Spacer height={1.5} />
								</Col>
								<Col>
									<Label type="small" margin="0rem 0rem 0.5rem 0rem">
										Gratuity
									</Label>
									<Flexed direction="row" align="center" gap={2} justify="space-between">
										<Flexed direction="row" align="center" gap={2}>
											<Flexed direction="row" align="center" gap={0.5} onClick={() => setGratuity(true)}>
												<input name="eligibleGratuity" type="radio" checked={gratuity === true} />
												<Label type="small">Eligible</Label>
											</Flexed>
											<Flexed direction="row" align="center" gap={0.5} onClick={() => setGratuity(false)}>
												<input name="nonEligibleGratuity" type="radio" checked={gratuity === false} />
												<Label type="small">Non-Eligible</Label>
											</Flexed>
										</Flexed>
									</Flexed>
									<Spacer height={1} />
								</Col>
								{gratuity && (
									<Col lg={6}>
										<InputDate
											value={gratuityDate}
											handleChange={(e: any) => {
												setGratuityDate(e)
											}}
										/>

										<Spacer height={1} />
									</Col>
								)}
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			<Spacer height={4} />
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="" viewBox="0 0 33 32">
							<path _ngcontent-sio-c152="" d="M30 22.667v6.666c0 .737-.597 1.334-1.333 1.334H3.333A1.333 1.333 0 0 1 2 29.333V9.047c0-.63.44-1.174 1.057-1.304L6 6.667"></path>
							<path
								_ngcontent-sio-c152=""
								d="M30 16v-5.95c0-.736-.597-1.333-1.333-1.333h-26M30 22.667h-8a1.333 1.333 0 0 1-1.333-1.334v-4c0-.736.597-1.333 1.333-1.333h8c.736 0 1.333.597 1.333 1.333v4c0 .737-.597 1.334-1.333 1.334z"></path>
							<path _ngcontent-sio-c152="" d="M24.667 20.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667zM6 8V6.667c0-.737.597-1.334 1.333-1.334H24c.736 0 1.333.597 1.333 1.334V8"></path>
							<path _ngcontent-sio-c152="" d="M7.333 5.333V4c0-.736.597-1.333 1.334-1.333H26c.736 0 1.333.597 1.333 1.333v4.667"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Payment Method
						</Text>
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={6}>
									<DropDown
										label="Salary Payment Method"
										firstSelected={paymentMethod.label}
										options={salaryPaymentMethodOption}
										hasChanged={(value: any) => setPaymentMethod({label: value, value: value})}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
							</Row>
							{paymentMethod.label === 'Bank Transfer' && (
								<Row>
									<Col lg={6}>
										<InputField
											label="Bank Name"
											value={bankName}
											handleChange={(e: any) => {
												setBankName(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
									<Col lg={6}>
										<InputField
											label="Branch"
											value={branch}
											handleChange={(e: any) => {
												setBranch(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
									<Col lg={6}>
										<InputField
											label="Branch Code"
											value={branchCode}
											min={3}
											maxLength={11}
											handleChange={(e: any) => {
												setBranchCode(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
									<Col lg={6}>
										<InputField
											label="Account No"
											value={accountNo}
											min={8}
											maxLength={18}
											allowOnlyNumbers={true}
											handleChange={(e: any) => {
												setAccountNo(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
									<Col lg={6}>
										<InputField
											label="Bank Code / Swift Code"
											value={swiftCode}
											min={8}
											maxLength={11}
											handleChange={(e: any) => {
												setSwiftCode(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
								</Row>
							)}
						</Container>
					</Form>
				</Col>
			</Row>
			<Row>
				<Col>
					<Divider margin="1rem 0rem" />
					<Flexed direction="row" gap={1} align="center" justify="flex-end">
						<Back onClick={doBack}>{isEdit ? 'Cancel' : 'Back'}</Back>
						<Next onClick={doUpdate}>{isEdit ? 'Save' : 'Next'}</Next>
					</Flexed>
				</Col>
			</Row>
			{openMedicalInsuranceModal && (
				<MedicalInsuranceModal
					insuranceCardNo={insuranceCardNo}
					setInsuranceCardNo={setInsuranceCardNo}
					insuranceClass={insuranceClass}
					setInsuranceClass={setInsuranceClass}
					insuranceClassOption={insuranceClassOption}
					medicalInsuranceExpiryDate={medicalInsuranceExpiryDate}
					setMedicalInsuranceExpiryDate={setMedicalInsuranceExpiryDate}
					medicalInsuranceAmountMonthly={medicalInsuranceAmountMonthly}
					setMedicalInsuranceAmountMonthly={setMedicalInsuranceAmountMonthly}
					onCloseModal={() => {
						setOpenMedicalInsuranceModal(false)
					}}
				/>
			)}

			{openAirTicketModalModal && (
				<AirTicketModal
					numberOfAirTicket={numberOfAirTicket}
					setNumberOfAirTicket={setNumberOfAirTicket}
					airTicketFrequency={airTicketFrequency}
					setAirTicketFrequency={setAirTicketFrequency}
					airTicketFrequencyOption={airTicketFrequencyOption}
					originCountry={originCountry}
					setOriginCountry={setOriginCountry}
					originCountryOption={originCountryOption}
					originCity={originCity}
					setOriginCity={setOriginCity}
					originCityOption={originCityOption}
					destinationCountry={destinationCountry}
					setDestinationCountry={setDestinationCountry}
					destinationCountryOption={destinationCountryOption}
					destinationCity={destinationCity}
					setDestinationCity={setDestinationCity}
					destinationCityOption={destinationCityOption}
					airTicketClass={airTicketClass}
					setAirTicketClass={setAirTicketClass}
					airTicketClassOption={airTicketClassOption}
					remarks={remarks}
					setRemarks={setRemarks}
					onCloseModal={() => {
						setOpenAirTicketModalModal(false)
					}}
				/>
			)}
		</>
	)
}
const Icons = styled.svg`
	max-width: 25px;
	fill: #0000;
	stroke: ${palette.purple_1};
	stroke-width: 2px;
	stroke-linecap: round;
`

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

const GroupButton = styled(Flexed)`
	border: 2px solid #dfe3e6;
	border-radius: 3px;
	width: fit-content;
`

const Box = styled(Flexed)`
	padding: 6px 12px;
	cursor: pointer;
	border-radius: 4px;
	font-size: 14px;
	line-height: 23px;
	cursor: pointer;
	background: #efefef;
`

const Form = styled.div`
	background: ${palette.silver_9};
	padding: 30px;
	border-radius: 4px;
	margin-bottom: 10px;
	border: 1px solid ${palette.gray_300};
`

const ProfileCover = styled.div`
	overflow: hidden;
	width: 130px;
	height: 130px;
	border-radius: 100%;
	display: inline-block;
	border: 2px solid ${palette.purple_1};
	margin: 1rem 0rem;
	& img {
		width: 100%;
		object-fit: cover;
		height: 130px;
	}
`

const ExpiryTag = styled.div`
	height: 20px;
	background: #ebeff4;
	line-height: 10px;
	font-size: 11px;
	color: ${palette.black_3};
	border-radius: 0.15rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.15rem 0.25rem;
	cursor: pointer;
	&:hover {
		background: ${palette.purple_1};
		color: ${palette.white};
	}
`

export default Official
