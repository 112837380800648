import styled from 'styled-components'
import {grid} from './constants'

// $ExpectError - not sure why
export default styled.h4<any>`
	padding: ${grid}px;
	transition: background-color ease 0.2s;
	flex-grow: 1;
	user-select: none;
	position: relative;
	font-size: 15px;
	font-weight: 600;
	margin:0 !important;
	&:focus {
		outline: 2px solid #998dd9;
		outline-offset: 2px;
	}
`
