import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import InputField from '../common/InputField'
import InputFile from '../common/InputFile'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import {TbRefresh} from 'react-icons/tb'
import SalaryAllowancesTable from './SalaryAllowancesTable'
import DeductionsTable from './DeductionsTable'
import {Back, Next, TagLine} from './PersonalIz'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'

const Salary = ({doBack, doNext, setIsLoading, employeeId, setEmployeeId, setEmployeeName, isEdit}: any) => {
	const [salaryType, setSalaryType] = useState({label: '', value: ''})
	const [baseSalary, setBaseSalary] = useState<any>(0)
	const [baseSalaryeEdit, setBaseSalaryeEdit] = useState<any>(false)

	const [salaryStartingFrom, setSalaryStartingFrom] = useState('')
	const [weekdayHourlySalary, setWeekdayHourlySalary] = useState('')
	const [weekendDayHourlySalary, setWeekendDayHourlySalary] = useState('')
	const [activeTable, setActiveTable] = useState('allowances')
	const [grossSalary, setGrossSalary] = useState<any>(0)
	const [taxableSalary, setTaxableSalary] = useState<any>(0)
	const [taxPerPeriod, setTaxPerPeriod] = useState<any>(0)
	const [netSalary, setNetSalary] = useState<any>(0)

	const [salaryTypeOption, setSalaryTypeOption] = useState([
		{value: '', label: 'Select'},
		{value: 'Salary Based', label: 'Salary Based'},
		{value: 'Hourly Based', label: 'Hourly Based'}
	])

	const [salaryTypeError, setSalaryTypeError] = useState('')
	const [baseSalaryError, setBaseSalaryError] = useState('')
	const [salaryStartingFromError, setSalaryStartingFromError] = useState('')
	const [weekdayHourlySalaryError, setWeekdayHourlySalaryError] = useState('')
	const [weekendDayHourlySalaryError, setWeekendDayHourlySalaryError] = useState('')

	const [allowanceList, setAllowanceList] = useState<any>([])
	const [deductionList, setDeductionList] = useState<any>([])

	useEffect(() => {
		if (employeeId) {
			doGetEmployeeDetails(employeeId)
		}
	}, [employeeId])

	const doGetEmployeeDetails = (id: any) => {
		setIsLoading(true)
		api.get(`/employee/get_employee/${id}`)
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					let _data = response?.data?.data?.data
					setEmployeeName(_data?.firstName)
					setSalaryType({label: _data?.salaryType ? _data?.salaryType : 'Salary Based', value: _data?.salaryType ? _data?.salaryType : 'Salary Based'})
					setBaseSalary(_data?.baseSalary)
					setSalaryStartingFrom(_data?.joiningDate)
					setWeekdayHourlySalary(_data?.weekdayHourlySalary)
					setWeekendDayHourlySalary(_data?.weekendDayHourlySalary)
					setGrossSalary(parseFloat(_data?.grossSalary))
					setTaxableSalary(parseFloat(_data?.taxableSalary))
					setTaxPerPeriod(parseFloat(_data?.taxPerPeriod))
					setNetSalary(parseFloat(_data?.netSalary))
					setAllowanceList(_data?.allowance ? _data?.allowance : [])
					setDeductionList(_data?.deduction)
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const doUpdate = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/employee/update_employee/${employeeId}`, {
				salaryType: salaryType?.value,
				baseSalary: baseSalary,
				salaryStartingFrom: salaryStartingFrom,
				weekdayHourlySalary: weekdayHourlySalary,
				weekendDayHourlySalary: weekendDayHourlySalary,
				allowance: allowanceList,
				deduction: deductionList,
				grossSalary: grossSalary,
				taxableSalary: taxableSalary,
				taxPerPeriod: taxPerPeriod,
				netSalary: netSalary
			})
				.then(async (response) => {
					setIsLoading(false)
					if (response?.data?.success == true) {
						let _data = response?.data?.data?.data
						setEmployeeName(_data?.firstName)
						doNext()
					}
				})
				.catch((e: any) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (salaryType?.value === '') {
			setSalaryTypeError('This value is required.')
			isValid = false
		}
		if (baseSalary === 0) {
			setBaseSalaryError('This value is required.')
			isValid = false
		}
		if (salaryStartingFrom === '') {
			setSalaryStartingFromError('This value is required.')
			isValid = false
		}

		if (salaryType?.value === 'Hourly Based') {
			if (weekdayHourlySalary === '') {
				setWeekdayHourlySalaryError('This value is required.')
				isValid = false
			}
			if (weekendDayHourlySalary === '') {
				setWeekendDayHourlySalaryError('This value is required.')
				isValid = false
			}
		}
		return isValid
	}

	const finalSalaryDetails = () => {
		let _totalAmount = 0
		let _totalTaxableAmount = 0
		let _totalDeductionAmount = 0
		let _totalDeductionTaxableAmount = 0
		allowanceList?.map((val: any) => {
			let _cal = 0

			if (val?.calculateAmount === 'Fixed') {
				if (baseSalary > 0) {
					_cal = val?.amount / baseSalary
				}
				val.percentage = _cal * 100
			} else {
				if (val.amount == 0 || baseSalaryeEdit) {
					let _percentageVal = 0
					_percentageVal = val?.percentage / 100
					val.amount = _percentageVal * baseSalary
				} else {
					if (baseSalary > 0) {
						_cal = val?.amount / baseSalary
					}
					val.percentage = _cal * 100
				}
			}
			_totalAmount = parseFloat(val?.amount) + _totalAmount
			if (val?.allowanceTaxPlan === 'Taxable') {
				_totalTaxableAmount = parseFloat(val?.amount) + _totalTaxableAmount
			}
		})
		deductionList?.map((val: any) => {
			let _cal = 0

			if (val?.calculateAmount === 'Fixed') {
				if (baseSalary > 0) {
					_cal = val?.amount / baseSalary
				}
				val.percentage = _cal * 100
			} else {
				if (val.amount == 0 || baseSalaryeEdit) {
					let _percentageVal = 0
					_percentageVal = val?.percentage / 100
					val.amount = _percentageVal * baseSalary
				} else {
					if (baseSalary > 0) {
						_cal = val?.amount / baseSalary
					}
					val.percentage = _cal * 100
				}
			}
			_totalDeductionAmount = parseFloat(val?.amount) + _totalDeductionAmount
			if (val?.deductionTaxPlan === 'Non Taxable') {
				_totalDeductionTaxableAmount = parseFloat(val?.amount) + _totalDeductionTaxableAmount
			}
		})
		setGrossSalary(parseFloat(baseSalary == '' ? 0 : baseSalary) + _totalAmount)
		setTaxableSalary(parseFloat(baseSalary == '' ? 0 : baseSalary) + _totalTaxableAmount - _totalDeductionTaxableAmount)
		setTaxPerPeriod(0)
		setNetSalary(parseFloat(baseSalary == '' ? 0 : baseSalary) + _totalAmount - _totalDeductionAmount)
	}

	useEffect(() => {
		finalSalaryDetails()
	}, [allowanceList])
	useEffect(() => {
		finalSalaryDetails()
	}, [deductionList])

	const removeDataFromAllowances = (name: string) => {
		const updatedList = allowanceList.filter((item: any) => item.allowanceCategory !== name)
		setAllowanceList(updatedList)
	}

	const taxableCheck = (isTaxable: any, name: string) => {
		const updatedList = allowanceList?.map((item: any) => {
			if (item.allowanceCategory === name) {
				item.allowanceTaxPlan = isTaxable
			}
			return item
		})
		setAllowanceList(updatedList)
	}

	const editAmount = (amount: any, name: string) => {
		const updatedList = allowanceList?.map((item: any) => {
			if (item.allowanceCategory === name) {
				item.amount = amount
			}
			return item
		})
		setAllowanceList(updatedList)
	}

	const removeDataFromDeduction = (name: string) => {
		const updatedList = deductionList.filter((item: any) => item.deductionCategory !== name)
		setDeductionList(updatedList)
	}

	const taxableDeductionCheck = (isTaxable: any, name: string) => {
		const updatedList = deductionList?.map((item: any) => {
			if (item.deductionCategory === name) {
				item.deductionTaxPlan = isTaxable
			}
			return item
		})
		setDeductionList(updatedList)
	}

	const editDeductionAmount = (amount: any, name: string) => {
		const updatedList = deductionList?.map((item: any) => {
			if (item.deductionCategory === name) {
				item.amount = amount
			}
			return item
		})
		setDeductionList(updatedList)
	}

	return (
		<>
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="" viewBox="0 0 33 32">
							<path _ngcontent-sio-c152="" d="M30 22.667v6.666c0 .737-.597 1.334-1.333 1.334H3.333A1.333 1.333 0 0 1 2 29.333V9.047c0-.63.44-1.174 1.057-1.304L6 6.667"></path>
							<path
								_ngcontent-sio-c152=""
								d="M30 16v-5.95c0-.736-.597-1.333-1.333-1.333h-26M30 22.667h-8a1.333 1.333 0 0 1-1.333-1.334v-4c0-.736.597-1.333 1.333-1.333h8c.736 0 1.333.597 1.333 1.333v4c0 .737-.597 1.334-1.333 1.334z"></path>
							<path _ngcontent-sio-c152="" d="M24.667 20.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667zM6 8V6.667c0-.737.597-1.334 1.333-1.334H24c.736 0 1.333.597 1.333 1.334V8"></path>
							<path _ngcontent-sio-c152="" d="M7.333 5.333V4c0-.736.597-1.333 1.334-1.333H26c.736 0 1.333.597 1.333 1.333v4.667"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Salary Details
						</Text>
						<TagLine type="normal" color="silver_8">
							Fill in the employee's salary details and pay schedule
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={6}>
									<DropDown
										label="Salary Type"
										required
										error={salaryTypeError}
										firstSelected={salaryType.label}
										options={salaryTypeOption}
										hasChanged={(value: any) => {
											setSalaryTypeError('')
											setSalaryType({label: value, value: value})
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
							</Row>
							<Row>
								{salaryType?.value === 'Salary Based' || salaryType?.value === '' ? (
									<Col lg={6}>
										<InputField
											label="Base Salary"
											onBlur={() => {
												finalSalaryDetails()
												if (baseSalary == '') {
													setBaseSalary(0)
												}
												setBaseSalaryeEdit(false)
											}}
											onFocus={() => {
												setBaseSalaryeEdit(true)
												if (baseSalary == 0) {
													setBaseSalary('')
												}
											}}
											allowOnlyNumbersAndDecimal={true}
											error={baseSalaryError}
											required
											value={baseSalary}
											handleChange={(e: any) => {
												setBaseSalaryError('')
												if (e === '') {
													setBaseSalaryError('This value is required.')
												}
												setBaseSalary(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
								) : (
									<>
										<Col lg={6}>
											<InputField
												label="Weekday Hourly Salary"
												allowOnlyNumbersAndDecimal={true}
												error={weekdayHourlySalaryError}
												required
												value={weekdayHourlySalary}
												handleChange={(e: any) => {
													setWeekdayHourlySalaryError('')
													if (e === '') {
														setWeekdayHourlySalaryError('This value is required.')
													}
													setWeekdayHourlySalary(e)
												}}
											/>
											<Spacer height={1} />
										</Col>
										<Col lg={6}>
											<InputField
												label="Weekend Day Hourly Salary"
												allowOnlyNumbersAndDecimal={true}
												error={weekendDayHourlySalaryError}
												required
												value={weekendDayHourlySalary}
												handleChange={(e: any) => {
													setWeekendDayHourlySalaryError('')
													if (e === '') {
														setWeekendDayHourlySalaryError('This value is required.')
													}
													setWeekendDayHourlySalary(e)
												}}
											/>
											<Spacer height={1} />
										</Col>
									</>
								)}
								<Col lg={6}>
									<InputDate
										label="Starting"
										disabled
										error={salaryStartingFromError}
										required
										value={salaryStartingFrom}
										handleChange={(e: any) => {
											setSalaryStartingFromError('')
											if (e === '') {
												setSalaryStartingFromError('This value is required.')
											}
											setSalaryStartingFrom(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			{(salaryType?.value === 'Salary Based' || salaryType?.value === '') && (
				<>
					<Spacer height={4} />
					<Row>
						<Col>
							<TableContent direction="row" align="center" gap={0.5}>
								<Button
									active={activeTable === 'allowances'}
									onClick={() => {
										setActiveTable('allowances')
									}}>
									Allowances
								</Button>
								<Button
									active={activeTable === 'deductions'}
									onClick={() => {
										setActiveTable('deductions')
									}}>
									Deductions
								</Button>
							</TableContent>
							{activeTable === 'allowances' && (
								<SalaryAllowancesTable
									baseSalary={baseSalary}
									setBaseSalaryError={setBaseSalaryError}
									allowanceList={allowanceList}
									setAllowanceList={setAllowanceList}
									taxableCheck={taxableCheck}
									editAmount={editAmount}
									removeDataFromAllowances={(allowanceCategory: any) => removeDataFromAllowances(allowanceCategory)}
								/>
							)}
							{activeTable === 'deductions' && (
								<DeductionsTable
									baseSalary={baseSalary}
									setBaseSalaryError={setBaseSalaryError}
									deductionList={deductionList}
									setDeductionList={setDeductionList}
									taxableDeductionCheck={taxableDeductionCheck}
									editDeductionAmount={editDeductionAmount}
									removeDataFromDeduction={(allowanceCategory: any) => removeDataFromDeduction(allowanceCategory)}
								/>
							)}
						</Col>
					</Row>
					<Spacer height={2} />
					<Row>
						<Col xl={6} lg={5}>
							<Flexed margin="2rem 0rem 0rem 0rem">
								<Icons _ngcontent-sio-c152="" id="" viewBox="0 0 33 32">
									<path _ngcontent-sio-c152="" d="M30 22.667v6.666c0 .737-.597 1.334-1.333 1.334H3.333A1.333 1.333 0 0 1 2 29.333V9.047c0-.63.44-1.174 1.057-1.304L6 6.667"></path>
									<path
										_ngcontent-sio-c152=""
										d="M30 16v-5.95c0-.736-.597-1.333-1.333-1.333h-26M30 22.667h-8a1.333 1.333 0 0 1-1.333-1.334v-4c0-.736.597-1.333 1.333-1.333h8c.736 0 1.333.597 1.333 1.333v4c0 .737-.597 1.334-1.333 1.334z"></path>
									<path _ngcontent-sio-c152="" d="M24.667 20.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667zM6 8V6.667c0-.737.597-1.334 1.333-1.334H24c.736 0 1.333.597 1.333 1.334V8"></path>
									<path _ngcontent-sio-c152="" d="M7.333 5.333V4c0-.736.597-1.333 1.334-1.333H26c.736 0 1.333.597 1.333 1.333v4.667"></path>
								</Icons>
								<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
									Final Salary Details
								</Text>
								<TagLine type="normal" color="silver_8">
									Payable by company to employee's.
								</TagLine>
								<Spacer height={1} />
							</Flexed>
						</Col>
						<Col xl={6} lg={7}>
							<Form>
								<Flexed direction="row" align="center" justify="space-between">
									<Text type="small" color="black_3">
										Gross Salary
									</Text>
									<Text type="large" color="silver_8">
										{grossSalary.toFixed(2)}
									</Text>
								</Flexed>
								<Flexed direction="row" align="center" justify="space-between">
									<Text type="small" color="black_3">
										Taxable Salary
									</Text>
									<Text type="large" color="silver_8">
										{taxableSalary.toFixed(2)}
									</Text>
								</Flexed>
								<Flexed direction="row" align="center" justify="space-between">
									<Text type="small" color="black_3">
										Tax Per Period
									</Text>
									<Text type="large" color="silver_8">
										{taxPerPeriod.toFixed(2)}
									</Text>
								</Flexed>
								<Flexed direction="row" align="center" margin="0.5rem 0rem 0rem 0rem" justify="space-between">
									<Text type="small" color="green_1" fontWeight={600}>
										Net Salary
									</Text>
									<Text type="large" color="green_1">
										{netSalary.toFixed(2)}
									</Text>
								</Flexed>
							</Form>
						</Col>
					</Row>
				</>
			)}
			<Row>
				<Col>
					<Divider margin="1rem 0rem" />
					<Flexed direction="row" gap={1} align="center" justify="flex-end">
						<Back onClick={doBack}>{isEdit ? 'Cancel' : 'Back'}</Back>
						<Next onClick={doUpdate}>{isEdit ? 'Save' : 'Next'}</Next>
					</Flexed>
				</Col>
			</Row>
		</>
	)
}
const Icons = styled.svg`
	max-width: 25px;
	fill: #0000;
	stroke: ${palette.purple_1};
	stroke-width: 2px;
	stroke-linecap: round;
`

const TableContent = styled(Flexed)`
	background: ${palette.silver_10};
	padding: 10px 15px;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
`

const Form = styled.div`
	background: ${palette.silver_9};
	padding: 30px;
	border-radius: 4px;
	margin-bottom: 10px;
	border: 1px solid ${palette.gray_300};
`

const ProfileCover = styled.div`
	overflow: hidden;
	width: 130px;
	height: 130px;
	border-radius: 100%;
	display: inline-block;
	border: 2px solid ${palette.purple_1};
	margin: 1rem 0rem;
	& img {
		width: 100%;
		object-fit: cover;
		height: 130px;
	}
`

const ExpiryTag = styled.div`
	height: 20px;
	background: #ebeff4;
	line-height: 10px;
	font-size: 11px;
	color: ${palette.black_3};
	border-radius: 0.15rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.15rem 0.25rem;
	cursor: pointer;
	&:hover {
		background: ${palette.purple_1};
		color: ${palette.white};
	}
`

const Button = styled.div<any>`
	width: fit-content;
	background-color: ${({active}) => (active ? palette.purple_1 : palette.white)};
	border: 1px solid ${({active}) => (active ? palette.purple_1 : palette.white)};
	height: 34px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 3px;
	font-weight: 500;
	font-size: 0.813rem;
	line-height: 1.5rem;
	color: ${({active}) => (active ? palette.white : palette.purple_1)};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
	&:hover {
		background-color: ${palette.purple_1};
		border: 1px solid ${palette.purple_1};
		color: ${palette.white};
	}
`

export default Salary
