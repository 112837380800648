import styled from 'styled-components';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Text, Spacer, Flexed } from '../../styled/shared';
import { palette } from '../../styled/colors';
import { BsXLg } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { media } from 'styled-bootstrap-grid';
import { Document, Page, pdfjs } from 'react-pdf'
import '@react-pdf-viewer/core/lib/styles/index.css'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { useState } from 'react';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

interface ICategoriesModalProps {
    open: boolean;
    onCloseModal: any;
    title?: string;
    pdfUrl?: any
    documentDetails?:any
}

interface IProps {
    isDarkTheme: boolean;
}

const PdfViewModel = ({ open, onCloseModal, title, pdfUrl, documentDetails }: ICategoriesModalProps) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);

    const [numPages, setNumPages] = useState<any>(null);

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    };

    const renderPages = () => {
        const pages = [];
        for (let i = 1; i <= numPages; i++) {
            pages.push(<Page key={i} pageNumber={i} />);
        }
        return pages;
    };

    return (
        <>
            <Modal
                open={open}
                center
                onClose={() => onCloseModal()}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'blockModal'
                }}
                showCloseIcon={false}
            >
                <ModalWrapper isDarkTheme={_isDarkTheme}>
                    <Head direction="row" align="center" justify="space-between" gap={1}>
                        <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                            {title}
                        </Text>
                        <CrossIcon
                            isDarkTheme={_isDarkTheme}
                            onClick={() => onCloseModal()}
                        />
                    </Head>
                    <Body>

                       {pdfUrl?.endsWith('.pdf') ?<Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                            {renderPages()}
                        </Document>: pdfUrl?.endsWith('.png') && <img src={pdfUrl} height={'100%'} width={'100%'} />}
                        <Spacer height={2} />
                    </Body>
                </ModalWrapper>
            </Modal>
        </>
    );
}

const ModalWrapper = styled.div<IProps>`
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
    padding: 1rem;
`

const Head = styled(Flexed)`
    padding: 1rem;
    ${media.sm`
        width: 45rem;
    `}
    ${media.md`
        width: 45rem;
    `}
`

const Body = styled.div`
    padding: 0rem 1rem 1rem;
    overflow: hidden;
    overflow-y: scroll;
    height: 80vh;
    ${media.sm`
        width: 45rem;
        height: 100%;
    `}
    ${media.md`
        width: 45rem;
        height: 100%;
    `}
    ${media.lg`
        width: 45rem;
        height: 80vh;
    `}

`

const CrossIcon = styled(BsXLg) <IProps>`
    cursor: pointer;
    z-index: 1;
    padding: 0.2rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
    font-size: 1.3rem;
`

export default PdfViewModel;
