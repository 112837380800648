import React from 'react'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { palette } from '../../styled/colors'

const FirstStep = () => {
	return (
		<Container>
			<Heading1>Acquire the versatility of our Hr management system for your business</Heading1>
			{/* <Heading1>Versatile Booking Solution tailored for Fitness, Salon, Hair, Beauty, Barber, Spa, Medical and enterprise Businesses</Heading1> */}
			<Wraper>
				<Image src="./images/main.svg" />

				<div>
					<Card>
						<MobileImage src="./images/1.svg" />
						<Heading2>Streamline Your Business Appointments Across Various Platforms</Heading2>
						<Text>We provide a comprehensive solution for managing your business bookings, enabling you to easily handle appointments via phone, website, your personalized booking app, and social media platforms.</Text>
						<Flex>
							<Button>Admin Panel</Button>
							<Button>Customer Panel</Button>
							<Button>Staff Panel</Button>
							<Button>Widget</Button>
							<Button>iOS & Android Apps</Button>
						</Flex>

						<HR></HR>
					</Card>
					<Card>
						<MobileImage src="./images/3.svg" />
						<Heading2>Access Hr Features Right at Your Fingertips</Heading2>
						<Text>From registration to scheduling, sales, reporting, and payment, we consolidate your day-to-day operations in one convenient place.</Text>
						<Flex>
							<Button>Dashboard</Button>
							<Button>Reports</Button>
							<Button>Calendar</Button>
							<Button>Sales</Button>
							<Button>Social/Online Booking</Button>
							<Button>Promotions</Button>
							<Button>Discounts</Button>
							<Button>Gift Vouchers</Button>
							<Button>Team</Button>
							<Button>Services</Button>
							<Button>Customers</Button>
							<Button>Multi-Business</Button>
							<Button>Marketing</Button>
							<Button>SMS</Button>
							<Button>Emails</Button>
							<Button>Pusher</Button>
							<Button>Reminders</Button>
							<Button>Online Payments</Button>
						</Flex>

						<HR></HR>
					</Card>
					<Card>
						<MobileImage src="./images/2.svg" />
						<Heading2>Purchase it now and start leveraging its capabilities immediately!</Heading2>
						<Text>
							Acquire this Hr management system to save time and money while benefiting from its well-written, commented, and thoroughly tested code.
							<br />
							Customize it with your desired feature set effortlessly using this reliable code base.
						</Text>

						<Flex>
							<Button>The backend has been developed using Nest.js, with integration into a MongoDB database</Button>
							<Button>The admin, staff and customer panels have been developed using React.js</Button>
							<Button>The mobile apps (iOS & Android) have been developed using ReactNative</Button>
						</Flex>

						<HR></HR>
					</Card>
				</div>
			</Wraper>
		</Container>
	)
}

const Wraper = styled.div`
	display: grid;
	gap: 1.4rem;
	grid-template-columns: 1fr;
	margin-top: 2rem;
	${media.lg` grid-template-columns: 1.5fr 2fr;`}
`

const Card = styled.div`
	padding: 1rem;
`

const Image = styled.img`
	width: 100%;
	display: none;
	${media.lg`display:block; `}
`

const MobileImage = styled.img`
	width: 100%;
	${media.lg` display:none`}
`

const Heading1 = styled.h1`
	font-size: 1.5rem;
	font-weight: bold;
	text-align: center;
	padding-top: 2rem;

	${media.md`font-size: 2.9rem;`}
`
const Heading2 = styled.h2`
	font-size: 2.3rem;
	margin-top: 2rem;
	font-weight: 500;
`
const Text = styled.p`
	font-size: 18px;
	margin-top: 0.8rem;
`
const Flex = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
`

const Button = styled.button`
	background: ${palette.purple_1};
	color: ${palette.white};
	padding: 8px 16px 5px;
	border: none;
	font-size: 19px;
	border-radius: 3px;
	font-weight: 500;
	margin-top: 1rem;
	width: 100%;
	${media.md` width: fit-content;`}
`

const HR = styled.hr`
	width: 586px;
	margin-top: 4rem;
	display: none;

	${'' /* ${media.md` display:none`} */}
`

export default FirstStep
