import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { Divider, Flexed, Spacer, Text } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { Checked, CheckIconWrapper, UnChecked } from '../../pages/Auth/SignUp'
import { IoClose } from "react-icons/io5";
import InputField from '../common/InputField'
import SalaryAllowancesList from './SalaryAllowancesList'
import AddAllowancesModal from '../modals/AddAllowancesModal'

const SalaryAllowancesTable = ({setBaseSalaryError,removeDataFromAllowances,taxableCheck,editAmount, setAllowanceList, allowanceList,baseSalary }: any) => {
    const [openAddModal, setOpenAddModal] = useState(false)
    return (<>
        <HeadWrapper>
            <Flexed direction="row" gap={1} align="center">
                <Name
                    gap={0.2}
                >
                    <Text type='small' fontWeight={400} color='black_1'>
                        Name
                    </Text>
                </Name>
                <Percentage gap={0.1}>
                    <Text type='small' fontWeight={400} color='black_1'>
                        Percentage
                    </Text>
                </Percentage>
                <Amount gap={0.2}>
                    <Text type="small" color='black_1'>
                        Amount
                    </Text>
                </Amount>
                <Taxable direction="row" gap={1}>
                    <Text type='small' fontWeight={400} color='black_1'>
                        Taxable
                    </Text>
                </Taxable>
                <Action direction="row" gap={0.5} align="center" justify="flex-end">
                    <Add onClick={() => {
                         if(baseSalary == 0){
                            setBaseSalaryError('This value is required.')
                    } else{
                        setOpenAddModal(true)
                    }
                     }}>
                        Add
                    </Add>
                </Action>
            </Flexed>
        </HeadWrapper>
        {allowanceList?.map((data:any) => {
            return <SalaryAllowancesList baseSalary={baseSalary} data={data} editAmount={(amount:any)=>{editAmount(amount,data?.allowanceCategory)}} taxableCheck={(taxable:any)=>taxableCheck(taxable,data?.allowanceCategory)} onRemove={()=>removeDataFromAllowances(data?.allowanceCategory)} />
        })}
        {openAddModal && <AddAllowancesModal allowanceList={allowanceList} setAllowanceList={setAllowanceList} onCloseModal={() => { setOpenAddModal(false) }} />}
    </>
    )
}
const HeadWrapper = styled.div<any>`
	width: 38rem;
	background: #eef0f1;
	padding: 0.5rem;
	transition: all ease 0.25s;
	position: relative;
	${media.sm`
	width: 42rem;
	`}
	${media.md`
	width: 100%;
	`}
`

export const Name = styled(Flexed)`
	width: 15%;
`

export const Percentage = styled(Flexed)`
    width: 30%;
    & #inputWrapper{
        width: 100%;
    }
    & input{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        line-height: 1.5rem !important;
    }
`

export const Amount = styled(Flexed)`
	width: 30%;
    & #inputWrapper{
        width: 100%;
    }
    & input{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        line-height: 1.5rem !important;
    }
`

export const Taxable = styled(Flexed)`
	width: 15%;
`

export const Action = styled(Flexed)`
	position: relative;
	width: 10%;
`

const Add = styled(Text)`
    font-size: 11px;
    font-weight: 400;
    border-radius: 4px;
    background: ${palette.green_1};
    padding: 1px 4px;
    width: 50px;
    height: auto;
    position: relative;
    color: ${palette.white};
    text-align: center;
    cursor: pointer;
`

export default SalaryAllowancesTable
