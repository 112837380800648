import {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Flexed, Loading, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import AddPublicHoliday from '../components/modals/AddPublicHoliday'
import {Back} from '../components/employee/EmployeeDetails'
import {useNavigate} from 'react-router-dom'
import {MDBDataTableV5} from 'mdbreact'
import {IoEye} from 'react-icons/io5'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import moment from 'moment-timezone'
import DeleteHolidayModal from '../components/modals/DeleteHolidayModal'
import {RiDeleteBinLine} from 'react-icons/ri'

const AddHoliday = ({setActiveTimeTab}: any) => {
	const _navigate = useNavigate()
	const [openAddPublicHolidayModal, setOpenAddPublicHolidayModal] = useState(false)
	const [openDeleteHolidayModal, setOpenDeleteHolidayModal] = useState(false)
	const [deleteHolidayId, setDeleteHolidayId] = useState('')
	const [editHoliday, setEditHoliday] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [holidayList, setHolidayList] = useState<any>({
		columns: [
			{label: 'Name', field: 'name', width: 200},
			{label: 'From Date', field: 'fromDate', width: 200},
			{label: 'To Date', field: 'toDate', width: 50},
			{label: 'Action', field: 'action', sort: String('disabled'), width: 50}
		],
		rows: []
	})

	useEffect(() => {
		getHolidays()
	}, [])

	const getHolidays = async () => {
		setIsLoading(true)
		await api
			.get(`/holiday/show_all_holidays`)
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					setHolidayList((preValue: any) => {
						return {
							columns: [...preValue.columns],
							rows: []
						}
					})
					if (response?.data?.data?.data?.length > 0) {
						response?.data?.data?.data?.map((data: any, index: any) => {
							createHolidayTable(data)
						})
					}
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const createHolidayTable = (data: any) => {
		let _obj = {
			name: data?.holidayName,
			fromDate: data?.holidayFrom,
			toDate: data?.holidayTo,
			action: (
				<Flexed direction="row" align="center" gap={1} justify="center">
					<IoEye
						cursor="pointer"
						fontSize="1.2rem"
						onClick={() => {
							setEditHoliday(data)
							setOpenAddPublicHolidayModal(true)
						}}
					/>
					<RiDeleteBinLine
						onClick={() => {
							setDeleteHolidayId(data?.id)
							setOpenDeleteHolidayModal(true)
						}}
						cursor="pointer"
						fontSize={16}
						color={palette.red}
					/>
				</Flexed>
			)
		}
		setHolidayList((preValue: any) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	const removeHoliday = async (id: any) => {
		setIsLoading(true)
		await api
			.delete(`holiday/remove_holiday/${id}`)
			.then((response) => {
				if (response?.data?.success == true) {
					setOpenDeleteHolidayModal(false)
					setDeleteHolidayId('')
					getHolidays()
					toast.success('Holiday deleted successfully.')
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	return (
		<>
			<Wrapper direction="row" align="center">
				<Content direction="row" gap={2} align="center" justify="space-between" pointer="pointer">
					<div>
						<Text type="normal" fontWeight={500} color="black">
							Public Holiday
						</Text>
					</div>
					<Flexed direction="row" gap={1}>
						<Button onClick={() => setOpenAddPublicHolidayModal(true)}>Create a New</Button>
						<Back
							onClick={() => {
								setActiveTimeTab('employeesAttendance')
								_navigate('/time')
							}}>
							<svg _ngcontent-kxy-c151="" width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00acac">
								<path
									_ngcontent-kxy-c151=""
									d="M116 121v260c0 13.785 11.215 25 25 25s25-11.215 25-25V121c0-13.785-11.215-25-25-25S116 107.215 116 121zM211.98 243.107c-5.985 3.555-5.996 12.224 0 15.785l219.648 130.416c7.391 4.39 16.269-2.86 13.317-11.014l-42.561-117.712c-1.455-4.026-1.564-8.549-.078-12.823 45.595-131.625 43.871-124.776 43.871-127.686 0 0 0 0 0-.001 0-6.511-7.428-11.61-13.906-7.763L211.98 243.107zM346 451c0 8.272 6.728 15 15 15 8.271 0 15-6.728 15-15 0-8.271-6.729-15-15-15C352.728 436 346 442.729 346 451zM66 396c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 5.515 4.486 10 10 10S66 401.515 66 396zM226 71c0 13.785 11.215 25 25 25 13.785 0 25-11.215 25-25s-11.215-25-25-25C237.215 46 226 57.215 226 71zM250.998 371.003A10 10 0 10250.998 391.003 10 10 0 10250.998 371.003zM350.998 80.997A10 10 0 10350.998 100.997 10 10 0 10350.998 80.997zM51.003 151.003A10 10 0 1051.003 171.003 10 10 0 1051.003 151.003zM460.998 261.003A10 10 0 10460.998 281.003 10 10 0 10460.998 261.003z"></path>
							</svg>
							Back
						</Back>
					</Flexed>
				</Content>
			</Wrapper>
			<Section>
				<Container fluid>
					<Row>
						<Col>
							<TableWraper>
								<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={holidayList} fullPagination searchTop searchBottom={false} />
							</TableWraper>
						</Col>
					</Row>
				</Container>
			</Section>
			{openAddPublicHolidayModal && (
				<AddPublicHoliday
					editHoliday={editHoliday}
					setIsLoading={setIsLoading}
					refreashTable={() => {
						getHolidays()
					}}
					onCloseModal={() => {
						setOpenAddPublicHolidayModal(false)
						setEditHoliday('')
					}}
				/>
			)}
			{openDeleteHolidayModal && <DeleteHolidayModal onCloseModal={() => setOpenDeleteHolidayModal(false)} deleteAction={() => removeHoliday(deleteHolidayId)} />}

			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`

const Content = styled(Flexed)`
	width: 100%;
`

const Section = styled.div`
	padding: 25px 5px 20px 5px;
`

const TableWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	padding: 15px;
	border-radius: 5px;
`

const Card = styled(Flexed)`
	background: ${palette.white};
	padding: 15px;
	border: 1px solid ${palette.silver_1};
	border-radius: 5px;
`

const NameCover = styled.div`
	width: 32px;
	height: 32px;
	background: #fde5c0;
	color: #d65a00;
	padding: 4px 8px;
	border-radius: 5px;
	font-size: 13px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1px #1c242b29;
`

const Status = styled.div`
	width: fit-content;
	background: ${palette.red};
	color: ${palette.white};
	padding: 4px 8px;
	border-radius: 5px;
	font-size: 13px;
	font-weight: 500;
`

const Button = styled.div<any>`
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	height: 34px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 3px;
	font-weight: 500;
	font-size: 0.813rem;
	line-height: 1.5rem;
	color: ${palette.white};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
	&:hover {
		background-color: ${palette.purple_2};
	}
`

export default AddHoliday
