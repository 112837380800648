import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Text, Spacer, Flexed } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { media } from 'styled-bootstrap-grid'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import moment from 'moment'

interface ICategoriesModalProps {
    onCloseModal: any
    itemDetails?: any
}

const AttendanceDetailsModal = ({ onCloseModal, itemDetails }: ICategoriesModalProps) => {


    const timeIn = moment(itemDetails?.attendance?.timeIn, 'DD-MM-YYYY hh:mm A');
    const timeOut = moment(itemDetails?.attendance?.timeOut, 'DD-MM-YYYY hh:mm A');

    // Calculate the difference in hours
    const differenceInHours = timeOut.diff(timeIn, 'hours') - 1 > 0 ? timeOut.diff(timeIn, 'hours') - 1 : 0;

    // Calculate the remaining minutes
    const remainingMinutes = timeOut.diff(timeIn, 'minutes') % 60;

    // Format the result
    const formattedDifference = `${differenceInHours} hours ${remainingMinutes} minutes`;

    return (
        <>
            <Modal
                open={true}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'blockModal'
                }}
                showCloseIcon={false}>
                <ModalWrapper>
                    <Head direction="row" align="center" justify="space-between" gap={1}>
                        <Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
                            Attendance Details of {itemDetails?.firstName + ' ' + itemDetails?.lastName}
                        </Text>
                        <CrossIcon
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Spacer height={1} />
                    <Body>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <Text margin='0rem 0rem 0.3rem 0rem' fontWeight={600}>Date: {moment(itemDetails?.start_time).format('DD-MM-YYYY')}</Text>
                                    <CheckDates>
                                        <Text type='xsmall' color='black' margin='0rem 0rem 0.3rem 0rem' fontWeight={'600'}>Clock in at</Text>
                                        <Text type='xsmall' color='silver_12'>{itemDetails?.isPresent ? moment(itemDetails?.attendance?.timeIn, 'DD-MM-YYYY hh:mm A').format('hh:mm A') : 'Not clock In'}</Text>
                                        <Spacer height={1} />
                                        <Text type='xsmall' color='black' margin='0rem 0rem 0.3rem 0rem' fontWeight={'600'}>Clock out at</Text>
                                        <Text type='xsmall' color='silver_12'>{itemDetails?.isPresent ? moment(itemDetails?.attendance?.timeOut, 'DD-MM-YYYY hh:mm A').format('hh:mm A') : "Not clock out"}</Text>
                                        <Spacer height={1} />
                                        <Text color='black' fontWeight={500} style={{ display: 'flex', alignItems: 'center' }}>Total Hours: {itemDetails?.isPresent ? formattedDifference : 0}</Text>
                                    </CheckDates>
                                </Col>

                            </Row>
                        </Container>
                    </Body>
                    <Spacer height={1} />
                    <Footer direction="row" align="center" justify="flex-end" gap={1}>
                        <Button
                            border="red"
                            color="white"
                            bg="red"
                            bg_hover="red_hover"
                            onClick={() => {
                                onCloseModal()
                            }}
                        >
                            Close
                        </Button>
                        {/* <Button
                            border="purple_2"
                            color="white"
                            bg="purple_1"
                            bg_hover="purple_2"
                            onClick={() => {
                            }}>
                            Save
                        </Button> */}
                    </Footer>
                </ModalWrapper>
            </Modal>
        </>
    )
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
    background-color: ${palette.gray_1};
    border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;
    ${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 800px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const ListHead = styled(Flexed)`
    padding: 0.5rem;
    &:hover{
        background :${palette.silver}
    };
`

const List = styled(Flexed)`
    padding: 0.5rem;
    &:hover{
        background :${palette.silver}
    };
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({ border }) => palette[border]};
	color: ${({ color }) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ bg }) => palette[bg]};
    cursor:pointer;
	&:hover {
		background: ${({ bg_hover }) => palette[bg_hover]};
	}
`

const CheckDates = styled.div`
    background-color: ${palette.silver_9};
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 10px 15px;
`;

const Footer = styled(Flexed)`
    border-top: 1px solid ${palette.silver};
	padding: 1rem 1.5rem;
`

export default AttendanceDetailsModal
