import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {AiFillCaretDown} from 'react-icons/ai'
import {Text} from '../../styled/shared'
import {useSelector} from 'react-redux'
import {Mandatory} from './InputField'

const DropDown = ({firstSelected, timeDrop, timeDropStart, timeDropEnd, label, subLable, options, name, hasChanged, error, disabled, width, dropDownListHeight, required, isCities, placeholder}: any) => {
	const _isDarkTheme: any = false
	// const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const myRef = useRef()
	const [isOpen, setIsOpen] = useState(false)
	const [selectedOption, setSelectedOption] = useState(firstSelected)
	// @ts-ignore
	DropDown['handleClickOutside_' + name] = () => {
		setIsOpen(false)
	}
	useEffect(() => {
		setSelectedOption(firstSelected)
	}, [firstSelected])

	const toggling = () => {
		if (options?.length > 0) {
			setIsOpen(!isOpen)
		}
	}

	const onOptionClicked = (option: any) => () => {
		setSelectedOption(option.label)
		hasChanged(option.value)
		setIsOpen(false)
	}

	const handleClickOutside = (e: any) => {
		// @ts-ignore
		if (!myRef.current.contains(e.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [])

	return (
		<>
			{label && (
				<Label type="small" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
					{label}
					{subLable && <SubLable>{subLable}</SubLable>}
					{required ? <Mandatory> *</Mandatory> : ''}
				</Label>
			)}
			<DropDownContainer id="ourDropDown" isDarkTheme={_isDarkTheme} ref={myRef} width={width} isdisabled={disabled || options?.length == 0} error={disabled || options?.length == 0 ? false : error}>
				<DropDownHeader timeDrop={timeDrop} onClick={() => toggling()}>
					{isCities ? (
						<DefaultValue isdisabled={disabled} isDarkTheme={_isDarkTheme}>
							{placeholder ? placeholder : `No result found`}
						</DefaultValue>
					) : (
						selectedOption || (
							<DefaultValue isdisabled={disabled} isDarkTheme={_isDarkTheme}>
								Select
							</DefaultValue>
						)
					)}{' '}
					{timeDrop && selectedOption ? (selectedOption < '12:00' ? 'am' : 'pm') : ''}
					<Icon id="icon" isDarkTheme={_isDarkTheme}>
						{' '}
						{!isOpen ? <AiFillCaretDown /> : <ArrowUp />}{' '}
					</Icon>
				</DropDownHeader>
				{isOpen && (
					<DropDownListContainer id="dropList" isdisabled={disabled}>
						{/* <PopInBottom friction={20}> */}
						<DropDownList isDarkTheme={_isDarkTheme} dropDownListHeight={dropDownListHeight}>
							{timeDrop
								? options?.map((option: any) =>
										timeDropStart
											? timeDropStart < option.label && (
													<ListItem timeDrop={timeDrop} isDarkTheme={_isDarkTheme} onClick={onOptionClicked(option)} key={Math.random()}>
														{option.label} {timeDrop ? (option.label < '12:00' ? 'am' : 'pm') : ''}
													</ListItem>
												)
											: timeDropEnd > option.label && (
													<ListItem timeDrop={timeDrop} isDarkTheme={_isDarkTheme} onClick={onOptionClicked(option)} key={Math.random()}>
														{option.label} {timeDrop ? (option.label < '12:00' ? 'am' : 'pm') : ''}
													</ListItem>
												)
									)
								: options?.map((option: any) => (
										<ListItem isDarkTheme={_isDarkTheme} onClick={onOptionClicked(option)} key={Math.random()}>
											{option.label}
										</ListItem>
									))}
						</DropDownList>
					</DropDownListContainer>
				)}
			</DropDownContainer>
			{error && !disabled && (
				<Text fontSize={0.7} type="small" color="danger">
					{error?.message ? error?.message : error}
				</Text>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`
const SubLable = styled.span`
	font-size: 0.875rem;
	color: ${palette.text_color};
	font-family: 'Roboto';
	text-transform: capitalize;
	margin-left: 0.5rem;
`

const DropDownContainer = styled('div')<any>`
	// color: ${({isdisabled, isDarkTheme}) => (isdisabled || isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	color: ${({isdisabled, isDarkTheme}) => (isdisabled ? (isDarkTheme ? palette.light_gray : palette.gray_400) : isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	border: 1px solid ${({error, isdisabled, isDarkTheme}) => (isdisabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	background: ${({isdisabled, bgTransparent, isDarkTheme}) => (bgTransparent ? 'transparent' : isdisabled ? (isDarkTheme ? `#606060` : '#fafafa') : isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	border-radius: 0.375rem;
	padding: 0.5rem 0.8rem;
	cursor: ${({isdisabled}) => (isdisabled ? `no-drop` : `pointer`)};
	pointer-events: ${({isdisabled}) => (isdisabled ? `none` : `auto`)};
	position: relative;
	font-size: 0.875rem;
	width: ${({width}) => (width ? `${width}rem` : '100%')};
	line-height: 2rem;
	// height:3.125rem;
	text-align: left;
	// &:hover {
	//     box - shadow: 0 0 0.31rem ${({error, isdisabled}) => (isdisabled ? 'none' : error ? `${palette.danger}` : 'rgba(0, 0, 0, 0.25)')};
	// }
	&:focus {
		border: 1px solid ${({error, isdisabled}) => (isdisabled ? 'none' : error ? `${palette.danger}` : `${palette.purple_1}`)};
	}
	&:focus #icon {
		color: ${({isdisabled}) => (isdisabled ? `${palette.light_gray}` : `${palette.gray}`)};
	}
`

const Icon = styled.i<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 0.7rem;
	margin-left: 1rem;
	opacity: 0.5;
`

const DropDownHeader = styled('div')<any>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: 'Roboto';
	text-transform: ${({timeDrop}) => (timeDrop ? '' : 'capitalize')};
`
const DefaultValue = styled.span<any>`
	opacity: 0.5;
	color: ${({isdisabled, isDarkTheme}) => (isdisabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
`

const DropDownListContainer = styled('div')<any>`
	display: ${({isdisabled}) => (isdisabled ? `none` : `block`)};
	position: absolute;
	left: 0;
	right: 0;
	top: 3.3rem;
	z-index: 99;
`

const DropDownList = styled('ul')<any>`
	padding: 0;
	border-radius: 0.375rem;
	text-align: left;
	margin: 0;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	box-sizing: border-box;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.gray}` : `${palette.dark_gray}`)};
	box-shadow: 0px 4px 8px rgb(0 0 0 / 15%);
	font-size: 0.875rem;
	line-height: 1.54rem;
	font-weight: 400;
	height: auto;
	max-height: ${({dropDownListHeight}) => (dropDownListHeight ? dropDownListHeight : `350px`)};
	overflow-y: auto;
	overflow-x: hidden;

	/* width */
	::-webkit-scrollbar {
		width: 0.5rem;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	}
`

const ListItem = styled('li')<any>`
	list-style: none;
	line-height: 1.5rem;
	white-space: nowrap;
	padding: 0.5rem 0.8rem;
	font-family: 'Roboto';
	text-transform: ${({timeDrop}) => (timeDrop ? '' : 'capitalize')};
	border-bottom: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};

	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.purple_1 : palette.purple_1)};
		color: ${palette.white};
	}
	&:last-child {
		border-bottom: 0;
	}
`

const ArrowUp = styled(AiFillCaretDown)`
	transform: rotate(180deg);
`

export default DropDown
