import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import {Checked, CheckIconWrapper, UnChecked} from '../../pages/Auth/SignUp'
import {MDBDataTableV5} from 'mdbreact'
import {Back, Next, TagLine} from './PersonalIz'
import moment from 'moment'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import DeleteModal from '../modals/DeleteModal'
import MultiSelect from '../common/MultiSelect'
import {Country} from 'country-state-city'

const Dependents = ({doBack, doNext, setIsLoading, employeeId, setEmployeeId, setEmployeeName, isEdit}: any) => {
	const [emergencyContact, setEmergencyContact] = useState(false)
	const [ticketEligible, setTicketEligible] = useState(false)
	const [relationship, setRelationship] = useState({label: 'Husband', value: 'Husband'})
	const [emergencyContactNo, setEmergencyContactNo] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [gender, setGender] = useState({label: 'Male', value: 'Male'})
	const [maritalStatus, setMaritalStatus] = useState({label: 'Single', value: 'Single'})
	const [identificationNo, setIdentificationNo] = useState('')
	const [passportNo, setPassportNo] = useState('')
	const [dob, setDob] = useState('')
	const [nationality, setNationality] = useState({label: '', value: ''})
	const [remarks, setRemarks] = useState('')

	const [identificationNoError, setIdentificationNoError] = useState('')
	const [emergencyContactNoError, setEmergencyContactNoError] = useState('')
	const [deleteModel, setDeleteModel] = useState(false)
	const [dependentId, setDependentId] = useState('')
	const optionsForCountry = useMemo(() => Country.getAllCountries(), [])
	const [countryOption, setCountryOption] = useState([])

	const [relationshipOption, setRelationshipOption] = useState([
		{value: 'Husband', label: 'Husband'},
		{value: 'Wife', label: 'Wife'},
		{value: 'Son', label: 'Son'},
		{value: 'Daughter', label: 'Daughter'},
		{value: 'Father', label: 'Father'},
		{value: 'Mother', label: 'Mother'},
		{value: 'Brother', label: 'Brother'},
		{value: 'Sister', label: 'Sister'}
	])
	const [genderOption, setGenderOption] = useState([
		{value: 'Male', label: 'Male'},
		{value: 'Female', label: 'Female'},
		{value: 'Others', label: 'Others'}
	])
	const [maritalStatusOption, setMaritalStatusOption] = useState([
		{value: 'Single', label: 'Single'},
		{value: 'Married', label: 'Married'},
		{value: 'Divorced or Widow', label: 'Divorced or Widow'},
		{value: 'N/A', label: 'N/A'}
	])
	const [nationalityOption, setNationalityOption] = useState([
		{value: 'Pakistani', label: 'Pakistani'},
		{value: 'USA', label: 'USA'},
		{value: 'Saudia Arab', label: 'Saudia Arab'},
		{value: 'UK', label: 'UK'}
	])
	const [dependentsList, setDependentsList] = useState<any>({
		columns: [
			{label: 'Relationship', field: 'relationship', width: 200},
			{label: 'Full Name', field: 'fullName', width: 200},
			{label: 'Gender', field: 'gender', width: 50},
			{label: 'Marital Status', field: 'maritalStatus', width: 50},
			{label: 'Identification No', field: 'identificationNo', width: 50},
			{label: 'Passport No', field: 'passportNo', width: 50},
			{label: 'DOB', field: 'dob', width: 50},
			{label: 'Action', field: 'action', sort: String('disabled'), width: 50}
		],
		rows: []
	})

	useEffect(() => {
		const _countries: any = []
		for (let i = 0; i < optionsForCountry.length; i++) {
			_countries.push({value: optionsForCountry[i].name, label: optionsForCountry[i].name})
		}
		setCountryOption(_countries)
	}, [optionsForCountry])

	useEffect(() => {
		if (employeeId) {
			setDependentsList((preValue: any) => {
				return {
					columns: [...preValue.columns],
					rows: []
				}
			})
			doGetEmployeeDetails(employeeId)
		}
	}, [employeeId])

	const doGetEmployeeDetails = (id: any) => {
		setIsLoading(true)
		api.get(`/employee/get_employee/${id}`)
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					let _data = response?.data?.data?.data
					setEmployeeName(_data?.firstName)
					_data?.dependents.map((data: any, index: any) => {
						createDependentsTable(data)
					})
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const doUpdate = () => {
		setIsLoading(true)
		api.put(`/employee/update_employee/${employeeId}`, {
			dependents: dependentsList?.rows
		})
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					let _data = response?.data?.data?.data
					setEmployeeName(_data?.firstName)
					doNext()
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const createDependentsTable = (data: any) => {
		let _obj = {
			emergencyContact: data?.emergencyContact,
			ticketEligible: data?.ticketEligible,
			relationship: data?.relationship,
			emergencyContactNo: data?.emergencyContactNo,
			fullName: data?.fullName,
			gender: data?.gender,
			maritalStatus: data?.maritalStatus,
			identificationNo: data?.identificationNo,
			passportNo: data?.passportNo,
			dob: data?.dob === 'Invalid date' || data?.dob == '' ? '' : moment(data?.dob).format('YYYY/MM/DD'),
			nationality: data?.nationality,
			remarks: data?.remarks,
			action: (
				<Flexed direction="row" align="center" justify="center">
					<Remove
						onClick={() => {
							setDependentId(data?.identificationNo)
							setDeleteModel(true)
						}}>
						Remove
					</Remove>
				</Flexed>
			)
		}
		setDependentsList((preValue: any) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	const addToList = () => {
		let _dob = moment(dob).format('YYYY/MM/DD')
		let _obj = {
			emergencyContact: emergencyContact,
			ticketEligible: ticketEligible,
			relationship: relationship?.value,
			emergencyContactNo: emergencyContactNo,
			fullName: firstName + '' + lastName,
			gender: gender?.value,
			maritalStatus: maritalStatus?.value,
			identificationNo: identificationNo,
			passportNo: passportNo,
			dob: _dob === 'Invalid date' ? '' : _dob,
			nationality: nationality?.value,
			remarks: remarks,
			action: (
				<Flexed direction="row" align="center" justify="center">
					<Remove
						onClick={() => {
							removeData(identificationNo)
						}}>
						Remove
					</Remove>
				</Flexed>
			)
		}
		setDependentsList((preValue: any) => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
		setEmergencyContact(false)
		setTicketEligible(false)
		setRelationship({label: 'Husband', value: 'Husband'})
		setEmergencyContactNo('')
		setFirstName('')
		setLastName('')
		setGender({label: 'Male', value: 'Male'})
		setMaritalStatus({label: 'Single', value: 'Single'})
		setIdentificationNo('')
		setPassportNo('')
		setDob('')
		setNationality({label: '', value: ''})
		setRemarks('')
	}

	const removeData = (id: any) => {
		setDependentsList((prevValue: any) => {
			const newRows = prevValue.rows.filter((item: any) => item.identificationNo !== id)
			setDependentId('')
			setDeleteModel(false)
			return {
				...prevValue,
				rows: newRows
			}
		})
	}

	const formValidation = () => {
		let isValid = true
		if (identificationNo.trim() === '') {
			setIdentificationNoError('This value is required.')
			isValid = false
		}
		if (emergencyContact) {
			if (emergencyContactNo.trim() === '') {
				setEmergencyContactNoError('This value is required.')
				isValid = false
			}
		}

		return isValid
	}

	return (
		<>
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-sio-c152="" id="" viewBox="0 0 33 32">
							<path _ngcontent-sio-c152="" d="M30 22.667v6.666c0 .737-.597 1.334-1.333 1.334H3.333A1.333 1.333 0 0 1 2 29.333V9.047c0-.63.44-1.174 1.057-1.304L6 6.667"></path>
							<path
								_ngcontent-sio-c152=""
								d="M30 16v-5.95c0-.736-.597-1.333-1.333-1.333h-26M30 22.667h-8a1.333 1.333 0 0 1-1.333-1.334v-4c0-.736.597-1.333 1.333-1.333h8c.736 0 1.333.597 1.333 1.333v4c0 .737-.597 1.334-1.333 1.334z"></path>
							<path _ngcontent-sio-c152="" d="M24.667 20.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667zM6 8V6.667c0-.737.597-1.334 1.333-1.334H24c.736 0 1.333.597 1.333 1.334V8"></path>
							<path _ngcontent-sio-c152="" d="M7.333 5.333V4c0-.736.597-1.333 1.334-1.333H26c.736 0 1.333.597 1.333 1.333v4.667"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Dependents
						</Text>
						<TagLine type="normal" color="silver_8">
							Fill in the employee's dependent's information
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={6}>
									<DropDown label="Relationship" firstSelected={relationship.label} options={relationshipOption} hasChanged={(value: any) => setRelationship({label: value, value: value})} dropDownListHeight="200px" />
								</Col>
								<Col lg={6}>
									<Flexed gap={0.2} justify="space-between" style={{height: '100%'}}>
										<Flexed direction="row" align="center" gap={0.2} margin="1.2rem 0rem 0rem 0rem">
											<CheckIconWrapper>
												{emergencyContact ? (
													<Checked
														onClick={() => {
															setEmergencyContact(!emergencyContact)
														}}
													/>
												) : (
													<UnChecked
														onClick={() => {
															setEmergencyContact(!emergencyContact)
														}}
													/>
												)}
											</CheckIconWrapper>
											<Text fontSize={0.813} fontWeight={500} color="black_3">
												Emergency Contact
											</Text>
										</Flexed>
										<Spacer height={0.5} />
										<Flexed direction="row" align="center" gap={0.2} margin="0rem 0rem 1rem 0rem">
											<CheckIconWrapper>
												{ticketEligible ? (
													<Checked
														onClick={() => {
															setTicketEligible(!ticketEligible)
														}}
													/>
												) : (
													<UnChecked
														onClick={() => {
															setTicketEligible(!ticketEligible)
														}}
													/>
												)}
											</CheckIconWrapper>
											<Text fontSize={0.813} fontWeight={500} color="black_3">
												Ticket Eligible
											</Text>
										</Flexed>
									</Flexed>
								</Col>
								{emergencyContact && (
									<Col lg={6}>
										<InputField
											allowPhoneNumberOnly={true}
											label="Emergency Contact"
											min={10}
											maxLength={15}
											error={emergencyContactNoError}
											required={emergencyContact}
											value={emergencyContactNo}
											handleChange={(e: any) => {
												setEmergencyContactNoError('')
												setEmergencyContactNo(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
								)}
								<Col lg={6}>
									<InputField
										label="First Name"
										value={firstName}
										handleChange={(e: any) => {
											setFirstName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Last Name"
										value={lastName}
										handleChange={(e: any) => {
											setLastName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Gender" firstSelected={gender.label} options={genderOption} hasChanged={(value: any) => setGender({label: value, value: value})} dropDownListHeight="200px" />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<DropDown label="Marital Status" firstSelected={maritalStatus.label} options={maritalStatusOption} hasChanged={(value: any) => setMaritalStatus({label: value, value: value})} dropDownListHeight="200px" />
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										allowPhoneNumberOnly={true}
										label="Identification No"
										error={identificationNoError}
										required
										min={8}
										maxLength={18}
										value={identificationNo}
										handleChange={(e: any) => {
											setIdentificationNoError('')
											setIdentificationNo(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputField
										label="Passport No"
										value={passportNo}
										min={6}
										maxLength={9}
										handleChange={(e: any) => {
											setPassportNo(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="DOB"
										value={dob}
										min={9}
										maxLength={12}
										handleChange={(e: any) => {
											setDob(e)
										}}
										maxDate={new Date()}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									{/* <DropDown label="Nationality" firstSelected={nationality.label} options={nationalityOption} hasChanged={(value: any) => setNationality({label: value, value: value})} dropDownListHeight="200px" /> */}
									<MultiSelect options={countryOption} enterToAdd={true} onChange={(e: any) => setNationality(e)} />
									<Spacer height={1} />
								</Col>
								<Col>
									<InputField
										type="textarea"
										label="Remarks"
										value={remarks}
										handleChange={(e: any) => {
											setRemarks(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col>
									<Button
										onClick={() => {
											if (formValidation()) {
												addToList()
											}
										}}>
										Add To List
									</Button>
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			<Table>
				<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={dependentsList} fullPagination searching={false} />
			</Table>
			<Row>
				<Col>
					<Divider margin="1rem 0rem" />
					<Flexed direction="row" gap={1} align="center" justify="flex-end">
						<Back onClick={doBack}>{isEdit ? 'Cancel' : 'Back'}</Back>
						<Next onClick={doUpdate}>{isEdit ? 'Save' : 'Next'}</Next>
					</Flexed>
				</Col>
			</Row>

			{deleteModel && (
				<DeleteModal
					onCloseModal={() => {
						setDeleteModel(false)
						setDependentId('')
					}}
					modalStatus=""
					open={deleteModel}
					title={`Delete this dependent?`}
					body={`Are you sure you want to delete this dependent?`}
					deletePost={() => dependentId && removeData(dependentId)}
				/>
			)}
		</>
	)
}
const Icons = styled.svg`
	max-width: 25px;
	fill: #0000;
	stroke: ${palette.purple_1};
	stroke-width: 2px;
	stroke-linecap: round;
`

const Form = styled.div`
	background: ${palette.silver_9};
	padding: 30px;
	border-radius: 4px;
	margin-bottom: 10px;
	border: 1px solid ${palette.gray_300};
`

const Button = styled.div<any>`
	width: fit-content;
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	height: 34px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 3px;
	font-weight: 500;
	font-size: 0.813rem;
	line-height: 1.5rem;
	color: ${palette.white};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
`

const Table = styled.div<any>`
	& div div div[data-test='mdb-datatable-table'] {
		padding: 0;
	}
`

const Remove = styled.div<any>`
	font-weight: 400;
	padding: 0px 7px;
	width: fit-content;
	color: #342b7f;
	background: #e2deff;
	border-radius: 10px;
	cursor: pointer;
`

export default Dependents
