import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Text, Spacer, Flexed, Divider } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'

interface ICategoriesModalProps {
    onCloseModal: any
    insuranceCardNo: any
    setInsuranceCardNo: any
    insuranceClass: any
    setInsuranceClass: any
    insuranceClassOption: any
    medicalInsuranceExpiryDate: any
    setMedicalInsuranceExpiryDate: any
    medicalInsuranceAmountMonthly: any
    setMedicalInsuranceAmountMonthly: any
}

const MedicalInsuranceModal = ({ onCloseModal, insuranceCardNo, setInsuranceCardNo, insuranceClass, setInsuranceClass, insuranceClassOption, medicalInsuranceExpiryDate, setMedicalInsuranceExpiryDate, medicalInsuranceAmountMonthly, setMedicalInsuranceAmountMonthly }: ICategoriesModalProps) => {
    const [insuranceCardNoError, setInsuranceCardNoError] = useState('');
    const [insuranceClassError, setInsuranceClassError] = useState('');
    const [medicalInsuranceExpiryDateError, setMedicalInsuranceExpiryDateError] = useState('');

    const formValidation = () => {
        let isValid = true
        if (insuranceCardNo === '') {
            setInsuranceCardNoError('This value is required.')
            isValid = false
        }
        if (insuranceClass?.value === '') {
            setInsuranceClassError('This value is required.')
            isValid = false
        }
        if (medicalInsuranceExpiryDate === '') {
            setMedicalInsuranceExpiryDateError('This value is required.')
            isValid = false
        }
        return isValid
    }

    return (
        <>
            <Modal
                open={true}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'blockModal'
                }}
                showCloseIcon={false}>
                <ModalWrapper>
                    <Head direction="row" align="center" justify="space-between" gap={1}>
                        <Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
                            Medical Insurance
                        </Text>
                        <CrossIcon
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Spacer height={1} />
                    <Body>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <InputField label='Insurance Card No' required value={insuranceCardNo}
                                        error={insuranceCardNoError}
                                        handleChange={(e: any) => {
                                            setInsuranceCardNoError('')
                                            if (e === '') {
                                                setInsuranceCardNoError('This value is required.')
                                            }
                                            setInsuranceCardNo(e)
                                        }} />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <DropDown label='Insurance Class' error={insuranceClassError} required firstSelected={insuranceClass.label} options={insuranceClassOption} hasChanged={(value: any) => setInsuranceClass({ label: value, value: value })} dropDownListHeight='130px' />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <InputDate label='Expiry Date of Policy' error={medicalInsuranceExpiryDateError} required value={medicalInsuranceExpiryDate} handleChange={(e: any) => {
                                        setMedicalInsuranceExpiryDateError('')
                                        if (e === '') {
                                            setMedicalInsuranceExpiryDateError('This value is required.')
                                        }
                                        setMedicalInsuranceExpiryDate(e)
                                    }}
                                    minDate={new Date()}
                                     />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <InputField label='Total Policy Amount Monthly' allowOnlyNumbersAndDecimal={true} value={medicalInsuranceAmountMonthly}
                                        handleChange={(e: any) => {
                                            setMedicalInsuranceAmountMonthly(e)
                                        }} />
                                    <Spacer height={1} />
                                </Col>
                            </Row>
                        </Container>
                    </Body>
                    <Footer direction="row" align="center" justify="flex-end" gap={1}>
                        <Button border="red" color="white" bg="red" bg_hover="red_hover" onClick={() => {
                            setInsuranceCardNo('');
                            setInsuranceClass({ label: 'VIP', value: 'VIP' });
                            setMedicalInsuranceExpiryDate('');
                            setMedicalInsuranceAmountMonthly('');
                            setInsuranceCardNoError('');
                            setInsuranceClassError('');
                            setMedicalInsuranceExpiryDateError('');
                        }}>
                            Reset
                        </Button>
                        <Button border="purple_2" color="white" bg="purple_1" bg_hover="purple_2" onClick={() => {
                            if (formValidation()) {
                                onCloseModal();
                            }
                        }} >
                            Save
                        </Button>
                    </Footer>
                </ModalWrapper>
            </Modal>
        </>
    )
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
    background-color: ${palette.gray_1};
    border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 0.5rem 1.5rem;
    ${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 600px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Footer = styled(Flexed)`
    border-top: 1px solid ${palette.silver};
	padding: 1rem 1.5rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({ border }) => palette[border]};
	color: ${({ color }) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ bg }) => palette[bg]};
    cursor:pointer;
	&:hover {
		background: ${({ bg_hover }) => palette[bg_hover]};
	}
`

export default MedicalInsuranceModal
