import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoIosArrowBack} from 'react-icons/io'
import {IoMdClose} from 'react-icons/io'
import {Popup} from '../modals/TrelloTaskModal'
import LocationSearch from '../common/LocationSearch'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import moment from 'moment'

const LocationPop = ({top, mainLocationModal, goBack, setLocation, onCloseModal, cardDetails, loadAgain}: any) => {
	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const updateCardDetails = async (isRemove = false, cardLocation: any) => {
		try {
			await api
				.put(`/trello/update_card/${cardDetails?.id}`, {
					location: isRemove ? '' : cardLocation,
					locationAddedDate: isRemove ? '' : moment().format()
				})
				.then((response) => {
					if (response?.data?.success == true) {
						loadAgain && loadAgain()
						onCloseModal()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}
	return (
		<Popup top={top} onClick={handleModalClick}>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				{mainLocationModal ? (
					<div></div>
				) : (
					<CloseCover onClick={goBack}>
						<IoIosArrowBack cursor="pointer" />
					</CloseCover>
				)}
				<Text fontWeight={600}>{mainLocationModal ? 'Add Location' : 'Change location'}</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			<Flexed margin="0.5rem 0rem" gap={0.5}>
				<div>
					<LocationSearch
						setSelectedAddress={(address: any) => {
							setLocation(address)
							updateCardDetails(false, address)
							onCloseModal()
						}}
					/>
				</div>
			</Flexed>
		</Popup>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

export default LocationPop
