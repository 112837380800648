import React, { useState } from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import { Flexed, Text } from '../../styled/shared';
import { palette } from '../../styled/colors'
import { IoMdClose } from "react-icons/io";

const MentionMemberPop = ({ onCloseModal }: any) => {
    const [search, setSearch] = useState('');
    const handleModalClick = (event: any) => {
        event.stopPropagation();
    };
    return (
        <Popup top='2.3rem' onClick={handleModalClick}>
            <Flexed direction='row' margin='0rem 0.5rem' align='center' justify='space-between' gap={0.2}>
                <div></div>
                <Text fontWeight={600}>Mention…</Text>
                <CloseCover onClick={onCloseModal}>
                    <IoMdClose cursor='pointer' />
                </CloseCover>
            </Flexed>
            <Flexed margin='0.5rem 0rem 0rem 0rem'>
                <div>
                    <Input placeholder='Search members...' value={search} onChange={(e: any) => { setSearch(e?.target?.value) }} />
                </div>
                <List margin='0.5rem 0rem 0.5rem 0rem' direction='row' align='center' gap={0.5}>
                    <UserProfile>
                        AR
                    </UserProfile>
                    <Text type='small' fontWeight={500}>Name</Text>
                </List>
                <List>
                <Text type='small' margin='0.2rem 0rem' fontWeight={600}>All members on the card (0)</Text>
                </List>
                <List>
                <Text type='small' margin='0.2rem 0rem' fontWeight={600}>All members on the board (0)</Text>
                </List>
            </Flexed>
        </Popup>
    )
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({ top }) => top};
	z-index:5;
	right:0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding:0.5rem 0.7rem;
	width: 300px;
`

const Input = styled.input <any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
    outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
`

const UserProfile = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
	font-size: 0.825rem;
	font-weight: 600;
	color: ${palette.white};
	background-color: ${palette.purple_1};
`

const List = styled(Flexed)`
	width: 100%;
	padding: 0.313rem;
    border-radius: 0.3rem;
	background: ${({ active }) => active ? palette.silver_1 : palette.white};
	position: relative;
	cursor:pointer;
	&:hover {
		background: ${palette.silver_1};
	}
`

export default MentionMemberPop
