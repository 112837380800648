// UserProfile.js

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {api, doGetOwnerProfile} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import useRouter from '../hooks/router'
import {TagUser} from '../components/trelloTaskModalComponents/AddMemberPop'
import {getInitials} from '../utils'
import {BiArrowBack} from 'react-icons/bi'
import ActivityLog from '../components/modals/ActivityLogs'
import CardItems from '../components/taskManagementComponents/CardItems'
import {Flexed} from '../styled/shared'
import Loader from '../components/common/Loader'

// Styled components for user profile
const ProfileContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Avatar = styled.img`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin-bottom: 10px;
`

const UserName = styled.h2`
	font-size: 24px;
	margin-bottom: 5px;
`

const Bio = styled.p`
	font-size: 16px;
	text-align: center;
	max-width: 300px;
`

const TabsContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
`

const Tab = styled.div<any>`
	padding: 10px;
	cursor: pointer;
	font-weight: ${({isActive}) => (isActive ? 'bold' : 'normal')};
`

const ContentContainer = styled.div`
	margin-top: 20px;
	width: 40%;
`

const UserProfile = () => {
	const [user, setUser] = useState<any>('')
	const [activeTab, setActiveTab] = useState('activity')
	const [isLoading, setIsLoading] = useState(false)
	const router = useRouter()

	useEffect(() => {
		if (router?.query?.id != 'admin') {
			employeeProfile(router?.query?.id)
		}
		if (router?.query?.id == 'admin') {
			getAdminProfile()
		}
	}, [router.query])

	const getAdminProfile = async () => {
		setIsLoading(true)
		let adminProfile = await doGetOwnerProfile()
		setUser(adminProfile)
		setIsLoading(false)
	}
	const employeeProfile = async (id?: any) => {
		setIsLoading(true)
		await api
			.get(`/employee/get_employee/${id}?activity=${true}`)
			.then((response) => {
				if (response?.data?.success == true) {
					const profileData = response?.data?.data?.data
					setUser(profileData)
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	return user ? (
		<>
			<BiArrowBack style={{margin: '1rem', cursor: 'pointer'}} onClick={() => router.navigate(-1)} />
			<ProfileContainer>
				{router?.query?.id == 'admin' && user ? <>{user?.logo ? <Avatar src={user?.logo ? process.env.REACT_APP_IMAGE_URL + user?.logo : ''} alt="User Avatar" /> : <TagUser>{user?.name ? getInitials(user?.name) : getInitials(user?.email)}</TagUser>}</> : user ? <>{user?.image ? <Avatar src={user?.image ? process.env.REACT_APP_IMAGE_URL + user?.image : ''} alt="User Avatar" /> : <TagUser>{user?.firstName ? getInitials(user?.firstName + ' ' + user?.lastName) : getInitials(user?.email)}</TagUser>}</> : ''}

				<UserName>{router?.query?.id == 'admin' ? user?.name : user?.firstName ? user?.firstName + ' ' + user?.lastName : user?.email}</UserName>

				<TabsContainer>
					<Tab isActive={activeTab === 'activity'} onClick={() => setActiveTab('activity')}>
						Activity
					</Tab>
					{router?.query?.id == 'admin' ? (
						''
					) : (
						<Tab isActive={activeTab === 'cards'} onClick={() => setActiveTab('cards')}>
							Cards
						</Tab>
					)}
				</TabsContainer>

				<ContentContainer>
					{activeTab === 'activity' && (
						<div style={{width: '100%'}}>
							<ActivityLog activities={user?.ActivityLogs?.length ? user?.ActivityLogs : []} />
						</div>
					)}

					{activeTab === 'cards' && (
						<Flexed>
							{user?.taskListCards?.length
								? user?.taskListCards?.map((res: any) => (
										<div style={{padding: '0.5rem'}} key={res?.id}>
											<CardItems
												quote={{
													card_id: res?.id,
													...res,
													author: {
														id: res?.id?.toString(),
														name: res?.title,
														url: null,
														avatarUrl: null,
														colors: {
															soft: '#efb300',
															hard: '#797979'
														},
														...res
													}
												}}
											/>
										</div>
									))
								: ''}
						</Flexed>
					)}
				</ContentContainer>
			</ProfileContainer>
		</>
	) : (
		<>{isLoading && <Loader />}</>
	)
}

export default UserProfile
