import React, {useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {Checked, CheckIconWrapper, UnChecked} from '../../pages/Auth/SignUp'
import {IoClose} from 'react-icons/io5'
import InputField from '../common/InputField'
import {DeductionsName, DeductionsPercentage, DeductionsAmount, DeductionsTaxable, DeductionsAction} from './DeductionsTable'

const DeductionsTableList = ({baseSalary, data, editDeductionAmount, taxableDeductionCheck, onRemove}: any) => {
	const [checked, setChecked] = useState(false)
	return (
		<>
			<Main>
				<Wrapper>
					<Flexed direction="row" gap={1} align="center">
						<DeductionsName gap={0.2}>
							<Text type="small" fontWeight={400} color="black_1">
								{data?.deductionCategory}
							</Text>
						</DeductionsName>
						<DeductionsPercentage direction="row">
							<InputTag>%</InputTag>
							<InputField disabled value={data?.percentage} allowOnlyNumbersAndDecimal={true} />
						</DeductionsPercentage>
						<DeductionsAmount direction="row">
							<InputTag>RS</InputTag>
							<InputField value={data?.amount} allowOnlyNumbersAndDecimal={true} handleChange={(e: any) => editDeductionAmount(e)} />
						</DeductionsAmount>
						{/* <DeductionsStartingBalance direction="row">
                        <InputField allowOnlyNumbersAndDecimal={true} />
                    </DeductionsStartingBalance> */}
						<DeductionsTaxable direction="row" align="center" gap={4}>
							<CheckIconWrapper>
								{data?.deductionTaxPlan === 'Taxable' ? (
									<Checked
										onClick={() => {
											taxableDeductionCheck('Non Taxable')
										}}
									/>
								) : (
									<UnChecked
										onClick={() => {
											taxableDeductionCheck('Taxable')
										}}
									/>
								)}
							</CheckIconWrapper>
							<IoClose color="#000" cursor="pointer" onClick={onRemove} />
						</DeductionsTaxable>
						<DeductionsAction direction="row" gap={0.5} align="center" justify="flex-end"></DeductionsAction>
					</Flexed>
				</Wrapper>
			</Main>
		</>
	)
}
const HeadWrapper = styled.div<any>`
	width: 38rem;
	background: #eef0f1;
	padding: 0.5rem;
	transition: all ease 0.25s;
	position: relative;
	${media.sm`
	width: 42rem;
	`}
	${media.md`
	width: 100%;
	`}
`

const Main = styled.div<any>`
	border: 1px solid #ddd;
`

const Wrapper = styled.div<any>`
	margin: 0 0 0.5rem 0;
	width: 38rem;
	padding: 0.5rem;
	border-radius: 0.39rem;
	transition: all ease 0.25s;
	position: relative;
	${media.sm`
	width: 42rem;
	`}
	${media.md`
	width: 100%;
	`}
`

const InputTag = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	color: ${palette.black_5};
	text-align: center;
	background-color: ${palette.silver_1};
	border: 1px solid ${palette.silver_6};
	border-right: 0.5px solid ${palette.silver_6};
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	min-width: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
`

export default DeductionsTableList
