import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {BiArchive, BiCheck, BiDotsHorizontal} from 'react-icons/bi'
import {colorsList} from '../../utils'
import BoardBackgroundsModel from './BoardBackgroundsModel'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import MultiSelect from '../common/MultiSelect'
import {GiPrivate} from 'react-icons/gi'
import {FaUserGroup} from 'react-icons/fa6'
import {RiChatPrivateFill} from 'react-icons/ri'
import {MdOutlineLock} from 'react-icons/md'
import InputField from '../common/InputField'
import Loader from '../common/Loader'
import DeleteCard from '../trelloTaskModalComponents/DeleteCard'
import {media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'

interface ICategoriesModalProps {
	onCloseModal: any
	openBoard?: any
}

const ClosedBoardModel = ({onCloseModal, openBoard}: ICategoriesModalProps) => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const [loading, setLoading] = useState(false)
	const [userBoards, setUserBoards] = useState<any>([])
	const [memberBoards, setMemberBoards] = useState<any>([])
	const [deleteBoard, setDeleteBoard] = useState(false)
	const [deletedBoardId, setDeletedBoardId] = useState('')
	const [boardDetails, setBoardDetails] = useState<any>('')

	useEffect(() => {
		getAllBoards()
	}, [])

	const getAllBoards = async () => {
		setLoading(true)
		await api
			.get(`/trello/get_all_boards?isClosed=${true}`)
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						setUserBoards(response?.data?.data?.userboards)

						const groupedData = response?.data?.data?.memberBoards.reduce((acc: any, curr: any) => {
							const adminName = curr.admin.name
							if (!acc[adminName]) {
								acc[adminName] = []
							}
							acc[adminName].push(curr)
							return acc
						}, {})

						setMemberBoards(groupedData)
					}
					setLoading(false)
				}
			})
			.catch(function (error) {
				setLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const deleteSingleBoard = async (id?: any) => {
		setLoading(true)
		await api
			.delete(`/trello/delete_board?id=${id}`)
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						setDeleteBoard(false)

						getAllBoards()
					}
					setLoading(false)
				}
			})
			.catch(function (error) {
				setLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const reopenCurrentBoard = async (id?: any, isClosed = false) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_board/${id}`, {isClosed})
				.then((response) => {
					if (response?.data?.success == true) {
						setDeletedBoardId('')
						setBoardDetails('')
						toast.success('Board deleted successful')
						openBoard && openBoard()
						getAllBoards()
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const leaveCurrentBoard = async (boardId?: any) => {
		try {
			setLoading(true)
			await api
				.post(`/trello/leave_board`, {boardId: boardId})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Leaved board successful')
						setDeletedBoardId('')
						setDeleteBoard(false)
						openBoard && openBoard()
						getAllBoards()
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					{loading && <Loader />}
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black">
							<BiArchive /> Closed Boards
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Container>
							<Spacer height={2} />
							<Flexed direction="column" flexWrap="wrap" gap={0.5}>
								{userBoards?.length
									? userBoards?.map((res: any) => (
											<>
												<Flexed direction="row" flexWrap="wrap" align={'center'} justify={'space-between'} gap={0.5}>
													<Text type="medium" lineHeight="1.438" fontWeight="300" color="black">
														{res?.name}
													</Text>
													<Flexed direction="row" flexWrap="wrap" align={'center'} gap={0.3}>
														<Button
															onClick={() => {
																setBoardDetails(res)
																setDeletedBoardId(res?.id)
																setDeleteBoard(true)
															}}
															border="silver_6"
															color="black_3"
															bg="white"
															bg_hover="silver_2">
															Delete
														</Button>
														<Button color="white" bg="purple_1" bg_hover="purple_2" onClick={() => reopenCurrentBoard(res?.id)}>
															Reopen
														</Button>
													</Flexed>
												</Flexed>
												<Divider />
											</>
										))
									: Object.keys(memberBoards)?.length == 0 && 'No closed board found'}
							</Flexed>
							<Spacer height={2} />
							{Object.keys(memberBoards)?.map((res: any) => (
								<Flexed direction="column" flexWrap="wrap" align={'start'} gap={0.3} style={{width: '100%'}}>
									<Text type="medium" lineHeight="1.438" fontWeight="300" color="black">
										{res}
									</Text>
									{memberBoards[res]?.map((e: any) => (
										<Flexed direction="row" flexWrap="wrap" align={'center'} justify={'space-between'} gap={0.3} style={{width: '100%'}}>
											<Text type="medium" lineHeight="1.438" fontWeight="300" color="black">
												{e?.name}
											</Text>
											<Button
												onClick={() => {
													setBoardDetails(e)
													setDeletedBoardId(e?.id)
													setDeleteBoard(true)
												}}
												border="silver_6"
												color="red"
												bg="white"
												bg_hover="silver_2">
												Leave
											</Button>
										</Flexed>
									))}
								</Flexed>
							))}
						</Container>
					</Body>
				</ModalWrapper>
				{deleteBoard && (
					<DeleteCard
						isMember={boardDetails?.adminId != _loginAdminDetails?.id || boardDetails?.employeeId != _loginUserDetails?.id}
						isBoardDelete={true}
						onCloseModal={() => setDeleteBoard(false)}
						onDelete={() => {
							if (_loginAdminDetails?.id == boardDetails?.adminId) {
								deletedBoardId && deleteSingleBoard(deletedBoardId)
							} else if (boardDetails?.adminId != _loginAdminDetails?.id || boardDetails?.employeeId != _loginUserDetails?.id) {
								leaveCurrentBoard(deletedBoardId)
							} else {
							}
						}}
					/>
				)}
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;

	${media.sm`
	width: 100%;
	`}
	${media.md`
	width: 40rem !important;
	`}
	${media.lg`
	width: 40rem !important;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

export const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: ${({removedBorder}) => (removedBorder ? '0.5rem' : `2rem`)};
	padding: 6px 16px;
	font-size: 14px;
	border: ${({removedBorder, border}) => (removedBorder ? '' : `1px solid ${palette[border]}`)};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

const Container = styled.div`
	/* max-height: 372px; */
`
export default ClosedBoardModel
