import React, {useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import QRCode from 'qrcode.react'
import {useSelector} from 'react-redux'

interface ICategoriesModalProps {
	data: any
	onCloseModal: any
	userDetails: any
}

const QRAScanModal = ({data, onCloseModal, userDetails}: ICategoriesModalProps) => {
	const [regenerateKey, setRegenerateKey] = useState(0)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)

	const handleRegenerateQR = () => {
		setRegenerateKey((prevKey) => prevKey + 1)
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="purple_1">
							{_loginUserDetails?.companyEmployees?.companyName ? _loginUserDetails?.companyEmployees?.companyName : _loginAdminDetails?.companyName}
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Flexed direction="row" align="center" justify="space-between" margin="0rem 0rem 1rem 0rem">
							<Text type="small" fontWeight={500}>
								Name
							</Text>
							<Text type="small" fontWeight={500}>
								{data?.firstName} {data?.lastName}
							</Text>
						</Flexed>
						<Flexed direction="row" align="center" justify="space-between" margin="0rem 0rem 1rem 0rem">
							<Text type="small" fontWeight={500}>
								Designation
							</Text>
							<Text type="small" fontWeight={500}>
								{data?.designation}
							</Text>
						</Flexed>
						<Flexed direction="row" align="center" justify="space-between" margin="0rem 0rem 1rem 0rem">
							<Text type="small" fontWeight={500}>
								Department
							</Text>
							<Text type="small" fontWeight={500}>
								{data?.department}
							</Text>
						</Flexed>
						<Flexed direction="row" align="center" justify="space-between">
							<Text type="small" fontWeight={500}>
								Location
							</Text>
							<Text type="small" fontWeight={500}>
								{data?.workLocation}
							</Text>
						</Flexed>
					</Body>
					<QraWrapper direction="row" align="center" justify="center">
						<QRCode key={regenerateKey} value={`Name: ${data?.firstName}, ${data?.lastName}`} size={150} />
					</QraWrapper>
					<Footer direction="row" align="center" justify="space-between" gap={1}>
						<Button onClick={handleRegenerateQR} border="silver_6" color="black_3" bg="white" bg_hover="silver_2">
							Regenerate QR
						</Button>
						<Button color="white" bg="purple_1" bg_hover="purple_2">
							Print
						</Button>
					</Footer>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;
`

const QraWrapper = styled(Flexed)`
	padding: 1rem 1.5rem;
	border-top: 1px solid ${palette.silver};
	border-bottom: 1px solid ${palette.silver};
`

const Footer = styled(Flexed)`
	padding: 1rem 1.5rem;
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({border}) => palette[border]};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

export default QRAScanModal
