import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {BiCalendar, BiLabel, BiUser} from 'react-icons/bi'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import Loader from '../common/Loader'
import DeleteCard from '../trelloTaskModalComponents/DeleteCard'
import {media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import LabelsList from '../trelloTaskModalComponents/LabelsList'
import {FaRegClock} from 'react-icons/fa'
import {ProfileImg, UserProfile} from '../taskManagementComponents/SubNavBar'
import {TagUser} from '../trelloTaskModalComponents/AddMemberPop'
import {getInitials} from '../../utils'
import {useDispatch} from 'react-redux'
import {filterBoardCards} from '../../actions/authActions'
import moment from 'moment'

interface ICategoriesModalProps {
	onCloseModal: any
	openBoard?: any
	boardDetails?: any
	filters?: any
}

const BoardFiltersModel = ({onCloseModal, openBoard, boardDetails, filters}: ICategoriesModalProps) => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const [loading, setLoading] = useState(false)
	const [keyword, setKeyword] = useState('')
	const [selectedMembers, setSelectedMembers] = useState<any[]>([])
	const [selectedLabels, setSelectedLabels] = useState<any[]>([])
	const [selectedDueDateOptions, setSelectedDueDateOptions] = useState<any[]>([])
	const [showAllOptions, setShowAllOptions] = useState(false)
	const [selectedDueDates, setSelectedDueDates] = useState<any[]>([])
	const [selectedActivities, setSelectedActivities] = useState<any[]>([])
	const _filterOptions: any = useSelector<any>((state: any) => state.auth.filterCards)
	const dispatch = useDispatch()

	console.log('=====_filterOptions', _filterOptions)
	useEffect(() => {
		if (_filterOptions?.query) {
			setKeyword(_filterOptions?.query)
		}
		if (_filterOptions?.members) {
			setSelectedMembers(_filterOptions?.members)
		}
		if (_filterOptions?.labels) {
			setSelectedLabels(_filterOptions?.labels)
		}
		if (_filterOptions?.dueDate) {
			setSelectedDueDates(_filterOptions?.dueDate)
		}
		if (_filterOptions?.activity) {
			setSelectedActivities(_filterOptions?.activity)
		}
	}, [_filterOptions])

	const activityDataSet = [
		{label: 'Active in the last week', value: moment().subtract(1, 'week').startOf('day').format()},
		{label: 'Active in the last two weeks', value: moment().subtract(2, 'week').startOf('day').format()},
		{label: 'Active in the last four weeks', value: moment().subtract(4, 'week').startOf('day').format()},
		{label: 'Without activity in the last four weeks', value: moment().subtract(6, 'week').startOf('day').format()}
	]

	const dueDateOptions = [
		{text: 'No dates', icon: <BiCalendar style={{marginRight: '0.4rem'}} />, value: 'null'},
		{text: 'Overdue', icon: <FaRegClock fontSize={16} style={{marginRight: '0.4rem', background: 'red', padding: '0.1rem', color: 'white', borderRadius: '50%'}} />, value: moment().subtract(1, 'day').startOf('day').format()},
		{text: 'Due in the next day', icon: <FaRegClock fontSize={16} style={{marginRight: '0.4rem', background: '#F2D600', padding: '0.1rem', color: 'white', borderRadius: '50%'}} />, value: moment().add(1, 'day').startOf('day').format()},
		{text: 'Due in the next week', icon: <FaRegClock fontSize={16} style={{marginRight: '0.4rem', background: 'gray', padding: '0.1rem', color: 'white', borderRadius: '50%'}} />, value: moment().add(1, 'week').startOf('day').format()},
		{text: 'Due in the next month', icon: <FaRegClock fontSize={16} style={{marginRight: '0.4rem', background: 'gray', padding: '0.1rem', color: 'white', borderRadius: '50%'}} />, value: moment().add(1, 'month').startOf('day').format()},
		{text: 'Marked as complete', value: 'isCompleted'},
		{text: 'Not marked as complete', value: 'not_complete'}
	]

	const colorList = [
		{color: '#baf3db', onHover: '#7ee2b8', title: 'subtle green'},
		{color: '#f8e6a0', onHover: '#f5cd47', title: 'subtle yellow'},
		{color: '#fedec8', onHover: '#fec195', title: 'subtle orange'},
		{color: '#ffd5d2', onHover: '#fd9891', title: 'subtle red'},
		{color: '#dfd8fd', onHover: '#b8acf6', title: 'subtle purple'},
		{color: '#4bce97', onHover: '#7ee2b8', title: 'green'},
		{color: '#f5cd47', onHover: '#e2b203', title: 'yellow'},
		{color: '#fea362', onHover: '#fec195', title: 'orange'},
		{color: '#f87168', onHover: '#fd9891', title: 'red'},
		{color: '#9f8fef', onHover: '#b8acf6', title: 'purple'},
		{color: '#1f845a', onHover: '#216e4e', title: 'bold green'},
		{color: '#946f00', onHover: '#7f5f01', title: 'bold yellow'},
		{color: '#c25100', onHover: '#a54800', title: 'bold orange'},
		{color: '#c9372c', onHover: '#ae2e24', title: 'bold red'},
		{color: '#6e5dc6', onHover: '#5e4db2', title: 'bold purple'},
		{color: '#cce0ff', onHover: '#85b8ff', title: 'subtle blue'},
		{color: '#c6edfb', onHover: '#9dd9ee', title: 'subtle sky'},
		{color: '#d3f1a7', onHover: '#b3df72', title: 'subtle lime'},
		{color: '#fdd0ec', onHover: '#f797d2', title: 'subtle pink'},
		{color: '#dcdfe4', onHover: '#b3b9c4', title: 'subtle black'},
		{color: '#579dff', onHover: '#85b8ff', title: 'blue'},
		{color: '#6cc3e0', onHover: '#9dd9ee', title: 'sky'},
		{color: '#94c748', onHover: '#b3df72', title: 'lime'},
		{color: '#e774bb', onHover: '#f797d2', title: 'pink'},
		{color: '#8590a2', onHover: '#b3b9c4', title: 'black'},
		{color: '#0c66e4', onHover: '#0055cc', title: 'bold blue'},
		{color: '#227d9b', onHover: '#206a83', title: 'bold sky'},
		{color: '#5b7f24', onHover: '#4c6b1f', title: 'bold lime'},
		{color: '#ae4787', onHover: '#943d73', title: 'bold pink'},
		{color: '#626f86', onHover: '#44546f', title: 'bold black'}
	]

	// Handle functions for updating filter states
	const handleKeywordChange = (event: any) => {
		setKeyword(event.target.value)
		filters({query: event.target.value})

		dispatch(filterBoardCards({query: event.target.value, members: selectedMembers, labels: selectedLabels, dueDate: selectedDueDates, activity: selectedActivities}))
	}

	const handleMemberSelection = (memberId: any) => {
		// Check if the memberId is already in the selectedMembers array
		const index = selectedMembers.indexOf(memberId)
		if (index === -1) {
			// If not, add it to the selectedMembers array
			setSelectedMembers([...selectedMembers, memberId])
			dispatch(filterBoardCards({query: keyword, members: [...selectedMembers, memberId], labels: selectedLabels, dueDate: selectedDueDates, activity: selectedActivities}))
		} else {
			// If it is, remove it from the selectedMembers array
			const updatedMembers = [...selectedMembers]
			updatedMembers.splice(index, 1)
			setSelectedMembers(updatedMembers)

			dispatch(filterBoardCards({query: keyword, members: updatedMembers, labels: selectedLabels, dueDate: selectedDueDates, activity: selectedActivities}))
		}
	}

	console.log('=====selectedMembers', selectedMembers, selectedLabels, selectedDueDates, selectedActivities)
	const handleLabelSelection = (labelColor: any) => {
		// Check if the labelColor is already in the selectedLabels array
		const index = selectedLabels.indexOf(labelColor)
		if (index === -1) {
			// If not, add it to the selectedLabels array
			setSelectedLabels([...selectedLabels, labelColor])
			dispatch(filterBoardCards({query: keyword, members: selectedMembers, labels: [...selectedLabels, labelColor], dueDate: selectedDueDates, activity: selectedActivities}))
		} else {
			// If it is, remove it from the selectedLabels array
			const updatedLabels = [...selectedLabels]
			updatedLabels.splice(index, 1)
			setSelectedLabels(updatedLabels)

			dispatch(filterBoardCards({query: keyword, members: selectedMembers, labels: updatedLabels, dueDate: selectedDueDates, activity: selectedActivities}))
		}
	}
	const handleDueDateSelection = (value: any) => {
		// Check if the value is already in the selectedDueDates array
		const index = selectedDueDates.indexOf(value)
		if (index === -1) {
			// If not, add it to the selectedDueDates array
			setSelectedDueDates([...selectedDueDates, value])
			dispatch(filterBoardCards({query: keyword, members: selectedMembers, labels: selectedLabels, dueDate: [...selectedDueDates, value], activity: selectedActivities}))
		} else {
			// If it is, remove it from the selectedDueDates array
			const updatedDueDates = [...selectedDueDates]
			updatedDueDates.splice(index, 1)
			setSelectedDueDates(updatedDueDates)

			dispatch(filterBoardCards({query: keyword, members: selectedMembers, labels: selectedLabels, dueDate: updatedDueDates, activity: selectedActivities}))
		}
	}

	const handleActivitySelection = (value: any) => {
		// Check if the value is already in the selectedActivities array
		const index = selectedActivities.indexOf(value)
		if (index === -1) {
			// If not, add it to the selectedActivities array
			setSelectedActivities([...selectedActivities, value])

			dispatch(filterBoardCards({query: keyword, members: selectedMembers, labels: selectedLabels, dueDate: selectedDueDates, activity: [...selectedActivities, value]}))
		} else {
			// If it is, remove it from the selectedActivities array
			const updatedActivities = [...selectedActivities]
			updatedActivities.splice(index, 1)
			setSelectedActivities(updatedActivities)

			dispatch(filterBoardCards({query: keyword, members: selectedMembers, labels: selectedLabels, dueDate: selectedDueDates, activity: updatedActivities}))
		}
	}
	return (
		<>
			{loading && <Loader />}
			<CustomModelWrapper
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black">
							Filter
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<ContentWrapper>
							<Paragraph>Keyword</Paragraph>
							<Input type="text" placeholder="Enter a keyword…" aria-placeholder="Enter a keyword…" value={keyword} onChange={handleKeywordChange} />
							<Paragraph>Search cards, members, labels, and more.</Paragraph>
							<Paragraph>Members</Paragraph>

							<MemberList>
								<MemberItem>
									<MemberLabel onClick={() => handleMemberSelection('no_members')}>
										<Checkbox type="checkbox" checked={selectedMembers?.some((e) => e == 'no_members')} />
										<MemberIcon>
											<BiUser />
										</MemberIcon>
										<MemberText>No members</MemberText>
									</MemberLabel>
									{_loginAdminDetails?.id ? (
										<MemberLabel onClick={() => handleMemberSelection(_loginAdminDetails.id)}>
											<Checkbox type="checkbox" checked={selectedMembers?.some((e) => e == _loginAdminDetails.id)} />
											{_loginAdminDetails?.id ? (
												<UserProfile direction="row" align="center" gap={0.5}>
													<ProfileImg>
														{_loginAdminDetails?.id ? (
															<>{_loginAdminDetails?.logo ? <img src={process.env.REACT_APP_IMAGE_URL + _loginAdminDetails?.logo} alt="i" /> : <img src={'/images/default_user_profile_img.png'} alt="i" />}</>
														) : (
															<></>
														)}
													</ProfileImg>
												</UserProfile>
											) : (
												''
											)}
											<MemberText>Cards assigned to me</MemberText>
										</MemberLabel>
									) : (
										''
									)}
								</MemberItem>
								{boardDetails?.taskBoardInvites?.map((res: any) => (
									<MemberItem>
										<MemberLabel onClick={() => handleMemberSelection(res.receiverUserId)}>
											<Checkbox type="checkbox" checked={selectedMembers?.some((e) => e == res.receiverUserId)} />
											{res?.receiverUserId ? (
												<UserProfile direction="row" align="center" gap={0.5}>
													<ProfileImg>
														{res?.receiver?.image ? (
															<>
																<img src={process.env.REACT_APP_IMAGE_URL + res?.receiver?.image} alt="i" />
															</>
														) : (
															<>
																<TagUser width="1.8rem" height="1.8rem">
																	{res?.receiver?.firstName + ' ' + res?.receiver?.lastName && getInitials(res?.receiver?.firstName + ' ' + res?.receiver?.lastName)}
																</TagUser>
															</>
														)}
													</ProfileImg>
												</UserProfile>
											) : (
												''
											)}
											<MemberText>{res?.receiver?.id == _loginUserDetails?.id ? 'Cards assigned to me' : res?.receiver?.firstName + ' ' + res?.receiver?.lastName}</MemberText>
										</MemberLabel>
									</MemberItem>
								))}
							</MemberList>
							<Divider />
							<Flexed gap={0.2} margin="0.5rem 0rem 1rem 0rem">
								<Flexed direction={'row'} align="center" gap={0.2} margin="0.5rem 0rem 1rem 0rem" onClick={() => handleLabelSelection('no_label')}>
									<DueDateCheckbox type="checkbox" checked={selectedLabels?.some((e) => e == 'no_label')} />
									<Text type="small" lineHeight="1.438" fontWeight="500" color="black">
										<BiLabel /> No Labels
									</Text>
								</Flexed>

								{colorList?.map(
									(res: any, i: any) =>
										i <= 10 && (
											<LabelsList
												isEdit={true}
												editLabel={() => ''}
												color={colorList[i]}
												onAdd={() => handleLabelSelection(res.color)}
												checked={selectedLabels?.some((e) => e == res.color)}
												// cardDetails={cardDetails}
												// onAdd={(e: any) => addNewLabel(e)}
											/>
										)
								)}
							</Flexed>

							<DueDateWrapper>
								<Spacer heigth={1} />
								<Paragraph>Due date</Paragraph>

								<DueDateList>
									{dueDateOptions.map((option, i) =>
										!showAllOptions && i <= 2 ? (
											<DueDateItem key={option.value} onClick={() => handleDueDateSelection(option.value)}>
												<DueDateLabel>
													<DueDateCheckbox type="checkbox" checked={selectedDueDates?.some((e) => e == option.value)} />
													{option?.icon}

													<DueDateText>{option.text}</DueDateText>
												</DueDateLabel>
											</DueDateItem>
										) : (
											showAllOptions && (
												<DueDateItem key={option.value} onClick={() => handleDueDateSelection(option.value)}>
													<DueDateLabel>
														<DueDateCheckbox type="checkbox" checked={selectedDueDates?.some((e) => e == option.value)} />
														{option?.icon}

														<DueDateText>{option.text}</DueDateText>
													</DueDateLabel>
												</DueDateItem>
											)
										)
									)}
								</DueDateList>
								{!showAllOptions ? (
									<ShowMoreButton onClick={() => setShowAllOptions(!showAllOptions)}>
										Show more options
										<DownIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
											<path
												d="M11.2929 16.7071L4.22185 9.63606C3.83132 9.24554 3.83132 8.61237 4.22185 8.22185C4.61237 7.83133 5.24554 7.83133 5.63606 8.22185L12 14.5858L18.364 8.22185C18.7545 7.83132 19.3877 7.83132 19.7782 8.22185C20.1687 8.61237 20.1687 9.24554 19.7782 9.63606L12.7071 16.7071C12.3166 17.0977 11.6834 17.0977 11.2929 16.7071Z"
												fill="currentColor"></path>
										</DownIcon>
									</ShowMoreButton>
								) : (
									''
								)}
							</DueDateWrapper>
							<Divider />

							<Flexed direction="column" align="start" gap={0.2}>
								<Spacer heigth={1} />
								<Paragraph>Activity</Paragraph>
								<ul>
									{activityDataSet?.map((res: any, i) => (
										<ListItem key={i} onClick={() => handleActivitySelection(res.value)}>
											<Label>
												<CheckBox type="checkbox" checked={selectedActivities?.some((e) => e == res.value)} />

												<Span>
													<Status>{res?.label}</Status>
												</Span>
											</Label>
										</ListItem>
									))}
								</ul>
							</Flexed>
						</ContentWrapper>
					</Body>
				</ModalWrapper>
			</CustomModelWrapper>
		</>
	)
}

const Popup = styled.div<any>`
	position: absolute;
	top: ${({top}) => top};
	z-index: 500000;
	left: ${({left}) => (left ? left : '0')};
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 40rem;
`
const CustomModelWrapper = styled(Modal)`
	top: 16% !important;
`
const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;

	${media.sm`
	width: 100%;
	`}
	${media.md`
	width: 40rem !important;
	`}
	${media.lg`
	width: 40rem !important;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({border}) => palette[border]};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

const Container = styled.div`
	/* max-height: 372px; */
`
const PopoverWrapper = styled.section`
	/* position: fixed; */
	inset: 100px auto auto 1048px;
	width: 384px;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	z-index: 999; /* Add z-index value as per your requirement */
`

const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border-bottom: 1px solid #e0e0e0;
`

const Title = styled.h2`
	margin: 0;
	font-size: 20px;
`

const CloseButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	padding: 8px;
`

const CloseIcon = styled.svg`
	width: 24px;
	height: 24px;
	fill: #757575;
`

const ContentWrapper = styled.div`
	padding: 16px;
`

const Input = styled.input`
	width: 100%;
	padding: 12px;
	margin-bottom: 16px;
	border: 1px solid #bdbdbd;
	border-radius: 4px;
	font-size: 16px;
`

const Paragraph = styled.p`
	margin: 0;
	margin-bottom: 16px;
	font-size: 14px;
	color: #757575;
`

const MemberList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`

const MemberItem = styled.li`
	margin-bottom: 8px;
`

const MemberLabel = styled.label`
	display: flex;
	align-items: center;
	font-size: 16px;
	gap: 0.3rem;
`

const Checkbox = styled.input`
	margin-right: 8px;
`

const MemberIcon = styled.span`
	width: 24px;
	height: 24px;
	background-color: #bdbdbd;
	border-radius: 50%;
	margin-right: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const MemberText = styled.span`
	color: #424242;
`

const DueDateWrapper = styled.div`
	margin-top: 16px;
`

const DueDateTitle = styled.p`
	margin: 0;
	font-size: 14px;
`

const DueDateList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`

const DueDateItem = styled.li`
	margin-bottom: 8px;
`

const DueDateLabel = styled.label`
	display: flex;
	align-items: center;
	font-size: 16px;
`

const DueDateCheckbox = styled.input`
	margin-right: 8px;
`

const DueDateIconWrapper = styled.span<any>`
	width: 24px;
	height: 24px;
	background-color: ${(props) => props.bgColor || '#fff'};
	border-radius: 50%;
	margin-right: 8px;
`

const DueDateText = styled.span`
	color: ${(props) => props.color || '#424242'};
`

const ShowMoreButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	font-size: 14px;
	color: #6b778c;
	display: flex;
	align-items: center;
	margin-top: 16px;
`

const DownIcon = styled.svg`
	width: 24px;
	height: 24px;
	fill: #6b778c;
`

const ListItem = styled.li`
	list-style-type: none;
`

const Label = styled.label`
	display: flex;
	align-items: center;
`

const Span = styled.span``

const Icon = styled.svg``

const Status = styled.div`
	margin-left: 10px;
`

const CheckBox = styled.input``

export default BoardFiltersModel
