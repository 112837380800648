import React, {useState} from 'react'
import styled from 'styled-components'
import {AuthFilledButton, CopyRight, Flexed, Loading, Spacer, Text} from '../../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import InputFieldAuth from '../../components/common/InputFieldAuth'
import {CommonEnum} from '../../utils'
import {useNavigate} from 'react-router-dom'
import useWindowSize from '../../hooks/useWindowSize'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import useRouter from '../../hooks/router'

const ResetPassword = () => {
	let _navigate = useNavigate()
	const router: any = useRouter()
	const _windowSize = useWindowSize()
	const [isLoading, setIsLoading] = useState(false)
	const [email, setEmail] = useState(router?.query?.email)
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const [passwordError, setPasswordError] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState('')

	const doResetPassword = async (e: any) => {
		e?.preventDefault()
		if (formValidation()) {
			setIsLoading(true)
			await api
				.put(router?.query?.role == 'employee' ? `/employee/forgot_password` : `/admin/forgot_password`, {
					email: email,
					password: password
				})
				.then(async (res) => {
					setIsLoading(false)
					if (res?.data?.success) {
						toast.success('Password updated successfully.')
						_navigate('/login')
					} else {
						toast.error(res?.data?.message)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (password === '') {
			setPasswordError('Password must be at least 8 characters.')
			isValid = false
		}
		if (confirmPassword === '') {
			setConfirmPasswordError('Password must match.')
			isValid = false
		}
		if (password?.trim().length < 7) {
			setPasswordError('Password must be at least 8 characters.')
			isValid = false
		}
		if (password != confirmPassword) {
			setConfirmPasswordError('Password must match.')
			isValid = false
		} else {
			setConfirmPasswordError('')
		}
		return isValid
	}

	return (
		<>
			<MainWrapper>
				<Flexed direction="row" justify="center">
					<ImageContent>
						<CoverImage src="/images/logo.png" alt="login_bg" />
					</ImageContent>
					<LoginContent>
						<Wrapper>
							<InnerWrapper>
								<Row justifyContent="center">
									<Col lg={10}>
										<form onSubmit={doResetPassword}>
											<Text fontSize={_windowSize?.width < 600 ? 1.5 : 1.875} lineHeight={2} fontWeight={600} color="black_3">
												Forgot your password?
											</Text>
											<Spacer height={1} />
											<Text fontSize={0.813} color="black_7">
												Now you can change your password here.
											</Text>
											<Spacer height={1.8} />
											<InputFieldAuth label="Email" disabled required type="text" value={email} />
											<Spacer height={0.8} />
											<InputFieldAuth
												label="Password"
												required
												type="password"
												value={password}
												error={passwordError}
												handleChange={(e: any) => {
													setPasswordError('')
													if (e === '') {
														setPasswordError('Password is required.')
													}
													setPassword(e)
												}}
											/>
											<Spacer height={0.8} />
											<InputFieldAuth
												label="Confirm Password"
												required
												type="password"
												value={confirmPassword}
												error={confirmPasswordError}
												handleChange={(e: any) => {
													if (e?.length < 7) {
														setConfirmPasswordError('Password must be at least 8 characters.')
													} else if (e !== password) {
														setConfirmPasswordError('Password must match.')
													} else {
														setConfirmPasswordError('')
													}
													setConfirmPassword(e)
												}}
											/>

											<Spacer height={2} />
											<Flexed direction="row" gap={1}>
												<AuthFilledButton onClick={doResetPassword}>Continue</AuthFilledButton>
											</Flexed>
										</form>
									</Col>
								</Row>
							</InnerWrapper>
							<CopyRight>© 2024 {CommonEnum.AppName}, All rights reserved</CopyRight>
						</Wrapper>
					</LoginContent>
				</Flexed>
			</MainWrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled.div`
	height: 100vh;
`

const LoginContent = styled.div`
	width: 100%;
	height: 100vh;
	background: url(/images/white_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	${media.sm`width: 80%;`};
	${media.md`width: 60%;`};
	${media.lg`width: 50%;`};
`

const Wrapper = styled.div`
	position: relative;
	background: #ffffffcc;
	height: 100%;
	display: flex;
	${media.lg` align-items: center;`};
`

const InnerWrapper = styled(Container)`
	margin-top: 2rem;
	margin-bottom: 0rem;
	${media.sm`margin-top: 4rem;`}
	${media.md`margin-top: 6rem;`}
    ${media.lg`margin-bottom: 6rem; margin-top: 0rem;`}
`

const ImageContent = styled.div`
	width: 50%;
	display: none;
	${media.lg`
		display: flex;
		justify-content: center;
		align-items: center;
	`};
`

const CoverImage = styled.img`
	width: 20rem;
	object-fit: cover;
	object-position: bottom;
`

export default ResetPassword
