import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import DocumentsTable from './DocumentsTable'
import {Back, Next, TagLine} from './PersonalIz'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import InputField from '../common/InputField'
import moment from 'moment'
import {toast} from 'react-toastify'
import {api} from '../api/callAxios'
import FileUpload from '../common/FileUpload'
import DeleteModal from '../modals/DeleteModal'
import Loader from '../common/Loader'

const Documents = ({doBack, doNext, setIsLoading, employeeId, setEmployeeId, setEmployeeName, isEdit}: any) => {
	const [documentType, setDocumentType] = useState({label: '', value: ''})
	const [expiryDate, setExpiryDate] = useState('')
	const [description, setDescription] = useState('')
	const [documentsList, setDocumentsList] = useState<any>([])
	const [document, setDocument] = useState('')
	const [documentName, setDocumentName] = useState('')
	const [loading, setLoading] = useState(false)
	const [deleteModel, setDeleteModel] = useState(false)
	const [documentId, setDocumentId] = useState('')

	const [documentTypeOption, setDocumentTypeOption] = useState([
		{value: '', label: 'Select an option'},
		{value: 'National ID', label: 'National ID'},
		{value: 'Passport', label: 'Passport'},
		{value: 'Family ID', label: 'Family ID'},
		{value: 'CV', label: 'CV'}
	])

	const [documentTypeError, setDocumentTypeError] = useState('')
	const [documentError, setDocumentError] = useState('')
	const [descriptionError, setDescriptionError] = useState('')

	useEffect(() => {
		if (employeeId) {
			doGetEmployeeDetails(employeeId)
		}
	}, [employeeId])

	useEffect(() => {
		if (document != '') {
			setDocumentError('')
		}
	}, [document])

	const doGetEmployeeDetails = (id: any) => {
		setLoading(true)
		api.get(`/employee/get_employee/${id}`)
			.then(async (response) => {
				setLoading(false)
				if (response?.data?.success == true) {
					let _data = response?.data?.data?.data
					setEmployeeName(_data?.firstName)
					setDocumentsList(_data?.employeeDocuments)
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setLoading(false)
				}
			})
	}

	const addToList = async () => {
		setLoading(true)
		await api
			.post(`/employee/document/add`, {
				employeeId: employeeId,
				documentType: documentType?.value,
				expiryDate: moment(expiryDate).format('YYYY/MM/DD'),
				document: document,
				documentName: documentName,
				description: description
			})
			.then(async (response) => {
				setLoading(false)
				if (response?.data?.success == true) {
					setDocumentsList(response?.data?.data?.data)
					setDocumentType({label: '', value: ''})
					setExpiryDate('')
					setDescription('')
					setDocument('')
					setDocumentName('')
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setLoading(false)
				}
			})
	}

	const formValidation = () => {
		let isValid = true
		if (documentType?.value === '') {
			setDocumentTypeError('This value is required.')
			isValid = false
		}
		if (documentName === '') {
			setDocumentError('This value is required.')
			isValid = false
		}
		if (description === '') {
			setDescriptionError('This value is required.')
			isValid = false
		}

		return isValid
	}

	const removeData = (id: any) => {
		setLoading(true)
		api.delete(`/employee/document/remove/${id}`)
			.then(async (response) => {
				setLoading(false)
				if (response?.data?.success == true) {
					doGetEmployeeDetails(employeeId)
				}
				setDeleteModel(false)
				setDocumentId('')
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setLoading(false)
				}
			})
	}

	return (
		<>
			{loading && <Loader />}
			<Row>
				<Col xl={6} lg={5}>
					<Flexed margin="2rem 0rem 0rem 0rem">
						<Icons _ngcontent-vbe-c153="" viewBox="0 0 32 32" className="imgblue">
							<path _ngcontent-vbe-c153="" d="M6 4l.026-1.667A1.333 1.333 0 0 1 7.36 1h17.284a1.333 1.333 0 0 1 1.333 1.333L26.004 8M10.016 4.99L21 5m-5 4h5"></path>
							<path _ngcontent-vbe-c153="" d="M2 9.996a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1a1 1 0 0 0 1 1h15a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-19z"></path>
						</Icons>
						<Text fontSize={1.375} color="black_3" margin="1.5rem 0rem 0.8rem 0rem" fontWeight={500}>
							Documents
						</Text>
						<TagLine type="normal" color="silver_8">
							Upload Employee's personal documents i.e identity card,passport etc and provide access to employees.
						</TagLine>
						<Spacer height={1} />
					</Flexed>
				</Col>
				<Col xl={6} lg={7}>
					<Form>
						<Container fluid>
							<Row>
								<Col lg={6}>
									<DropDown
										label="Document Type"
										required
										error={documentTypeError}
										firstSelected={documentType.label}
										options={documentTypeOption}
										hasChanged={(value: any) => {
											setDocumentTypeError('')
											setDocumentType({label: value, value: value})
										}}
										dropDownListHeight="200px"
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="Expiry Date"
										value={expiryDate}
										handleChange={(e: any) => {
											setExpiryDate(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col>
									<FileUpload disabled label="Upload Employee Docs" value={documentName} required setFileName={setDocumentName} setFileValue={setDocument} error={documentError} />
									<Spacer height={1} />
								</Col>
								<Col>
									<InputField
										type="textarea"
										label="Description"
										error={descriptionError}
										required
										value={description}
										handleChange={(e: any) => {
											setDescriptionError('')
											if (e === '') {
												setDescriptionError('This value is required.')
											}
											setDescription(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col>
									<Button
										onClick={() => {
											if (formValidation()) {
												addToList()
											}
										}}>
										Add To List
									</Button>
								</Col>
							</Row>
						</Container>
					</Form>
				</Col>
			</Row>
			{documentsList?.length > 0 && (
				<Row>
					<Col>
						<Spacer height={1.4} />
						<DocumentsTable
							documentsList={documentsList}
							removeData={(id: any) => {
								setDocumentId(id)
								setDeleteModel(true)
							}}
						/>
						<Spacer height={2} />
					</Col>
				</Row>
			)}
			<Row>
				<Col>
					<Divider margin="1rem 0rem" />
					<Flexed direction="row" gap={1} align="center" justify="flex-end">
						<Back onClick={doBack}>{'Back'}</Back>
						{isEdit ? '' : <Next onClick={doNext}>{isEdit ? 'Save' : 'Next'}</Next>}
					</Flexed>
				</Col>
			</Row>
			{deleteModel && (
				<DeleteModal
					onCloseModal={() => {
						setDeleteModel(false)
						setDocumentId('')
					}}
					modalStatus=""
					open={deleteModel}
					title={`Delete this document?`}
					body={`Are you sure you want to delete this document?`}
					deletePost={() => documentId && removeData(documentId)}
				/>
			)}
		</>
	)
}
const Icons = styled.svg`
	max-width: 25px;
	fill: #0000;
	stroke: ${palette.purple_1};
	stroke-width: 2px;
	stroke-linecap: round;
`

const Form = styled.div`
	background: ${palette.silver_9};
	padding: 30px;
	border-radius: 4px;
	margin-bottom: 10px;
	border: 1px solid ${palette.gray_300};
`

const Button = styled.div<any>`
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	border-radius: 3px;
	font-weight: 500;
	font-size: 0.813rem;
	line-height: 1.5rem;
	color: ${palette.white};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
`

const Count = styled.div`
	height: 30px;
	width: 30px;
	background: ${palette.purple_1};
	border-radius: 100%;
	color: ${palette.white} !important;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
`

export default Documents
