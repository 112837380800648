import React from 'react'
import GoogleLogin from 'react-google-login'
import styled from 'styled-components'
import { Text } from '../../styled/shared'
import { useSelector } from 'react-redux'
import { palette } from '../../styled/colors'

const LogInGoogle = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);

	return (
		<>
			{/* <GoogleLogin
				clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
				render={(renderProps) => ( */}
			{/* <Button onClick={renderProps.onClick} disabled={renderProps.disabled} justify="center" isDarkTheme={_isDarkTheme}> */}
			<Button isDarkTheme={_isDarkTheme}>
				<Cover>
					<Icon src="/icons/google.svg" />
				</Cover>
				<Label>Sign in with Google</Label>
			</Button>
			{/* )}
				// onSuccess={responseGoogle}
				// onFailure={responseGoogle}
				cookiePolicy={'single_host_origin'}
			/> */}
		</>
	)
}
const Button = styled.div<any>`
	width: 240px;
	height: 50px;
	background-color: ${palette.purple_1};
	color: ${palette.white};
	border-radius: 1px;
	box-shadow: 0 2px 4px #00000040;
	transition: background-color .218s,border-color .218s,box-shadow .218s;
	font-size: 0.813rem;
	position:relative;
	display:flex;
	align-items: center;
	font-weight: 500;
`

const Cover = styled.div`
	padding: 15px;
    background: ${palette.white};
    width: 48px;
    height: 100%;
    border-radius: 1px;
    display: inline-block;
	border: 1px solid ${palette.purple_1};
`;

const Icon = styled.img`
	margin-right: 0.7rem;
	width: 1.125rem;
	height: 1.125rem;
`

const Label = styled.div`
	width: 100%;
	text-align: center;
`;

export default LogInGoogle
