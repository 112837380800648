import {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Flexed, Loading, Text, Spacer} from '../styled/shared'
import {palette} from '../styled/colors'
import {IoLocationOutline} from 'react-icons/io5'
import EmployeesAttendance from '../components/EmployeesAttendance'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import moment, {Moment} from 'moment'

const Time = ({activeTimeTab, setActiveTimeTab}: any) => {
	const [isLoading, setIsLoading] = useState(false)
	const [allEmployeeList, setAllEmployeeList] = useState<any>([])
	const [allEmployeeListUpdateList, setAllEmployeeListUpdateList] = useState<any>([])
	const [allEmployeeListUpdateListFilters, setAllEmployeeListUpdateListFilters] = useState<any>([])
	const [allAttendanceList, setAllAttendanceList] = useState<any>([])
	const [leaveRequests, setLeaveRequests] = useState<any>([])
	const [filter, setFilter] = useState('all')
	const [allHolidayList, setAllHolidayList] = useState([])
	const [todayDate, setTodayDate] = useState(moment().format('DD-MM-YYYY'))
	const [searchQuery, setSearchQuery] = useState('')

	useEffect(() => {
		const fetchData = async () => {
			await allEmployee()
			await getAttendance()
			await getHolidays()
			await getLeaves()
		}
		fetchData()
	}, [])

	const getLeaves = async () => {
		try {
			await api
				.post('/request/all_requests', {
					requestName: 'Leave Request',
					status: 'approved'
				})
				.then((res: any) => {
					if (res?.data) {
						setLeaveRequests(res?.data?.data?.data?.length ? res?.data?.data?.data : [])
					}
				})
		} catch (error) {}
	}
	useEffect(() => {
		if (allAttendanceList?.length > 0 && allEmployeeList?.length > 0) {
			const _empId: any = []
			for (let i = 0; i < allAttendanceList.length; i++) {
				if (moment(allAttendanceList[i]?.attendanceDate, 'DD-MM-YYYY').isSame(moment(todayDate, 'DD-MM-YYYY'), 'day')) {
					_empId.push(parseInt(allAttendanceList[i]?.employeeId))
				}
			}
			for (let i = 0; i < allEmployeeList.length; i++) {
				if (_empId.includes(allEmployeeList[i]?.id)) {
					allEmployeeList[i].attendanceStatus = 'present'
				} else {
					allEmployeeList[i].attendanceStatus = 'absent'
				}
			}
			setAllEmployeeListUpdateList(allEmployeeList)
			setAllEmployeeListUpdateListFilters(allEmployeeList)
		}
	}, [allEmployeeList, allAttendanceList])

	const getAttendance = () => {
		setIsLoading(true)
		api.get(`attendance/show_all_attendances`)
			.then(async (response: any) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					setAllAttendanceList(response?.data?.data?.data)
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const getHolidays = () => {
		setIsLoading(true)
		api.get(`/holiday/show_all_holidays`)
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					setAllHolidayList(response?.data?.data?.data)
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}
	const allEmployee = (query = '') => {
		setIsLoading(true)
		setAllEmployeeList([])
		api.get(`/employee/all_employees?query=${query}`)
			.then(async (response) => {
				if (response?.data?.success === true) {
					setAllEmployeeList(response?.data?.data?.data)
				}

				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	useEffect(() => {
		if (activeTimeTab === 'employeesAttendance') {
			allEmployee(searchQuery)
		} else {
			allEmployee()
		}
	}, [searchQuery, activeTimeTab])
	return (
		<>
			<Wrapper direction="row" align="center">
				<Content direction="row" gap={2} align="center" justify="space-between" pointer="pointer">
					<div>
						<Text type="normal" fontWeight={500} color="black">
							Attendance
						</Text>
					</div>
					<Flexed direction="row" gap={1}>
						<DashboardMenu active={activeTimeTab === 'liveAttendance'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={activeTimeTab === 'liveAttendance'} onClick={() => setActiveTimeTab('liveAttendance')}>
								Live Attendance
							</StyledText>
						</DashboardMenu>
						<DashboardMenu active={activeTimeTab === 'employeesAttendance'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={activeTimeTab === 'employeesAttendance'} onClick={() => setActiveTimeTab('employeesAttendance')}>
								Employees Attendance
							</StyledText>
						</DashboardMenu>
					</Flexed>
					<div></div>
				</Content>
			</Wrapper>
			<Section>
				<Container fluid>
					<Row>
						<Col>
							<TableWraper>
								{activeTimeTab === 'liveAttendance' && (
									<Container fluid>
										<Row>
											<Col>
												<FilterWrapper direction="row" align="center" gap={1} flexWrap="wrap">
													<Filter
														direction="row"
														align="center"
														gap={1}
														active={filter === 'all'}
														onClick={() => {
															setFilter('all')
															setAllEmployeeListUpdateListFilters(allEmployeeListUpdateList)
														}}>
														<FilterBar bg="black" />
														All (({allEmployeeListUpdateList?.length})
													</Filter>
													<Filter
														direction="row"
														align="center"
														gap={1}
														active={filter === 'present'}
														onClick={() => {
															setFilter('present')
															setAllEmployeeListUpdateListFilters(allEmployeeListUpdateList.filter((attendance: any) => attendance?.attendanceStatus === 'present'))
														}}>
														<FilterBar bg="green" />
														Present ({allEmployeeListUpdateList.filter((attendance: any) => attendance?.attendanceStatus === 'present')?.length})
													</Filter>
													<Filter
														direction="row"
														align="center"
														gap={1}
														active={filter === 'absent'}
														onClick={() => {
															setFilter('absent')
															setAllEmployeeListUpdateListFilters(allEmployeeListUpdateList.filter((attendance: any) => attendance?.attendanceStatus === 'absent'))
														}}>
														<FilterBar bg="red" />
														Absent ({allEmployeeListUpdateList.filter((attendance: any) => attendance?.attendanceStatus === 'absent')?.length})
													</Filter>
												</FilterWrapper>
												<Spacer height={1} />
											</Col>
										</Row>
										<Row>
											{allEmployeeListUpdateListFilters?.map((val: any) => {
												return (
													<>
														<Col lg={3} md={4} sm={6}>
															<Card align="center" gap={0.5}>
																<NameCover>{val?.firstName[0] + val?.lastName[0]}</NameCover>
																<Text type="xsmall" fontWeight={500}>
																	{val?.id} - {val?.firstName + ' ' + val?.lastName}
																</Text>
																<Flexed direction="row" gap={0.5} align="center">
																	<IoLocationOutline />
																	<Text type="small">{'Generals'} </Text>
																</Flexed>
																<Status active={val?.attendanceStatus === 'present'}>{val?.attendanceStatus}</Status>
															</Card>
															<Spacer height={1.5} />
														</Col>
													</>
												)
											})}
										</Row>
									</Container>
								)}
								{activeTimeTab === 'employeesAttendance' && (
									<Container fluid>
										<Row>
											<Col>
												<EmployeesAttendance
													allAttendanceList={allAttendanceList}
													allHolidayList={allHolidayList}
													allEmployeeList={allEmployeeList}
													leaveRequests={leaveRequests}
													setSearchQuery={setSearchQuery}
													searchQuery={searchQuery}
												/>
											</Col>
										</Row>
									</Container>
								)}
							</TableWraper>
						</Col>
					</Row>
				</Container>
			</Section>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`
const Content = styled(Flexed)`
	width: 100%;
`
const DashboardMenu = styled.div<any>`
	border-bottom: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
	cursor: pointer;
`
const StyledText = styled(Text)`
	padding: 10px 0px 10px 0px;
	color: ${({active}) => (active ? `${palette.purple_1}` : 'black')};
`
const Section = styled.div`
	padding: 25px 5px 20px 5px;
`
const TableWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	padding: 15px;
	border-radius: 5px;
`
const Card = styled(Flexed)`
	background: ${palette.white};
	padding: 15px;
	border: 1px solid ${palette.silver_1};
	border-radius: 5px;
`
const NameCover = styled.div`
	width: 32px;
	height: 32px;
	background: #fde5c0;
	color: #d65a00;
	padding: 4px 8px;
	border-radius: 5px;
	font-size: 13px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1px #1c242b29;
`

const Status = styled.div<any>`
	width: fit-content;
	background: ${({active}) => (active ? palette.green : palette.red)};
	color: ${palette.white};
	padding: 4px 8px;
	border-radius: 5px;
	font-size: 13px;
	font-weight: 500;
`

const FilterWrapper = styled(Flexed)`
	background: ${palette.white};
	padding: 15px;
	border: 1px solid ${palette.silver_1};
	border-radius: 5px;
`

const Filter = styled(Flexed)`
	background: ${({active}) => (active ? palette.silver_11 : palette.white)};
	padding: 0.3rem 0.5rem;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
`

const FilterBar = styled(Flexed)`
	background: ${({bg}) => palette[bg]};
	height: 1rem;
	width: 5px;
	border-radius: 5px;
`

export default Time
