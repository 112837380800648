import React from 'react'
import { Loading } from '../../styled/shared'

const Loader = () => {
    return (
        <Loading>
            <div className="dots-loading"></div>
        </Loading>
    )
}

export default Loader