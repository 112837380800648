import React, {useState, useEffect, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Link} from 'react-router-dom'
import {Text, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import Button from './Button'
import {loginUserDetailsAction, loginAdminDetailsAction} from '../../actions/authActions'
import {FaAngleDown} from 'react-icons/fa'
import {api, doGetCustomerProfile, doGetOwnerProfile} from '../api/callAxios'
import useWindowSize from '../../hooks/useWindowSize'
import {RiLogoutBoxRFill} from 'react-icons/ri'
import {RiSettings5Line} from 'react-icons/ri'
import {LiaThListSolid} from 'react-icons/lia'
import {CommonEnum} from '../../utils'
import useRouter from '../../hooks/router'

interface IProps {
	scroll: number
	path?: boolean
	active?: boolean
	isDarkTheme?: boolean
}

const Header = () => {
	const {pathname} = useLocation()
	const windowSize = useWindowSize()
	const _ref: any = useRef(null)
	const _userProfileRef: any = useRef(null)
	let _navigate = useNavigate()
	let _dispatch = useDispatch()
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const _authToken: any = sessionStorage.getItem('authorization')
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')
	const [scrollPosition, setScrollPosition] = useState(0)
	const [openNotificationList, setOpenNotificationList] = useState(false)
	const [openUserProfileDrop, setOpenUserProfileDrop] = useState(false)
	const employeeId: any = sessionStorage.getItem('employeeId')
	const router = useRouter()

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, {passive: true})

		const handleClickOutside = (event: any) => {
			if (_ref?.current && !_ref?.current?.contains(event.target)) {
				setOpenNotificationList(false)
			}
			if (_userProfileRef?.current && !_userProfileRef?.current?.contains(event.target)) {
				setOpenUserProfileDrop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const getProfile = async (status: string) => {
		if (status === 'admin') {
			let response = await doGetOwnerProfile()
			if (response?.response?.status == 401) {
				router.navigate('/login')
			} else if (response) {
				_dispatch(loginAdminDetailsAction(response))
			}
		} else if (status === 'employee') {
			let response = await doGetCustomerProfile(employeeId)
			if (response) {
				_dispatch(loginUserDetailsAction(response))
			}
		}
	}

	useEffect(() => {
		if (_authToken) {
			getProfile(_loginUserStatus)
		} else {
			if (!_authToken && pathname == '/create-candidate') {
			} else {
				_navigate('/login')
			}
		}
	}, [_authToken])

	return (
		<Main>
			<Container fluid>
				<Row>
					<CustomCol>
						<Link to={_authToken ? '/dashboard' : '/login'}>
							{/* <Logo src="/images/Logo.png" alt="logo" /> */}
							<Text type="normal" fontWeight={600} color="black">
								{CommonEnum.AppName}
							</Text>
						</Link>
						<ActionMenu direction="row" align="center">
							{_authToken ? (
								<>
									<CompanyName>{_loginUserStatus === 'admin' ? _loginAdminDetails?.companyName : _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName}</CompanyName>
									{/* <NotificationMenu
										active={openNotificationList}
										ref={_ref}
										onClick={() => {
											setOpenNotificationList(!openNotificationList)
										}}>
										{notificationsCounts > 0 && (
											<NotificationsCounts direction="row" align="center" justify="center">
												{notificationsCounts > 9 ? '9+' : notificationsCounts}
											</NotificationsCounts>
										)}
										<MdOutlineNotifications fontSize={windowSize?.width < 575 ? '1.4rem' : '1.5rem'} />
										{openNotificationList && (
											<DropContent id="notificationMenuContent">
												{notifications?.map((val: any, index: any) => {
													return (
														<NotificationContent active={!val?.isRead} key={index + 'notification'} direction="row" gap={1} align="center">
															{val?.image && (
																<div>
																	<ProfileImg>
																		<img src={process.env.REACT_APP_IMAGE_URL + val?.image} alt="i" />
																	</ProfileImg>
																</div>
															)}
															<div>
																<Text fontWeight="bold" type="small" margin="0rem 0rem 0.5rem 0rem">
																	{val?.body}
																</Text>
																<Text type="xsmall">{moment(val?.createdAt).fromNow(true)} ago </Text>
															</div>
														</NotificationContent>
													)
												})}
												{notifications?.length === 0 && (
													<NotificationContent direction="row" gap={1} align="center" justify="center">
														<Text isCentered type="xsmall">
															You haven't received any notifications yet.
														</Text>
													</NotificationContent>
												)}
											</DropContent>
										)}
									</NotificationMenu> */}
									{_loginUserStatus === 'admin' ? (
										<Settings
											onClick={() => {
												_navigate('/organization')
											}}>
											<RiSettings5Line fontSize={windowSize?.width < 575 ? '1.4rem' : '1.5rem'} />
										</Settings>
									) : null}
									<MenuListProfile
										ref={_userProfileRef}
										type="normal"
										active={pathname === '/'}
										scroll={scrollPosition}
										path={pathname === '/'}
										onMouseEnter={() => {
											setOpenNotificationList(false)
										}}
										onClick={() => {
											setOpenUserProfileDrop(!openUserProfileDrop)
										}}>
										<UserProfile direction="row" align="center" gap={0.5}>
											<ProfileImg>
												{_loginUserStatus === 'admin' ? (
													<>{_loginAdminDetails?.logo ? <img src={process.env.REACT_APP_IMAGE_URL + _loginAdminDetails?.logo} alt="i" /> : <img src={'/images/default_user_profile_img.png'} alt="i" />}</>
												) : (
													<>
														{_loginUserDetails?.image ? (
															<img src={process.env.REACT_APP_IMAGE_URL + _loginUserDetails?.image} alt="i" />
														) : (
															<>
																<img src={'/images/default_user_profile_img.png'} alt="i" />
															</>
														)}
													</>
												)}
											</ProfileImg>
											{openUserProfileDrop && (
												<DropContent right={'0'} top={4} width={'20rem'}>
													<DropHeader gap={0.5} direction="row" align="center" justify="space-between">
														<Text type="normal" fontWeight={600} color="black">
															{CommonEnum.AppName}
														</Text>
														<Flexed
															gap={0.5}
															direction="row"
															align="center"
															onClick={() => {
																sessionStorage.removeItem('authorization')
																sessionStorage.removeItem('userStatus')
																sessionStorage.removeItem('employeeId')
																_dispatch(loginUserDetailsAction({}))
																_dispatch(loginAdminDetailsAction({}))
																_navigate('/login')
															}}>
															Logout
															<RiLogoutBoxRFill fontSize="1rem" />
														</Flexed>
													</DropHeader>
													<ProfileMenuHeader>
														<ProfileImg>
															{_loginUserStatus === 'admin' ? (
																<>
																	{_loginAdminDetails?.logo ? (
																		<img src={process.env.REACT_APP_IMAGE_URL + _loginAdminDetails?.logo} alt="i" />
																	) : (
																		<Text color="white" fontWeight="bold" type="small">
																			{_loginAdminDetails?.companyName && _loginAdminDetails?.companyName[0].toUpperCase()}
																		</Text>
																	)}
																</>
															) : (
																<>
																	{_loginUserDetails?.image ? (
																		<img src={process.env.REACT_APP_IMAGE_URL + _loginUserDetails?.image} alt="i" />
																	) : (
																		<>
																			{/* <Text color="white" fontWeight="bold" type="small">
																				{_loginUserDetails?.firstName && _loginUserDetails?.firstName[0].toUpperCase()}
																			</Text> */}
																			<img src={'/images/default_user_profile_img.png'} alt="i" />
																		</>
																	)}
																</>
															)}
														</ProfileImg>
														<div>
															<Text type="small" fontWeight={600} color="black_3">
																{_loginUserStatus === 'admin' ? _loginAdminDetails?.companyName : _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName}
																{/* {_loginAdminDetails?.companyName} */}
															</Text>
															<Text fontSize={0.688} color="black_7">
																{_loginUserStatus === 'admin' ? _loginAdminDetails?.email : _loginUserDetails?.email}
															</Text>
														</div>
													</ProfileMenuHeader>
													<Divider />
													<ProfileMenuList
														onClick={() => {
															_navigate('/change-password')
														}}>
														<LiaThListSolid fontSize="1rem" />
														Change Password
													</ProfileMenuList>

													<ProfileMenuList
														onClick={() => {
															_navigate('/dashboard')
														}}>
														<RiSettings5Line fontSize="1rem" />
														Privacy Policy
													</ProfileMenuList>

													<ProfileMenuList
														onClick={() => {
															_navigate('/dashboard')
														}}>
														<RiSettings5Line fontSize="1rem" />
														Terms of Service
													</ProfileMenuList>

													<ProfileMenuList
														onClick={() => {
															_navigate('/dashboard')
														}}>
														<RiSettings5Line fontSize="1rem" />
														Security Compliance
													</ProfileMenuList>
												</DropContent>
											)}
										</UserProfile>
									</MenuListProfile>
								</>
							) : (
								<AuthFlex direction="row" gap={1} align="center">
									<Link to="/feature">
										<Text type="small" fontWeight={600} color={pathname == '/feature' ? 'purple_1' : 'black'}>
											Feature
										</Text>
									</Link>
									<Button
										medium={windowSize?.width > 768}
										small={windowSize?.width < 768}
										label={'login & SignUp'}
										type="red"
										ifClicked={() => {
											_navigate('/login')
										}}
									/>
								</AuthFlex>
							)}
						</ActionMenu>
					</CustomCol>
				</Row>
			</Container>
		</Main>
	)
}
const Main = styled.div<any>`
	background: ${palette.silver_3};
	position: sticky;
	top: 0;
	z-index: 5;
	width: 100%;
`

const CustomCol = styled(Col)`
	display: flex;
	height: 3.6rem;
	justify-content: space-between;
	align-items: center;
`

const Logo = styled.img`
	height: 2.5rem;
`

const ActionMenu = styled(Flexed)`
	${media.xs`gap:0.7rem`};
	${media.sm`gap:0.7rem`};
	${media.md`gap:0.7rem`};
`
const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`

const DropContent = styled.div<any>`
	margin-top: 0.3rem;
	position: absolute;
	background-color: ${palette.white};
	min-width: ${({width}) => (width ? width : 'max-content')};
	right: ${({right}) => (right ? `${right}rem` : 'auto')};
	top: ${({top}) => (top ? `${top}rem` : 'auto')};
	box-shadow: ${palette.shadow};
	z-index: 5;
	border-radius: 0.5rem;
	overflow: hidden;
	animation: ${slideInFromRight} 0.9s ease;
`

const Arrow = styled(FaAngleDown)<any>`
	transition: transform 0.2s;
	transform: rotate(0deg);
`

const MenuList = styled(Text)<IProps>`
	position: relative;
	color: ${palette.dark_gray};
	letter-spacing: 0.05em;
	font-weight: 600;
	cursor: pointer;
	&:after {
		content: '';
		display: block;
		margin: 0 auto;
		width: 70%;
		margin-top: 0.35rem;
	}
	& ${Arrow} {
		color: ${palette.gray};
	}

	&:hover {
		color: ${palette.black};
	}
	${media.xs`font-size: 0.9rem`};
	${media.sm`font-size: 1rem`};
	${media.md`font-size: 1rem`};
`

const MenuListProfile = styled(MenuList)`
	padding-right: 0rem !important;
`

const UserProfile = styled(Flexed)`
	cursor: pointer;
	padding: 0.5rem;
	height: 4rem;
	border-radius: 0.5rem;
	&:hover {
		background: ${palette.silver_4};
	}
`

const ProfileImg = styled.div<any>`
	position: relative;
	width: 2.188rem;
	height: 2.188rem;
	border-radius: 100%;
	overflow: hidden;
	background: ${palette.purple_1};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	& > img {
		width: 2.188rem;
		height: 2.188rem;
		border-radius: 3rem;
		object-fit: cover;
	}
`

const ProfileMenuHeader = styled.div<any>`
	display: flex;
	gap: 0.5rem;
	padding: 0.8rem;
`

const DropHeader = styled(Flexed)`
	padding: 0.5rem 0.8rem;
`

const ProfileMenuList = styled(Text)<any>`
	color: ${palette.purple_1};
	font-size: 0.875rem;
	padding: 0.5rem 1rem 0.5rem 0.8rem;
	text-decoration: underline;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	&:hover {
		background-color: ${palette.silver_4};
		color: ${palette.black};
	}
`

const NotificationMenu = styled.div<any>`
	position: relative;
	background-color: ${({active}) => (active ? palette.red_light : '')};
	color: ${palette.black};
	&:hover {
		background-color: ${palette.light_grey};
		color: ${palette.black};
	}
	padding: 0.3rem;
	border-radius: 100%;
	cursor: pointer;
	& #notificationMenuContent {
		display: block !important;
		min-width: 20rem;
		right: -4rem;
		top: 3.1rem;
		max-height: 23rem;
		overflow: auto;
	}
	@media screen and (min-width: 0px) and (max-width: 575px) {
		& #notificationMenuContent {
			right: -2rem;
			min-width: 16rem;
		}
	}
`
const Settings = styled.div<any>`
	position: relative;
	background-color: ${({active}) => (active ? palette.red_light : '')};
	color: ${palette.black};
	&:hover {
		background-color: ${palette.light_grey};
		color: ${palette.black};
	}
	padding: 0.3rem;
	border-radius: 100%;
	cursor: pointer;
`
const CompanyName = styled.div<any>`
	position: relative;
	background-color: ${({active}) => (active ? palette.red_light : '')};
	color: ${palette.purple_1};
	&:hover {
		background-color: ${palette.light_grey};
	}
	padding: 0.9rem;
	border-radius: 10%;
	cursor: pointer;
	font-weight: 500;
`
const NotificationsCounts = styled(Flexed)`
	position: absolute;
	top: -1px;
	right: -1px;
	padding: 0.1rem;
	background: ${palette.red};
	color: ${palette.white};
	font-size: 0.7rem;
	font-weight: 600;
	width: 1.3rem;
	height: 1.3rem;
	border-radius: 100%;
`

const NotificationContent = styled(Flexed)<any>`
	position: relative;
	background-color: ${({active}) => (active ? '#074ed117' : '')};
	&:not(:last-child) {
		border-bottom: 1px solid ${palette.silver};
	}
	&:hover {
		background-color: #074ed117;
	}
	padding: 1rem;
	cursor: pointer;
`

const AllAds = styled(Text)<any>`
	color: ${palette.black};
	cursor: pointer;
	&:hover {
		color: ${palette.red_hover};
	}
	text-decoration: underline;
	text-underline-offset: 3px;
	${media.xs`font-size: 0.75rem`};
	${media.sm`font-size: 1rem`};
	${media.md`font-size: 1rem`};
`

const AuthFlex = styled(Flexed)`
	${media.xs`margin-left: 0.5rem`};
	& button {
		${media.xs`min-width: 5rem; padding:0rem;`}
		${media.sm`min-width: 6rem; padding:0.65rem 1rem;`}
	${media.md`min-width: 6rem; padding:0.65rem 1rem;`};
	}
`

export default Header
