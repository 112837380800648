import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text, Spacer} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BiUserPlus} from 'react-icons/bi'
import {FaRegClock} from 'react-icons/fa'
import {MdAlternateEmail} from 'react-icons/md'
import {BsEmojiSmile, BsThreeDots} from 'react-icons/bs'
import {IoMdClose} from 'react-icons/io'
import AssignPop from './AssignPop'
import MentionMemberPop from './MentionMemberPop'
import EmojiPop from './EmojiPop'
import DatePop from './DatePop'
import CheckBoxItemActionPop from './CheckBoxItemActionPop'
import {Checked, CheckIconWrapper, UnChecked} from '../../pages/Auth/SignUp'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {ProfileImg, TagUser} from './AddMemberPop'
import {getInitials} from '../../utils'
import moment from 'moment'
import {useDispatch} from 'react-redux'
import {updateBoardList} from '../../actions/authActions'

const CheckBoxList = ({subList, loadAgain, cardMembers, boardMembers, cardDetails}: {subList?: any; loadAgain?: any; cardMembers?: any; boardMembers?: any; cardDetails?: any}) => {
	const _assignPopRef: any = useRef(null)
	const _datePopRef: any = useRef(null)
	const _assignMentionMemberRef: any = useRef(null)
	const _assignEmojiRef: any = useRef(null)
	const _itemActionPopRef: any = useRef(null)
	const [isChecked, setIsChecked] = useState(false)
	const [openAssignPop, setOpenAssignPop] = useState(false)
	const [openDatePop, setOpenDatePop] = useState(false)
	const [openMentionMemberPop, setOpenMentionMemberPop] = useState(false)
	const [openEmojiPop, setOpenEmojiPop] = useState(false)
	const [openItemActionPop, setOpenItemActionPop] = useState(false)
	const [isEditCheckBoxList, setIsEditCheckBoxList] = useState(false)
	const [name, setName] = useState<any>('')

	const dispatch = useDispatch()

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_assignPopRef?.current && !_assignPopRef?.current?.contains(event.target)) {
				setOpenAssignPop(false)
			}
			if (_datePopRef?.current && !_datePopRef?.current?.contains(event.target)) {
				setOpenDatePop(false)
			}
			if (_assignMentionMemberRef?.current && !_assignMentionMemberRef?.current?.contains(event.target)) {
				setOpenMentionMemberPop(false)
			}
			if (_assignEmojiRef?.current && !_assignEmojiRef?.current?.contains(event.target)) {
				setOpenEmojiPop(false)
			}
			if (_itemActionPopRef?.current && !_itemActionPopRef?.current?.contains(event.target)) {
				setOpenItemActionPop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		// setIsEditCheckBoxList(subList?.isCheck)
		setName(subList?.name)
		setIsChecked(subList?.isCheck)
	}, [subList])

	const updateCheckList = async (ischeck = false) => {
		try {
			await api
				.put(`/trello/update_checklist/${subList?.id}`, {name: name, isCheck: ischeck})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
					}
					setIsEditCheckBoxList(false)
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const deleteCheckList = async (id = '') => {
		try {
			await api
				.delete(`/trello/delete_checklist/${id}`)
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const addCard = async () => {
		try {
			await api
				.post(`/trello/create_card`, {title: subList?.name, boardId: cardDetails?.boardId, listId: cardDetails.listId, checklistId: subList?.id})
				.then((response) => {
					if (response?.data?.success == true) {
						dispatch(updateBoardList(true))
						loadAgain && loadAgain()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	return (
		<>
			<Flexed direction="row" align={isEditCheckBoxList ? 'flex-start' : 'center'} margin={'0rem 0rem 0.5rem 0rem'}>
				<Icon>
					<CheckIconWrapper>
						{isChecked ? (
							<Checked
								onClick={() => {
									setIsChecked(!isChecked)
									updateCheckList(!isChecked)
								}}
							/>
						) : (
							<UnChecked
								color="rgb(221 223 229)"
								onClick={() => {
									setIsChecked(!isChecked)
									updateCheckList(!isChecked)
								}}
							/>
						)}
					</CheckIconWrapper>
				</Icon>
				<Wrapper isEditCheckBoxList={isEditCheckBoxList}>
					{isEditCheckBoxList && <Input placeholder="Add an item" type="textarea" rows={2} value={name} onChange={(e: any) => setName(e?.target?.value)} />}
					<Flexed margin={isEditCheckBoxList && '0.5rem 0rem 0rem 0rem'} direction="row" justify="space-between" align="center" gap={0.5}>
						{isEditCheckBoxList ? (
							<Flexed direction="row" gap={0.5} align="center">
								<Button
									onClick={() => {
										if (name?.trim()?.length) {
											updateCheckList()
										}
									}}>
									Save
								</Button>
								<IoMdClose cursor="pointer" onClick={() => setIsEditCheckBoxList(false)} />
							</Flexed>
						) : isChecked ? (
							<CheckedText
								onClick={() => {
									setIsEditCheckBoxList(true)
								}}>
								{' '}
								{subList?.name}
							</CheckedText>
						) : (
							<CheckText
								onClick={() => {
									setIsEditCheckBoxList(true)
								}}
								type="small">
								{subList?.name}
							</CheckText>
						)}
						<CustomFlex isEditCheckBoxList={isEditCheckBoxList} direction="row" align="center" gap={!isEditCheckBoxList && 0.3}>
							{subList?.taskChecklistMembers?.map((e: any) => (
								<Flexed direction="row" gap={0.5}>
									{e?.employee ? (
										<>
											{e?.employee?.image ? (
												<ProfileImg>
													<img src={process.env.REACT_APP_IMAGE_URL + e?.employee?.image} alt={e?.employee?.firstName + ' ' + e?.employee?.lastName} />
												</ProfileImg>
											) : (
												<TagUser width="1.8rem" height="1.8rem">
													{e?.employee?.firstName + ' ' + e?.employee?.lastName && getInitials(e?.employee?.firstName + ' ' + e?.employee?.lastName)}
												</TagUser>
											)}
										</>
									) : e?.adminId ? (
										<>
											{e?.admin?.logo ? (
												<ProfileImg>
													<img src={process.env.REACT_APP_IMAGE_URL + e?.admin?.logo} alt={e?.admin?.name} />
												</ProfileImg>
											) : (
												<TagUser width="1.8rem" height="1.8rem">
													{e?.admin?.name && getInitials(e?.admin?.name)}
												</TagUser>
											)}
										</>
									) : (
										''
									)}
								</Flexed>
							))}
							<div ref={_assignPopRef} onClick={() => setOpenAssignPop(!openAssignPop)}>
								<Link direction="row" gap={0.1} align="center" justify="center" small={!isEditCheckBoxList}>
									<BiUserPlus fontSize={18} /> {isEditCheckBoxList ? 'Assign' : ''}
								</Link>
								{openAssignPop && <AssignPop onCloseModal={() => setOpenAssignPop(false)} cardMembers={cardMembers} checklistDetails={subList} loadAgain={loadAgain} boardMembers={boardMembers} />}
							</div>
							<div ref={_datePopRef} onClick={() => setOpenDatePop(!openDatePop)}>
								<DueDateLink direction="row" gap={0.2} align="center" justify="center" small={!isEditCheckBoxList} dueDate={subList?.dueDate} isSameDay={moment().isSame(subList?.dueDate, 'day')} isComplete={subList?.isCheck}>
									<FaRegClock fontSize={14} />
									<div style={{fontSize: '0.7rem'}}> {isEditCheckBoxList ? (subList?.dueDate ? moment(subList?.dueDate).format('DD MMM') : 'Due date') : subList?.dueDate ? moment(subList?.dueDate).format('DD MMM') : ''}</div>
								</DueDateLink>
								{openDatePop && <DatePop bottom="-7rem" onCloseModal={() => setOpenDatePop(false)} loadAgain={loadAgain} subList={subList} />}
							</div>
							{isEditCheckBoxList && (
								<div ref={_assignMentionMemberRef} onClick={() => setOpenMentionMemberPop(!openMentionMemberPop)}>
									<Link direction="row" align="center" justify="center">
										<MdAlternateEmail fontSize={14} />
									</Link>
									{openMentionMemberPop && <MentionMemberPop onCloseModal={() => setOpenMentionMemberPop(false)} />}
								</div>
							)}
							{isEditCheckBoxList && (
								<div ref={_assignEmojiRef} onClick={() => setOpenEmojiPop(!openEmojiPop)}>
									<Link direction="row" align="center" justify="center">
										<BsEmojiSmile fontSize={14} />
									</Link>
									{openEmojiPop && <EmojiPop onCloseModal={() => setOpenEmojiPop(false)} />}
								</div>
							)}
							<div ref={_itemActionPopRef} onClick={() => setOpenItemActionPop(!openItemActionPop)}>
								<Link direction="row" align="center" justify="center" small={!isEditCheckBoxList}>
									<BsThreeDots fontSize={14} />
								</Link>
								{openItemActionPop && <CheckBoxItemActionPop onCloseModal={() => setOpenItemActionPop(false)} onDelete={() => deleteCheckList(subList?.id)} onAddCard={() => addCard()} />}
							</div>
						</CustomFlex>
					</Flexed>
				</Wrapper>
			</Flexed>
		</>
	)
}

const Icon = styled.span`
	font-size: 1.3rem;
	color: black;
	width: 2rem;
`

const CustomFlex = styled(Flexed)`
	position: relative;
	display: ${({isEditCheckBoxList}) => (isEditCheckBoxList ? 'flex' : 'none')};
`

const Wrapper = styled(Flexed)`
	width: 100%;
	background: ${({isEditCheckBoxList}) => (isEditCheckBoxList ? palette.silver_14 : '')};
	justify-content: ${({isEditCheckBoxList}) => (isEditCheckBoxList ? '' : 'center')};
	padding: 0.4rem;
	border-radius: 0.5rem;
	cursor: pointer;
	min-height: 2.599rem;
	&:hover {
		background: ${palette.silver_14};
	}
	&:hover ${CustomFlex} {
		display: flex;
	}
`

const CheckedText = styled.del`
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.125rem;
	color: ${palette.dark_gray};
	text-align: left;
	opacity: 1;
	letter-spacing: 0.02em;
	width: 100%;
	word-break: break-all;
`
const CheckText = styled(Text)`
	width: 100%;
	word-break: break-all;
`

const Input = styled.textarea<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
	outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
	&:focus {
		box-shadow: inset 0 0 0 2px var(--ds-border-input, ${palette.purple_1});
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({cancel}) => (cancel ? palette.black : palette.white)};
	background: ${({cancel}) => (cancel ? palette.white : palette.purple_1)};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		color: ${({cancel}) => (cancel ? palette.black : palette.white)};
		background: ${({cancel}) => (cancel ? palette.silver_14 : palette.purple_2)};
	}
`

const Link = styled(Flexed)<any>`
	border: none;
	border-radius: ${({small}) => (small ? '100%' : '0.25rem')};
	padding: ${({small}) => (small ? '0.2rem' : '0.65rem')};
	font-size: 0.875rem;
	width: ${({small}) => (small ? '1.8rem' : 'auto')};
	height: ${({small}) => (small ? '1.8rem' : '2rem')};
	background: ${({small, isSameDay, isComplete}) => (isComplete ? palette.green : isSameDay ? palette.yellow : small ? palette.silver_14 : '')};
	color: ${({small, isSameDay, isComplete}) => (isComplete || isSameDay ? palette.white : palette.black)};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	position: relative;
	&:hover {
		background: ${({small, isSameDay, isComplete}) => (isSameDay || isComplete ? '' : small ? palette.silver_2 : palette.silver_14)};
	}
`

export const DueDateLink = styled(Flexed)<any>`
	border: none;
	border-radius: ${({small, dueDate}) => (dueDate ? '0.25rem' : small ? '100%' : '0.25rem')};
	padding: ${({small, dueDate}) => (dueDate ? '0.65rem' : small ? '0.2rem' : '0.65rem')};
	font-size: 0.875rem;
	width: ${({small, dueDate}) => (dueDate ? '5rem' : small ? '1.8rem' : 'auto')};
	height: ${({small, dueDate}) => (dueDate ? '2rem' : small ? '1.8rem' : '2rem')};
	background: ${({small, isSameDay, isComplete}) => (isComplete ? palette.greenDark : isSameDay ? palette.yellow : small ? palette.silver_14 : '')};
	color: ${({small, isSameDay, isComplete}) => (isComplete || isSameDay ? palette.white : palette.black)};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	position: relative;
	&:hover {
		background: ${({small, isSameDay, isComplete}) => (isSameDay || isComplete ? '' : small ? palette.silver_2 : palette.silver_14)};
	}
`

export default CheckBoxList
