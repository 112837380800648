import React, {useState} from 'react'
import styled from 'styled-components'
import {AuthFilledButton, Flexed, Loading, Spacer, Text} from '../../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import InputFieldAuth from '../../components/common/InputFieldAuth'
import {useNavigate} from 'react-router-dom'
import {palette} from '../../styled/colors'
import {BsCheckSquareFill} from 'react-icons/bs'
import {ImCheckboxUnchecked} from 'react-icons/im'
import useWindowSize from '../../hooks/useWindowSize'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
const _loginUserStatus: any = sessionStorage.getItem('userStatus')

const ChangePassword = () => {
	let _navigate = useNavigate()
	const _windowSize = useWindowSize()
	const [isLoading, setIsLoading] = useState(false)
	const [password, setPassword] = useState('')
	const [currentPassword, setCurrentPassword] = useState('')
	const [currentPasswordError, setCurrentPasswordError] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const [passwordError, setPasswordError] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState('')
	const doResetPassword = async () => {
		if (formValidation()) {
			setIsLoading(true)
			await api
				.put(`/admin/update_password`, {
					password: currentPassword,
					newPassword: password
				})
				.then(async (res) => {
					setIsLoading(false)
					if (res?.data?.success) {
						toast.success('Password updated successfully.')
						_navigate('/dashboard')
					} else {
						toast.error(res?.data?.message)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}
	const doResetEmployeePassword = async () => {
		if (formValidation()) {
			setIsLoading(true)
			await api
				.put(`/employee/update_password`, {
					password: currentPassword,
					newPassword: password
				})
				.then(async (res) => {
					setIsLoading(false)
					if (res?.data?.success) {
						toast.success('Password updated successfully.')
						_navigate('/dashboard')
					} else {
						toast.error(res?.data?.message)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (currentPassword === '') {
			setCurrentPasswordError('Current password required.')
			isValid = false
		}
		if (password === '') {
			setPasswordError('Password must be at least 8 characters.')
			isValid = false
		}
		if (confirmPassword === '') {
			setConfirmPasswordError('Password must match.')
			isValid = false
		}
		if (password?.trim().length < 7) {
			setPasswordError('Password must be at least 8 characters.')
			isValid = false
		}
		if (password != confirmPassword) {
			setConfirmPasswordError('Password must match.')
			isValid = false
		} else {
			setConfirmPasswordError('')
		}
		return isValid
	}

	return (
		<>
			<MainWrapper>
				<Flexed direction="row" justify="center">
					<LoginContent>
						<Wrapper>
							<InnerWrapper>
								<Row justifyContent="center">
									<Col lg={10}>
										<Text fontSize={_windowSize?.width < 600 ? 1.5 : 1.875} lineHeight={2} fontWeight={600} color="black_3">
											Password
										</Text>
										<Spacer height={1} />
										<Text fontSize={0.813} color="black_7">
											Now you can change your password here.
										</Text>
										<Spacer height={1.8} />
										<InputFieldAuth
											label="Current Password"
											required
											type="password"
											value={currentPassword}
											error={currentPasswordError}
											handleChange={(e: any) => {
												setCurrentPasswordError('')
												if (e === '') {
													setCurrentPasswordError('Password is required.')
												}
												setCurrentPassword(e)
											}}
										/>
										<Spacer height={0.8} />
										<InputFieldAuth
											label="New Password"
											required
											type="password"
											value={password}
											error={passwordError}
											handleChange={(e: any) => {
												setPasswordError('')
												if (e === '') {
													setPasswordError('Password is required.')
												}
												setPassword(e)
											}}
										/>
										<Spacer height={0.8} />
										<InputFieldAuth
											label="Confirm Password"
											required
											type="password"
											value={confirmPassword}
											error={confirmPasswordError}
											handleChange={(e: any) => {
												if (e?.length < 7) {
													setConfirmPasswordError('Password must be at least 8 characters.')
												} else if (e !== password) {
													setConfirmPasswordError('Password must match.')
												} else {
													setConfirmPasswordError('')
												}
												setConfirmPassword(e)
											}}
										/>

										<Spacer height={2} />
										<Flexed direction="row" gap={1}>
											<AuthBackButton
												onClick={() => {
													_navigate('/dashboard')
												}}>
												Cancel
											</AuthBackButton>
											<AuthFilledButton onClick={_loginUserStatus === 'admin' ? doResetPassword : doResetEmployeePassword}>Change Password</AuthFilledButton>
										</Flexed>
									</Col>
								</Row>
							</InnerWrapper>
						</Wrapper>
					</LoginContent>
				</Flexed>
			</MainWrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled.div`
	height: 100vh;
`

const LoginContent = styled.div`
	width: 100%;
	height: 100vh;
	background: url(/images/white_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	${media.sm`width: 80%;`};
	${media.md`width: 60%;`};
	${media.lg`width: 50%;`};
`

const Wrapper = styled.div`
	position: relative;
	background: #ffffffcc;
	height: 100%;
	display: flex;
	${media.lg` align-items: center;`};
`

const InnerWrapper = styled(Container)`
	margin-top: 2rem;
	margin-bottom: 0rem;
	${media.sm`margin-top: 4rem;`}
	${media.md`margin-top: 6rem;`}
    ${media.lg`margin-bottom: 6rem; margin-top: 0rem;`}
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.8rem;
	${media.lg`  grid-template-columns: 1fr 1fr;`};
`

const ImageContent = styled.div`
	width: 50%;
	display: none;
	${media.lg`display: block`};
`

const CoverImage = styled.img`
	height: 100vh;
	width: 100%;
	object-fit: cover;
	object-position: bottom;
`

const CustomText = styled(Text)`
	bottom: 3rem;
	left: 0;
	right: 0;
	text-align: center;
	& span {
		color: ${palette.purple_1};
		cursor: pointer;
	}
	& span:hover {
		color: ${palette.blue_1};
		cursor: pointer;
		text-decoration: underline;
		text-decoration-thickness: 1px;
		text-underline-offset: 1px;
	}
`

const CheckIconWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	cursor: pointer;
	width: 1.25rem;
`

const Checked = styled(BsCheckSquareFill)<any>`
	color: ${palette.purple_1};
	pointer-events: ${({disabled}) => (disabled ? 'none' : '')};
`
const UnChecked = styled(ImCheckboxUnchecked)<any>`
	color: ${palette.black_7};
	pointer-events: ${({disabled}) => (disabled ? 'none' : '')};
`
const AuthBackButton = styled(AuthFilledButton)`
	padding: 13px 46px;
	background: #fb7979;
	border-radius: 1px;
	font-size: 16px;
	border: 0;
	color: ${palette.white};
	font-weight: 500;
	width: max-content;
	max-width: unset;
	box-shadow: #00acac26 0 4px 12px;
	cursor: pointer;
`

export default ChangePassword
