export const palette: any = {
	white: '#ffffff',
	black: '#141414',
	black_1: '#000000',
	black_2: '#253556',
	black_3: '#333333',
	black_4: '#444444',
	black_5: '#555',
	black_7: '#777777',

	purple_1: '#00acac',
	purple_2: '#05b6b6',

	silver_1: '#eee',
	silver_2: '#d9d9d9',
	silver_3: '#f0f0f0',
	silver_4: '#f5f5f5',
	silver_5: '#fafafa',
	silver_6: '#cccccc',
	silver_7: '#797979',
	silver_8: '#989898',
	silver_9: '#f9f9f9',
	silver_10: '#fcfcfc',
	silver_11: '#efefef',
	silver_12: '#4a4a4a',
	silver_13: '#ccc',
	silver_14: '#091e420f',
	silver_15: '#f7f8f9',
	silver_16: '#dfe1e6',

	see_blue: '#455767e6',
	gray_1: '#f7f7f7',
	gray_2: '#f4f4f4',
	gray_3: '#172b4d29',
	gray_4: '#00000029',
	gray_5: '#00000014',
	gray_6: '#091e4224',

	transparent_1: '#00000094',
	transparent_2: '#a6c5e229',
	transparent_3: '#00000075',

	green_1: '#36d493',

	blue_1: '#4285f4',
	blue_2: '#172b4d',
	blue_3: '#44546f',

	light_black: '#232323',
	dark_gray: '#434343',
	light_gray: '#DCDCDC',
	blue: '#1366ff',
	blue_hover: '#074ed1',
	blue_light_hover: '#074ed124',
	aqua_blue: '#00BCDD',
	golden: '#efb300',
	success: '#0e7b2f',
	gray: '#5C6B73',
	gray_300: '#f3f3f3',
	gray_400: '#9f9f9f',
	silver: '#E1E1E1',
	light_silver: '#434343',
	danger: '#ff0000',
	danger_2: '#d9534f',
	danger_hover: '#e3311b',
	danger_light_hover: '#e3311b4a',
	// red: '#E00000',
	red: '#fe5b60',
	red_hover: '#f94e53',
	red_hover_text: 'rgb(242, 61, 61)',
	red_light: '#fff6f5',
	red_light_hover: 'rgba(255, 218, 212, 0.8)',
	light_grey: '#f5f5f5;',
	green: '#1dad18',
	green_light: '#eef8ed',
	green_light_hover: 'rgba(67, 187, 63, 0.2)',
	yellow: 'rgb(255, 206, 50)',
	vegan: '#4d7c1b',
	hot: '#E00000',
	recommended: '#03a9f4',
	popular: '#e67400',
	opacity: {
		white: '#ffffff2b'
	},
	whatsApp: '#43BD47',
	twitter: '#00acee',
	mail: '#38B000',
	linkedIn: '#2595EE',
	faceBook: '#344D8C',
	shadow: '0px 4px 10px rgba(0, 0, 0, 0.10)',
	shadowHover: '0px 4px 10px rgba(0, 0, 0, 0.30)',
	main_cover_overlay_light: 'rgb(0 0 0 / 40%)',
	main_cover_overlay_dark: 'rgb(0 0 0 / 65%)',
	swamp: '#1D1E31',
	greenDark: '#1F845A'
}

// Follow this light & dark theme setting.
//             Light Mode             Dark Mode
// SiteTheme	white			.	   light_black
// Heading    	black     		.      white
// Text   		dark_gray   	.      light_gray
// Border   	silver      	.      light_silver
// Icons		gray			.	   silver
