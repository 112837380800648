// @flow
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import PropTypes from 'prop-types'
import Column from './Column'
import reorder, {reorderQuoteMap} from '../reorder'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {Flexed} from '../../styled/shared'
import {IoMdAdd} from 'react-icons/io'
import {CgClose} from 'react-icons/cg'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import Loader from '../../components/common/Loader'
import SubNavBar from '../../components/taskManagementComponents/SubNavBar'
import SideMenuBar from '../../components/taskManagementComponents/SideMenuBar'
import {useSelector} from 'react-redux'
import useRouter from '../../hooks/router'

interface BoardProps {
	initial: any
	withScrollableColumns: boolean
	isCombineEnabled?: boolean
	onAddCard?: any
	boardDetails?: any
	filters?: any
}
const Board: React.FC<BoardProps> = ({isCombineEnabled, initial, useClone, containerHeight, withScrollableColumns, onAddCard, boardDetails, filters}: any) => {
	const [columns, setColumns] = useState<any>()
	const [ordered, setOrdered] = useState<any>([])
	const [openSideMenuBar, setOpenSideMenuBar] = useState<any>(false)
	const [showAddListContent, setShowAddListContent] = useState(false)
	const [listLable, setListLable] = useState('')
	const [loading, setLoading] = useState(false)
	const router: any = useRouter()
	const _updateTrelloList = useSelector<any>((state: any) => state.auth?.updateTrelloList)

	useEffect(() => {
		setOrdered(Object.keys(initial))
		setColumns(initial)
	}, [Object.keys(initial).length, _updateTrelloList, initial])

	const onDragEnd = (result: any) => {
		if (result.combine) {
			if (result.type === 'COLUMN') {
				const shallow = [...ordered]
				shallow.splice(result.source.index, 1)
				setOrdered(shallow)
				return
			}

			const column = columns[result.source.droppableId]
			const withQuoteRemoved = [...column]

			withQuoteRemoved.splice(result.source.index, 1)

			const orderedColumns = {
				...columns,
				[result.source.droppableId]: withQuoteRemoved
			}
			setColumns(orderedColumns)
			return
		}
		if (!result.destination) {
			return
		}

		const source = result.source
		const destination = result.destination

		if (source.droppableId === destination.droppableId && source.index === destination.index) {
			return
		}
		if (result.type === 'COLUMN') {
			const reorderedorder = reorder(ordered, source.index, destination.index).map((item) => String(item))
			reorderList({...result, source: {...source, index: source.index + 1}, destination: {...destination, index: destination.index + 1}, reorderedorder})
			setOrdered(reorderedorder)
			return
		}

		const data = reorderQuoteMap({
			quoteMap: columns,
			source,
			destination
		})

		if (result.type != 'COLUMN') {
			reorderCards({...result, source: {...source, index: source.index + 1}, destination: {...destination, index: destination.index + 1}, sourceCards: data.quoteMap[source.droppableId], destinationCards: data.quoteMap[destination.droppableId]})
		}

		//data reloaded again so that's why this commented
		setColumns(data.quoteMap)
	}

	const reorderCards = async (data = {}) => {
		try {
			await api
				.post(`/trello/reorder_cards`, {...data, boardId: router?.query?.id})
				.then((response) => {
					if (response?.data?.success == true) {
						onAddCard && onAddCard()
					}
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const reorderList = async (data = {}) => {
		try {
			await api
				.post(`/trello/reorder_list`, {...data, boardId: router?.query?.id})
				.then((response) => {
					if (response?.data?.success == true) {
						onAddCard && onAddCard()
						setListLable('')
					}
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const addList = async () => {
		try {
			setLoading(true)
			await api
				.post(`/trello/create_list`, {title: listLable, boardId: router?.query?.id})
				.then((response) => {
					if (response?.data?.success == true) {
						onAddCard && onAddCard()
						setListLable('')
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	return (
		<>
			{loading && <Loader />}
			<SubNavBar openSideMenu={() => setOpenSideMenuBar(true)} filters={filters} />
			<SideMenuBar open={openSideMenuBar} onClose={() => setOpenSideMenuBar(false)} boardDetails={boardDetails} />
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="board" type="COLUMN" direction="horizontal" ignoreContainerClipping={Boolean(containerHeight)} isCombineEnabled={isCombineEnabled}>
					{(provided) => (
						<Container ref={provided.innerRef} {...provided.droppableProps}>
							{ordered?.length
								? ordered.map((key: any, index: any) => (
										<Column key={key} index={index} title={key} quotes={columns[key]} isScrollable={withScrollableColumns} isCombineEnabled={isCombineEnabled} useClone={useClone} onAddCard={onAddCard} />
									))
								: ''}
							<div>
								<AddAnOtherList
									align={showAddListContent ? 'flex-start' : 'center'}
									gap={0.5}
									margin="1rem 1rem 0rem 0rem"
									onClick={() => {
										if (!showAddListContent) {
											setShowAddListContent(true)
										}
									}}>
									{showAddListContent ? (
										<>
											<Input
												placeholder="Enter list title"
												value={listLable}
												onChange={(e: any) => {
													setListLable(e?.target?.value)
												}}
											/>
											<Flexed gap={0.5} direction="row" align="center" justify="center">
												<Button
													onClick={() => {
														if (listLable?.trim()?.length > 0) {
															addList()
															setShowAddListContent(false)
														}
													}}>
													Add list
												</Button>
												<CgClose
													fontSize={20}
													cursor="pointer"
													onClick={() => {
														setShowAddListContent(false)
														setListLable('')
													}}
												/>
											</Flexed>
										</>
									) : (
										<Flexed gap={0.5} direction="row" align="center" justify="center">
											<IoMdAdd /> Add another list{' '}
										</Flexed>
									)}
								</AddAnOtherList>
							</div>
						</Container>
					)}
				</Droppable>
			</DragDropContext>
		</>
	)
}

Board.defaultProps = {
	isCombineEnabled: false
}

Board.propTypes = {
	isCombineEnabled: PropTypes.bool
}

const Container = styled.div`
	background-color: ${palette.silver_6};
	/* min-height: 100vh */
	min-width: 100%;
	// display: inline-flex;
	display: flex;
	overflow: auto;
	align-items: flex-start;
	gap: 1rem;
`

const AddAnOtherList = styled(Flexed)<any>`
	background-color: rgb(235, 236, 240);
	border-radius: 16px;
	font-size: 0.875rem;
	font-weight: 500;
	padding: 0.7rem 0.6rem;
	cursor: pointer;
	min-height: 44px;
	width: 250px;
	&:hover {
		background-color: ${palette.white};
	}
`

export const Input = styled.input<any>`
	width: 100%;
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.purple_1};
	font-weight: 600;
	cursor: pointer;
	width: 5rem;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
`

export default Board
