import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'
import InputFile from '../common/InputFile'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import FileUpload from '../common/FileUpload'

interface ICategoriesModalProps {
	onCloseModal: any
	setIsLoading: any
}

const ImportEmployeesModal = ({onCloseModal, setIsLoading}: ICategoriesModalProps) => {
	let _navigate = useNavigate()

	const [candidateOption, setCandidateOption] = useState([])
	const [candidate, setCandidate] = useState('')
	const [candidateId, setCandidateId] = useState<any>('')
	const [candidateDetail, setCandidateDetail] = useState<any>([])

	useEffect(() => {
		allCandidates()
	}, [])

	const allCandidates = () => {
		api.get('/employee/confirmed_candidate')
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data?.data?.data.length > 0) {
						const candidatesData = response?.data?.data?.data
						const arr = candidatesData.map((candidate: any) => ({
							label: `${candidate.firstName}  ${candidate?.lastName}`,
							value: candidate.id,
							data: candidate
						}))
						setCandidateOption(arr)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const createEmployee = () => {
		setIsLoading(true)
		api.post(`/employee/create`, {
			email: candidateDetail?.data?.email,
			firstName: candidateDetail?.data?.firstName,
			lastName: candidateDetail?.data?.lastName,
			qualification: candidateDetail?.data?.qualification,
			country: candidateDetail?.data?.country,
			state: candidateDetail?.data?.state,
			city: candidateDetail?.data?.city,
			zipCode: candidateDetail?.data?.zip,
			homePhone: candidateDetail?.data?.phone,
			mobile: candidateDetail?.data?.mobile,
			designation: candidateDetail?.data?.postTitle,
			candidateId: candidateDetail?.data?.id
		})
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.success == true) {
					toast.success(response?.data?.message)
					onCloseModal()
				} else {
					toast.error(response?.data?.message)
					onCloseModal()
				}
			})
			.catch((e: any) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					onCloseModal()
				}
			})
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
							Import Employee From Selected Candidates
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Spacer height={1} />
					<Body>
						<Container>
							<Row>
								<Col lg={12}>
									<DropDown
										label="Select Candidate"
										firstSelected={candidate}
										options={candidateOption}
										hasChanged={(value: any) => {
											let res: any = candidateOption.find((a: any) => a.value == value)
											setCandidateId(res?.value)
											setCandidate(res?.label)
											setCandidateDetail(res)
										}}
										dropDownListHeight="100px"
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Container>
					</Body>
					<Footer direction="row" align="center" justify="flex-end" gap={1}>
						<Button
							border="red"
							color="white"
							bg="red"
							bg_hover="red_hover"
							onClick={() => {
								onCloseModal()
							}}>
							Cancel
						</Button>
						<Button
							border="purple_2"
							color="white"
							bg="purple_1"
							bg_hover="purple_2"
							onClick={() => {
								createEmployee()
							}}>
							Add
						</Button>
					</Footer>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
	background-color: ${palette.gray_1};
	border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 0.5rem 1.5rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 600px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Footer = styled(Flexed)`
	border-top: 1px solid ${palette.silver};
	padding: 1rem 1.5rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({border}) => palette[border]};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

export default ImportEmployeesModal
