import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {IoIosArrowBack, IoIosArrowDown} from 'react-icons/io'
import {Text, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {RxActivityLog} from 'react-icons/rx'
import {IoMdClose} from 'react-icons/io'
import {RiArchiveLine} from 'react-icons/ri'
import {BiArrowBack, BiChevronLeft, BiLabel} from 'react-icons/bi'
import {IoIosSettings} from 'react-icons/io'
import {LuEye} from 'react-icons/lu'
import {MdContentCopy} from 'react-icons/md'
import {GoDotFill, GoShareAndroid} from 'react-icons/go'
import {FaMinus, FaRotateLeft} from 'react-icons/fa6'
import BoardBackgroundsModel from '../modals/BoardBackgroundsModel'
import {toast} from 'react-toastify'
import {api} from '../api/callAxios'
import {useDispatch} from 'react-redux'
import {updateBoard, updateBoardList} from '../../actions/authActions'
import {SearchIcon, SearchInput, SearchWapper} from './SubNavBar'
import QuoteItem from '../../dnd/styles/item'
import CardItems from './CardItems'
import {GiReturnArrow} from 'react-icons/gi'
import {useSelector} from 'react-redux'
import {TiTick} from 'react-icons/ti'
import {Input} from '../../dnd/board/Board'
import {CgClose} from 'react-icons/cg'
import {Icon} from '../trelloTaskModalComponents/MoveCard'
import {CheckIconWrapper, Checked, UnChecked} from '../../pages/Auth/SignUp'
import {colorsList} from '../../utils'
import Loader from '../common/Loader'
import DeleteCard from '../trelloTaskModalComponents/DeleteCard'
import useRouter from '../../hooks/router'
import ActivityLog from '../modals/ActivityLogs'
const TextEditor = require('../common/TextEditor').default

const SideMenuBar = ({open, onClose, boardDetails, loadAgain}: any) => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const [backgroundImages, setBackgroudImages] = useState<any[]>([])
	const [selectedBackground, setSelectedBackground] = useState<any>('/images/boardImages/image1.avif')
	const [selectedBackgroundColor, setSelectedBackgroundColor] = useState<any>('')
	const [backgroundsModel, setBackgroundsModel] = useState<any>(false)
	const [addCardDescription, setAddCardDescription] = useState(false)
	const [description, setDescription] = useState<any>('')
	const [loading, setLoading] = useState(false)
	const [boardDetail, setBoardDetails] = useState<any>('')
	const [acrhiveItemsPopup, setArchiveItemsPopup] = useState(false)
	const [acrhiveCards, setArchiveCards] = useState(true)
	const [acrhiveCardsData, setArchiveCardsData] = useState<any[]>([])
	const [archiveListsData, setArchiveListsData] = useState<any[]>([])
	const [isLoaded, setIsLoaded] = useState(true)
	const [copyBoard, setCopyBoard] = useState(false)
	const [boardLabel, setBoardLable] = useState<any>('')
	const [checkList, setChecklist] = useState<any>(true)
	const [closeBoard, setCloseBoard] = useState<any>(false)
	const router = useRouter()
	const [openActivityLogs, setOpenActivityLogs] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		let arr = []
		for (let i = 1; i <= 32; i++) {
			arr.push(`/images/boardImages/image${i}.avif`)
		}

		setBackgroudImages(arr)
		getBoardById(boardDetails?.id)
		setDescription(boardDetails?.description)
	}, [boardDetails, open])

	useEffect(() => {
		if (acrhiveCards && acrhiveItemsPopup) {
			getArchiveCards()
		} else if (!acrhiveCards && acrhiveItemsPopup) {
			getArchiveLists()
		}
	}, [boardDetails, acrhiveCards, acrhiveItemsPopup])

	const updateUserBoard = async (backgroundImage?: any, backgroundColor?: any) => {
		await api
			.put(`/trello/update_board/${boardDetails?.id}`, {backgroundColor: backgroundColor, backgroundImage: backgroundImage})
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						dispatch(updateBoard(true))
						setBackgroundsModel(false)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const updateBoardDetails = async (isCompleted = false) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_board/${boardDetails?.id}`, {description, isCompleted})
				.then((response) => {
					if (response?.data?.success == true) {
						setAddCardDescription(false)
						getBoardById(boardDetails?.id)
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const getArchiveCards = async (query = '') => {
		try {
			await api
				.post(`/trello/get_archive_cards`, {boardId: boardDetails?.id, query})
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							setArchiveCardsData(response?.data?.data)
						}
						setIsLoaded(false)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const getBoardById = async (id?: string, query = '') => {
		try {
			await api
				.get(`/trello/get_board?id=${id}`)
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							setDescription(response?.data?.data?.description)
							setBoardDetails(response?.data?.data)
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const removeCard = async (id?: any) => {
		try {
			setLoading(true)
			await api
				.delete(`/trello/delete_card?id=${id}`)
				.then((response) => {
					if (response?.data?.success == true) {
						getArchiveCards()
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const updateCardDetails = async (id?: any, isArchive = false) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_card/${id}`, {isArchive})
				.then((response) => {
					if (response?.data?.success == true) {
						getArchiveCards()
						dispatch(updateBoardList(true))
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const getArchiveLists = async (query = '') => {
		try {
			await api
				.post(`/trello/get_all_lists?id=${boardDetails?.id}&isArchive=${true}&query=${query}`)
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							setArchiveListsData(response?.data?.data)
						}
						setIsLoaded(false)
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const updatelistDetails = async (id?: any, isArchive = false) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_list?id=${id}`, {isArchive})
				.then((response) => {
					if (response?.data?.success == true) {
						getArchiveLists()
						dispatch(updateBoardList(true))
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const isMemberWatch = async (adminId?: any, employeeId?: any, isWatch?: any) => {
		try {
			await api
				.post(`/trello/watch_card`, {cardId: null, listId: null, boardId: boardDetails?.id, adminId, employeeId})
				.then((res: any) => {
					if (res?.data?.success == true) {
						getBoardById(boardDetails?.id)
						dispatch(updateBoard(true))
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const addBoard = async () => {
		try {
			setLoading(true)
			await api
				.post('/trello/create_board', {name: boardLabel, keepCards: checkList, boardId: boardDetail?.id, isCopy: true, backgroundColor: colorsList[Math.floor(Math.random() * colorsList.length)]})
				.then((response) => {
					if (response?.data?.success == true) {
						setCopyBoard(false)
						setBoardLable('')
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const closeCurrentBoard = async (isClosed = false) => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_board/${boardDetails?.id}`, {isClosed})
				.then((response) => {
					if (response?.data?.success == true) {
						router.navigate('/boards')
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const leaveCurrentBoard = async (isClosed = false) => {
		try {
			setLoading(true)
			await api
				.post(`/trello/leave_board`, {boardId: boardDetail?.id})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Leaved board successful')
						router.navigate('/boards')
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const sortedActivityLogs = boardDetail?.ActivityLogs?.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

	return (
		<SidebarContainer open={open}>
			{loading && <Loader />}
			{openActivityLogs ? (
				<Inner>
					<LogoWraper direction="row" justify="space-between" align="center">
						<BiChevronLeft
							fontSize={'1.5rem'}
							cursor={'pointer'}
							onClick={() => {
								setOpenActivityLogs(false)
							}}
						/>
						<Text type="small" fontWeight={600} color="blue_2">
							Activity Logs
						</Text>
						<IoMdClose
							cursor="pointer"
							color="black"
							onClick={() => {
								onClose()
								setOpenActivityLogs(false)
							}}
						/>
					</LogoWraper>
					<Flexed direction="column" align="start" gap={0.5} style={{padding: '1rem'}}>
						<ActivityLog activities={sortedActivityLogs} />
					</Flexed>
				</Inner>
			) : copyBoard ? (
				<Inner>
					<LogoWraper direction="row" justify="space-between" align="center">
						<BiChevronLeft
							fontSize={'1.5rem'}
							cursor={'pointer'}
							onClick={() => {
								setCopyBoard(false)
							}}
						/>
						<Text type="small" fontWeight={600} color="blue_2">
							Copy Board
						</Text>
						<IoMdClose
							cursor="pointer"
							color="black"
							onClick={() => {
								onClose()
								setCopyBoard(false)
							}}
						/>
					</LogoWraper>
					<Flexed direction="column" align="start" gap={0.5} style={{padding: '1rem'}}>
						<Input
							placeholder="Enter board title"
							value={boardLabel}
							onChange={(e: any) => {
								setBoardLable(e?.target?.value)
							}}
						/>

						<Flexed direction="row" align="center" gap={0.2}>
							<Icon>
								<CheckIconWrapper>
									{checkList ? (
										<Checked
											onClick={() => {
												setChecklist(!checkList)
											}}
										/>
									) : (
										<UnChecked
											color="rgb(221 223 229)"
											onClick={() => {
												setChecklist(!checkList)
											}}
										/>
									)}
								</CheckIconWrapper>
							</Icon>
							<Text type="xsmall" fontWeight={600}>
								Keep cards
							</Text>
						</Flexed>
						<Flexed gap={0.5} direction="row" align="center" justify="center">
							<Button
								onClick={() => {
									if (boardLabel?.trim()?.length > 0) {
										addBoard()
										setCopyBoard(false)
									}
								}}>
								Create
							</Button>
						</Flexed>
					</Flexed>
				</Inner>
			) : acrhiveItemsPopup ? (
				<Inner>
					<LogoWraper direction="row" justify="space-between" align="center">
						<BiChevronLeft
							fontSize={'1.5rem'}
							cursor={'pointer'}
							onClick={() => {
								setArchiveItemsPopup(false)
							}}
						/>
						<Text type="small" fontWeight={600} color="blue_2">
							Archive
						</Text>
						<IoMdClose
							cursor="pointer"
							color="black"
							onClick={() => {
								onClose()
								setArchiveItemsPopup(false)
							}}
						/>
					</LogoWraper>
					<InnerContent>
						<SearchWapper>
							<Flexed direction="row" align="center" gap={0.5}>
								<SearchInput placeholder="Search archive..." onChange={(e) => (acrhiveCards ? getArchiveCards(e.target.value) : getArchiveLists(e.target.value))} />
								<Button
									style={{width: 'max-content', padding: '0.1rem', fontSize: '0.8rem'}}
									cancel
									isDefault
									onClick={() => {
										setIsLoaded(true)
										setArchiveCards(!acrhiveCards)
									}}>
									{acrhiveCards ? `Switch to lists` : 'Switch to cards'}
								</Button>
							</Flexed>
						</SearchWapper>
					</InnerContent>
					{acrhiveCards ? (
						<div style={{overflow: 'scroll', height: '25rem'}}>
							{acrhiveCardsData?.length
								? acrhiveCardsData?.map((res: any) => {
										return (
											<div style={{padding: '0.5rem'}} key={res?.id}>
												<CardItems
													quote={{
														card_id: res?.id,
														...res,
														author: {
															id: res?.id?.toString(),
															name: res?.title,
															url: null,
															avatarUrl: null,
															colors: {
																soft: '#efb300',
																hard: '#797979'
															},
															...res
														}
													}}
												/>
												<Flexed direction="row" align={'center'} gap={0}>
													<Button onClick={() => updateCardDetails(res?.id)} cancel isDefault style={{width: 'fit-content'}}>
														Send to board
													</Button>
													<GoDotFill />
													<Button onClick={() => removeCard(res?.id)} cancel>
														Delete
													</Button>
												</Flexed>
											</div>
										)
									})
								: !isLoaded && <div>No card found</div>}
						</div>
					) : (
						<div style={{overflow: 'scroll', height: '25rem', padding: '0.4rem'}}>
							{archiveListsData?.length
								? archiveListsData?.map((res: any) => {
										return (
											<List direction="row" align="center" justify="space-between">
												<Text type="small" fontWeight={500} color="blue_2">
													{res?.title}
												</Text>

												<Button onClick={() => updatelistDetails(res?.id)} isDefault cancel style={{width: 'max-content'}}>
													<FaRotateLeft /> &nbsp; Send to board
												</Button>
											</List>
										)
									})
								: !isLoaded && <div>No list found</div>}
						</div>
					)}
				</Inner>
			) : (
				<Inner>
					<LogoWraper direction="row" justify="center" align="center">
						<Text type="small" fontWeight={600} color="blue_2">
							Menu
						</Text>
						<SideBarOpenCloseBtn
							direction="row"
							align="center"
							justify="center"
							onClick={() => {
								onClose()
								setAddCardDescription(false)
							}}>
							<IoMdClose cursor="pointer" color="black" />
						</SideBarOpenCloseBtn>
					</LogoWraper>
					<InnerContent>
						{!addCardDescription ? (
							<List direction="row" align="center" justify="space-between">
								<Flexed direction="row" gap={0.5}>
									<svg width="20" height="20" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
											fill="currentColor"></path>
										<path d="M11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11Z" fill="currentColor"></path>
										<path d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z" fill="currentColor"></path>
									</svg>
									<div onClick={() => setAddCardDescription(true)}>
										<Text type="small" fontWeight={500} color="blue_2">
											About this board
										</Text>
										<Text type="xsmall" fontWeight={500} color="blue_3">
											<span dangerouslySetInnerHTML={{__html: description}}></span>
										</Text>
									</div>
								</Flexed>
							</List>
						) : (
							''
						)}
						{addCardDescription ? (
							<Flexed direction="column" gap={0.5}>
								<TextEditor value={description} onChange={(e: any) => setDescription(e)} />

								<Flexed direction="row" gap={0.5}>
									<Button onClick={() => description && updateBoardDetails()}>Save</Button>
									<Button onClick={() => setAddCardDescription(false)} cancel>
										Cancel
									</Button>
								</Flexed>
							</Flexed>
						) : (
							''
						)}
						<List direction="row" align="center" justify="space-between" onClick={() => setOpenActivityLogs(true)}>
							<Flexed direction="row" align="center" gap={0.5}>
								<ActivityIcon fontSize={16} />
								<Text type="small" fontWeight={500} color="blue_2">
									Activity
								</Text>
							</Flexed>
						</List>
						<List direction="row" align="center" justify="space-between" onClick={() => setArchiveItemsPopup(true)}>
							<Flexed direction="row" align="center" gap={0.5}>
								<ArchiveLine fontSize={16} />
								<Text type="small" fontWeight={500} color="blue_2">
									Archived items
								</Text>
							</Flexed>
						</List>
						<Divider margin="0.5rem 0rem" />

						<List direction="row" align="center" justify="space-between" onClick={() => setBackgroundsModel(true)}>
							<Flexed direction="row" align="center" gap={0.5}>
								<ImageDiv imageUrl={boardDetails?.backgroundImage} backgroundColor={boardDetails?.backgroundColor}></ImageDiv>
								<Text type="small" fontWeight={500} color="blue_2">
									Change Background
								</Text>
							</Flexed>
						</List>
						{/* <List direction="row" align="center" justify="space-between">
						<Flexed direction="row" align="center" gap={0.5}>
							<svg width="20" height="20" role="presentation" focusable="false" viewBox="0 0 24 24">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6H3ZM4 16V12H20V16H4ZM2 12C2 10.8954 2.89543 10 4 10H20C21.1046 10 22 10.8954 22 12V16C22 17.1046 21.1046 18 20 18H4C2.89543 18 2 17.1046 2 16V12Z"
									fill="currentColor"></path>
							</svg>
							<Text type="small" fontWeight={500} color="blue_2">
								Custom Fields
							</Text>
						</Flexed>
					</List> */}
						{/* <List direction="row" align="center" justify="space-between">
							<Flexed direction="row" align="center" gap={0.5}>
								<BiLabelIcon fontSize={16} />
								<Text type="small" fontWeight={500} color="blue_2">
									Labels
								</Text>
							</Flexed>
						</List> */}
						<Divider margin="0.5rem 0rem" />
						<List direction="row" align="center" justify="space-between" onClick={() => isMemberWatch(_loginAdminDetails?.id ? _loginAdminDetails?.id : null, _loginUserDetails?.id ? _loginUserDetails?.id : null)}>
							<Flexed direction="row" align="center" gap={0.5}>
								<LuEyeIcon fontSize={16} />
								<Text type="small" fontWeight={500} color="blue_2">
									Watch
								</Text>
								{boardDetail?.isWatchBoard ? <TiTick /> : ''}
							</Flexed>
						</List>
						<List direction="row" align="center" justify="space-between" onClick={() => setCopyBoard(true)}>
							<Flexed direction="row" align="center" gap={0.5}>
								<MdContentCopyIcon fontSize={16} />
								<Text type="small" fontWeight={500} color="blue_2">
									Copy board
								</Text>
							</Flexed>
						</List>
						{/* <List direction="row" align="center" justify="space-between">
							<Flexed direction="row" align="center" gap={0.5}>
								<GoShareAndroidIcon fontSize={16} />
								<Text type="small" fontWeight={500} color="blue_2">
									Print, export, and share
								</Text>
							</Flexed>
						</List> */}
						<List direction="row" align="center" justify="space-between" onClick={() => setCloseBoard(true)}>
							<Flexed direction="row" align="center" gap={0.5}>
								<FaMinusIcon fontSize={16} />
								<Text type="small" fontWeight={500} color="blue_2">
									{boardDetail?.adminId != _loginAdminDetails?.id || boardDetail?.employeeId != _loginUserDetails?.id ? 'Leave Board' : 'Close board'}
								</Text>
							</Flexed>
						</List>
					</InnerContent>
				</Inner>
			)}
			{backgroundsModel && (
				<BoardBackgroundsModel
					onCloseModal={() => setBackgroundsModel(false)}
					selectedBackground={selectedBackground}
					setSelectedBackground={(e: any) => {
						if (e) {
							updateUserBoard(e, '')
						}
						setSelectedBackground(e)
					}}
					selectedBackgroundColor={selectedBackground}
					setSelectedBackgroundColor={(e: any) => {
						if (e) {
							updateUserBoard('', e)
						}

						setSelectedBackgroundColor(e)
					}}
				/>
			)}

			{closeBoard && (
				<DeleteCard
					isBoard={true}
					isMember={boardDetail?.adminId != _loginAdminDetails?.id || boardDetail?.employeeId != _loginUserDetails?.id}
					onCloseModal={() => setCloseBoard(false)}
					onDelete={() => (boardDetail?.adminId != _loginAdminDetails?.id || boardDetail?.employeeId != _loginUserDetails?.id ? leaveCurrentBoard() : closeCurrentBoard(true))}
				/>
			)}
		</SidebarContainer>
	)
}

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({cancel}) => (cancel ? palette.black : palette.white)};
	background: ${({cancel, isDefault}) => (isDefault ? palette.silver_14 : cancel ? palette.white : palette.purple_1)};
	font-weight: 600;
	cursor: pointer;
	width: 5rem;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		color: ${({cancel}) => (cancel ? palette.black : palette.white)};
		background: ${({cancel}) => (cancel ? palette.silver_14 : palette.purple_2)};
	}
`

const SidebarContainer = styled.div<any>`
	background: ${palette.white};
	position: fixed;
	top: 3.599rem;
	right: 0rem;
	bottom: 0;
	/* width: 260px; */
	z-index: 7;
	border-right: 1px solid ${palette.gray_3};
	width: ${({open}) => (open ? '21.25rem' : '0rem')};
	height: calc(100vh - 3.599rem);
	transition: width 0.3s ease;
	overflow: hidden;
`

const Inner = styled.div<any>`
	overflow: hidden;
`

const Arrows = styled(IoIosArrowBack)<any>`
	transform: ${({open}) => (open ? '' : 'rotate(180deg)')};
`

const SideBarOpenCloseBtn = styled(Flexed)`
	width: 2rem;
	height: 2rem;
	cursor: pointer;
	position: absolute;
	right: 0.5rem;
	top: 0rem;
	padding: 0.5rem;
	border-radius: 0.188rem;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const LogoWraper = styled(Flexed)`
	position: relative;
	padding: 0.5rem 0.75rem 0.5rem;
	min-height: 3.563rem;
	border-bottom: 0.063rem solid ${palette.gray_3};
`

const Logo = styled(Flexed)`
	background: ${palette.purple_1};
	color: ${palette.white};
	font-weight: 600;
	font-size: 1.25rem;
	width: 2rem;
	height: 2rem;
	border-radius: 0.3rem;
	cursor: pointer;
	overflow: hidden;
	& img {
		width: 2rem;
		height: 2rem;
		border-radius: 0.3rem;
		object-fit: cover;
	}
`

const InnerContent = styled.div`
	padding: 0.75rem 0.75rem 0.5rem;
`

const Cover = styled(Flexed)`
	width: 1.8rem;
	height: 1.8rem;
	cursor: pointer;
	border-radius: 0.188rem;
	&:hover {
		background: ${palette.gray_6};
	}
`

const Star = styled(Flexed)`
	width: 1.8rem;
	height: 1.8rem;
	cursor: pointer;
	border-radius: 0.188rem;
	&:hover {
		transform: scale(1.25);
	}
`

const ActionCover = styled(Cover)`
	display: none;
`

const List = styled(Flexed)`
	width: 100%;
	padding: 0.375rem 0.375rem 0.375rem 0.75rem;
	background: ${({active}) => (active ? palette.silver_1 : palette.white)};
	position: relative;
	border-radius: 0.5rem;
	cursor: pointer;
	min-height: 2.426rem;
	&:hover {
		background: ${palette.gray_5};
	}
	&:hover ${ActionCover} {
		display: flex;
	}
`

const ActivityIcon = styled(RxActivityLog)`
	width: 1.25rem;
`

const ArchiveLine = styled(RiArchiveLine)`
	width: 1.25rem;
`

const BiLabelIcon = styled(BiLabel)`
	width: 1.25rem;
`

const IoIosSettingsIcon = styled(IoIosSettings)`
	width: 1.25rem;
`

export const LuEyeIcon = styled(LuEye)`
	width: 1.25rem;
`

const MdContentCopyIcon = styled(MdContentCopy)`
	width: 1.25rem;
`

const GoShareAndroidIcon = styled(GoShareAndroid)`
	width: 1.25rem;
`

const FaMinusIcon = styled(FaMinus)`
	width: 1.25rem;
`

const ImageDiv = styled.div<any>`
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background: ${({imageUrl, backgroundColor}) => !imageUrl && !backgroundColor && palette.white};
	background-color: ${(props) => props.backgroundColor};
	background-image: url(${(props) => props.imageUrl});
	height: 2rem;
	width: 2rem;
	border-radius: 0.4rem;
`
export default SideMenuBar
