import {useState, useEffect, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../styled/shared'
import {useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../styled/colors'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import InputField from '../components/common/InputField'
import DropDown from '../components/common/DropDown'
import InputDate from '../components/common/InputDate'
import {Loading} from '../styled/shared'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {Back} from '../components/employee/EmployeeDetails'
import {Logo} from '../components/modals/AddEmployeeAttendanceModal'
import MultiSelect from '../components/common/MultiSelect'

const LoanRequest = () => {
	const {pathname} = useLocation()
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')
	let _navigate = useNavigate()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const requestId = queryParams.get('id')
	const [isLoading, setIsLoading] = useState<any>(false)
	const [loanTakenDate, setLoanTakenDate] = useState('')
	const [loanTakenDateError, setLoanTakenDateError] = useState('')
	const [paymentStartDate, setPaymentStartDate] = useState('')
	const [paymentStartDateError, setPaymentStartDateError] = useState('')
	const [loanAmount, setLoanAmount] = useState('')
	const [loanAmountError, setLoanAmountError] = useState('')
	const [recoveryLoanAmount, setRecoveryLoanAmount] = useState('')
	const [recoveryLoanAmountError, setRecoveryLoanAmountError] = useState('')
	const [deductionValue, setDeductionValue] = useState('')
	const [deductionValueError, setDeductionValueError] = useState('')
	const [description, setDescription] = useState('')
	const [attachmentName, setAttachmentName] = useState('')
	const [attachment, setAttachment] = useState('')
	const [actualAmount, setActualAmount] = useState('')
	const [approvedAmount, setApprovedAmount] = useState('')
	const [providentAmount, setProvidentAmount] = useState('')
	const [noOfAdvanceSalaries, setNoOfAdvanceSalaries] = useState('')
	const [employeeCode, setEmployeeCode] = useState(_loginUserStatus === 'employee' ? _loginUserDetails?.employeeCode : '')
	const [nationality, setNationality] = useState('')
	const [employee, setEmployee] = useState({
		label: _loginUserStatus === 'employee' ? _loginUserDetails?.firstName : '',
		value: _loginUserStatus === 'employee' ? _loginUserDetails?.id : '',
		image: _loginUserStatus === 'employee' && _loginUserDetails?.image ? _loginUserDetails?.image : ''
	})
	const [employeeError, setEmployeeError] = useState('')
	const [employeeId, setEmployeeId] = useState<any>(_loginUserStatus === 'employee' ? _loginUserDetails?.id : '')
	const [employeeOption, setEmployeeOption] = useState<any>([])
	const [loanType, setLoanType] = useState('')
	const [loanTypeError, setLoanTypeError] = useState('')
	const [loanTypeOption, setLoanTypeOption] = useState<any>([
		{value: 'Loan', label: 'Loan'},
		{value: 'Advance', label: 'Advance'},
		{value: 'Provident Fund', label: 'Provident Fund'}
	])
	const [loanRecoveryMethod, setLoanRecoveryMethod] = useState('')
	const [loanRecoveryMethodError, setLoanRecoveryMethodError] = useState('')
	const [loanRecoveryMethodOption, setLoanRecoveryMethodOption] = useState<any>([
		{value: 'Salary', label: 'Salary'},
		{value: 'Cash', label: 'Cash'}
	])
	const [paymentInstallmentAmount, setPaymentInstallmentAmount] = useState('')
	const [paymentInstallmentAmountError, setPaymentInstallmentAmountError] = useState('')
	const [paymentInstallmentAmountOption, setPaymentInstallmentAmountOption] = useState<any>([
		{value: 'Fixed', label: 'Fixed'},
		{value: 'Percentage of Basix Salary', label: 'Percentage of Basix Salary'}
	])

	useEffect(() => {
		allEmployee()
	}, [])
	useEffect(() => {
		api.get(`/request/get_request/${requestId}`)
			.then((response) => {
				if (response?.data?.success == true) {
					const data = response?.data?.data?.data
					setEmployee({label: data?.employeeName, value: data?.employeeId, image: data?.employeeImage})
					setEmployeeId(data?.employeeId)
					setNationality(data?.nationality)
					setLoanType(data?.loanType)
					setLoanRecoveryMethod(data?.loanRecoveryMethod)
					setPaymentInstallmentAmount(data?.installmentAmount)
					setLoanTakenDate(data?.loanTakenDate)
					setPaymentStartDate(data?.paymentStartDate)
					setLoanAmount(data?.loanAmount)
					setRecoveryLoanAmount(data?.recoveryLoanAmount)
					setEmployeeCode(data?.employeeCode)
					setActualAmount(data?.expenseAmountActual)
					setApprovedAmount(data?.expenseApprovedActual)
					setAttachmentName(data?.document)
					setAttachment(data?.document)
					setDescription(data?.description)
					setProvidentAmount(data?.providentAmount)
					setNoOfAdvanceSalaries(data?.noOfAdvanceSalaries)
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}, [requestId])

	const allEmployee = async () => {
		await api
			.get('/employee/all_employees')
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data?.data?.data.length > 0) {
						const employeeData = response?.data?.data?.data

						const arr = employeeData.map((employee: any) => ({
							label: (
								<span style={{display: 'flex', gap: '0.2rem'}}>
									{' '}
									<Logo src={employee?.image ? `${process.env.REACT_APP_IMAGE_URL + employee?.image}` : '/images/default_user_profile_img.png'}></Logo>
									{`${employee.firstName}`}
									&nbsp;
									{`${employee.id}`}
								</span>
							),
							value: employee
						}))
						setEmployeeOption(arr)
					}
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const createRequest = async (e: any) => {
		e?.preventDefault()
		if (loanType === 'Loan') {
			const takenDate = moment(loanTakenDate).format('YYYY-MM-DD')
			const startDate = moment(paymentStartDate).format('YYYY-MM-DD')
			if (moment(takenDate, 'YYYY-MM-DD').isAfter(moment(startDate, 'YYYY-MM-DD'))) {
				toast.error('Start date should be greater')
				return
			}
		}
		if (formValidation()) {
			setIsLoading(true)
			await api
				.post(`/request/create`, {
					requestType: 'Loan Request',
					employeeCode: employeeCode,
					nationality: nationality,
					loanType: loanType,
					loanRecoveryMethod: loanRecoveryMethod,
					installmentAmount: paymentInstallmentAmount,
					loanTakenDate: loanTakenDate ? moment(loanTakenDate).format('YYYY-MM-DD') : '',
					paymentStartDate: paymentStartDate ? moment(paymentStartDate).format('YYYY-MM-DD') : '',
					loanAmount: loanAmount,
					recoveryLoanAmount: recoveryLoanAmount,
					document: attachment,
					description: description,
					employeeId: employeeId,
					noOfAdvanceSalaries: noOfAdvanceSalaries,
					providentAmount: providentAmount
				})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Request created successfully')
						_navigate('/requests')
					} else if (response?.data?.success == false) {
						toast.error(response?.data?.message ? response?.data?.message : 'Request creation failed')
					}
					setIsLoading(false)
				})
				.catch(function (error) {
					setIsLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}
	const updateRequest = async (e: any) => {
		e?.preventDefault()
		if (loanType === 'Loan') {
			const takenDate = moment(loanTakenDate).format('YYYY-MM-DD')
			const startDate = moment(paymentStartDate).format('YYYY-MM-DD')
			if (moment(takenDate, 'YYYY-MM-DD').isAfter(moment(startDate, 'YYYY-MM-DD'))) {
				toast.error('Start date should be greater')
				return
			}
		}
		if (formValidation()) {
			setIsLoading(true)
			await api
				.put(`/request/update_request/${requestId}`, {
					requestType: 'Loan Request',
					employeeName: employee.label,
					employeeCode: employeeCode,
					nationality: nationality,
					loanType: loanType,
					loanRecoveryMethod: loanRecoveryMethod,
					installmentAmount: paymentInstallmentAmount,
					loanTakenDate: loanTakenDate ? moment(loanTakenDate).format('YYYY-MM-DD') : loanTakenDate,
					paymentStartDate: paymentStartDate ? moment(paymentStartDate).format('YYYY-MM-DD') : paymentStartDate,
					loanAmount: loanAmount,
					recoveryLoanAmount: recoveryLoanAmount,
					document: attachment,
					description: description,
					employeeId: employeeId,
					employeeImage: employee.image,
					noOfAdvanceSalaries: noOfAdvanceSalaries,
					providentAmount: providentAmount
				})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Request updated successfully')
						_navigate('/requests')
					}
					setIsLoading(false)
				})
				.catch(function (error) {
					setIsLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (!employeeId) {
			setEmployeeError('Field is required')
			isValid = false
		}
		if (loanType === '') {
			setLoanTypeError('Field is required')
			isValid = false
		}
		if (loanType === 'Loan' && loanRecoveryMethod === '') {
			setLoanRecoveryMethodError('Field is required')
			isValid = false
		}
		if (loanType === 'Loan' && paymentInstallmentAmount === '') {
			setPaymentInstallmentAmountError('Field is required')
			isValid = false
		}
		if (loanType === 'Loan' && loanTakenDate === '') {
			setLoanTakenDateError('Date required')
			isValid = false
		}
		if (loanType === 'Loan' && paymentStartDate === '') {
			setPaymentStartDateError('Date required')
			isValid = false
		}
		if (loanType === 'Loan' && loanAmount === '') {
			setLoanAmountError('Amount required')
			isValid = false
		}
		if (loanType === 'Loan' && recoveryLoanAmount === '') {
			setRecoveryLoanAmountError('Amount required')
			isValid = false
		}
		return isValid
	}

	return (
		<>
			<Section>
				<Container>
					<Row justifyContent="center">
						<Col lg={11}>
							<Container>
								<Title direction="row" gap={1}>
									<HeadingWrapper>
										<JobIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50px" height="30px">
											<path d="M 23 8 L 23 12.910156 A 5.992 5.992 0 0 0 27.910156 8 L 23 8 z M 29.929688 8 A 8 8 0 0 1 23 14.929688 L 23 17.070312 A 8 8 0 0 1 29.929688 24 L 50.070312 24 A 8 8 0 0 1 57 17.070312 L 57 14.929688 A 8 8 0 0 1 50.070312 8 L 29.929688 8 z M 52.089844 8 A 5.992 5.992 0 0 0 57 12.910156 L 57 8 L 52.089844 8 z M 39.792969 11.003906 A 5 5 0 0 1 45 16 A 5.006 5.006 0 0 1 40 21 A 5 5 0 0 1 39.792969 11.003906 z M 40 13 A 3 3 0 0 0 40 19 A 3 3 0 0 0 40 13 z M 23 19.089844 L 23 24 L 27.910156 24 A 5.992 5.992 0 0 0 23 19.089844 z M 57 19.089844 A 5.992 5.992 0 0 0 52.089844 24 L 57 24 L 57 19.089844 z M 23 26 L 23 28 L 34 28 L 34 26 L 23 26 z M 36 26 L 36 36 L 44 36 L 44 26 L 36 26 z M 46 26 L 46 28 L 57 28 L 57 26 L 46 26 z M 23 30 L 23 32 L 34 32 L 34 30 L 23 30 z M 46 30 L 46 32 L 57 32 L 57 30 L 46 30 z M 23 34 L 23 36 L 34 36 L 34 34 L 23 34 z M 46 34 L 46 36 L 57 36 L 57 34 L 46 34 z M 27.769531 42 A 9 9 0 0 0 23.140625 43.279297 L 20.509766 44.859375 A 1 1 0 0 1 20 45 L 3 45 L 3 56.890625 L 22.910156 55 A 1.408 1.408 0 0 1 23.109375 55.009766 L 35.890625 56.429688 A 14.956 14.956 0 0 0 45.050781 54.509766 L 58.679688 46.630859 A 2 2 0 0 0 56.679688 43.169922 L 46.009766 49.330078 A 4.982 4.982 0 0 1 43.509766 50 L 33 50 L 33 48 L 43 48 A 2 2 0 0 0 43 44 L 36 44 A 0.937 0.937 0 0 1 35.630859 43.929688 L 32.419922 42.640625 A 9.149 9.149 0 0 0 29.070312 42 L 27.769531 42 z"></path>
										</JobIcon>

										<Text type="normal" fontWeight={500} color="black" fontSize={1.4}>
											Loan Request
										</Text>
									</HeadingWrapper>
									<div>
										<Back onClick={() => _navigate('/requests')}>
											<svg _ngcontent-kxy-c151="" width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00acac">
												<path
													_ngcontent-kxy-c151=""
													d="M116 121v260c0 13.785 11.215 25 25 25s25-11.215 25-25V121c0-13.785-11.215-25-25-25S116 107.215 116 121zM211.98 243.107c-5.985 3.555-5.996 12.224 0 15.785l219.648 130.416c7.391 4.39 16.269-2.86 13.317-11.014l-42.561-117.712c-1.455-4.026-1.564-8.549-.078-12.823 45.595-131.625 43.871-124.776 43.871-127.686 0 0 0 0 0-.001 0-6.511-7.428-11.61-13.906-7.763L211.98 243.107zM346 451c0 8.272 6.728 15 15 15 8.271 0 15-6.728 15-15 0-8.271-6.729-15-15-15C352.728 436 346 442.729 346 451zM66 396c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 5.515 4.486 10 10 10S66 401.515 66 396zM226 71c0 13.785 11.215 25 25 25 13.785 0 25-11.215 25-25s-11.215-25-25-25C237.215 46 226 57.215 226 71zM250.998 371.003A10 10 0 10250.998 391.003 10 10 0 10250.998 371.003zM350.998 80.997A10 10 0 10350.998 100.997 10 10 0 10350.998 80.997zM51.003 151.003A10 10 0 1051.003 171.003 10 10 0 1051.003 151.003zM460.998 261.003A10 10 0 10460.998 281.003 10 10 0 10460.998 261.003z"></path>
											</svg>
											Back
										</Back>
									</div>
								</Title>
								<Row>
									<Col lg={11}>
										<form onSubmit={requestId ? updateRequest : createRequest}>
											<Row>
												<Col lg={6}>
													<MultiSelect
														label="Employee"
														value={employeeOption?.find((e: any) => e?.value?.id == employeeId)}
														required
														options={employeeOption}
														onChange={(e: any) => setEmployeeId(e?.value?.id)}
														error={employeeError}
													/>
													<Spacer height={1} />
												</Col>
												<Col lg={6}>
													<DropDown
														label="Loan Type"
														required
														firstSelected={loanType}
														options={loanTypeOption}
														hasChanged={(value: any) => {
															setLoanTypeError('')
															if (value === '') {
																setLoanTypeError('Field is required')
															}
															setLoanType(value)
														}}
														error={loanTypeError}
														dropDownListHeight="100px"
													/>
													<Spacer height={1} />
												</Col>
												{loanType === 'Provident Fund' ? (
													<Col lg={6}>
														<Spacer height={2} />
														<Flexed direction="row" align="center" gap={1}>
															<Label type="small">Available Total Provident Fund Amount:</Label>
															10000 RS
														</Flexed>
														<Spacer height={1} />
													</Col>
												) : null}
												{loanType === 'Provident Fund' ? (
													<Col lg={6}>
														<InputField
															label="Amout do you want?"
															value={providentAmount}
															handleChange={(e: any) => {
																setProvidentAmount(e)
															}}
														/>
														<Spacer height={1} />
													</Col>
												) : null}
												{loanType === 'Advance' ? (
													<Col lg={6}>
														<InputField
															label="No of salaries"
															value={noOfAdvanceSalaries}
															handleChange={(e: any) => {
																setNoOfAdvanceSalaries(e)
															}}
														/>
														<Spacer height={1} />
													</Col>
												) : null}
												<Divider margin="1rem 1rem" />
												{loanType === 'Loan' ? (
													<>
														Loan Recovery details
														<Divider margin="2rem 1rem" />
														<Col lg={6}>
															<DropDown
																label="Loan Recovery Method"
																required
																firstSelected={loanRecoveryMethod}
																options={loanRecoveryMethodOption}
																hasChanged={(value: any) => {
																	setLoanRecoveryMethodError('')
																	if (value === '') {
																		setLoanRecoveryMethodError('Field is required')
																	}
																	setLoanRecoveryMethod(value)
																}}
																error={loanRecoveryMethodError}
																dropDownListHeight="100px"
															/>
															<Spacer height={1} />
														</Col>
														<Col lg={6}>
															<DropDown
																label="Payment Installment Amount"
																required
																firstSelected={paymentInstallmentAmount}
																options={paymentInstallmentAmountOption}
																hasChanged={(value: any) => {
																	setPaymentInstallmentAmountError('')
																	if (value === '') {
																		setPaymentInstallmentAmountError('Field is required')
																	}
																	setPaymentInstallmentAmount(value)
																}}
																error={paymentInstallmentAmountError}
																dropDownListHeight="100px"
															/>
															<Spacer height={1} />
														</Col>
														<Col lg={6}>
															<InputDate
																label="Loan Taken Date"
																required
																value={loanTakenDate}
																handleChange={(e: any) => {
																	setLoanTakenDateError('')
																	if (e === '') {
																		setLoanTakenDateError('Date is required')
																	}
																	setLoanTakenDate(e)
																}}
																error={loanTakenDateError}
															/>
															<Spacer height={1} />
														</Col>
														<Col lg={6}>
															<InputDate
																label="Payment Start Date"
																required
																value={paymentStartDate}
																handleChange={(e: any) => {
																	setPaymentStartDateError('')
																	if (e === '') {
																		setPaymentStartDateError('Date is required')
																	}
																	setPaymentStartDate(e)
																}}
																error={paymentStartDateError}
															/>
															<Spacer height={1} />
														</Col>
														<Col lg={6}>
															<Label type="small" margin="0rem 0rem 0.19rem 0rem">
																Loan Amount <Mandatory>*</Mandatory>
															</Label>
															<Amount direction="row">
																<InputTag>RS</InputTag>
																<InputField
																	allowOnlyNumbersAndDecimal={true}
																	value={loanAmount}
																	handleChange={(e: any) => {
																		setLoanAmountError('')
																		if (e === '') {
																			setLoanAmountError(' Amount required')
																		}
																		setLoanAmount(e)
																	}}
																	error={loanAmountError}
																/>
															</Amount>
															<Spacer height={1} />
														</Col>
														<Col lg={6}>
															<Label type="small" margin="0rem 0rem 0.19rem 0rem">
																Recovery Loan Amount <Mandatory>*</Mandatory>
															</Label>
															<Amount direction="row">
																<InputTag>RS</InputTag>
																<InputField
																	allowOnlyNumbersAndDecimal={true}
																	value={recoveryLoanAmount}
																	handleChange={(e: any) => {
																		setRecoveryLoanAmountError('')
																		if (e === '') {
																			setRecoveryLoanAmountError(' Amount required')
																		}
																		setRecoveryLoanAmount(e)
																	}}
																	error={recoveryLoanAmountError}
																/>
															</Amount>
															<Spacer height={1} />
														</Col>
													</>
												) : null}
											</Row>

											<ButtonRow direction="row" gap={1}>
												<CancelButton onClick={() => _navigate('/requests')}>Cancel</CancelButton>
												<SaveButton type="submit">{requestId ? 'Save' : 'Create'}</SaveButton>
											</ButtonRow>
										</form>
									</Col>
									<Col lg={1}></Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>
			</Section>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Section = styled.div`
	margin-top: 16px;
`
const Title = styled(Flexed)`
	top: 57px;
	width: 100%;
	padding: 2rem 0rem;
	z-index: 2;
	display: flex;
	justify-content: space-between;
`
const ButtonRow = styled(Flexed)`
	top: 57px;
	width: 100%;
	padding: 2rem 0rem;
	z-index: 2;
	display: flex;
	justify-content: flex-end;
`
const JobIcon = styled.svg`
	max-width: 25px;
	fill: ${palette.purple_1};
	stroke-width: 2px;
	stroke-linecap: round;
`

const CancelButton = styled.div`
	font-size: 0.9rem;
	height: 25px;
	padding: 20px;
	border-radius: 25px;
	color: ${palette.white};
	font-weight: 400;
	cursor: pointer;
	min-width: 3rem;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
	font-family: 'Montserrat', sans-serif;
	background: #fb7979;
	border-color: #fb7979;
	margin-left: 3px;
	transition: all 0.3s ease;
	min-width: 85px;
	cursor: pointer;
	&:hover {
		background: #fb7979;
		border-color: #fb7979;
		box-shadow: 0 0 0 3px #fb79794d;
	}
`
const SaveButton = styled.button`
	font-size: 0.9rem;
	height: 25px;
	padding: 20px;
	border-radius: 25px;
	color: ${palette.white};
	font-weight: 400;
	cursor: pointer;
	min-width: 3rem;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
	font-family: 'Montserrat', sans-serif;
	background: ${palette.purple_1};
	margin-left: 3px;
	transition: all 0.3s ease;
	min-width: 85px;
	cursor: pointer;
	border: none;
`
const Amount = styled(Flexed)`
	& #inputWrapper {
		width: 100%;
	}
	& input {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		line-height: 2rem !important;
	}
`
const InputTag = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 2;
	color: ${palette.black_5};
	text-align: center;
	background-color: ${palette.silver_1};
	border: 1px solid ${palette.silver_6};
	border-right: 0.5px solid ${palette.silver_6};
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	min-width: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
`
const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

export const Mandatory = styled.span`
	color: ${palette.danger};
`
const HeadingWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 1rem;
`

export default LoanRequest
