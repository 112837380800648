import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'
import DropDown from '../common/DropDown'
import {Checked, CheckIconWrapper, UnChecked} from '../../pages/Auth/SignUp'
import Calendar from 'react-calendar'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import moment from 'moment'

const DatePop = ({mainDateModal, top, bottom, onCloseModal, subList, loadAgain, isCard, cardDetails, left}: any) => {
	const [dueDateReminder, setDueDateReminder] = useState({label: '', value: ''})
	const [startDate, setStartDate] = useState(false)
	const [newStartDate, setNewStartDate] = useState<any>('')
	const [dueDate, setDueDate] = useState(true)
	const [fixDueDate, setFixDueDate] = useState<any>('')
	const [fixDueDateTime, setFixDueDateTime] = useState<any>(moment().format('HH:mm'))
	const [salaryTypeOption, setSalaryTypeOption] = useState([
		{value: '', label: 'None'},
		{value: 'At time of due date', label: 'At time of due date'},
		{value: '5 Minutes before', label: '5 Minutes before'},
		{value: '10 Minutes before', label: '10 Minutes before'},
		{value: '15 Minutes before', label: '15 Minutes before'},
		{value: '1 Hour before', label: '1 Hour before'},
		{value: '2 Hour before', label: '2 Hour before'},
		{value: '1 Day before', label: '1 Day before'},
		{value: '2 Day before', label: '2 Day before'}
	])

	useEffect(() => {
		if (subList) {
			setFixDueDate(subList?.dueDate)
			setFixDueDateTime(subList?.time ? subList?.time : fixDueDateTime)
			setNewStartDate(subList?.startDate ? subList?.startDate : '')
			setStartDate(subList?.startDate ? true : false)

			let value: any = salaryTypeOption?.find((e: any) => e?.value == subList?.remindBefore)
			setDueDateReminder(value ? value : dueDateReminder)
		}
		if (isCard && cardDetails) {
			setFixDueDate(cardDetails?.dueDate)
			setFixDueDateTime(cardDetails?.time ? cardDetails?.time : fixDueDateTime)
			setNewStartDate(cardDetails?.startDate ? cardDetails?.startDate : '')
			setStartDate(cardDetails?.startDate ? true : false)
			let value: any = salaryTypeOption?.find((e: any) => e?.value == cardDetails?.remindBefore)
			setDueDateReminder(value ? value : dueDateReminder)
		}
	}, [subList])

	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const updateCheckList = async (remindBefore = 2, isRemove = false) => {
		try {
			await api
				.put(`/trello/update_checklist/${subList?.id}`, {
					dueDate: isRemove ? '' : fixDueDate,
					time: isRemove ? '' : fixDueDateTime,
					remindBefore: isRemove ? '' : dueDateReminder?.value ? dueDateReminder?.value : remindBefore,
					startDate: isRemove ? '' : startDate ? newStartDate : null
				})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
						onCloseModal()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const updateCardDetails = async (isRemove = false) => {
		try {
			await api
				.put(`/trello/update_card/${cardDetails?.id}`, {
					dueDate: isRemove ? '' : fixDueDate,
					time: isRemove ? '' : fixDueDateTime,
					remindBefore: isRemove ? '' : dueDateReminder?.value ? dueDateReminder?.value : '',
					startDate: isRemove ? '' : startDate ? newStartDate : null
				})
				.then((response) => {
					if (response?.data?.success == true) {
						loadAgain && loadAgain()
						onCloseModal()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	return (
		<Popup top={top} bottom={bottom} onClick={handleModalClick} left={left}>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				<div></div>
				<Text fontWeight={600}>{mainDateModal ? 'Dates' : 'Change due date'}</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			<Flexed margin="0.5rem 0rem 0rem 0rem">
				<CalendarWrapper id="ddd">
					<Calendar onChange={(e: any) => setFixDueDate(moment(e).format())} />
				</CalendarWrapper>
				{mainDateModal && (
					<div>
						<Text type="xsmall" margin="0.5rem 0rem 0.2rem 0rem" fontWeight={600}>
							Start date
						</Text>
						<Flexed direction="row" align="center" gap={0.5}>
							<CheckIconWrapper>
								{startDate ? (
									<Checked
										onClick={() => {
											setStartDate(!startDate)
										}}
									/>
								) : (
									<UnChecked
										onClick={() => {
											setStartDate(!startDate)
										}}
									/>
								)}
							</CheckIconWrapper>
							<Input disabled={!startDate} type="date" value={newStartDate} onChange={(e: any) => setNewStartDate(e?.target?.value)} />
						</Flexed>
					</div>
				)}
				<div>
					<Text type="xsmall" margin="0.5rem 0rem 0.2rem 0rem" fontWeight={600}>
						Due date
					</Text>
					<Flexed direction="row" align="center" gap={0.5}>
						<CheckIconWrapper>
							{dueDate ? (
								<Checked
									onClick={() => {
										setDueDate(!dueDate)
									}}
								/>
							) : (
								<UnChecked
									onClick={() => {
										setDueDate(!dueDate)
									}}
								/>
							)}
						</CheckIconWrapper>
						<Input type="date" value={moment(fixDueDate).format('YYYY-MM-DD')} />
						<Input type="time" value={fixDueDateTime} onChange={(e: any) => setFixDueDateTime(e?.target?.value)} />
					</Flexed>
				</div>
				<DropDownWrapper>
					<Text type="xsmall" margin="0.5rem 0rem 0.2rem 0rem" fontWeight={600}>
						Set due date reminder
					</Text>
					<DropDown
						firstSelected={dueDateReminder.label}
						options={salaryTypeOption}
						hasChanged={(value: any) => {
							setDueDateReminder({label: value, value: value})
						}}
						dropDownListHeight="150px"
					/>
				</DropDownWrapper>
				<Text type="xsmall" margin="0.5rem 0rem" fontWeight={500}>
					Reminders will be sent to members assigned to this checklist item.
				</Text>
			</Flexed>
			<Flexed gap={0.5}>
				<Button
					onClick={() => {
						if (fixDueDate?.trim()?.length) {
							if (isCard) {
								updateCardDetails()
							} else {
								updateCheckList()
							}
						}
					}}>
					Save
				</Button>
				<Button
					cancel
					onClick={() => {
						if (isCard) {
							updateCardDetails(true)
						} else {
							updateCheckList(1, true)
						}
					}}>
					Remove
				</Button>
			</Flexed>
		</Popup>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({top}) => top};
	bottom: ${({bottom}) => bottom};
	left: ${({left}) => left};
	z-index: 5;
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

const Input = styled.input<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
	outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
	&::-webkit-calendar-picker-indicator {
		display: none;
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({cancel}) => (cancel ? palette.black : palette.white)};
	background: ${({cancel}) => (cancel ? palette.silver_14 : palette.purple_1)};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		color: ${({cancel}) => (cancel ? palette.black : palette.white)};
		background: ${({cancel}) => (cancel ? palette.silver_1 : palette.purple_2)};
	}
`

const DropDownWrapper = styled.div`
	width: 100%;
	& #ourDropDown {
		border: none;
		padding: 0.5rem;
		outline: none;
		line-height: 1.25rem;
		box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
		border-radius: 0.3rem;
	}
	& #dropList {
		top: 2.3rem;
		line-height: 1.2rem;
	}
`

const CalendarWrapper = styled.div`
	margin-bottom: 1rem;
	& .react-calendar {
		border: none;
	}
`

export default DatePop
