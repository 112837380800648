import React, { useState } from 'react'
import styled from 'styled-components'
import { Flexed, Spacer, Text } from '../../styled/shared'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../../styled/colors'

const ForgotPasswordConfirmation = () => {
    return (
        <>
            <MainWrapper>
                <Flexed direction='row' justify='center'>
                    <Wrapper>
                        <Row justifyContent='center'>
                            <Col xl={5.4} lg={7.5} md={9.8} sm={9.8}>
                                <Text fontSize={1.5} lineHeight={2} fontWeight={700} color='black_4'>Forgot Password Confirmation</Text>
                                <Spacer height={1} />
                                <Text fontSize={0.813} color='black_3'>
                                Please check your email and confirm your email address by clicking the provided link. Check your Spam Folder if you can't find the email in your inbox
                                </Text>
                            </Col>
                        </Row>
                    </Wrapper>
                </Flexed>
            </MainWrapper >
        </>
    )
}

const MainWrapper = styled.div`
    height: 100vh;
    background: ${palette.silver_3};
`

const Wrapper = styled(Container)`
    margin-top: 2rem;
    ${media.sm`margin-top: 4rem;`}
    ${media.md`margin-top: 6rem;`}
`

export default ForgotPasswordConfirmation
