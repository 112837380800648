import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoIosArrowBack} from 'react-icons/io'
import {IoMdClose} from 'react-icons/io'

const ArchiveAllCards = ({goBack, onCloseModal, onArchiveClick}: any) => {
	return (
		<>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				<CloseCover onClick={goBack}>
					<IoIosArrowBack cursor="pointer" />
				</CloseCover>
				<Text fontWeight={600}>Archive all cards in this list?</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			<Flexed margin="0.5rem 0.8rem" gap={0.5}>
				<div>
					<Text type="small" margin="0rem 0rem 0.2rem 0rem">
						This will remove all the cards in this list from the board. To view archived cards and bring them back to the board, click "Menu" {'>'} "Archived Items."
					</Text>
				</div>
				<Button onClick={onArchiveClick}>Archive all</Button>
			</Flexed>
		</>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.red};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		background: ${palette.red_hover};
	}
`

export default ArchiveAllCards
