import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import Loader from '../components/common/Loader'
import {MdOutlineLock} from 'react-icons/md'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Flexed, Text, Spacer} from '../styled/shared'
import {IoMdClose} from 'react-icons/io'
import {BiUserPlus} from 'react-icons/bi'
import InputField from '../components/common/InputField'
import InviteToWorkSpace from '../components/modals/InviteToWorkSpace'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import useRouter from '../hooks/router'
import CreateNewBoard from '../components/modals/CreateNewBoardModel'
import ClosedBoardModel from '../components/modals/ClosedBoardModel'

const Boards = () => {
	const _changeLogoPopRef: any = useRef(null)
	const [logoImage, setLogoImage] = useState('/images/bmo.png')
	const [name, setName] = useState('Space-x')
	const [openChangeLogoPop, setOpenChangeLogoPop] = useState(false)
	const [closedBoardsModel, setCloesedBoardsModel] = useState(false)
	const [openInviteToWorkspaceModal, setOpenInviteToWorkspaceModal] = useState(false)
	const [userBoards, setUserBoards] = useState<any>([])
	const [memberBoards, setMemberBoards] = useState<any>([])
	const [loading, setLoading] = useState(false)
	const [createBoardModel, setCreateBoardModel] = useState(false)

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_changeLogoPopRef?.current && !_changeLogoPopRef?.current?.contains(event.target)) {
				setOpenChangeLogoPop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		getAllBoards()
	}, [])

	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const handleCapture = (event: any) => {
		const _files = event.target.files
		let _fileName: any = ''
		if (_files && _files.length > 0) {
			let _file = event.target.files?.[0]
			const _reader: any = new FileReader()
			_reader.readAsDataURL(event.target.files[0])
			_reader.onload = () => {
				if (_reader.readyState === 2) {
					setLogoImage(_reader?.result)
				}
			}
		}
	}

	const getAllBoards = async () => {
		setLoading(true)
		await api
			.get('/trello/get_all_boards')
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						setUserBoards(response?.data?.data?.userboards)

						const groupedData = response?.data?.data?.memberBoards.reduce((acc: any, curr: any) => {
							const adminName = curr.admin.name
							if (!acc[adminName]) {
								acc[adminName] = []
							}
							acc[adminName].push(curr)
							return acc
						}, {})

						setMemberBoards(groupedData)
					}
					setLoading(false)
				}
				setLoading(false)
			})
			.catch(function (error) {
				setLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	return (
		<Main>
			{loading && <Loader />}
			<Container>
				<Row>
					<Col>
						<Spacer height={2} />
					</Col>
					{/* <Col lg={6}>
						{!edit ? (
							<Wrapper
								ref={_changeLogoPopRef}
								direction="row"
								align="center"
								gap={0.5}
								onClick={() => {
									setOpenChangeLogoPop(!openChangeLogoPop)
								}}>
								<Logo direction="row" align="center" justify="center">
									{logoImage ? <img src={logoImage} /> : name[0]}
									<OverlayContent direction="row" align="center" justify="center">
										Change
									</OverlayContent>
								</Logo>
								{openChangeLogoPop && (
									<Popup top="4.2rem" left={0} onClick={handleModalClick}>
										<Flexed direction="row" align="center" justify="space-between" gap={0.2}>
											<div></div>
											<Text fontWeight={600}>Change Logo</Text>
											<IoMdClose cursor="pointer" onClick={() => setOpenChangeLogoPop(false)} />
										</Flexed>
										<Spacer height={1} />
										<Button cancel>
											<svg width="18" height="18" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M12.5048 5.67168C11.9099 5.32669 11.2374 5.10082 10.5198 5.0267C11.2076 3.81639 12.5085 3 14 3C16.2092 3 18 4.79086 18 7C18 7.99184 17.639 8.89936 17.0413 9.59835C19.9512 10.7953 22 13.6584 22 17C22 17.5523 21.5523 18 21 18H18.777C18.6179 17.2987 18.3768 16.6285 18.0645 16H19.917C19.4892 13.4497 17.4525 11.445 14.8863 11.065C14.9608 10.7218 15 10.3655 15 10C15 9.58908 14.9504 9.18974 14.857 8.80763C15.5328 8.48668 16 7.79791 16 7C16 5.89543 15.1046 5 14 5C13.4053 5 12.8711 5.25961 12.5048 5.67168ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12ZM14 10C14 10.9918 13.639 11.8994 13.0412 12.5984C15.9512 13.7953 18 16.6584 18 20C18 20.5523 17.5523 21 17 21H3C2.44772 21 2 20.5523 2 20C2 16.6584 4.04879 13.7953 6.95875 12.5984C6.36099 11.8994 6 10.9918 6 10C6 7.79086 7.79086 6 10 6C12.2091 6 14 7.79086 14 10ZM9.99999 14C12.973 14 15.441 16.1623 15.917 19H4.08295C4.55902 16.1623 7.02699 14 9.99999 14Z"
													fill="currentColor"></path>
											</svg>
											Upload a new logo
											<InputFile
												type="file"
												accept="image/*"
												onChange={(e: any) => {
													handleCapture(e)
												}}
											/>
										</Button>
										<Spacer height={0.5} />
										<Button
											cancel
											onClick={() => {
												setLogoImage('')
											}}>
											Remove Logo
										</Button>
									</Popup>
								)}
								<Flexed gap={0.3}>
									<Flexed direction="row" align="center" gap={0.5}>
										<Text type="large" fontWeight={600} color="blue_2">
											{name}
										</Text>
										<Cover direction="row" align="center" justify="center" onClick={() => setEdit(true)}>
											<svg width="16" height="16" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M7.82034 14.4893L9.94134 16.6103L18.4303 8.12131L16.3093 6.00031H16.3073L7.82034 14.4893ZM17.7233 4.58531L19.8443 6.70731C20.6253 7.48831 20.6253 8.7543 19.8443 9.53531L10.0873 19.2933L5.13734 14.3433L14.8943 4.58531C15.2853 4.19531 15.7973 4.00031 16.3093 4.00031C16.8203 4.00031 17.3323 4.19531 17.7233 4.58531ZM5.20094 20.4097C4.49794 20.5537 3.87694 19.9327 4.02094 19.2297L4.80094 15.4207L9.00994 19.6297L5.20094 20.4097Z"
													fill="currentColor"></path>
											</svg>
										</Cover>
									</Flexed>
									<Flexed direction="row" align="center" gap={0.2}>
										<MdOutlineLock />
										<Text type="xsmall" fontWeight={500} color="blue_2">
											Private
										</Text>
									</Flexed>
								</Flexed>
							</Wrapper>
						) : (
							<Form>
								<InputWrapper>
									<InputField label="Name" required />
								</InputWrapper>
								<InputWrapper>
									<InputField label="Short name" required />
								</InputWrapper>
								<InputWrapper>
									<InputField label="Website (optional)" />
								</InputWrapper>
								<InputWrapper>
									<InputField label="Description (optional)" type="textarea" />
								</InputWrapper>
								<Flexed direction="row" gap={0.5} margin="1rem 0rem">
									<Button active>Save</Button>
									<Button
										cancel
										onClick={() => {
											setEdit(false)
										}}>
										Cancel
									</Button>
								</Flexed>
							</Form>
						)}
					</Col> */}
					<Col lg={6}></Col>
					{/* <Col lg={6}>
						<Flexed direction="row" align="center" justify="flex-end">
							<Button
								width="fit-content"
								active
								onClick={() => {
									setOpenInviteToWorkspaceModal(true)
								}}>
								<BiUserPlus />
								Invite Workspace members
							</Button>
						</Flexed>
					</Col> */}
					<Col>
						<Spacer height={2} />
					</Col>
				</Row>
			</Container>
			{openInviteToWorkspaceModal && <InviteToWorkSpace onCloseModal={() => setOpenInviteToWorkspaceModal(false)} />}

			<Container>
				<Row>
					{userBoards?.length ? (
						<Col>
							<Text fontWeight={600}> YOUR WORKSPACES</Text>
							<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem" flexWrap="wrap" align={'center'} gap={1}>
								{userBoards?.map((res: any, i: any) => (
									<BoardContainer key={i} imageUrl={res?.backgroundImage}>
										<TrelloCard title={res?.name} id={res?.id} data={res} />
									</BoardContainer>
								))}

								<CardContainer style={{background: palette.silver_4}} onClick={() => setCreateBoardModel(true)}>
									<CardContent>
										<CardTitle style={{fontWeight: 'normal'}}>{'Create New Board'}</CardTitle>
									</CardContent>
								</CardContainer>
							</Flexed>
						</Col>
					) : (
						<Col>
							<Text fontWeight={600}> YOUR WORKSPACES</Text>
							<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem" flexWrap="wrap" align={'center'} gap={1}>
								<CardContainer style={{background: palette.silver_4}} onClick={() => setCreateBoardModel(true)}>
									<CardContent>
										<CardTitle style={{fontWeight: 'normal'}}>{'Create New Board'}</CardTitle>
									</CardContent>
								</CardContainer>
							</Flexed>
						</Col>
					)}
					<Spacer height={1}></Spacer>

					<Col>
						{Object.keys(memberBoards).map((adminName: any) => (
							<div key={adminName}>
								<h2>{adminName}</h2>
								{/* Map over the array of objects for the current admin */}
								<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem" flexWrap="wrap" align={'center'} gap={1}>
									{memberBoards[adminName].map((board: any, i: any) => (
										<BoardContainer key={i}>
											<TrelloCard title={board.name} id={board.id} desciption={board.description} data={board} />
										</BoardContainer>
									))}
								</Flexed>
							</div>
						))}
					</Col>
				</Row>
				<Spacer height={2}></Spacer>
				<Flexed direction="row" margin="0.625rem 0rem 0rem 0rem" flexWrap="wrap" align={'center'} gap={1} onClick={() => setCloesedBoardsModel(true)}>
					{/* <Text fontWeight={600}> Closed Boards</Text> */}
					<Button cancel>Closed Boards</Button>
				</Flexed>
			</Container>
			{closedBoardsModel && <ClosedBoardModel onCloseModal={() => setCloesedBoardsModel(false)} openBoard={getAllBoards} />}
			{createBoardModel && <CreateNewBoard onCloseModal={() => setCreateBoardModel(false)} onCreateBoard={getAllBoards} />}
		</Main>
	)
}

const TrelloCard = ({title, desciption, id, data}: any) => {
	const router = useRouter()
	let checkIsCode = desciption?.startsWith('<') || desciption?.includes('<')

	return (
		<CardContainer onClick={() => router.navigate(`/task-management?id=${id}`)} imageUrl={data?.backgroundImage} backgroundColor={data?.backgroundColor}>
			<CardContent>
				<CardTitle>{title}</CardTitle>
				{checkIsCode ? <CardDescription dangerouslySetInnerHTML={{__html: desciption}}></CardDescription> : <CardDescription>{desciption}</CardDescription>}
			</CardContent>
		</CardContainer>
	)
}

const CardContainer = styled.div<any>`
	background: ${({imageUrl, backgroundColor}) => !imageUrl && !backgroundColor && palette.white};
	background-color: ${(props) => props.backgroundColor};
	background-image: url(${(props) => props.imageUrl});
	background-position: center center;
	color: ${(props) => (props.backgroundColor || props?.imageUrl ? 'white' : '')};
	border-radius: 0.25rem;
	box-shadow: 0px 2px 4px rgba(9, 30, 66, 0.25);
	margin-bottom: 0.5rem;
	padding: 2rem;
	cursor: pointer;
	background-size: cover;
	display: flex;
	align-items: center;
`

const CardContent = styled.div`
	padding: 0.5rem;
`

const CardTitle = styled.div`
	font-weight: bold;
	margin-bottom: 0.25rem;
`

const CardDescription = styled.div`
	color: ${palette.white};
`

const BoardContainer = styled.div<any>`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	background-color: ${(props) => props.backgroundColor};
`

const Main = styled.div`
	background: ${palette.white};
	width: auto !important;
	min-height: calc(100vh - 3.599rem);
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({cancel}) => (cancel ? palette.black : palette.white)};
	background: ${({cancel}) => (cancel ? palette.silver_14 : palette.purple_1)};
	font-weight: 600;
	cursor: pointer;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	gap: 0.5rem;
	&:hover {
		color: ${({cancel}) => (cancel ? palette.black : palette.white)};
		background: ${({cancel}) => (cancel ? palette.silver_11 : palette.purple_2)};
	}
`

const InputWrapper = styled.div<any>`
	max-width: 15.625rem;
	margin-bottom: 0.5rem;
	& input {
		line-height: 1.25rem;
		border-radius: 0.188rem;
	}
	& #inputLabel {
		font-size: 0.75rem;
		font-weight: 600;
	}
`

const Form = styled.div<any>`
	max-width: 15.625rem;
`

export default Boards
