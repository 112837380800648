import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text, Spacer} from '../../styled/shared'
import {IoMdClose} from 'react-icons/io'
import {Popup} from '../modals/TrelloTaskModal'
import {palette} from '../../styled/colors'
import {GoogleMap, useJsApiLoader, MarkerF} from '@react-google-maps/api'
import {BsThreeDots} from 'react-icons/bs'
import axios from 'axios'
import {IoIosArrowBack} from 'react-icons/io'
import LocationPop from './LocationPop'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import moment from 'moment'

const LocationList = ({cardDetails, loadAgain}: {cardDetails?: any; loadAgain?: any}) => {
	const _openActionPopRef: any = useRef(null)
	const _locationPop: any = useRef(null)
	const [map, setMap] = useState(null)
	const [openActionPop, setOpenActionPop] = useState(false)
	const [openDeletePop, setOpenDeletePop] = useState(false)
	const [openLocationPop, setOpenLocationPop] = useState(false)
	const [clat, setCLat] = useState<any>('')
	const [clng, setCLng] = useState<any>('')
	const [location, setLocation] = useState('')

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_openActionPopRef?.current && !_openActionPopRef?.current?.contains(event.target)) {
				setOpenActionPop(false)
				setOpenDeletePop(false)
				setOpenLocationPop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		setLocation(cardDetails?.location)
		if (cardDetails?.location) getCoordinatesFromAddress(cardDetails?.location)
	}, [cardDetails?.location])

	const getCoordinatesFromAddress = async (address: any) => {
		try {
			const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_MAP_API_KEY}`)

			// Extract latitude and longitude from the response
			const location = response.data.results[0].geometry.location
			const {lat, lng} = location
			setCLat(lat)
			setCLng(lng)
			return {lat, lng}
		} catch (error) {
			console.error('Error getting coordinates from address:', error)
			throw error // You may want to handle this error appropriately in your application
		}
	}

	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const containerStyle = {
		width: '100%',
		height: '14.74rem'
	}

	const center = {
		lat: clat ? clat : 38.892708,
		lng: clng ? clng : -94.6426741,
		zoom: 15
	}

	const {isLoaded} = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk'
	})

	const getCurrentLatLng = async () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				setCLat(position.coords.latitude)
				setCLng(position.coords.longitude)
				await getCurrentAddress(position.coords.latitude, position.coords.longitude)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				if (error.code == error.PERMISSION_DENIED) {
				} else {
				}
			},
			{timeout: 5000, enableHighAccuracy: true}
		)
	}

	//Function to get Exact Address from above taken Latitude and longitude
	const getCurrentAddress = async (lat: any, lng: any) => {
		await axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
			.then((response) => {
				setLocation(response.data.results[0].formatted_address)
				updateCardDetails(false, response.data.results[0].formatted_address)
			})
			.catch((error) => {
				setLocation('')
			})
	}

	const updateCardDetails = async (isRemove = false, cardLocation: any) => {
		try {
			await api
				.put(`/trello/update_card/${cardDetails?.id}`, {
					location: isRemove ? '' : cardLocation,
					locationAddedDate: isRemove ? '' : moment().format()
				})
				.then((response) => {
					if (response?.data?.success == true) {
						loadAgain && loadAgain()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	return (
		<Wrapper>
			{isLoaded ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					zoom={center.zoom}
					center={center}
					onLoad={(map: any) => {
						setMap(map)
					}}
					options={{
						mapTypeControl: false,
						fullscreenControl: false,
						streetViewControl: false
					}}>
					<MarkerF
						position={{lat: parseFloat(clat), lng: parseFloat(clng)}}
						icon={{
							// url: `/images/icons/marker.svg`,
							scaledSize: new window.google.maps.Size(40, 40),
							// @ts-ignore
							shape: {coords: [17, 17, 18], type: 'circle'},
							optimized: false
						}}
					/>
				</GoogleMap>
			) : (
				<Text>Loading</Text>
			)}
			<Content>
				<Flexed direction="row" justify="space-between" gap={0.5}>
					<div>
						<Text
							fontWeight={600}
							onClick={() => {
								const url = `https://www.google.com/maps/search/?api=1&query=${clat},${clng}`
								window.open(url, '_blank')
							}}>
							{cardDetails?.location}
						</Text>
						<Text>Added {cardDetails?.locationAddedDate ? moment(cardDetails?.locationAddedDate).format('DD MMM [at] hh:mm') : ''}</Text>
					</div>
					<ActionFlex direction="row" gap={0.3} flexWrap="wrap" align="center">
						<ActionsText direction="row" align="center" justify="center" ref={_openActionPopRef} onClick={() => setOpenActionPop(!openActionPop)} pointer>
							<BsThreeDots />
						</ActionsText>
						{openActionPop && (
							<Popup onClick={handleModalClick}>
								<Flexed direction="row" align="center" justify="space-between" gap={0.2}>
									{openDeletePop ? (
										<CloseCover onClick={() => setOpenDeletePop(false)}>
											<IoIosArrowBack cursor="pointer" />
										</CloseCover>
									) : (
										<div></div>
									)}
									<Text pointer fontWeight={600}>
										{openDeletePop ? 'Remove location?' : 'Location name'}
									</Text>
									<CloseCover
										onClick={() => {
											setOpenActionPop(false)
											setOpenDeletePop(false)
										}}>
										<IoMdClose cursor="pointer" />
									</CloseCover>
								</Flexed>
								<Spacer height={0.5} />
								{!openDeletePop && (
									<>
										<List
											onClick={() => {
												setOpenLocationPop(true)
												setOpenActionPop(false)
											}}>
											Change location
										</List>
										<List onClick={() => setOpenDeletePop(true)}>Remove</List>
									</>
								)}
								{openDeletePop && (
									<>
										{' '}
										<Text>Removing a location is permanent. There is no undo.</Text>
										<Spacer height={0.5} />
										<Delete
											onClick={() => {
												updateCardDetails(true, '')
												setOpenActionPop(false)
												setOpenDeletePop(false)
											}}>
											Remove
										</Delete>
									</>
								)}
							</Popup>
						)}
						{openLocationPop && (
							<LocationPop
								location={location}
								setLocation={setLocation}
								goBack={() => {
									setOpenLocationPop(false)
									setOpenActionPop(true)
								}}
								cardDetails={cardDetails}
								loadAgain={loadAgain}
								onCloseModal={() => setOpenLocationPop(false)}
							/>
						)}
					</ActionFlex>
				</Flexed>
			</Content>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background: ${palette.silver_14};
	border-radius: 0.188rem;
`

const Content = styled.div`
	padding: 0.5rem 0.6rem;
`

const ActionFlex = styled(Flexed)`
	position: relative;
`

const ActionsText = styled(Flexed)`
	width: 2rem;
	height: 2rem;
	border-radius: 0.188rem;
	&:hover {
		background: ${palette.silver_2};
	}
`

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({cancel}) => (cancel ? palette.black : palette.white)};
	background: ${({cancel}) => (cancel ? palette.white : palette.purple_1)};
	font-weight: 600;
	cursor: pointer;
	width: 5rem;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		color: ${({cancel}) => (cancel ? palette.black : palette.white)};
		background: ${({cancel}) => (cancel ? palette.silver_14 : palette.purple_2)};
	}
`

const Delete = styled(Button)`
	width: 100%;
	background: ${palette.red};
	&:hover {
		background: ${palette.red_hover};
	}
`

const List = styled(Flexed)`
	width: 100%;
	padding: 0.4rem 0.313rem;
	border-radius: 0.3rem;
	background: ${({active}) => (active ? palette.silver_1 : palette.white)};
	position: relative;
	cursor: pointer;
	&:hover {
		background: ${palette.silver_1};
	}
`

export default LocationList
