import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import Board from '../dnd/board/Board'
import Loader from '../components/common/Loader'
import {toast} from 'react-toastify'
import {api} from '../components/api/callAxios'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {updateBoard, updateBoardList} from '../actions/authActions'
import useRouter from '../hooks/router'
import {Col, Container, Row} from 'styled-bootstrap-grid'

const TaskManagement = () => {
	const [loading, setLoading] = useState(false)
	const [cards, setCards] = useState<any>([])
	const [boardDetails, setBoardDetails] = useState<any>('')
	const _updateTrelloList = useSelector<any>((state: any) => state.auth?.updateTrelloList)
	const _updateBoard = useSelector<any>((state: any) => state.auth?.updateBoard)
	const _filterOptions: any = useSelector<any>((state: any) => state.auth.filterCards)
	const updatedTrelloList: any = useSelector<any>((state: any) => state.auth?.updatedTrelloList)
	const dispatch = useDispatch()
	const router = useRouter()

	useEffect(() => {
		if (router?.query?.id) {
			getBoardById(router?.query?.id)
			setCards([])
			getCards(true)
		}
	}, [_updateTrelloList, router])

	useEffect(() => {
		if (router?.query?.id && _filterOptions && Object.keys(_filterOptions)?.length) {
			setCards([])
			getCards(true)
		}
	}, [_filterOptions])

	useEffect(() => {
		if (_updateBoard) {
			getBoardById(router?.query?.id)
		}
	}, [_updateBoard])

	const getBoardById = async (id?: any) => {
		try {
			await api
				.get(`/trello/get_board?id=${id}`)
				.then((response) => {
					if (response?.data?.success === true) {
						if (response?.data) {
							setBoardDetails(response?.data?.data)
							dispatch(updateBoard(false))
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const getCards = async (loading = true) => {
		try {
			setLoading(loading)
			const response = await api.post(`/trello/get_all_lists?id=${router?.query?.id}`, {filters: _filterOptions})
			if (response.data.success) {
				dispatch(updateBoardList(false))
				const data = response.data.data
				let formattedData: any = {}
				for (const card of data) {
					const listId = card.id
					const authorName = `${card?.title}_${listId}`
					let quote = []

					for (let i = 0; i < card?.taskListCards.length; i++) {
						const _quote = {
							...card?.taskListCards[i],
							id: `G${card?.taskListCards[i].id}`,
							title: card?.title,
							listData: card,
							list_id: card?.id,
							board_id: card?.boardId,
							card_id: card?.taskListCards[i]?.id,

							description: card?.taskListCards[i]?.description,
							attachments: card?.taskListCards[i]?.taskListCardAttachments?.length,
							viewColorLabel: card?.taskListCards[i]?.viewColorLabel,
							taskLabels: card?.taskListCards[i]?.taskLabels,
							content: card?.taskListCards[i]?.title,
							coverPhoto: card?.taskListCards[i]?.taskListCardAttachments?.find((e: any) => e?.makeCover == true),
							author: {
								id: card?.taskListCards[i]?.id?.toString(),
								name: card?.taskBoard?.name,
								url: null,
								avatarUrl: null,
								colors: {
									soft: '#efb300',
									hard: '#797979'
								},
								...card
							}
						}
						quote.push(_quote)
					}

					if (card?.taskListCards?.length === 0) {
						const _quote = {
							title: card?.title,
							author: {
								id: card?.id.toString(),
								name: card?.taskBoard.name,
								url: null,
								avatarUrl: null,
								colors: {
									soft: '#efb300',
									hard: '#797979'
								},
								...card
							}
						}
						quote.push(_quote)
					}

					if (!formattedData[authorName]) {
						formattedData[authorName] = quote
					} else {
						formattedData[authorName].push(...quote)
					}
					// console.log('======formattedData', formattedData, quote)
					setCards(formattedData)
				}
			}
			setLoading(false)
		} catch (error) {
			setLoading(false)
			toast.error('Something went wrong. Please try again later.')
		}
	}

	return (
		<Container fluid>
			{loading && <Loader />}
			<Wrapper imageUrl={boardDetails?.backgroundImage} backgroundColor={boardDetails?.backgroundColor}>
				<Board initial={cards} withScrollableColumns={true} onAddCard={getCards} boardDetails={boardDetails} filters={(e: any) => ''} />
			</Wrapper>
		</Container>
	)
}
const Wrapper = styled.div<any>`
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background: ${({imageUrl, backgroundColor}) => !imageUrl && !backgroundColor && palette.white};
	background-color: ${(props) => props.backgroundColor};
	background-image: url(${(props) => props.imageUrl});
`

const Section = styled.div`
	padding: 30px 15px;
	background: ${palette.white};
	border-radius: 5px;
`

export default TaskManagement
