import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'

const DeleteCard = ({onCloseModal, onDelete, isBoard, isBoardDelete, isMember}: any) => {
	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}
	console.log('====isBoardDelete', isBoardDelete)
	return (
		<Popup onClick={handleModalClick}>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				<div></div>
				<Text fontWeight={600}>{isBoardDelete ? 'Delete Board ?' : isMember ? 'leave baord ?' : isBoard ? 'Delete Board ?' : 'Delete card ?'} </Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>
			<Flexed margin="0.5rem 0rem 0rem 0rem" gap={1}>
				<Text type="xsmall" margin="0.5rem 0rem 0.2rem 0rem" fontWeight={600}>
					{isBoardDelete ? (
						'All lists, cards and actions will be deleted, and you won’t be able to re-open the board. There is no undo.'
					) : isMember ? (
						'You will be removed from all cards on this board.'
					) : isBoard ? (
						<>You can find and reopen closed boards at the bottom of your boards page. </>
					) : (
						'All actions will be removed from the activity feed and you won’t be able to re-open the card. There is no undo.'
					)}
				</Text>
				<Button onClick={onDelete && onDelete}>{isBoard ? 'Close' : isMember ? 'leave' : 'Delete'} </Button>
			</Flexed>
		</Popup>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	bottom: 1.5rem;
	z-index: 5;
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.red};
	font-weight: 600;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	cursor: pointer;
	justify-content: center;
	&:hover {
		background: ${palette.red_hover};
	}
`

export default DeleteCard
