import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Flexed, Text} from '../../styled/shared'
import {BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs'
import {useSelector} from 'react-redux'

const InputFieldAuth = ({
	label,
	value,
	required,
	type,
	placeholder,
	handleChange,
	disabled,
	error,
	errorMsg,
	bgTransparent,
	name,
	allowNumber,
	allowPhoneNumberOnly,
	allowText,
	allowOnlyNumbers,
	allowOnlyNumbersAndDecimal,
	info,
	onBlur,
	onFocus
}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _myRef: any = useRef()
	const [visible, setVisible] = useState(false)
	const [keyValue, setKeyValue] = useState<any>('')
	const [labelStyles, setLabelStyles] = useState({})

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (allowNumber) {
			const re = /^[0-9 ]+/
			let val = event.target.value.replace(' ', '')
			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
			}
		} else if (allowPhoneNumberOnly) {
			const re = /^[0-9 -]+$/
			let val = event.target.value.replace(' ', '')
			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
			}
		} else if (allowText) {
			const re = /^[a-z]+$/
			let val = event.target.value
			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value)
			}
		} else if (allowOnlyNumbers) {
			const re = /^\d+$/
			let val = event.target.value.replace(' ', '')
			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				handleChange(event.target.value)
			} else {
				if (re.test(val) || val?.length == 0) {
					if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
				}
			}
		} else if (allowOnlyNumbersAndDecimal) {
			const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/
			let val = event.target.value.replace(' ', '')
			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				handleChange(event.target.value)
			} else {
				if (re.test(val) || val?.length == 0) {
					if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
				}
			}
		} else {
			if (handleChange) handleChange(event.target.value)
		}
	}

	const onKeyDown = (e: any) => {
		e.preventDefault()
	}

	const handleClickOutside = (e: any) => {
		// @ts-ignore
		if (!_myRef.current.contains(e.target)) {
			setLabelStyles({})
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [])

	return (
		<>
			<InputWrapper
				ref={_myRef}
				onClick={() => {
					if (!disabled) {
						setLabelStyles({
							top: '27%',
							fontSize: '12px'
						})
					}
				}}>
				{label && (
					<Label active={value?.length > 0} style={labelStyles}>
						{label}
					</Label>
				)}
				<TextInput
					required={required}
					value={value}
					type={`${type && !visible ? (type === 'search' ? 'text' : type) : 'text'}`}
					disabled={disabled}
					placeholder={placeholder}
					error={error}
					name={name}
					onChange={(event: any) => onChange(event)}
					bgTransparent={bgTransparent}
					isDarkTheme={_isDarkTheme}
					onBlur={onBlur}
					onFocus={onFocus}
					onKeyDown={(e: any) => {
						if (type == 'date') {
							type == 'date' && onKeyDown(e)
						} else {
							setKeyValue(e.key)
						}
					}}
				/>
				{type === 'password' && (
					<Icon direction="row" align="center" justify="center" isDarkTheme={_isDarkTheme}>
						{visible ? (
							<Eye
								onClick={() => {
									setVisible(false)
								}}
								isDarkTheme={_isDarkTheme}
							/>
						) : (
							<CloseEye
								onClick={() => {
									setVisible(true)
								}}
								isDarkTheme={_isDarkTheme}
							/>
						)}
					</Icon>
				)}
				{type === 'search' && (
					<Icon isDarkTheme={_isDarkTheme} direction="row" align="center" justify="center">
						<Search isDarkTheme={_isDarkTheme} src="/images/icons/search.svg" />
					</Icon>
				)}
			</InputWrapper>
			{required && error && !disabled && (
				<Text fontSize={0.7} type="small" color="danger">
					{error?.message ? error?.message : error}
				</Text>
			)}
		</>
	)
}

export const Mandatory = styled.span`
	color: ${palette.danger};
`

const InputWrapper = styled.div<any>`
	position: relative;
`

const Label = styled.div<any>`
	text-transform: capitalize;
	position: absolute;
	top: ${({active}) => (active ? '27%' : '50%')};
	font-size: ${({active}) => (active ? '12px' : '15px')};
	left: 20px;
	color: ${palette.black_2};
	font-weight: bold;
	font-weight: 500;
	transform: translateY(-50%);
	transition: all 0.3s ease;
`

const TextInput = styled.input<any>`
	font-family: 'Roboto';
	width: 100%;
	padding: 25px 20px 10px;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	line-height: 1.42857143;
	border-radius: 0.2rem;
	border: none;
	color: ${({disabled}) => (disabled ? palette.gray_400 : `${palette.black_5}`)};
	width: 100%;
	border: 1px solid ${palette.silver_2};
	border-left: ${({required}) => (required ? `4px solid ${palette.purple_1}` : '')};
	// cursor: ${({disabled}) => (disabled ? `no-drop` : `pointer`)};
	background: ${({disabled, bgTransparent}) => (bgTransparent ? 'transparent' : disabled ? `` : `${palette.white}`)};

	// &:hover {
	// 	box-shadow: 0 0 0.31rem ${({error, disabled}) => (disabled ? 'none' : error ? `${palette.danger}` : 'rgba(0, 0, 0, 0.25)')};
	// }
	// &:focus {
	// 	border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.red}`)};
	// }

	&::placeholder {
		color: ${({disabled}) => (disabled ? palette.light_silver : palette.black)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({disabled}) => (disabled ? palette.light_silver : palette.black)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		// color: ${palette.black};
	}
`

const Icon = styled(Flexed)<any>`
	position: absolute;
	top: 0.063rem;
	bottom: 0.063rem;
	margin: auto;
	right: 0.063rem;
	width: 2.5rem;
	background: ${palette.white};
	border-bottom-right-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
`

const Eye = styled(BsFillEyeFill)<any>`
	font-size: 1.25rem;
	color: ${palette.black_5}
	opacity: 0.5;
	cursor: pointer;
`
const CloseEye = styled(BsFillEyeSlashFill)<any>`
	font-size: 1.25rem;
	color: ${palette.black_5}
	opacity: 0.5;
	cursor: pointer;
`

const Search = styled.img<any>`
	width: 1rem;
	filter: ${({isDarkTheme}) => (isDarkTheme ? 'invert(100%) sepia(100%) saturate(7%) hue-rotate(137deg) brightness(102%) contrast(102%);' : '')};
`

export default InputFieldAuth
