import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Text, Spacer, Flexed, Divider } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import InputField from '../common/InputField'
import DropDown from '../common/DropDown'
import InputDate from '../common/InputDate'

interface ICategoriesModalProps {
    onCloseModal: any
    numberOfAirTicket: any
    setNumberOfAirTicket: any

    airTicketFrequency: any
    setAirTicketFrequency: any
    airTicketFrequencyOption: any

    originCountry: any
    setOriginCountry: any
    originCountryOption: any

    originCity: any
    setOriginCity: any
    originCityOption: any

    destinationCountry: any
    setDestinationCountry: any
    destinationCountryOption: any

    destinationCity: any
    setDestinationCity: any
    destinationCityOption: any

    airTicketClass: any
    setAirTicketClass: any
    airTicketClassOption: any

    remarks: any
    setRemarks: any
}

const AirTicketModal = ({ onCloseModal, numberOfAirTicket, setNumberOfAirTicket, airTicketFrequency, setAirTicketFrequency, airTicketFrequencyOption, originCountry, setOriginCountry,originCountryOption, originCity, setOriginCity, originCityOption, destinationCountry, setDestinationCountry, destinationCountryOption, destinationCity, setDestinationCity, destinationCityOption, airTicketClass, setAirTicketClass, airTicketClassOption, remarks, setRemarks }: ICategoriesModalProps) => {
    const [numberOfAirTicketError, setNumberOfAirTicketError] = useState('');
    const [airTicketFrequencyError, setAirTicketFrequencyError] = useState('');
    const [originCountryError, setOriginCountryError] = useState('');
    const [originCityError, setOriginCityError] = useState('');
    const [destinationCountryError, setDestinationCountryError] = useState('');
    const [destinationCityError, setDestinationCityError] = useState('');
    const [airTicketClassError, setAirTicketClassError] = useState('');

    const formValidation = () => {
        let isValid = true
        if (numberOfAirTicket === '') {
            setNumberOfAirTicketError('This value is required.')
            isValid = false
        }
        if (airTicketFrequency?.value === '') {
            setAirTicketFrequencyError('This value is required.')
            isValid = false
        }
        if (originCountry?.value === '') {
            setOriginCountryError('This value is required.')
            isValid = false
        }
        if (originCity?.value === '') {
            setOriginCityError('This value is required.')
            isValid = false
        }
        if (destinationCountry?.value === '') {
            setDestinationCountryError('This value is required.')
            isValid = false
        }
        if (destinationCity?.value === '') {
            setDestinationCityError('This value is required.')
            isValid = false
        }
        if (airTicketClass?.value === '') {
            setAirTicketClassError('This value is required.')
            isValid = false
        }
        return isValid
    }

    return (
        <>
            <Modal
                open={true}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'blockModal'
                }}
                showCloseIcon={false}>
                <ModalWrapper>
                    <Head direction="row" align="center" justify="space-between" gap={1}>
                        <Text type="large" lineHeight="1.438" fontWeight="700" color="black_3">
                        Air Ticket
                        </Text>
                        <CrossIcon
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Spacer height={1} />
                    <Body>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <InputField label='Number of Air Ticket' required allowOnlyNumbers={true} value={numberOfAirTicket}
                                        error={numberOfAirTicketError}
                                        handleChange={(e: any) => {
                                            setNumberOfAirTicketError('')
                                            if (e === '') {
                                                setNumberOfAirTicketError('This value is required.')
                                            }
                                            setNumberOfAirTicket(e)
                                        }} />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <DropDown label='Air Ticket Frequency' error={airTicketFrequencyError} required firstSelected={airTicketFrequency.label} options={airTicketFrequencyOption} hasChanged={(value: any) => setAirTicketFrequency({ label: value, value: value })} dropDownListHeight='130px' />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <DropDown label='Origin Country' error={originCountryError} required firstSelected={originCountry.label} options={originCountryOption} hasChanged={(value: any) => setOriginCountry({ label: value, value: value })} dropDownListHeight='130px' />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <DropDown label='Origin City' error={originCityError} required firstSelected={originCity.label} options={originCityOption} hasChanged={(value: any) => setOriginCity({ label: value, value: value })} dropDownListHeight='130px' />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <DropDown label='Destination Country' error={destinationCountryError} required firstSelected={destinationCountry.label} options={destinationCountryOption} hasChanged={(value: any) => setDestinationCountry({ label: value, value: value })} dropDownListHeight='130px' />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <DropDown label='Destination City' error={destinationCityError} required firstSelected={destinationCity.label} options={destinationCityOption} hasChanged={(value: any) => setDestinationCity({ label: value, value: value })} dropDownListHeight='130px' />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <DropDown label='Air Ticket Class' error={airTicketClassError} required firstSelected={airTicketClass.label} options={airTicketClassOption} hasChanged={(value: any) => setAirTicketClass({ label: value, value: value })} dropDownListHeight='130px' />
                                    <Spacer height={1} />
                                </Col>
                                <Col>
                                    <InputField label='Remarks' type='textarea' value={remarks}
                                        handleChange={(e: any) => {
                                            setRemarks(e)
                                        }} />
                                    <Spacer height={1} />
                                </Col>
                            </Row>
                        </Container>
                    </Body>
                    <Footer direction="row" align="center" justify="flex-end" gap={1}>
                        <Button border="red" color="white" bg="red" bg_hover="red_hover" onClick={() => {
                            setNumberOfAirTicket('');
                            setAirTicketFrequency({ label: '6 Months', value: '6 Months' });
                            setOriginCountry({ label: '', value: '' });
                            setOriginCity({ label: '', value: '' });
                            setDestinationCountry({ label: '', value: '' });
                            setDestinationCity({ label: '', value: '' });
                            setAirTicketClass({ label: 'First Class', value: 'First Class' });
                            setRemarks('');

                            setNumberOfAirTicketError('');
                            setAirTicketFrequencyError('');
                            setOriginCountryError('');
                            setOriginCityError('');
                            setDestinationCountryError('');
                            setDestinationCityError('');
                            setAirTicketClassError('');
                        }}>
                            Reset
                        </Button>
                        <Button border="purple_2" color="white" bg="purple_1" bg_hover="purple_2" onClick={() => {
                            if (formValidation()) {
                                onCloseModal();
                            }
                        }} >
                            Save
                        </Button>
                    </Footer>
                </ModalWrapper>
            </Modal>
        </>
    )
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
    background-color: ${palette.gray_1};
    border-bottom: 1px solid ${palette.silver};
`

const Body = styled.div`
	padding: 0rem 1.5rem 0.5rem 1.5rem;
    ${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 600px;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

const Footer = styled(Flexed)`
    border-top: 1px solid ${palette.silver};
	padding: 1rem 1.5rem;
`

const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: 2rem;
	padding: 6px 16px;
	font-size: 14px;
	border: 1px solid ${({ border }) => palette[border]};
	color: ${({ color }) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ bg }) => palette[bg]};
    cursor:pointer;
	&:hover {
		background: ${({ bg_hover }) => palette[bg_hover]};
	}
`

export default AirTicketModal
