export const CommonEnum = Object.freeze({
	AppName: 'Hr-system',
	logo: '/images/Logo.png',
	fbUrl: 'https://www.facebook.com/SmartSoftStudios',
	linkedinUrl: 'https://www.linkedin.com/company/smart-soft-studios',
	websiteUrl: 'https://www.smartsoftstudios.com/',
	appStoreUrl: 'https://itunes.apple.com/app/',
	googleStoreUrl: 'https://play.google.com/store/apps/details?id={YOUR_PACKAGE_NAME}',
	contactUsServerUrl: `https://api.economicsale.finance/amore/`,
	second_widget: false,
	user_default: '/images/default_image.png',
})

//get initials from text
export function getInitials(name) {
	const words = name.split(' ');
	const initials = words.map(word => word.charAt(0).toUpperCase());
	return initials.join('');
}

export const colors = [
	"hsl(195, 100%, 25%)",
	"hsl(278, 100%, 25%)",
	"hsl(120, 100%, 25%)",
	"hsl(294, 100%, 25%)",
	"hsl(284, 100%, 25%)",
	"hsl(296, 100%, 25%)",
	"hsl(113, 100%, 25%)",
	"hsl(189, 100%, 25%)",
	"hsl(259, 100%, 25%)",
	"hsl(23, 100%, 25%)",
	"hsl(240, 100%, 25%)",
	"hsl(261, 100%, 25%)",
	"hsl(4, 100%, 25%)",
	"hsl(55, 100%, 25%)",
	"hsl(301, 100%, 25%)",
	"hsl(256, 100%, 25%)",
	"hsl(275, 100%, 25%)",
	"hsl(284, 100%, 25%)",
	"hsl(37, 100%, 25%)",
	"hsl(81, 100%, 25%)",
	"hsl(262, 100%, 25%)",
	"hsl(186, 100%, 25%)",
	"hsl(112, 100%, 25%)",
	"hsl(277, 100%, 25%)",
	"hsl(48, 100%, 25%)",
	"hsl(174, 100%, 25%)",
	"hsl(31, 100%, 25%)",
	"hsl(215, 100%, 25%)",
	"hsl(308, 100%, 25%)",
	"hsl(17, 100%, 25%)",
	"hsl(203, 100%, 25%)",
	"hsl(94, 100%, 25%)",
	"hsl(351, 100%, 25%)",
	"hsl(272, 100%, 25%)",
	"hsl(82, 100%, 25%)",
	"hsl(5, 100%, 25%)",
	"hsl(215, 100%, 25%)",
	"hsl(319, 100%, 25%)",
	"hsl(225, 100%, 25%)",
	"hsl(313, 100%, 25%)",
	"hsl(318, 100%, 25%)",
	"hsl(234, 100%, 25%)",
	"hsl(313, 100%, 25%)",
	"hsl(243, 100%, 25%)",
	"hsl(286, 100%, 25%)",
	"hsl(326, 100%, 25%)",
	"hsl(332, 100%, 25%)",
	"hsl(64, 100%, 25%)",
	"hsl(265, 100%, 25%)",
	"hsl(259, 100%, 25%)"
]

export const colorsList = [...new Set(colors)];