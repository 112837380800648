import React, {useState, useEffect, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {MDBDataTableV5} from 'mdbreact'
import {Flexed, Spacer, Text} from '../styled/shared'
import {NavLink, createSearchParams, useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../styled/colors'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import {Loading} from '../styled/shared'
import {Back} from '../components/employee/EmployeeDetails'
import {IoMdCheckmark} from 'react-icons/io'
import {FaUndo} from 'react-icons/fa'
import {MdOutlinePendingActions} from 'react-icons/md'
import AddRequestSideBar from '../components/common/AddRequestSideBar'
import {HiDotsHorizontal} from 'react-icons/hi'
import {IoArrowUndoOutline} from 'react-icons/io5'
import {useSelector} from 'react-redux'
import DeleteModal from '../components/modals/DeleteModal'
import moment from 'moment'
import {ProfileImg} from './Team'

const Requests = () => {
	const {pathname} = useLocation()
	let _navigate = useNavigate()
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')
	const [isLoading, setIsLoading] = useState<any>(false)
	const [sidebar, setSidebar] = useState(false)
	const [filterName, setFilterName] = useState('')
	const [totalRecord, setTotalRecord] = useState(0)
	const [requestName, setRequestName] = useState('Leave Request')
	const [deleteModel, setDeleteModel] = useState(false)
	const [deleteItemId, setDeleteItemId] = useState('')
	const [leaveRequests, setLeaveRequests] = useState<any>({
		columns: [
			{label: 'Employee Name', field: 'employeeName', width: 200},
			{label: 'Leave From', field: 'leaveFrom', width: 200},
			{label: 'Leave To', field: 'leaveTo', width: 200},
			{label: 'Status', field: 'status', width: 200},
			{label: 'Action', field: 'action', sort: String('disabled'), width: 50}
		],
		rows: []
	})
	const [expenseRequests, setExpenseRequests] = useState({
		columns: [
			{label: 'Employee Name', field: 'employeeName', width: 200},
			{label: 'Expense Category', field: 'expenseCategory', width: 200},
			{label: 'Expense From', field: 'from', width: 200},
			{label: 'Expense To', field: 'to', width: 200},
			{label: 'Actual Amount', field: 'amount', width: 200},
			{label: 'Status', field: 'status', width: 200},
			{label: 'Action', field: 'action', sort: String('disabled'), width: 50}
		],
		rows: []
	})
	const [loanRequest, setLoanRequest] = useState({
		columns: [
			{label: 'Employee Name', field: 'employeeName', width: 400},
			{label: 'Loan Type', field: 'loanType', width: 200},
			{label: 'Loan Taken Date', field: 'loanTakenDate', width: 200},
			{label: 'Payment Start Date', field: 'paymentStartDate', width: 200},
			{label: 'Loan Amount', field: 'loanAmount', width: 200},
			{label: 'Recovery Loan Amount', field: 'recoveryLoanAmount', width: 200},
			{label: 'Status', field: 'status', width: 200},
			{label: 'Action', field: 'action', sort: String('disabled'), width: 50}
		],
		rows: []
	})
	const [documentRequest, setDocumentRequest] = useState({
		columns: [
			{label: 'Employee Name', field: 'employeeName', width: 200},
			{label: 'Reamrks', field: 'remarks', width: 200},
			{label: 'Status', field: 'status', width: 200},
			{label: 'Action', field: 'action', sort: String('disabled'), width: 50}
		],
		rows: []
	})

	useEffect(() => {
		allRequests()
	}, [requestName, filterName])

	const allRequests = async () => {
		setIsLoading(true)
		{
			_loginUserStatus === 'admin'
				? await api
						.post('/request/all_requests', {
							requestName: requestName,
							status: filterName
						})
						.then((response) => {
							if (response?.data?.success == true) {
								if (response?.data?.data?.data?.length > 0) {
									if (requestName === 'Leave Request') {
										setLeaveRequests((preValue: any) => {
											return {
												columns: [...preValue.columns],
												rows: []
											}
										})
										setTotalRecord(response?.data?.data?.data?.length)
										response?.data?.data?.data?.map((data: any, index: any) => {
											createLeaveRequestTable(data)
										})
									} else if (requestName === 'Expense Request') {
										setExpenseRequests((preValue) => {
											return {
												columns: [...preValue.columns],
												rows: []
											}
										})
										setTotalRecord(response?.data?.data?.data?.length)
										response?.data?.data?.data?.map((data: any, index: any) => {
											createExpenseRequestTable(data)
										})
									} else if (requestName === 'Loan Request') {
										setLoanRequest((preValue) => {
											return {
												columns: [...preValue.columns],
												rows: []
											}
										})
										setTotalRecord(response?.data?.data?.data?.length)
										response?.data?.data?.data?.map((data: any, index: any) => {
											createLoanRequestTable(data)
										})
									} else if (requestName === 'Document Request') {
										setDocumentRequest((preValue) => {
											return {
												columns: [...preValue.columns],
												rows: []
											}
										})
										setTotalRecord(response?.data?.data?.data?.length)
										response?.data?.data?.data?.map((data: any, index: any) => {
											createDocumentRequestTable(data)
										})
									}
								}
							} else if (response?.data?.success == false) {
								setTotalRecord(0)
								setLeaveRequests((preValue: any) => {
									return {
										columns: [...preValue.columns],
										rows: []
									}
								})
								setDocumentRequest((preValue) => {
									return {
										columns: [...preValue.columns],
										rows: []
									}
								})
								setLoanRequest((preValue) => {
									return {
										columns: [...preValue.columns],
										rows: []
									}
								})
								setExpenseRequests((preValue) => {
									return {
										columns: [...preValue.columns],
										rows: []
									}
								})
							}
							setIsLoading(false)
						})
						.catch(function (error) {
							setIsLoading(false)
							toast.error('Something went wrong. Please try again later.')
						})
				: await api
						.post('/request/employee_requests', {
							requestName: requestName,
							status: filterName
						})
						.then((response) => {
							if (response?.data?.success == true) {
								if (response?.data?.data?.data?.length > 0) {
									if (requestName === 'Leave Request') {
										setLeaveRequests((preValue: any) => {
											return {
												columns: [...preValue.columns],
												rows: []
											}
										})
										setTotalRecord(response?.data?.data?.data?.length)
										response?.data?.data?.data?.map((data: any, index: any) => {
											createLeaveRequestTable(data)
										})
									} else if (requestName === 'Expense Request') {
										setExpenseRequests((preValue) => {
											return {
												columns: [...preValue.columns],
												rows: []
											}
										})
										setTotalRecord(response?.data?.data?.data?.length)
										response?.data?.data?.data?.map((data: any, index: any) => {
											createExpenseRequestTable(data)
										})
									} else if (requestName === 'Loan Request') {
										setLoanRequest((preValue) => {
											return {
												columns: [...preValue.columns],
												rows: []
											}
										})
										setTotalRecord(response?.data?.data?.data?.length)
										response?.data?.data?.data?.map((data: any, index: any) => {
											createLoanRequestTable(data)
										})
									} else if (requestName === 'Document Request') {
										setDocumentRequest((preValue) => {
											return {
												columns: [...preValue.columns],
												rows: []
											}
										})
										setTotalRecord(response?.data?.data?.data?.length)
										response?.data?.data?.data?.map((data: any, index: any) => {
											createDocumentRequestTable(data)
										})
									}
								}
							} else {
								setTotalRecord(0)
							}
							setIsLoading(false)
						})
						.catch(function (error) {
							setIsLoading(false)
							toast.error('Something went wrong. Please try again later.')
						})
		}
	}
	const updateRequestStatus = async (requestId: any, requestStatus: any) => {
		setIsLoading(true)
		await api
			.put(`/request/update_status/${requestId}`, {
				status: requestStatus
			})
			.then((response) => {
				if (response?.data?.success == true) {
					toast.success(response?.data?.message)
					allRequests()
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const removeRequest = async (requestId: any) => {
		setIsLoading(true)
		await api
			.delete(`/request/remove_request/${requestId}`)
			.then((response) => {
				if (response?.data?.success == true) {
					setDeleteModel(false)
					setDeleteItemId('')
					toast.success(response?.data?.message)
					if (requestName === 'Leave Request') {
						setLeaveRequests((preValue: any) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response?.data?.data?.data?.map((data: any, index: any) => {
							createLeaveRequestTable(data)
						})
					} else if (requestName === 'Expense Request') {
						setExpenseRequests((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response?.data?.data?.data?.map((data: any, index: any) => {
							createExpenseRequestTable(data)
						})
					} else if (requestName === 'Loan Request') {
						setLoanRequest((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response?.data?.data?.data?.map((data: any, index: any) => {
							createLoanRequestTable(data)
						})
					} else if (requestName === 'Document Request') {
						setDocumentRequest((preValue) => {
							return {
								columns: [...preValue.columns],
								rows: []
							}
						})
						response?.data?.data?.data?.map((data: any, index: any) => {
							createDocumentRequestTable(data)
						})
					}

					allRequests()
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const createLeaveRequestTable = (data: any) => {
		let employeeNameComponent = (
			<Flexed direction="row" align="center" justify="flex-start" gap={0.5}>
				<div>
					<ProfileImg>
						<img src={data?.employee_data?.image ? process.env.REACT_APP_IMAGE_URL + data?.employee_data?.image : '/images/default_user_profile_img.png'} alt="i" />
					</ProfileImg>
				</div>
				<div>
					{data?.employee_data?.firstName} {data?.employee_data?.lastName}
				</div>
			</Flexed>
		)
		let _obj = {
			employeeName: employeeNameComponent,
			_employeeName: data?.employee_data?.firstName + ' ' + data?.employee_data?.lastName,
			leaveFrom: moment(data?.from).format('DD-MM-YYYY'),
			leaveTo: moment(data?.to).format('DD-MM-YYYY'),
			status: <StatusIndicator active={data?.status}>{data?.status}</StatusIndicator>,
			action: (
				<Action>
					<HiDotsHorizontal fontSize="1.2rem" />
					<DropContent>
						{_loginUserStatus === 'admin' ? (
							<>
								<DropMenu onClick={() => updateRequestStatus(data?.id, 'approved')}>Approved</DropMenu>
								<DropMenu onClick={() => updateRequestStatus(data?.id, 'rejected')}>Reject</DropMenu>

								<DropMenu
									onClick={() => {
										setDeleteItemId(data?.id)
										setDeleteModel(true)
									}}>
									Delete
								</DropMenu>
								<DropMenu
									onClick={() => {
										_navigate({
											pathname: '/leave-request',
											search: createSearchParams({
												id: data?.id
											}).toString()
										})
									}}>
									Edit
								</DropMenu>
							</>
						) : null}
					</DropContent>
				</Action>
			)
		}
		setLeaveRequests((preValue: any): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}
	const createExpenseRequestTable = (data: any) => {
		let _obj = {
			employeeName: (
				<Flexed direction="row" align="center" justify="flex-start" gap={0.5}>
					<div>
						<ProfileImg>
							<img src={data?.employee_data?.image ? process.env.REACT_APP_IMAGE_URL + data?.employee_data?.image : '/images/default_user_profile_img.png'} alt="i" />
						</ProfileImg>
					</div>
					<div>
						{data?.employee_data?.firstName} {data?.employee_data?.lastName}
					</div>
				</Flexed>
			),
			_employeeName: data?.employee_data?.firstName + ' ' + data?.employee_data?.lastName,
			expenseCategory: data?.expenseCategory,
			from: moment(data?.from).format('DD-MM-YYYY'),
			to: moment(data?.to).format('DD-MM-YYYY'),
			amount: data?.expenseAmountActual,
			status: <StatusIndicator active={data?.status}>{data?.status}</StatusIndicator>,
			action: (
				<Action>
					<HiDotsHorizontal fontSize="1.2rem" />
					<DropContent>
						{_loginUserStatus === 'admin' ? (
							<>
								<DropMenu onClick={() => updateRequestStatus(data?.id, 'approved')}>Approved</DropMenu>
								<DropMenu onClick={() => updateRequestStatus(data?.id, 'rejected')}>Reject</DropMenu>
							</>
						) : null}
						<DropMenu
							onClick={() => {
								setDeleteItemId(data?.id)
								setDeleteModel(true)
							}}>
							Delete
						</DropMenu>
						<DropMenu
							onClick={() => {
								_navigate({
									pathname: '/expense-request',
									search: createSearchParams({
										id: data?.id
									}).toString()
								})
							}}>
							Edit
						</DropMenu>
					</DropContent>
				</Action>
			)
		}
		setExpenseRequests((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}
	const createLoanRequestTable = (data: any) => {
		let _obj = {
			employeeName: (
				<Flexed direction="row" align="center" justify="flex-start" gap={0.2}>
					<div>
						<ProfileImg>
							<img src={data?.employee_data?.image ? process.env.REACT_APP_IMAGE_URL + data?.employee_data?.image : '/images/default_user_profile_img.png'} alt="i" />
						</ProfileImg>
					</div>
					<div style={{fontSize: '0.8rem'}}>
						{data?.employee_data?.firstName} {data?.employee_data?.lastName}
					</div>
				</Flexed>
			),
			loanType: data?.loanType,
			_employeeName: data?.employee_data?.firstName + ' ' + data?.employee_data?.lastName,
			loanAmount: data?.loanAmount,
			loanTakenDate: data?.loanTakenDate ? moment(data?.loanTakenDate).format('DD-MM-YYYY') : '',
			paymentStartDate: data?.paymentStartDate ? moment(data?.paymentStartDate).format('DD-MM-YYYY') : '',
			recoveryLoanAmount: data?.recoveryLoanAmount,
			status: <StatusIndicator active={data?.status}>{data?.status}</StatusIndicator>,
			action: (
				<Action>
					<HiDotsHorizontal fontSize="1.2rem" />
					<DropContent>
						{_loginUserStatus === 'admin' ? (
							<>
								<DropMenu onClick={() => updateRequestStatus(data?.id, 'approved')}>Approved</DropMenu>
								<DropMenu onClick={() => updateRequestStatus(data?.id, 'rejected')}>Reject</DropMenu>
							</>
						) : null}
						<DropMenu
							onClick={() => {
								setDeleteItemId(data?.id)
								setDeleteModel(true)
							}}>
							Delete
						</DropMenu>
						<DropMenu
							onClick={() => {
								_navigate({
									pathname: '/loan-request',
									search: createSearchParams({
										id: data?.id
									}).toString()
								})
							}}>
							Edit
						</DropMenu>
					</DropContent>
				</Action>
			)
		}
		setLoanRequest((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}
	const createDocumentRequestTable = (data: any) => {
		let _obj = {
			employeeName: (
				<Flexed direction="row" align="center" justify="flex-start" gap={0.5}>
					<div>
						<ProfileImg>
							<img src={data?.employee_data?.image ? process.env.REACT_APP_IMAGE_URL + data?.employee_data?.image : '/images/default_user_profile_img.png'} alt="i" />
						</ProfileImg>
					</div>
					<div>
						{data?.employee_data?.firstName} {data?.employee_data?.lastName}
					</div>
				</Flexed>
			),
			_employeeName: data?.employee_data?.firstName + ' ' + data?.employee_data?.lastName,
			remarks: <RemarksText>{data?.description}</RemarksText>,
			status: <StatusIndicator active={data?.status}>{data?.status}</StatusIndicator>,
			action: (
				<Action>
					<HiDotsHorizontal fontSize="1.2rem" />
					<DropContent>
						{_loginUserStatus === 'admin' ? (
							<>
								<DropMenu onClick={() => updateRequestStatus(data?.id, 'approved')}>Approved</DropMenu>
								<DropMenu onClick={() => updateRequestStatus(data?.id, 'rejected')}>Reject</DropMenu>
							</>
						) : null}
						<DropMenu
							onClick={() => {
								setDeleteItemId(data?.id)
								setDeleteModel(true)
							}}>
							Delete
						</DropMenu>
						<DropMenu
							onClick={() => {
								_navigate({
									pathname: '/document-request',
									search: createSearchParams({
										id: data?.id
									}).toString()
								})
							}}>
							Edit
						</DropMenu>
					</DropContent>
				</Action>
			)
		}
		setDocumentRequest((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}

	return (
		<>
			<Section>
				<ContentFlexed>
					<Aside>
						<RequestBar>
							<AddButton direction="row" gap={1} onClick={() => setSidebar(true)}>
								<PlusIcon _ngcontent-idf-c268="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="16px" height="16px">
									<path
										_ngcontent-idf-c268=""
										d="M 14.970703 2.9726562 A 2.0002 2.0002 0 0 0 13 5 L 13 13 L 5 13 A 2.0002 2.0002 0 1 0 5 17 L 13 17 L 13 25 A 2.0002 2.0002 0 1 0 17 25 L 17 17 L 25 17 A 2.0002 2.0002 0 1 0 25 13 L 17 13 L 17 5 A 2.0002 2.0002 0 0 0 14.970703 2.9726562 z"></path>
								</PlusIcon>
								<Text type="normal" fontWeight={500} color="white" fontSize={1}>
									Add New Request
								</Text>
							</AddButton>
							<Flexed direction="row" align="center" justify="space-between" gap={1} margin="0.5rem 0rem 0rem 0rem"></Flexed>
							<SidebarContent>
								<RequestMenu active={requestName === 'Leave Request'} onClick={() => setRequestName('Leave Request')}>
									<IconContainer>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="22px" height="22px">
											<path fill="#FFCDD2" d="M5,38V14h38v24c0,2.2-1.8,4-4,4H9C6.8,42,5,40.2,5,38z"></path>
											<path fill="#F44336" d="M43,10v6H5v-6c0-2.2,1.8-4,4-4h30C41.2,6,43,7.8,43,10z"></path>
											<path fill="#B71C1C" d="M33 7A3 3 0 1 0 33 13 3 3 0 1 0 33 7zM15 7A3 3 0 1 0 15 13 3 3 0 1 0 15 7z"></path>
											<path fill="#BDBDBD" d="M33 3c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2s2-.9 2-2V5C35 3.9 34.1 3 33 3zM15 3c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2s2-.9 2-2V5C17 3.9 16.1 3 15 3z"></path>
											<g>
												<path
													fill="#F44336"
													d="M22.2,35.3c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.5s0.3-0.3,0.5-0.3c0.2-0.1,0.5-0.1,0.7-0.1s0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.3s0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0.3-0.6,0.3S24.3,37,24,37s-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5C22.3,35.8,22.2,35.6,22.2,35.3z M25.3,31h-2.6l-0.4-11h3.3L25.3,31z"></path>
											</g>
										</svg>
										<Text fontSize={0.9}>Leave Request</Text>
									</IconContainer>
								</RequestMenu>
							</SidebarContent>
							<SidebarContent>
								<RequestMenu active={requestName === 'Loan Request'} onClick={() => setRequestName('Loan Request')}>
									<IconContainer>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="22px" height="22px">
											<g data-name="Capital-Finance-Hand-Revenue-Money Bag">
												<path fill="#d8d7da" d="M27,17.66V32a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V17.66l10-10Z"></path>
												<rect width="6" height="8" x="14" y="25" fill="#acabb1"></rect>
												<circle cx="17" cy="18" r="3" fill="#e6e7e8"></circle>
												<polygon fill="#ee8700" points="27 4 27 12 23 8 23 4 27 4"></polygon>
												<path fill="#c6c5ca" d="M19,9.66,7.69,20.97a2.057,2.057,0,0,1-.69.45V17.66l10-10Z"></path>
												<path fill="#ee8700" d="M27,17.66l-10-10-10,10L5.69,18.97a2,2,0,0,1-2.83-2.83L16.29,2.71a1.008,1.008,0,0,1,1.42,0L23,8l4,4,4.14,4.14a2,2,0,1,1-2.83,2.83Z"></path>
												<path fill="#3d9ae2" d="M43,22a2.006,2.006,0,0,1,2,2,2.015,2.015,0,0,1-2,2H39a2.006,2.006,0,0,1-2-2,2.015,2.015,0,0,1,2-2Z"></path>
												<path fill="#5aaae7" d="M38,16c2,0,1,1,3,1s1-1,3-1,1,1,3,1l-4,5H39l-4-5C37,17,36,16,38,16Z"></path>
												<path
													fill="#5aaae7"
													d="M54,43.39A2.595,2.595,0,0,1,51.39,46H30.61a2.6,2.6,0,0,1-1.45-4.77l.95-.64A1.981,1.981,0,0,0,31,38.93V34a8,8,0,0,1,8-8h4a8,8,0,0,1,8,8v4.93a1.981,1.981,0,0,0,.89,1.66l.95.64A2.6,2.6,0,0,1,54,43.39Z"></path>
												<path
													fill="#ffc477"
													d="M44,52h.51a3.925,3.925,0,0,0,2-.54L57.18,45.3a3,3,0,0,1,3,5.2L46.55,58.37a15.968,15.968,0,0,1-9.77,2.05L24,59,2,61V47H21l2.62-1.57A9.973,9.973,0,0,1,28.77,44h1.3a9.853,9.853,0,0,1,3.72.72L37,46h7a3,3,0,0,1,0,6Z"></path>
												<path fill="#1e81ce" d="M42,35H40a1,1,0,0,1,0-2h2a1,1,0,0,1,1,1h2a3,3,0,0,0-3-3V30H40v1a3,3,0,0,0,0,6h2a1,1,0,0,1,0,2H40a1,1,0,0,1-1-1H37a3,3,0,0,0,3,3v1h2V41a3,3,0,0,0,0-6Z"></path>
												<rect width="10" height="2" x="34" y="51" fill="#ffb655" rx="1"></rect>
											</g>
										</svg>

										<Text fontSize={0.9}>Loan Request</Text>
									</IconContainer>
								</RequestMenu>
							</SidebarContent>
							<SidebarContent>
								<RequestMenu active={requestName === 'Document Request'} onClick={() => setRequestName('Document Request')}>
									<IconContainer>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="22px" height="22px">
											<path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path>
											<path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path>
											<path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
										</svg>
										<Text fontSize={0.9}>Document Request</Text>
									</IconContainer>
								</RequestMenu>
							</SidebarContent>
						</RequestBar>
					</Aside>
					<RightContainer fluid>
						<HeaderFlexed>
							<Title direction="row" gap={0.3} align="center">
								<Text type="normal" fontWeight={500} color="black" fontSize={1.5}>
									{requestName}
								</Text>
								<Flexed direction="row" align="baseline" gap={0.3}>
									<DotPulse />
									<Text type="normal" fontWeight={500} color="black" fontSize={0.9}>
										Total Records: {totalRecord}
									</Text>
								</Flexed>
							</Title>
							<FilterFlexed direction="row" align="center" gap={0.5}>
								<FilterButtons onClick={() => setFilterName('')}>
									<IoArrowUndoOutline />
									Reset
								</FilterButtons>

								<FilterButtons active={filterName === 'approved'} onClick={() => setFilterName('approved')}>
									<IoMdCheckmark />
									Approve
								</FilterButtons>
								<FilterButtons active={filterName === 'rejected'} onClick={() => setFilterName('rejected')}>
									<FaUndo />
									Reject
								</FilterButtons>
								<FilterButtons active={filterName === 'pending'} onClick={() => setFilterName('pending')}>
									<MdOutlinePendingActions />
									Pending
								</FilterButtons>
							</FilterFlexed>
						</HeaderFlexed>
						<Row>
							<Col>
								{requestName == 'Leave Request' ? (
									<TableWraper>
										<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={leaveRequests} fullPagination searchTop searchBottom={false} searching />
									</TableWraper>
								) : requestName === 'Expense Request' ? (
									<TableWraper>
										<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={expenseRequests} fullPagination searchTop searchBottom={false} />
									</TableWraper>
								) : requestName === 'Loan Request' ? (
									<TableWraper>
										<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={loanRequest} fullPagination searchTop searchBottom={false} />
									</TableWraper>
								) : requestName === 'Document Request' ? (
									<TableWraper>
										<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={documentRequest} fullPagination searchTop searchBottom={false} />
									</TableWraper>
								) : null}
							</Col>
						</Row>
					</RightContainer>
				</ContentFlexed>
			</Section>
			{sidebar && (
				<AddRequestSideBar
					data={'postId'}
					show={sidebar}
					onClick={() => {
						setSidebar(true)
					}}
					onClose={() => {
						setSidebar(false)
					}}
				/>
			)}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
			{deleteModel && deleteItemId && (
				<DeleteModal
					open={deleteModel}
					deletePost={() => removeRequest(deleteItemId)}
					modalStatus={''}
					title={`Delete this ${requestName} ?`}
					body={`Are you sure you want to delete this ${requestName} ?`}
					onCloseModal={() => setDeleteModel(false)}
				/>
			)}
		</>
	)
}

const Section = styled.div``
const Title = styled(Flexed)`
	padding: 1rem 0rem;
	z-index: 2;
	align-items: baseline;
`

const PlusIcon = styled.svg`
	max-width: 25px;
	fill: ${palette.white};
	stroke: ${palette.white};
	stroke-width: 1px;
	stroke-linecap: round;
`

const ContentFlexed = styled(Flexed)`
	${media.lg`
		flex-direction: row;
	`};
`

const Aside = styled.div`
	background-color: #fafafa;
	padding: 10px 10px 15px;
	border-radius: 4px;
	${media.lg`
		position: fixed;
		width: 300px;
		height: 100vh;
	`};
`
const RightContainer = styled(Container)`
	background-color: ${palette.white};
	${media.lg`margin-left: 300px;`};
`
const AddButton = styled(Flexed)`
	background-color: ${palette.purple_1};
	padding: 10px 12px;
	border-radius: 7px;
	white-space: nowrap;
	vertical-align: middle;
	margin-top: 10px;
	align-items: center;
`
const RequestBar = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	gap: 0.5rem;
	flex-wrap: wrap;
	${media.lg`
	display: block;
	`}
`

const HeaderFlexed = styled(Flexed)`
	${media.lg`
		flex-direction:row;
		justify-content:space-between;
	`}
`

const FilterFlexed = styled(Flexed)`
	flex-wrap: wrap;
	${media.lg`
		flex-wrap: nowrap;
	`}
`

const SidebarContent = styled.div`
	display: flex;
	flex-direction: column;
	&:hover {
		background-color: ${palette.white};
	}
`
const IconContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	padding: 10px;
	svg {
		width: 24px;
		height: 24px;
	}
`

const RequestMenu = styled(NavLink)<any>`
	border-left: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
	background-color: ${({active}) => (active ? `${palette.white}` : '')};
`
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const DotPulse = styled.div`
	height: 6px;
	width: 6px;
	background: ${palette.purple_1};
	border-radius: 50%;
	animation: ${pulseAnimation} 2s infinite;
`
const FilterButtons = styled(Back)<any>`
	background: ${({active}) => (active ? `${palette.purple_1}` : 'white')};
	color: ${({active}) => (active ? `${palette.white}` : `${palette.purple_1}`)};
	border: 1px solid ${palette.purple_1};
`
const TableWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	border-radius: 5px;
	padding: 15px 0px;
	${media.lg`padding: 15px;`};
`
const DropContent = styled.div<any>`
	display: none;
	top: 1.7rem;
	position: absolute;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	min-width: 6.5rem;
	right: 1.5rem;
	box-shadow: ${palette.shadow};
	z-index: 1;
	border-radius: 0.5rem;
	overflow: hidden;
`

const Action = styled.div`
	position: relative;
	&:hover ${DropContent} {
		display: block;
	}
`

const DropMenu = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-size: 0.8rem;
	padding: 0rem 0.5rem 0rem 0.5rem;
	text-decoration: none;
	display: block;
	&:hover {
		background-color: ${palette.purple_1};
		color: ${palette.white};
	}
	z-index: 1;
	cursor: pointer;
	text-align: left;
`
const StatusIndicator = styled.div<any>`
	background-color: ${({active}) => (active === 'approved' ? `${palette.green}` : active === 'rejected' ? `${palette.red}` : '')};
	color: white;
	color: ${({active}) => (active === 'approved' ? `${palette.white}` : active === 'rejected' ? `${palette.white}` : `${palette.black_3}`)};
	border-radius: 7px;
	padding: 1px 8px;
`
const RemarksText = styled(Text)`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
`

export default Requests
