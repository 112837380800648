import {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {MDBDataTableV5} from 'mdbreact'
import { Flexed, Text} from '../styled/shared'
import {  NavLink, useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../styled/colors'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import  {Back} from '../components/employee/EmployeeDetails'
import {HiDotsHorizontal} from 'react-icons/hi'
import CompanyDocumentModal from '../components/modals/CompanyDocumentModal'
import DeleteModal from '../components/modals/DeleteModal'
import {Loading} from '../styled/shared'
import {useSelector} from 'react-redux'
import { IoEye } from 'react-icons/io5'
import PdfViewModel from '../components/modals/PdfViewModel'

const Document = () => {
	const {pathname} = useLocation()
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')
	const _navigate = useNavigate()
	const _ref: any = useRef(null)
	const [isLoading, setIsLoading] = useState<any>(false)
	const [modalStatus, setModalStatus] = useState('deleteDocument')
	const [openCompanyDocumentModal, setOpenCompanyDocumentModal] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [openCreateNewDrop, setOpenCreateNewDrop] = useState(false)
	const [documentDetail, setDocumentDetail] = useState('')
	const [documentId, setDocumentId] = useState('')
	const [tabName, setTabName] = useState('companyFiles')
	const [openDocumentMode, setOpenDocumentModel] = useState(false)
	const [documentUrl, setDocumentUrl] = useState('')
	const [documentDetails, setDocumentDetails] = useState<any>('')

	const [users, setUsers] = useState({
		columns: [
			{label: 'Employee ID', field: 'employeeId', width: 200},
			{label: 'Employee Name', field: 'employeeName', width: 200},
			{label: 'Document Name', field: 'documentName', width: 200},
			{label: 'Document Type', field: 'documentType', width: 200},
			{label: 'Document', field: 'document', width: 200},
			{label: 'Expiry Date', field: 'expiryDate', width: 200},
			{label: '', field: 'view', sort: String('disabled'), width: 50}
		],
		rows: []
	})

	const [company, setCompany] = useState({
		columns: [
			{label: 'Document Name', field: 'documentName', width: 200},
			{label: 'Document Type', field: 'documentType', width: 200},
			{label: 'Document', field: 'document', width: 200},
			{label: 'Access Rights', field: 'accessrights', width: 200},
			{label: 'Expiry Date', field: 'expiryDate', width: 200},
			{label: 'Action', field: 'action', sort: String('disabled'), width: 50}
		],
		rows: []
	})

	useEffect(() => {
		employeesFiles()
		companyFiles()

		const handleClickOutside = (event: any) => {
			if (_ref?.current && !_ref?.current?.contains(event.target)) {
				setOpenCreateNewDrop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const companyFiles = async () => {
		{
			_loginUserStatus === 'admin'
				? await api
						.get('/admin/document/all_documents')
						.then((response) => {
							if (response?.data?.success == true) {
								setCompany((preValue) => {
									return {
										columns: [...preValue.columns],
										rows: []
									}
								})
								if (response?.data?.data?.data?.length > 0) {
									response?.data?.data?.data?.map((data: any, index: any) => {
										createCompanyFilesTable(data)
									})
								}
							}
						})
						.catch(function (error) {
							toast.error('Something went wrong. Please try again later.')
						})
				: await api
						.get('/admin/document/employee_access_documents')
						.then((response) => {
							if (response?.data?.success == true) {
								setCompany((preValue) => {
									return {
										columns: [...preValue.columns],
										rows: []
									}
								})
								if (response?.data?.data?.data?.length > 0) {
									response?.data?.data?.data?.map((data: any, index: any) => {
										createCompanyFilesTable(data)
									})
								}
							}
						})
						.catch(function (error) {
							toast.error('Something went wrong. Please try again later.')
						})
		}
	}
	const employeesFiles = async () => {
		if (_loginUserStatus === 'admin') {
			await api
				.get('/employee/document/all_documents')
				.then((response) => {
					if (response?.data?.success == true) {
						if (response?.data?.data?.data?.length > 0) {
							setUsers((preValue) => {
								return {
									columns: [...preValue.columns],
									rows: []
								}
							})
							response?.data?.data?.data?.map((data: any, index: any) => {
								createUserTable(data)
							})
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} else {
			await api
				.get('/employee/document/my_documents')
				.then((response) => {
					if (response?.data?.success == true) {
						if (response?.data?.data?.data?.length > 0) {
							setUsers((preValue) => {
								return {
									columns: [...preValue.columns],
									rows: []
								}
							})
							response?.data?.data?.data?.map((data: any, index: any) => {
								createUserTable(data)
							})
						}
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}
	const createUserTable = (data: any) => {
		let _obj = {
			employeeId: data?.employeeId,
			employeeName: <Flexed direction="row" align="center" gap={0.5}>
				<div>
					<ProfileImg>
						<img src={data?.employeeDocuments?.image ? process.env.REACT_APP_IMAGE_URL + data?.employeeDocuments?.image : '/images/default_user_profile_img.png'} alt="i" />
					</ProfileImg>
				</div>
				{data?.employeeDocuments?.firstName} {data?.employeeDocuments?.lastName}
			</Flexed>,
			documentName: data?.documentName,
			documentOff: data?.documentOff,
			documentType: data?.documentType,
			document: data?.document,
			expiryDate: data?.expiryDate,
			view: (
				<IoEye
					cursor="pointer"
					fontSize="1.2rem"
					onClick={() => {
						const imageUrl = process.env.REACT_APP_IMAGE_URL + data?.document
						setDocumentUrl(imageUrl)
						setOpenDocumentModel(true)
						setDocumentDetails(data)
					}}
				/>
			)
		}
		setUsers((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}
	const createCompanyFilesTable = (data: any) => {
		let _obj = {
			employeeId: data?.employeeId,
			documentName: data?.documentName,
			documentType: data?.documentType,
			document: data?.document,
			accessrights: data?.accessRights,
			expiryDate: data?.expiryDate,
			action: (
				<Action>
					<HiDotsHorizontal fontSize="1.2rem" />
					<DropContent>
						<DropMenu
							onClick={() => {
								const imageUrl = process.env.REACT_APP_IMAGE_URL + data?.document

								window.open(imageUrl, '_blank')
							}}>
							View
						</DropMenu>

						<DropMenu
							onClick={() => {
								setDocumentId(data?.id)
								setOpenDeleteModal(true)
							}}>
							Delete
						</DropMenu>
						<DropMenu
							onClick={() => {
								setDocumentDetail(data)
								setOpenCompanyDocumentModal(true)
							}}>
							Edit
						</DropMenu>
					</DropContent>
				</Action>
			)
		}
		setCompany((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}
	const deleteCompanyDocument = (id: any) => {
		setIsLoading(true)
		api.delete(`/admin/document/remove/${id}`)
			.then((response) => {
				if (response?.data?.success == true) {
					toast.success('Document deleted successfully')
					setOpenDeleteModal(false)
					companyFiles()
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	return (
		<>
			<>
				<Wrapper direction="row" align="center">
					<Content direction="row" gap={2} align="center" justify="space-between" pointer="pointer">
						<div>
							<Text type="normal" fontWeight={500} color="black">
								Team Detail
							</Text>
						</div>
						<Flexed direction="row" gap={1}>
							<DashboardMenu to="/team" active={pathname === '/team'}>
								<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/team'}>
									{_loginUserStatus === 'admin' ? 'Team' : 'Profile'}
								</StyledText>
							</DashboardMenu>
							<DashboardMenu to="/doc" active={pathname === '/doc'}>
								<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/doc'}>
									Docs
								</StyledText>
							</DashboardMenu>
							<DashboardMenu to="/tasks" active={pathname === '/tasks'}>
								<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/tasks'}>
									Tasks
								</StyledText>
							</DashboardMenu>
						</Flexed>
						<Flexed direction="row" align="center" gap={0.5}>
							{_loginUserStatus === 'admin' && tabName === 'companyFiles' ? (
								<GroupButton direction="row" align="center">
									<Button
										onClick={() => {
											setOpenCompanyDocumentModal(true)
										}}>
										{' '}
										Add Company Documents
									</Button>
								</GroupButton>
							) : null}
							<Back onClick={() => _navigate('/team')}>
								<svg _ngcontent-kxy-c151="" width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00acac">
									<path
										_ngcontent-kxy-c151=""
										d="M116 121v260c0 13.785 11.215 25 25 25s25-11.215 25-25V121c0-13.785-11.215-25-25-25S116 107.215 116 121zM211.98 243.107c-5.985 3.555-5.996 12.224 0 15.785l219.648 130.416c7.391 4.39 16.269-2.86 13.317-11.014l-42.561-117.712c-1.455-4.026-1.564-8.549-.078-12.823 45.595-131.625 43.871-124.776 43.871-127.686 0 0 0 0 0-.001 0-6.511-7.428-11.61-13.906-7.763L211.98 243.107zM346 451c0 8.272 6.728 15 15 15 8.271 0 15-6.728 15-15 0-8.271-6.729-15-15-15C352.728 436 346 442.729 346 451zM66 396c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 5.515 4.486 10 10 10S66 401.515 66 396zM226 71c0 13.785 11.215 25 25 25 13.785 0 25-11.215 25-25s-11.215-25-25-25C237.215 46 226 57.215 226 71zM250.998 371.003A10 10 0 10250.998 391.003 10 10 0 10250.998 371.003zM350.998 80.997A10 10 0 10350.998 100.997 10 10 0 10350.998 80.997zM51.003 151.003A10 10 0 1051.003 171.003 10 10 0 1051.003 151.003zM460.998 261.003A10 10 0 10460.998 281.003 10 10 0 10460.998 261.003z"></path>
								</svg>
								Back
							</Back>
						</Flexed>
					</Content>
				</Wrapper>
				<Section>
					<Container fluid>
						<Row>
							<Col>
								<HeadingWraper>
									<Flexed direction="row" align="center" justify="space-between" gap={0.2} margin="0rem 0rem 0rem 1rem">
										<Flexed direction="row" align="center" gap={1}>
											<DashboardMenu active={tabName === 'companyFiles'} onClick={() => setTabName('companyFiles')}>
												<StyledText type="normal" fontWeight={500} fontSize={0.8} active={tabName === 'companyFiles'}>
													Company Files
												</StyledText>
											</DashboardMenu>
											<DashboardMenu active={tabName === 'employeeFiles'} onClick={() => setTabName('employeeFiles')}>
												<StyledText type="normal" fontWeight={500} fontSize={0.8} active={tabName === 'employeeFiles'}>
													Employees Files
												</StyledText>
											</DashboardMenu>
										</Flexed>
									</Flexed>
								</HeadingWraper>
							</Col>
						</Row>
						<Row>
							<Col>
								{tabName === 'employeeFiles' && (
									<TableWraper>
										<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={users} fullPagination searchTop searchBottom={false} />
									</TableWraper>
								)}

								{tabName === 'companyFiles' && (
									<TableWraper>
										<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={company} fullPagination searchTop searchBottom={false} />
									</TableWraper>
								)}
							</Col>
						</Row>
					</Container>
				</Section>
				{openCompanyDocumentModal && (
					<CompanyDocumentModal
						onCloseModal={() => {
							setOpenCompanyDocumentModal(false)
							setIsLoading(false)
						}}
						documentDetail={documentDetail}
						refreshTable={() => {
							companyFiles()
						}}
						setIsLoading={() => {
							setIsLoading(true)
						}}
					/>
				)}
				{openDeleteModal && (
					<DeleteModal
						modalStatus={modalStatus}
						open={openDeleteModal}
						onCloseModal={() => {
							setOpenDeleteModal(false)
						}}
						deletePost={() => deleteCompanyDocument(documentId)}
					/>
				)}
				{isLoading && (
					<Loading>
						<div className="dots-loading"></div>
					</Loading>
				)}
			</>

			{openDocumentMode && <PdfViewModel open={openDocumentMode} onCloseModal={() => setOpenDocumentModel(false)} pdfUrl={documentUrl} title={documentDetails?.documentType + ' document of ' + documentDetails?.employeeDocuments?.firstName} documentDetails={documentDetails} />}
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`

const Content = styled(Flexed)`
	width: 100%;
`
const DashboardMenu = styled(NavLink)<any>`
	border-bottom: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
`
const StyledText = styled(Text)`
	padding: 10px 0px 10px 0px;
	color: ${({active}) => (active ? `${palette.purple_1}` : 'black')};
`

const Section = styled.div`
	padding: 25px 5px 20px 5px;
`
const TableWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	padding: 15px;
	border-radius: 5px;
`
const HeadingWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	border-radius: 5px;
	border-bottom: 1px solid #ced4da;
`
const ProfileImg = styled.div<any>`
	position: relative;
	width: 1.9rem;
	height: 1.9rem;
	border-radius: 100%;
	overflow: hidden;
	background: ${palette.purple_1};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	border: 1px solid ${palette.purple_1};
	& > img {
		width: 1.9rem;
		height: 1.9rem;
		border-radius: 3rem;
		object-fit: cover;
	}
`

const GroupButton = styled(Flexed)`
	position: relative;
`

const Button = styled.div<any>`
	background-color: ${palette.purple_1};
	border: 1px solid ${palette.purple_1};
	height: 34px;
	display: flex;
	align-items: center;
	padding: 10px 10px 10px 10px;
	border-radius: 3px;
	font-weight: 500;
	font-size: 0.813rem;
	line-height: 1.5rem;
	color: ${palette.white};
	letter-spacing: 0.02em;
	gap: 0.3rem;
	cursor: pointer;
`

const DropContent = styled.div<any>`
	display: none;
	// margin-top: 0rem;
	top: 1.7rem;
	position: absolute;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	min-width: 6.5rem;
	right: 1.5rem;
	box-shadow: ${palette.shadow};
	z-index: 1;
	border-radius: 0.5rem;
	overflow: hidden;
`

const Action = styled.div`
	position: relative;
	&:hover ${DropContent} {
		display: block;
	}
`

const DropMenu = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-size: 0.8rem;
	padding: 0rem 0.5rem 0rem 0.5rem;
	text-decoration: none;
	display: block;
	&:hover {
		background-color: ${palette.purple_1};
		color: ${palette.white};
	}
	z-index: 1;
	cursor: pointer;
	text-align: left;
`

export default Document
