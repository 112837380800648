import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {updateBoardList} from '../../actions/authActions'
import {CheckIconWrapper} from '../../pages/Auth/SignUp'
import {Checked} from '../../pages/Auth/SignUp'
import {UnChecked} from '../../pages/Auth/SignUp'
import Loader from '../common/Loader'

const MoveCard = ({onCloseModal, cardDetails, isCopy}: any) => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const [title, setTitle] = useState('')

	const [allBoards, setAllBoards] = useState<any[]>([])
	const [userBoards, setUserBoards] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const [optionsList, setOptionsList] = useState<any[]>([])
	const [listPosition, setListPosition] = useState<any[]>([])
	const [boardId, setBoardId] = useState<any>('')
	const [listId, setListId] = useState<any>('')
	const [cardPosition, setCardPosition] = useState<any>('')
	const dispatch = useDispatch()
	const [checkList, setChecklist] = useState<any>(true)
	const [members, setMembers] = useState<any>(true)
	const [attachments, setAttachments] = useState<any>(true)
	const [commnets, setComments] = useState<any>(true)
	const [labels, setLabels] = useState<any>(true)

	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	useEffect(() => {
		getAllBoards()
	}, [])

	useEffect(() => {
		if (isCopy) {
			setTitle(cardDetails?.title)

			setBoardId(cardDetails?.boardId)
			setCardPosition(cardDetails?.position)
			setListId(cardDetails?.listId)
		}
	}, [cardDetails, userBoards])

	const getAllBoards = async () => {
		setLoading(true)
		await api
			.get('/trello/get_all_boards')
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						if (response?.data?.data?.userboards?.length && !isCopy) {
							setOptionsList(response?.data?.data?.userboards[0]?.taskBoardLists)
							setBoardId(response?.data?.data?.userboards[0]?.id)
							if (response?.data?.data?.userboards[0]?.taskBoardLists?.length) {
								setListId(response?.data?.data?.userboards[0]?.taskBoardLists[0]?.id)
								setListPosition(response?.data?.data?.userboards[0]?.taskBoardLists[0]?.taskListCards)
								setCardPosition(1)
							} else {
								setListId('')
								setCardPosition('')
							}
						}
						if (response?.data?.data?.userboards?.length && isCopy) {
							let findBoard = response?.data?.data?.userboards.find((e: any) => e?.id == cardDetails?.boardId)
							let findList = findBoard?.taskBoardLists?.find((e: any) => e?.id == cardDetails?.listId)
							setOptionsList(findBoard?.taskBoardLists)
							setBoardId(findBoard?.id)
							if (findBoard?.taskBoardLists?.length) {
								setListId(findList?.id)
								setListPosition(findList?.taskListCards)
								setCardPosition(cardDetails?.position)
							}
						}

						const groupedData = response?.data?.data?.memberBoards.reduce((acc: any, curr: any) => {
							const adminName = curr.admin.name
							if (!acc[adminName]) {
								acc[adminName] = []
							}
							acc[adminName].push(curr)
							return acc
						}, {})
						setUserBoards(response?.data?.data?.userboards)
						setAllBoards(groupedData)
					}
					setLoading(false)
				}
			})
			.catch(function (error) {
				setLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	function handleSelectChange(event: any) {
		const selectedOption = event.target.value
		let optgroupLabel: any = null

		for (let childNode of event.target.childNodes) {
			if (childNode.tagName === 'OPTGROUP') {
				for (let option of childNode.childNodes) {
					if (option.tagName === 'OPTION' && option.selected) {
						optgroupLabel = childNode.label
						break
					}
				}
				if (optgroupLabel) break
			}
		}

		let employeeName = _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName
		if (_loginAdminDetails?.name && _loginAdminDetails?.name == optgroupLabel) {
			let _optionsList: any = userBoards?.find((board: any) => board?.id == selectedOption)
			setOptionsList(_optionsList?.taskBoardLists)
			if (_optionsList?.taskBoardLists?.length) {
				setListId(_optionsList?.taskBoardLists[0]?.id)
				setCardPosition(1)
			} else {
				setListId('')
				setCardPosition('')
			}
		} else if (!_loginAdminDetails?.name && employeeName != optgroupLabel) {
			let _optionsList: any = allBoards[optgroupLabel]?.find((board: any) => board?.id == selectedOption)
			setOptionsList(_optionsList?.taskBoardLists)
			if (_optionsList?.taskBoardLists?.length) {
				setListId(_optionsList?.taskBoardLists[0]?.id)
				setCardPosition(1)
			} else {
				setListId('')
				setCardPosition('')
			}
		}

		if (_loginUserDetails?.firstName && employeeName == optgroupLabel) {
			let _optionsList = userBoards?.find((board: any) => board?.id == selectedOption)
			setOptionsList(_optionsList?.taskBoardLists)
			if (_optionsList?.taskBoardLists?.length) {
				setListId(_optionsList?.taskBoardLists[0]?.id)
				setCardPosition(1)
			} else {
				setListId('')
				setCardPosition('')
			}
		} else if (!_loginUserDetails?.name && _loginAdminDetails?.name != optgroupLabel) {
			let _optionsList: any = allBoards[optgroupLabel]?.find((board: any) => board?.id == selectedOption)
			setOptionsList(_optionsList?.taskBoardLists)
			if (_optionsList?.taskBoardLists?.length) {
				setListId(_optionsList?.taskBoardLists[0]?.id)
				setCardPosition(1)
			} else {
				setListId('')
				setCardPosition('')
			}
		}

		setBoardId(selectedOption)
	}

	function handleSelectListChange(event: any) {
		const selectedOption = event.target.value
		let _optionsList: any = optionsList?.find((list: any) => list?.id == selectedOption)
		setListPosition(_optionsList?.taskListCards)

		setListId(selectedOption)
	}

	const moveCard = async () => {
		setLoading(true)
		if (cardDetails?.id && boardId && listId && listPosition) {
			await api
				.put(`/trello/update_card/${cardDetails?.id}`, {boardId, listId, position: cardPosition, isMove: true})
				.then((response) => {
					if (response?.data?.success === true) {
						setLoading(false)
						dispatch(updateBoardList(true))
					}
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		}
	}

	const addCard = async () => {
		try {
			await api
				.post(`/trello/create_card`, {title: title, boardId: boardId, listId: listId, position: cardPosition, cardId: cardDetails?.id, attachments, labels, isCopy: true, members, checkList, comments: commnets})
				.then((response) => {
					if (response?.data?.success == true) {
						dispatch(updateBoardList(true))
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	console.log('=====card position', cardPosition, cardDetails)
	return (
		<>
			{loading && <Loader />}
			<Popup top="-13rem" onClick={handleModalClick}>
				<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
					<div></div>
					{isCopy ? <Text fontWeight={600}>Copy card</Text> : <Text fontWeight={600}>Move card</Text>}

					<CloseCover onClick={onCloseModal}>
						<IoMdClose cursor="pointer" />
					</CloseCover>
				</Flexed>
				<Flexed margin="0.5rem 0rem" gap={0.5}>
					{isCopy ? (
						<>
							<div>
								<Text type="xsmall" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
									Title
								</Text>
								<Input
									placeholder=""
									type="textarea"
									rows={2}
									value={title}
									onChange={(e: any) => {
										setTitle(e?.target?.value)
									}}
								/>
							</div>
							<Flexed direction="column" margin="0rem 0.5rem" align="flex-start" gap={0.2}>
								{cardDetails?.taskChecklists?.length ? (
									<Flexed direction="row" align="center" gap={0.2}>
										<Icon>
											<CheckIconWrapper>
												{checkList ? (
													<Checked
														onClick={() => {
															setChecklist(!checkList)
														}}
													/>
												) : (
													<UnChecked
														color="rgb(221 223 229)"
														onClick={() => {
															setChecklist(!checkList)
														}}
													/>
												)}
											</CheckIconWrapper>
										</Icon>
										<Text type="xsmall" fontWeight={600}>
											Checklists ({cardDetails?.taskChecklists?.length})
										</Text>
									</Flexed>
								) : (
									''
								)}
								{cardDetails?.taskListCardAttachments?.length ? (
									<Flexed direction="row" align="center" gap={0.2}>
										<Icon>
											<CheckIconWrapper>
												{attachments ? (
													<Checked
														onClick={() => {
															setAttachments(!attachments)
														}}
													/>
												) : (
													<UnChecked
														color="rgb(221 223 229)"
														onClick={() => {
															setAttachments(!attachments)
														}}
													/>
												)}
											</CheckIconWrapper>
										</Icon>
										<Text type="xsmall" fontWeight={600}>
											Attachments ({cardDetails?.taskListCardAttachments?.length})
										</Text>
									</Flexed>
								) : (
									''
								)}
								{cardDetails?.taskLabels?.length ? (
									<Flexed direction="row" align="center" gap={0.2}>
										<Icon>
											<CheckIconWrapper>
												{labels ? (
													<Checked
														onClick={() => {
															setLabels(!labels)
														}}
													/>
												) : (
													<UnChecked
														color="rgb(221 223 229)"
														onClick={() => {
															setLabels(!labels)
														}}
													/>
												)}
											</CheckIconWrapper>
										</Icon>
										<Text type="xsmall" fontWeight={600}>
											Labels ({cardDetails?.taskLabels?.length})
										</Text>
									</Flexed>
								) : (
									''
								)}
								{cardDetails?.taskListCardcomments?.length ? (
									<Flexed direction="row" align="center" gap={0.2}>
										<Icon>
											<CheckIconWrapper>
												{/* {e?.isCheck ? ( */}
												<Checked
													onClick={() => {
														console.log('=')
													}}
												/>
												{/* ) : (
														<UnChecked
															color="rgb(221 223 229)"
															onClick={() => {
																console.log('=')
															}}
														/>
													)} */}
											</CheckIconWrapper>
										</Icon>
										<Text type="xsmall" fontWeight={600}>
											Comments ({cardDetails?.taskListCardcomments?.length})
										</Text>
									</Flexed>
								) : (
									''
								)}

								{cardDetails?.taskCardMembers?.length ? (
									<Flexed direction="row" align="center" gap={0.2}>
										<Icon>
											<CheckIconWrapper>
												{members ? (
													<Checked
														onClick={() => {
															setMembers(!members)
														}}
													/>
												) : (
													<UnChecked
														color="rgb(221 223 229)"
														onClick={() => {
															setMembers(!members)
														}}
													/>
												)}
											</CheckIconWrapper>
										</Icon>
										<Text type="xsmall" fontWeight={600}>
											Card Members ({cardDetails?.taskCardMembers?.length})
										</Text>
									</Flexed>
								) : (
									''
								)}
							</Flexed>

							<Text type="xsmall" fontWeight={600}>
								Copy to...
							</Text>
						</>
					) : (
						<Text type="xsmall" fontWeight={600}>
							Select destination
						</Text>
					)}

					<Content>
						<Text type="xsmall" fontWeight={600}>
							Board
						</Text>
						<Select onChange={(e: any) => handleSelectChange(e)} value={boardId}>
							<optgroup label={_loginAdminDetails?.name ? _loginAdminDetails?.name : _loginUserDetails?.firstName ? _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName : ''}>
								{userBoards?.map((board: any, i: any) => (
									<option key={i} value={board?.id}>
										{board?.name}
									</option>
								))}
							</optgroup>
							{Object.keys(allBoards).map((board: any) => (
								<optgroup label={board}>
									{allBoards[board]?.map((res: any, i: any) => (
										<option key={i} value={res?.id}>
											{res?.name}
										</option>
									))}
								</optgroup>
							))}
						</Select>
					</Content>
					<Grid>
						<Content>
							<Text type="xsmall" fontWeight={600}>
								List
							</Text>
							<Select onChange={handleSelectListChange} value={listId}>
								{optionsList?.length ? (
									optionsList?.map((list: any, i: any) => (
										<option key={i} value={list?.id}>
											{list?.title}
										</option>
									))
								) : (
									<option>No Lists</option>
								)}
							</Select>
						</Content>
						<Content>
							<Text type="xsmall" fontWeight={600}>
								Position
							</Text>
							<Select
								onChange={(e) => {
									if (e.target.value) {
										setCardPosition(e.target.value)
									}
								}}
								value={cardPosition}>
								{optionsList?.length ? (
									listPosition?.length ? (
										listPosition?.map((list: any, i: any) => (
											<option key={i} value={i + 1}>
												{i + 1}
											</option>
										))
									) : (
										<option value={1}>1</option>
									)
								) : (
									<option>N/A</option>
								)}
							</Select>
						</Content>
					</Grid>
					{isCopy ? <Button onClick={() => addCard()}>Create card</Button> : <Button onClick={() => moveCard()}>Move</Button>}
				</Flexed>
			</Popup>
		</>
	)
}

export const Icon = styled.span`
	font-size: 1.3rem;
	color: black;
	width: 2rem;
`

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Content = styled.div<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem 0.8rem;
	background: ${palette.silver_1};
	&:hover {
		background: ${palette.silver_2};
	}
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.purple_1};
	font-weight: 600;
	cursor: pointer;
	width: fit-content;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
`

const Select = styled.select`
	border: none;
	cursor: pointer;
	background: transparent;
	width: 100%;
	max-height: 300px;
	outline: none;
	font-weight: 600;
	font-size: 0.75rem;
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({top}) => top};
	z-index: 5;
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 0.5fr;
	gap: 0.5rem;
`

const Input = styled.textarea<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
	outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
`

export default MoveCard
