import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'
import {useSelector} from 'react-redux'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {getInitials} from '../../utils'

const AddMemberPop = ({top, left, onCloseModal, employees, loadAgain, cardDetails, boardMembers}: any) => {
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [members, setMembers] = useState<any>([])
	const [query, setQuery] = useState<any>('')
	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}
	useEffect(() => {
		if (query?.trim()?.length) {
			const filteredInvitations = employees.filter((res: any) => {
				const receiverEmail = res.receiver.email.toLowerCase()
				const firstName = res.receiver.firstName.toLowerCase()
				const lastName = res.receiver.lastName.toLowerCase()
				return receiverEmail.includes(query.toLowerCase()) || firstName.includes(query.toLowerCase()) || lastName.includes(query.toLowerCase())
			})
			setMembers(filteredInvitations)
		} else {
			setMembers(employees)
		}
	}, [employees, query])

	const updateInvitation = async (id?: any, cardMember?: any, cardId?: any) => {
		try {
			await api
				.put(`/trello/update_invitation/${id}`, {cardMember, cardId})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const addCardMember = async (invitationId?: any, employeeId?: any, cardId?: any, taskBoardId?: any, cardMember?: any, isAdmin?: any) => {
		try {
			await api
				.post(`/trello/add_card_member`, {employeeId, cardId, invitationId, taskBoardId, cardMember, isAdmin})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	return (
		<>
			<Popup top={top} left={left} onClick={handleModalClick}>
				<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
					<div></div>
					<Text fontWeight={600}>Members</Text>
					<CloseCover onClick={onCloseModal}>
						<IoMdClose cursor="pointer" color="black" />
					</CloseCover>
				</Flexed>
				<Spacer height={0.5} />
				<Input placeholder="Search members" onChange={(e: any) => setQuery(e?.target?.value)} />

				<div>
					<Text type="xsmall" margin="1rem 0rem 0.3rem 0rem " color="black_3" fontWeight={700}>
						Card members
					</Text>
					{members?.length && members?.some((e: any) => e?.cardMember == true && (e?.employeeId || e?.adminId)) ? (
						members?.map((e: any) =>
							e?.cardMember && e?.employeeId ? (
								<MemberList margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" gap={0.5} onClick={() => addCardMember(e?.invitationId, e?.employee?.id, cardDetails?.id, cardDetails?.boardId, false)}>
									{e?.employee?.image ? (
										<ProfileImg>
											<img src={process.env.REACT_APP_IMAGE_URL + e?.employee?.image} alt={e?.employee?.firstName + ' ' + e?.employee?.lastName} />
										</ProfileImg>
									) : (
										<TagUser width="1.8rem" height="1.8rem">
											{e?.employee?.firstName + ' ' + e?.employee?.lastName && getInitials(e?.employee?.firstName + ' ' + e?.employee?.lastName)}
										</TagUser>
									)}
									<Text type="xsmall" color="black_3" fontWeight={500}>
										{e?.employee?.firstName + ' ' + e?.employee?.lastName}
									</Text>
								</MemberList>
							) : e?.cardMember && e?.adminId ? (
								<MemberList margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" gap={0.5} onClick={() => addCardMember('', '', cardDetails?.id, cardDetails?.boardId, true, true)}>
									{e?.admin?.logo ? (
										<ProfileImg>
											<img src={process.env.REACT_APP_IMAGE_URL + e?.admin?.logo} alt={e?.admin?.name} />
										</ProfileImg>
									) : (
										<TagUser width="1.8rem" height="1.8rem">
											{e?.admin?.name && getInitials(e?.admin?.name)}
										</TagUser>
									)}
									<Text type="xsmall" color="black_3" fontWeight={500}>
										{e?.admin?.name}
									</Text>
								</MemberList>
							) : (
								''
							)
						)
					) : (
						<Text type="xsmall" margin="0.5rem 0rem 0.1rem 0rem " color="black_3" fontWeight={400}>
							No Card Member Found
						</Text>
					)}
				</div>
				<div>
					{_loginUserDetails?.firstName && boardMembers?.length == 0 ? (
						''
					) : (
						<Text type="xsmall" margin="1rem 0rem 0.3rem 0rem " color="black_3" fontWeight={700}>
							Board members
						</Text>
					)}
					{boardMembers?.length
						? boardMembers?.map((e: any) => (
								<MemberList margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" gap={0.5} onClick={() => addCardMember(e?.id, e?.receiver?.id, cardDetails?.id, cardDetails?.boardId, true)}>
									{e?.receiver?.image ? (
										<ProfileImg>
											<img src={process.env.REACT_APP_IMAGE_URL + e?.receiver?.image} alt={e?.receiver?.firstName + ' ' + e?.receiver?.lastName} />
										</ProfileImg>
									) : (
										<TagUser width="1.8rem" height="1.8rem">
											{e?.receiver?.firstName + ' ' + e?.receiver?.lastName && getInitials(e?.receiver?.firstName + ' ' + e?.receiver?.lastName)}
										</TagUser>
									)}
									<Text type="xsmall" color="black_3" fontWeight={500}>
										{e?.receiver?.firstName + ' ' + e?.receiver?.lastName}
									</Text>
								</MemberList>
							))
						: members?.some((e: any) => e?.cardMember == true && e?.adminId) && (
								<Text type="xsmall" margin="0.5rem 0rem 0.1rem 0rem " color="black_3" fontWeight={400}>
									No Board Member Found
								</Text>
							)}

					{!_loginUserDetails?.firstName && _loginAdminDetails && !members?.some((e: any) => e?.cardMember == true && e?.adminId) ? (
						<MemberList margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" gap={0.5} onClick={() => addCardMember('', '', cardDetails?.id, cardDetails?.boardId, true, true)}>
							{_loginAdminDetails?.logo ? (
								<ProfileImg>
									<img src={process.env.REACT_APP_IMAGE_URL + _loginAdminDetails?.logo} alt={_loginAdminDetails?.name} />
								</ProfileImg>
							) : (
								<TagUser width="1.8rem" height="1.8rem">
									{_loginAdminDetails?.name ? getInitials(_loginAdminDetails?.name) : _loginUserDetails?.firstName ? getInitials(_loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName) : ''}
								</TagUser>
							)}
							<Text type="xsmall" color="black_3" fontWeight={500}>
								{_loginAdminDetails?.name ? _loginAdminDetails?.name : _loginUserDetails?.firstName ? _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName : ''}
							</Text>
						</MemberList>
					) : (
						''
					)}
				</div>
			</Popup>
		</>
	)
}
const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

export const TagUser = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${({width}) => (width ? width : '2rem')};
	height: ${({height}) => (height ? height : '2rem')};
	border-radius: 100%;
	font-size: 0.875rem;
	font-weight: 600;
	color: ${palette.white};
	background-color: ${palette.purple_1};
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({top}) => top};
	z-index: 5;
	left: ${({left}) => (left ? left : '0')};
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

export const MemberList = styled(Flexed)<any>`
	padding: 4px 8px 4px 4px;
	border-radius: 3px;
	box-sizing: border-box;
	cursor: pointer;
	&:hover {
		background: ${({hover}) => (hover ? 'transparent' : palette.silver_1)};
	}
`

const Input = styled.input<any>`
	width: 100%;
	padding: 6px 12px;
	border: none;
	border-radius: 3px;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
	box-sizing: border-box;
	outline: none;
	font-size: 14px;
	color: black;
	&:focus {
		box-shadow: inset 0 0 0 2px var(--ds-border-input, ${palette.purple_1});
	}
`

export const ProfileImg = styled.div<any>`
	position: relative;
	width: 2rem;
	height: 2rem;
	border-radius: 100%;
	overflow: hidden;
	background: ${palette.purple_1};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	& > img {
		width: 2rem;
		height: 2rem;
		border-radius: 3rem;
		object-fit: cover;
	}
`

export default AddMemberPop
