import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {BiArchive} from 'react-icons/bi'
import {colorsList, getInitials} from '../../utils'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import MultiSelect from '../common/MultiSelect'
import Loader from '../common/Loader'
import {media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import {MemberList, ProfileImg, TagUser} from '../trelloTaskModalComponents/AddMemberPop'
import useRouter from '../../hooks/router'

interface ICategoriesModalProps {
	onCloseModal: any
	openBoard?: any
	boardDetails?: any
}

const ShareBoardModel = ({onCloseModal, openBoard, boardDetails}: ICategoriesModalProps) => {
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const [loading, setLoading] = useState(false)
	const [inviteMembers, setInviteMember] = useState<any>([])
	const [suggestionOptions, setSuggestionsOptions] = useState<any>([])
	const router = useRouter()

	useEffect(() => {
		getAllEmployees()
	}, [])

	const getAllEmployees = async () => {
		setLoading(true)
		await api
			.get(`/employee/get_suggested_employees?boardId=${boardDetails?.id}`)
			.then((response) => {
				if (response?.data?.success === true) {
					if (response?.data) {
						if (response?.data?.data?.length) {
							let employees: any = response?.data?.data?.map((e: any) => ({
								label: (
									<span>
										<MemberList margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" gap={0.5} hover={true}>
											{e?.image ? (
												<ProfileImg>
													<img src={process.env.REACT_APP_IMAGE_URL + e?.image} alt={e?.firstName} />
												</ProfileImg>
											) : (
												<TagUser width="2rem" height="2rem">
													{e?.firstName ? getInitials(e?.firstName + ' ' + e?.lastName) : getInitials(e?.email)}
												</TagUser>
											)}
											<Flexed direction="column">
												<Text type="small" color="black_3" fontWeight={500}>
													{e?.firstName ? e?.firstName + ' ' + e?.lastName : e?.email}
												</Text>
												{e?.firstName ? (
													<Text type="xsmall" color="silver_8" fontWeight={500}>
														{e?.email}
													</Text>
												) : (
													''
												)}
											</Flexed>
										</MemberList>
									</span>
								),
								value: e?.email
							}))
							setSuggestionsOptions(employees)
						}

						// setMemberBoards(groupedData)
					}
					setLoading(false)
				}
			})
			.catch(function (error) {
				setLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const leaveCurrentBoard = async (boardId?: any) => {
		try {
			setLoading(true)
			await api
				.post(`/trello/leave_board`, {boardId: boardId})
				.then((response) => {
					if (response?.data?.success == true) {
						toast.success('Leaved board successful')
						router.navigate('/boards')
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const handleShare = async () => {
		try {
			setLoading(true)
			await api
				.post(`/trello/invite_user`, {boardId: boardDetails?.id, users: inviteMembers?.map((e: any) => e?.value)})
				.then((response) => {
					if (response?.data?.success == true) {
						setInviteMember([])
						getAllEmployees()
						toast.success('Invite sent successfully.')
						openBoard && openBoard()
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	return (
		<>
			<Modal
				open={true}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}>
				<ModalWrapper>
					{loading && <Loader />}
					<Head direction="row" align="center" justify="space-between" gap={1}>
						<Text type="large" lineHeight="1.438" fontWeight="700" color="black">
							<BiArchive /> Share board
						</Text>
						<CrossIcon
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Container>
							<div>Share this board with a link</div>

							<Flexed direction="row" gap={0.5}>
								<div style={{width: '100%'}}>
									<MultiSelect isMulti={true} placeholder={'Email address or name'} value={inviteMembers} onChange={setInviteMember} searchMembers options={suggestionOptions} noOptionsMessage={'no suggestions available'} />
								</div>

								<ShareButton color="white" bg="purple_1" bg_hover="purple_2" onClick={handleShare}>
									Share
								</ShareButton>
							</Flexed>
						</Container>
						<Container>
							<Flexed direction="row" flexWrap="wrap" justify={'space-between'} align={'center'} gap={0.5}>
								{!_loginUserDetails?.firstName ? (
									<MemberList margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" gap={0.5} hover={true}>
										{_loginAdminDetails?.logo ? (
											<ProfileImg>
												<img src={process.env.REACT_APP_IMAGE_URL + _loginAdminDetails?.logo} alt={_loginAdminDetails?.name} />
											</ProfileImg>
										) : (
											<TagUser width="2rem" height="2rem">
												{_loginAdminDetails?.name ? getInitials(_loginAdminDetails?.name) : _loginUserDetails?.firstName ? getInitials(_loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName) : ''}
											</TagUser>
										)}
										<Text type="small" color="black_3" fontWeight={500}>
											{_loginAdminDetails?.name ? _loginAdminDetails?.name : _loginUserDetails?.firstName ? _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName : ''} {'(You)'}
										</Text>
									</MemberList>
								) : _loginUserDetails?.firstName ? (
									<MemberList margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" gap={0.5} hover={true}>
										{_loginAdminDetails?.logo ? (
											<ProfileImg>
												<img src={process.env.REACT_APP_IMAGE_URL + _loginUserDetails?.image} alt={_loginUserDetails?.firstName} />
											</ProfileImg>
										) : (
											<TagUser width="2rem" height="2rem">
												{_loginUserDetails?.firstName ? getInitials(_loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName) : ''}
											</TagUser>
										)}
										<Text type="small" color="black_3" fontWeight={500}>
											{_loginUserDetails?.firstName ? _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName : ''} {'(You)'}
										</Text>
									</MemberList>
								) : (
									''
								)}
								{boardDetails?.adminId == _loginAdminDetails?.id || boardDetails?.employeeId == _loginUserDetails?.id ? (
									<Button color="black" bg="white" style={{cursor: 'default'}}>
										Admin
									</Button>
								) : (
									<Button color="black" bg="white" bg_hover="silver_2" onClick={() => leaveCurrentBoard(boardDetails?.id)}>
										Leave Board
									</Button>
								)}
							</Flexed>
							<Spacer height={2} />
						</Container>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div`
	background-color: ${palette.white};
`

const Head = styled(Flexed)`
	padding: 1.5rem;
`

const Body = styled.div`
	padding: 0rem 1.5rem 1rem 1.5rem;

	${media.sm`
	width: 100%;
	`}
	${media.md`
	width: 40rem !important;
	`}
	${media.lg`
	width: 40rem !important;
	`}
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.3rem;
`

export const Button = styled.div<any>`
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: ${({removedBorder}) => (removedBorder ? '0.5rem' : `0.5rem`)};
	padding: 6px 16px;
	font-size: 14px;
	border: ${({removedBorder, border}) => (removedBorder ? '' : `1px solid ${palette[border]}`)};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

const Container = styled.div`
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 20px;
`

const ShareButton = styled.button<any>`
	border: none;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	padding: 12px 20px;
	cursor: pointer;
	height: 40px;
	min-width: 6rem;
	border-radius: ${({removedBorder}) => (removedBorder ? '0.5rem' : `0.5rem`)};
	padding: 6px 16px;
	font-size: 14px;
	border: ${({removedBorder, border}) => (removedBorder ? '' : `1px solid ${palette[border]}`)};
	color: ${({color}) => palette[color]};
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({bg}) => palette[bg]};
	&:hover {
		background: ${({bg_hover}) => palette[bg_hover]};
	}
`

export default ShareBoardModel
