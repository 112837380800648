import {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Divider, Flexed, Spacer, Text} from '../styled/shared'
import {NavLink, useLocation} from 'react-router-dom'
import {palette} from '../styled/colors'
import {useSelector} from 'react-redux'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'

const Home = () => {
	const {pathname} = useLocation()
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [requests, setRequests] = useState<any>('')
	const [upcomingHolidays, setUpcoming_holidays] = useState<any>('')
	const _loginUserStatus: any = sessionStorage.getItem('userStatus')

	useEffect(() => {
		if (_loginUserStatus === 'employee') {
			getEmployeeRequests()
		} else {
			getRequest()
		}

		getUpComingHolidays()
	}, [])

	const getRequest = async () => {
		await api
			.get(`/request/request_counts`)
			.then((response) => {
				if (response?.data?.success == true) {
					setRequests(response?.data?.data?.data)
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const getEmployeeRequests = async () => {
		await api
			.get(`/request/employee_request_counts`)
			.then((response) => {
				if (response?.data?.success == true) {
					setRequests(response?.data?.data?.data)
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}

	const getUpComingHolidays = async () => {
		await api
			.get(`/holiday/upcoming_holidays`)
			.then((response) => {
				if (response?.data?.success == true) {
					setUpcoming_holidays(response?.data?.data?.data)
				}
			})
			.catch(function (error) {
				toast.error('Something went wrong. Please try again later.')
			})
	}
	return (
		<>
			<Wrapper direction="row" align="center">
				<Content direction="row" gap={2} align="center" justify="space-between" pointer="pointer">
					<div>
						<Text type="normal" fontWeight={500} color="black">
							Dashboard
						</Text>
					</div>
					<Flexed direction="row" gap={1}>
						<DashboardMenu to="/dashboard" active={pathname === '/dashboard'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/dashboard'}>
								Dashboard
							</StyledText>
						</DashboardMenu>
					</Flexed>
					<div></div>
				</Content>
			</Wrapper>
			<Section>
				<Container>
					<Row>
						<Col>
							<SabNav direction="row" align="center" justify="space-between">
								<Heading fontWeight={500}>
									This account have 5 days left to expire. To continue using HR-App, Please upgrade your plan. Contact here
									<PhoneBox>+92 333 3331225</PhoneBox>
								</Heading>
							</SabNav>
						</Col>
					</Row>
					<Spacer height={1.5} />
					<Row>
						<Col lg={8.5}>
							<InnerWrapper>
								<Row>
									<Col lg={12}>
										<ImageCard>
											<Row>
												<Col lg={7}>
													<WelcomeText>
														<Text type="normal" fontWeight={500} fontSize={1.125}>
															Welcome To {_loginUserDetails?.companyEmployees?.companyName ? _loginUserDetails?.companyEmployees?.companyName : _loginAdminDetails?.companyName}
														</Text>
														<Text type="normal" fontWeight={400} fontSize={0.8} margin="10px 0 0 0 " lineHeight={1.2}>
															Your Record & information at one centralised and secure place, Want to Edit your Information?
														</Text>
													</WelcomeText>
												</Col>
												<Col lg={5}>
													<StyledImg src="/images/welcome.png" alt="welcome" />
												</Col>
											</Row>
										</ImageCard>
									</Col>
									{_loginUserStatus === 'admin' ? (
										<>
											<Col lg={4}>
												<BlueCard>
													<CardHeading>Total Employees</CardHeading>
													<Text isCentered fontSize={3} fontWeight={600} margin="4.5rem 0rem 0rem 0rem">
														{requests?.employess}
													</Text>
												</BlueCard>
											</Col>
											<Col lg={4}>
												<RedCard>
													<CardHeading>Job Posts</CardHeading>
													<Text isCentered fontSize={3} fontWeight={600} margin="4.5rem 0rem 0rem 0rem">
														{requests?.jobPosts}
													</Text>
												</RedCard>
											</Col>

											<Col lg={4}>
												<YellowCard>
													<CardHeading>UPCOMING HOLIDAYS</CardHeading>

													<Text isCentered fontSize={3} fontWeight={600} margin="4.5rem 0rem 0rem 0rem">
														{upcomingHolidays?.upcomingHolidaysCount}
													</Text>
												</YellowCard>
											</Col>
										</>
									) : null}
								</Row>
							</InnerWrapper>
						</Col>
						<Col lg={3.5}>
							<SideCard>
								<CardHeading>All Requests</CardHeading>
								<List direction="row" align="center" justify="space-between">
									<Text>Leave Request</Text>
									<Text>{requests?.leaveRequestCounts}</Text>
								</List>
								<Divider />
								<List direction="row" align="center" justify="space-between">
									<Text>Loan Request</Text>
									<Text>{requests?.loanRequestCounts}</Text>
								</List>
								<Divider />
								<List direction="row" align="center" justify="space-between">
									<Text>Document Request</Text>
									<Text>{requests?.documentRequestCounts}</Text>
								</List>
								<Divider />
							</SideCard>
						</Col>
					</Row>
				</Container>
			</Section>
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`

const Content = styled(Flexed)`
	width: 100%;
`
const DashboardMenu = styled(NavLink)<any>`
	border-bottom: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
`
const StyledText = styled(Text)`
	padding: 10px 0px 10px 0px;
	color: ${({active}) => (active ? `${palette.purple_1}` : 'black')};
`
const Section = styled.div`
	margin-top: 16px;
`
const SabNav = styled(Flexed)`
	box-shadow: 0 10px 10px -10px #2123261a;
	background: ${palette.white};
	padding: 15px 10px 15px 10px;
	border-radius: 3px;
`
const PhoneBox = styled.span`
	font-weight: 500;
	padding: 2px 5px;
	min-width: 60px;
	color: ${palette.white};
	background: ${palette.purple_1};
	border-radius: 10px;
	display: inline-block;
	margin-left: 2px;
	font-size: 0.7rem;
`
const BlueCard = styled.div`
	min-height: 235px;
	box-shadow: 0 5px 20px #0000000d;
	text-align: center;
	padding-top: 0;
	border-radius: 5px;
	position: relative;
	margin: 0 0 26px;
	border-top: 3px solid ${palette.purple_1};
`
const RedCard = styled.div`
	min-height: 235px;
	box-shadow: 0 5px 20px #0000000d;
	text-align: center;
	padding-top: 0;
	border-radius: 5px;
	position: relative;
	margin: 0 0 26px;
	border-top: 3px solid #d83556;
`

const YellowCard = styled.div`
	min-height: 235px;
	box-shadow: 0 5px 20px #0000000d;
	text-align: center;
	padding-top: 0;
	border-radius: 5px;
	position: relative;
	margin: 0 0 26px;
	border-top: 3px solid #f9d084;
`
const InnerWrapper = styled(Container)`
	padding: 0;
`

const ImageCard = styled.div`
	min-height: 235px;
	box-shadow: 0 5px 20px #0000000d;
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	position: relative;
	margin: 0 0 26px;
`
const SideCard = styled.div`
	min-height: 100%;
	box-shadow: 0 5px 20px #0000000d;
	text-align: center;
	padding-top: 0;
	border-radius: 5px;
	position: relative;
	margin: 0 0 26px;
	border-top: 3px solid #555d61;
`
const Heading = styled(Text)`
	padding: 10px;
`
const WelcomeText = styled(Text)`
	padding: 25px 0px 0px 20px;
`

const StyledImg = styled.img`
	width: 100%;
`
const CardHeading = styled.div`
	font-size: 12px;
	padding: 11px 0;
	border-bottom: 1px solid #eee;
	margin: 0;
	text-transform: uppercase;
	position: sticky;
	letter-spacing: 0.8px;
	background: var(--normal-background);
	top: 0;
	z-index: 1;
`

const List = styled(Flexed)`
	padding: 0.5rem 1rem;
`

export default Home
