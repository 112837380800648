import React, {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Draggable} from 'react-beautiful-dnd'
import QuoteList from '../styles/list'
import Title from '../styles/title'
import {Flexed} from '../../styled/shared'
import {IoMdAdd} from 'react-icons/io'
import {CgClose} from 'react-icons/cg'
import {HiDotsHorizontal} from 'react-icons/hi'
import ListActionPop from '../../components/trelloTaskModalComponents/ListActionPop'
import {LuEye} from 'react-icons/lu'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import Loader from '../../components/common/Loader'
import {useDispatch} from 'react-redux'

const Column = ({title, quotes, index, isScrollable, isCombineEnabled, useClone, onAddCard}: any) => {
	const _userProfileRef: any = useRef(null)
	const [showAddListContent, setShowAddListContent] = useState(false)
	const [cardTitle, setCardTitle] = useState('')
	const [openDropDown, setOpenDropDown] = useState(false)
	const [watch, setWatch] = useState(false)
	const [listDetails, setListDetails] = useState<any>('')

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_userProfileRef?.current && !_userProfileRef?.current?.contains(event.target)) {
				setOpenDropDown(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const [loading, setLoading] = useState(false)
	let _title = title && title?.split('_')

	const addCard = async () => {
		try {
			setLoading(true)
			await api
				.post(`/trello/create_card`, {title: cardTitle, boardId: quotes[0]?.author?.boardId, cardId: quotes[0]?.author?.id, listId: quotes[0]?.author?.id})
				.then((response) => {
					if (response?.data?.success == true) {
						setCardTitle('')
						// dispatch(updateBoardList(true))
						onAddCard && onAddCard()
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	const removeList = async () => {
		try {
			setLoading(true)
			await api
				.put(`/trello/update_list?id=${_title[1]}`, {isArchive: true})
				.then((response) => {
					if (response?.data?.success == true) {
						onAddCard && onAddCard()
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	useEffect(() => {
		if (quotes?.length && quotes[0]?.listData) {
			setListDetails(quotes[0]?.listData)
			setWatch(quotes[0]?.listData?.isWatch)
		} else if (quotes?.length == 0) {
			getListById(_title[1])
		} else if (openDropDown && _title?.length == 2 && _title[1]) {
			// getListById(_title[1])
		}
	}, [quotes])

	const getListById = async (id = '') => {
		try {
			setLoading(true)
			await api
				.get(`/trello/get_list?id=${id}`)
				.then((response) => {
					if (response?.data?.success == true) {
						setWatch(response?.data?.data?.isWatch)
						setListDetails(response?.data?.data)
					}
					setLoading(false)
				})
				.catch(function (error) {
					setLoading(false)
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {}
	}

	return (
		<>
			{loading && <Loader />}

			<Draggable draggableId={title} index={index}>
				{(provided, snapshot) => (
					<>
						<Container ref={provided.innerRef} {...provided.draggableProps}>
							<Header isDragging={snapshot.isDragging}>
								<Title isDragging={snapshot.isDragging} {...provided.dragHandleProps} aria-label={`${title} quote list`}>
									{_title ? _title[0] : quotes[0]?.title}
								</Title>
								<Flexed direction="row" gap={0.5} align="center">
									{watch ? <LuEye /> : ''}
									<Action ref={_userProfileRef} onClick={() => setOpenDropDown(!openDropDown)}>
										<HiDotsHorizontal fontSize="1.2rem" />
										{openDropDown && (
											<ListActionPop
												watch={watch}
												isWatch={() => setWatch(!watch)}
												onCloseModal={(isUpdated: any) => {
													if (isUpdated == true) {
														getListById(_title[1])
													}
													setOpenDropDown(false)
												}}
												archiveList={removeList}
												listId={_title?.length == 2 && _title[1]}
												listDetails={listDetails}
												onAddCardClick={() => {
													setShowAddListContent(true)
													setCardTitle('')
												}}
											/>
										)}
									</Action>
								</Flexed>
							</Header>
							{quotes?.some((e: any) => e?.content != undefined) ? (
								<QuoteList
									listIndex={index}
									listId={title}
									listType="QUOTE"
									style={{
										backgroundColor: snapshot.isDragging ? palette.purple_1 : null
									}}
									quotes={quotes}
									internalScroll={isScrollable}
									isCombineEnabled={Boolean(isCombineEnabled)}
									useClone={Boolean(useClone)}
									showAddListContent={showAddListContent}
								/>
							) : (
								''
							)}
							<AddAnOtherList
								gap={0.5}
								onClick={() => {
									if (!showAddListContent) {
										setShowAddListContent(true)
									}
								}}>
								{showAddListContent ? (
									<>
										<Input
											placeholder="Enter a title for this card..."
											type="textarea"
											rows={2}
											value={cardTitle}
											onChange={(e: any) => {
												setCardTitle(e?.target?.value)
											}}
										/>
										<Flexed gap={0.5} direction="row" align="center">
											<Button
												onClick={() => {
													if (cardTitle?.trim()?.length > 0) {
														setShowAddListContent(false)
														addCard()
													}
												}}>
												Add card
											</Button>
											<CgClose
												fontSize={20}
												cursor="pointer"
												onClick={() => {
													setShowAddListContent(false)
													setCardTitle('')
												}}
											/>
										</Flexed>
									</>
								) : (
									<Flexed gap={0.5} direction="row" align="center">
										<IoMdAdd />
										Add a Card{' '}
									</Flexed>
								)}
							</AddAnOtherList>
						</Container>
					</>
				)}
			</Draggable>
		</>
	)
}

const Container = styled.div`
	margin: 1px;
	display: flex;
	flex-direction: column;
`

const Header = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({isDragging}) => (isDragging ? palette.purple_1 : palette.purple_6)};
	transition: background-color 0.2s ease;
	background-color: rgb(235, 236, 240);
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	padding: 0.1rem 0.6rem;
	margin-top: 1rem;

	// &:hover {
	// 	background-color: ${palette.silver_8};
	// }
`

const AddAnOtherList = styled(Flexed)<any>`
	background-color: rgb(235, 236, 240);
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	font-size: 0.875rem;
	font-weight: 500;
	padding: 0.7rem 1rem;
	cursor: pointer;
	min-height: 44px;
	width: 250px;
`

const Input = styled.textarea<any>`
	width: 100%;
	border-radius: 0.5rem;
	border: none;
	padding: 0.5rem;
	box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${palette.white};
	background: ${palette.purple_1};
	font-weight: 600;
	cursor: pointer;
	width: 5.5rem;
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
`

const Action = styled.div`
	position: relative;
	padding: 0.5rem;
	cursor: pointer;
	&:hover {
		background: ${palette.silver_1};
	}
`

export default Column
