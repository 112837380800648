import React, {useState} from 'react'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Flexed, Text} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {IoMdClose} from 'react-icons/io'
import {LuCheck} from 'react-icons/lu'
import {MemberList, ProfileImg} from './AddMemberPop'
import {useSelector} from 'react-redux'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {getInitials} from '../../utils'

const AssignPop = ({onCloseModal, cardMembers, loadAgain, checklistDetails, boardMembers}: any) => {
	const [search, setSearch] = useState('')
	const [selectedUser, setSelectedUser] = useState(false)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)

	const handleModalClick = (event: any) => {
		event.stopPropagation()
	}

	const addCardMember = async (checklistId: any, employeeId?: any, isAdmin?: any) => {
		try {
			await api
				.post(`/trello/add_checklist_member`, {employeeId, isAdmin, checklistId})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	const getCheckListMembers = async (checklistId: any, employeeId?: any, isAdmin?: any) => {
		try {
			await api
				.post(`/trello/add_checklist_member`, {employeeId, isAdmin, checklistId})
				.then((res: any) => {
					if (res?.data?.success == true) {
						loadAgain && loadAgain()
					}
				})
				.catch(function (error) {
					toast.error('Something went wrong. Please try again later.')
				})
		} catch (error) {
			toast.error('Something went wrong. Please try again later.')
		}
	}

	console.log('=====employees', cardMembers, checklistDetails?.taskChecklistMembers)
	return (
		<Popup top="2.3rem" onClick={handleModalClick}>
			<Flexed direction="row" margin="0rem 0.5rem" align="center" justify="space-between" gap={0.2}>
				<div></div>
				<Text fontWeight={600}>Assign</Text>
				<CloseCover onClick={onCloseModal}>
					<IoMdClose cursor="pointer" />
				</CloseCover>
			</Flexed>

			<Flexed margin="0.5rem 0rem 0rem 0rem">
				<div>
					<Input
						placeholder="Search member"
						value={search}
						onChange={(e: any) => {
							setSearch(e?.target?.value)
						}}
					/>
				</div>

				{cardMembers?.length ? (
					<div>
						<Text type="xsmall" margin="1rem 0rem 0.3rem 0rem " color="black_3" fontWeight={700}>
							Card members
						</Text>
						{cardMembers?.length && cardMembers?.some((e: any) => e?.cardMember == true && (e?.employeeId || e?.adminId)) ? (
							cardMembers?.map((e: any) =>
								e?.cardMember && e?.employeeId ? (
									<MemberList direction="row" align="center" gap={0.5} onClick={() => addCardMember(checklistDetails?.id, e?.employee?.id, false)}>
										<List
											active={checklistDetails?.taskChecklistMembers?.some((re: any) => re?.employeeId == e?.employeeId)}
											margin="0rem 0rem 0.3rem 0rem"
											direction="row"
											align="center"
											justify="space-between"
											gap={0.5}
											onClick={() => setSelectedUser(!selectedUser)}>
											<Flexed direction="row" align="center" gap={0.5}>
												{e?.employee?.image ? (
													<ProfileImg>
														<img src={process.env.REACT_APP_IMAGE_URL + e?.employee?.image} alt={e?.employee?.firstName + ' ' + e?.employee?.lastName} />
													</ProfileImg>
												) : (
													<UserProfile width="1.8rem" height="1.8rem">
														{e?.employee?.firstName + ' ' + e?.employee?.lastName && getInitials(e?.employee?.firstName + ' ' + e?.employee?.lastName)}
													</UserProfile>
												)}
												<Text type="xsmall" color="black_3" fontWeight={500}>
													{e?.employee?.firstName + ' ' + e?.employee?.lastName}
												</Text>
											</Flexed>
											{checklistDetails?.taskChecklistMembers?.some((re: any) => re?.employeeId == e?.employeeId) && <LuCheck />}
										</List>
									</MemberList>
								) : e?.cardMember && e?.adminId ? (
									<List
										active={checklistDetails?.taskChecklistMembers?.some((re: any) => re?.adminId == e?.adminId)}
										margin="0rem 0rem 0.3rem 0rem"
										direction="row"
										align="center"
										justify="space-between"
										gap={0.5}
										onClick={() => {
											setSelectedUser(!selectedUser)
											addCardMember(checklistDetails?.id, '', true)
										}}>
										<Flexed direction="row" align="center" gap={0.5}>
											{e?.admin?.logo ? (
												<ProfileImg>
													<img src={process.env.REACT_APP_IMAGE_URL + e?.admin?.logo} alt={e?.admin?.name} />
												</ProfileImg>
											) : (
												<UserProfile width="1.8rem" height="1.8rem">
													{e?.admin?.name && getInitials(e?.admin?.name)}
												</UserProfile>
											)}
											<Text type="xsmall" color="black_3" fontWeight={500}>
												{e?.admin?.name}
											</Text>
										</Flexed>
										{checklistDetails?.taskChecklistMembers?.some((re: any) => re?.adminId == e?.adminId) && <LuCheck />}
									</List>
								) : (
									''
								)
							)
						) : (
							<Text type="xsmall" margin="0.5rem 0rem 0.1rem 0rem " color="black_3" fontWeight={400}>
								No Card Member Found
							</Text>
						)}
					</div>
				) : (
					''
				)}

				{_loginUserDetails?.firstName && boardMembers?.length == 0 ? (
					''
				) : (
					<Text type="xsmall" margin="0.5rem 0rem 0.2rem 0rem" fontWeight={600}>
						Board members
					</Text>
				)}
				{boardMembers?.length
					? boardMembers?.map((e: any) => (
							<MemberList
								margin="0.5rem 0rem 0rem 0rem"
								direction="row"
								align="center"
								gap={0.5}
								onClick={() => {
									addCardMember(checklistDetails?.id, e?.receiver?.id, false)
									setSelectedUser(!selectedUser)
								}}>
								<List
									active={checklistDetails?.taskChecklistMembers?.some((re: any) => re?.employeeId == e?.receiver?.id)}
									margin="0rem 0rem 0rem 0rem"
									direction="row"
									align="center"
									justify="space-between"
									gap={0.5}
									onClick={() => setSelectedUser(!selectedUser)}>
									<Flexed direction="row" align="center" gap={0.5}>
										{e?.receiver?.image ? (
											<ProfileImg>
												<img src={process.env.REACT_APP_IMAGE_URL + e?.receiver?.image} alt={e?.receiver?.firstName + ' ' + e?.receiver?.lastName} />
											</ProfileImg>
										) : (
											<UserProfile width="1.8rem" height="1.8rem">
												{e?.receiver?.firstName + ' ' + e?.receiver?.lastName && getInitials(e?.receiver?.firstName + ' ' + e?.receiver?.lastName)}
											</UserProfile>
										)}
										<Text type="xsmall" color="black_3" fontWeight={500}>
											{e?.receiver?.firstName + ' ' + e?.receiver?.lastName}
										</Text>
									</Flexed>
									{checklistDetails?.taskChecklistMembers?.some((re: any) => re?.employeeId == e?.receiver?.id) && <LuCheck />}
								</List>
							</MemberList>
						))
					: boardMembers?.some((e: any) => e?.cardMember == true && e?.adminId) && (
							<Text type="xsmall" margin="0.5rem 0rem 0.1rem 0rem " color="black_3" fontWeight={400}>
								No Board Member Found
							</Text>
						)}

				{!_loginUserDetails?.firstName && _loginAdminDetails && !cardMembers?.some((e: any) => e?.cardMember == true && e?.adminId) ? (
					<MemberList direction="row" align="center" gap={0.5} onClick={() => addCardMember(checklistDetails?.id, '', true)}>
						<List
							active={checklistDetails?.taskChecklistMembers?.some((re: any) => re?.adminId == _loginAdminDetails?.id)}
							margin="0rem 0rem 0rem 0rem"
							direction="row"
							align="center"
							justify="space-between"
							gap={0.5}
							onClick={() => setSelectedUser(!selectedUser)}>
							<Flexed direction="row" align="center" gap={0.5}>
								{_loginAdminDetails?.logo ? (
									<ProfileImg>
										<img src={process.env.REACT_APP_IMAGE_URL + _loginAdminDetails?.logo} alt={_loginAdminDetails?.name} />
									</ProfileImg>
								) : (
									<UserProfile width="1.8rem" height="1.8rem">
										{_loginAdminDetails?.name ? getInitials(_loginAdminDetails?.name) : _loginUserDetails?.firstName ? getInitials(_loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName) : ''}
									</UserProfile>
								)}
								<Text type="xsmall" color="black_3" fontWeight={500}>
									{_loginAdminDetails?.name ? _loginAdminDetails?.name : _loginUserDetails?.firstName ? _loginUserDetails?.firstName + ' ' + _loginUserDetails?.lastName : ''}
								</Text>
							</Flexed>
							{checklistDetails?.taskChecklistMembers?.some((re: any) => re?.adminId == _loginAdminDetails?.id) && <LuCheck />}
						</List>
					</MemberList>
				) : (
					''
				)}
				<Button active={selectedUser}>Remove member</Button>
			</Flexed>
		</Popup>
	)
}

const CloseCover = styled.div`
	position: relative;
	padding: 0.5rem;
	border-radius: 3px;
	display: flex;
	&:hover {
		background: ${palette.silver_1};
	}
`

const Popup = styled.div<any>`
	position: absolute;
	top: ${({top}) => top};
	z-index: 5;
	right: 0;
	margin: auto;
	background: ${palette.white};
	border-radius: 3px;
	box-shadow: var(--ds-shadow-overlay, 0 8px 12px #091e4226, 0 0 1px #091e424f);
	padding: 0.5rem 0.7rem;
	width: 300px;
`

const Input = styled.input<any>`
	width: 100%;
	border-radius: 0.3rem;
	border: none;
	padding: 0.5rem;
	outline: none;
	box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
`

const Button = styled.div<any>`
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem;
	font-size: 0.875rem;
	height: 2rem;
	color: ${({active}) => (active ? palette.black : palette.silver_8)};
	background: ${palette.silver_14};
	font-weight: 600;
	cursor: ${({active}) => (active ? 'pointer' : 'no-drop')};
	line-height: 32px;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	&:hover {
		background: ${({active}) => (active ? palette.silver_1 : '')};
	}
`

const UserProfile = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.8rem;
	height: 1.8rem;
	border-radius: 100%;
	font-size: 0.825rem;
	font-weight: 600;
	color: ${palette.white};
	background-color: ${palette.purple_1};
`

const List = styled(Flexed)`
	width: 100%;
	padding: 0.313rem;
	border-radius: 0.3rem;
	background: ${({active}) => (active ? palette.silver_1 : palette.white)};
	position: relative;
	cursor: pointer;
	&:hover {
		background: ${palette.silver_1};
	}
`

export default AssignPop
