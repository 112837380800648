 import React, {useRef, useEffect, useState} from 'react'
 import styled from 'styled-components'
 import {palette} from '../../styled/colors'
 import PlacesAutocomplete, {
	 geocodeByAddress,
	 getLatLng
	 // @ts-ignore
 } from 'react-places-autocomplete'
 import axios from 'axios'
 import {BsXLg} from 'react-icons/bs'
 import {useSelector} from 'react-redux'
 import {Text} from '../../styled/shared'
 
 const LocationSearch = ({label, setSelectedAddress, required, error, placeholder, disabled, isEdit}: any) => {
	 const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	 const [address, setAddress] = useState('')
	 const [isNotSeleted, setIsNotSelected] = useState(isEdit ? false : true)
	 const wrapperRef = useRef<HTMLDivElement>(null)
 
	 const handleClickOutside = () => {
		 if (isEdit) {
			 if (isNotSeleted) {
				 setIsNotSelected(true)
				 setAddress('')
				 setSelectedAddress('')
			 }
		 } else {
			 if (isNotSeleted && address) {
				 setIsNotSelected(true)
				 setAddress('')
				 setSelectedAddress('')
			 }
		 }
	 }
 
	 useEffect(() => {
		 const handleClickOutside2 = (event: MouseEvent) => {
			 if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				 handleClickOutside() // Perform action when clicked outside
			 }
		 }
 
		 // Add event listener when the component mounts
		 document.addEventListener('mousedown', handleClickOutside2)
 
		 // Remove event listener when the component unmounts
		 return () => {
			 document.removeEventListener('mousedown', handleClickOutside2)
		 }
	 }, [handleClickOutside])
 
	 useEffect(() => {
		 if (address === '') {
			 setIsNotSelected(true)
		 }
	 }, [address])
 
	 const handleSelect = async (address: any) => {
		 if (address !== '') {
			 const results = await geocodeByAddress(address)
			 const latLng = await getLatLng(results[0])
			 setIsNotSelected(false)
			 setAddress(address)
			 setSelectedAddress(address)
		 }
	 }
 
	 //Function to get Exact Address from above taken Latitude and longitude
	 const getCurrentAddress = async (lat: any, lng: any) => {
		 await axios
			 .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
			 .then((response) => {
				 if (response?.status == 200) {
					 setAddress(response?.data?.results[0]?.formatted_address)
					 setSelectedAddress(response?.data?.results[0]?.formatted_address)
					 setIsNotSelected(false)
				 }
			 })
			 .catch((error) => {
				 setIsNotSelected(true)
				 setAddress('')
				 setSelectedAddress('')
			 })
	 }
 
	 return (
		 <div
			 ref={wrapperRef}
			 onClick={() => {
				 handleClickOutside()
			 }}>
			 {label && (
				 <Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
					 {label} {required ? <Mandatory>*</Mandatory> : ''}
				 </Label>
			 )}
			 <PlacesAutocomplete value={address ? address?.trimStart() : ''} onChange={setAddress} onSelect={handleSelect}>
				 {({getInputProps, suggestions, getSuggestionItemProps, loading}: any) => (
					 <div>
						 <InnerWrapper isDarkTheme={_isDarkTheme} error={error}>
							 <Input
								 width={'100%'}
								 borderRadius={'0.2rem'}
								 margin={'0'}
								 padding={'0.8rem 0.8rem 0.8rem 2.5rem'}
								 value={address ? address?.trimStart() : ''}
								 {...getInputProps()}
								 isDarkTheme={_isDarkTheme}
								 placeholder="Search Google Map"
								 error={error}
							 />
							 {address ? (
								 <CrossIcon
									 isDarkTheme={_isDarkTheme}
									 onClick={() => {
										 setIsNotSelected(true)
										 setAddress('')
										 setSelectedAddress('')
									 }}
								 />
							 ) : (
								//  <CurrentLocation
								// 	 isDarkTheme={_isDarkTheme}
								// 	 onClick={() => {
								// 		 getCurrentLatLng()
								// 	 }}
								//  />
								null
							 )}
						 </InnerWrapper>
						 {suggestions.length > 0 && (
							 <ListItems isDarkTheme={_isDarkTheme}>
								 {loading ? (
									 <ListItems>
										 <LocationList isDarkTheme={_isDarkTheme}>Fetching locations</LocationList>{' '}
									 </ListItems>
								 ) : null}
								 {suggestions.map((suggestion: any, i: any) => {
									 const style = {
										 backgroundColor: suggestion.active ? '#F5F3ED' : `${_isDarkTheme ? `${palette.black}` : `${palette.white}`}`
									 }
									 return (
										 <LocationList
											 isDarkTheme={_isDarkTheme}
											 key={i + 'mapkey'}
											 {...getSuggestionItemProps(suggestion, {
												 style
											 })}>
											 {suggestion?.description}
										 </LocationList>
									 )
								 })}
							 </ListItems>
						 )}
					 </div>
				 )}
			 </PlacesAutocomplete>
			 {required && error && !disabled && (
				 <Text fontSize={0.7} type="small" color="danger">
					 {error?.message ? error?.message : error}
				 </Text>
			 )}
		 </div>
	 )
 }
 
 const Label = styled(Text)`
	 font-weight: 500;
	 text-transform: capitalize;
 `
 
 const Mandatory = styled.span`
	 color: ${palette.danger};
 `
 
 const InnerWrapper = styled.div<any>`
	 width: 100%;
	 position: relative;
	 border-radius: 0.375rem;
 `
 
 const Input = styled.input<any>`
	 font-size: 1rem;
	 width: 100%;
	 border: 0;
	 font-size: 0.875rem;
	 outline: none;
	 line-height: 1.7rem;
	 padding: 0.5rem 3rem 0.5rem 0.8rem;
	 color: ${palette.dark_gray};
	 border-radius: 0.375rem;
	 box-shadow: inset 0 0 0 2px var(--ds-border-input, #091e4224);
 `
 
 const CrossIcon = styled(BsXLg)<any>`
	 cursor: pointer;
	 position: absolute;
	 top: 0;
	 right: 1rem;
	 bottom: 0;
	 margin: auto;
	 z-index: 1;
	 padding: 0.2rem;
	 color: ${palette.gray};
	 font-size: 1.3rem;
 `
 
 const ListItems = styled.div<any>`
	 background: ${palette.white};
	 color: ${palette.gray};
	 z-index: 1;
	 width: 100%;
	 0.875
	 overflow-X: hidden;
	 overflow-Y: auto;
	 overflow-wrap: anywhere;
	 max-height: 10rem;
 `
 
 const LocationList = styled.div<any>`
	 background: ${palette.white};
	 color: ${palette.gray};
	 text-align: left;
	 padding: 0.5rem;
	 cursor: pointer;
	 font-weight: 300;
	 font-size: 0.875rem;
	 &:hover {
		 background-color: ${palette.silver_1} !important;
		 color: ${palette.black};
	 }
 `
 
 export default LocationSearch
 