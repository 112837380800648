import React, {useState} from 'react'
import styled from 'styled-components'
import {AuthFilledButton, AuthUnFilledButton, CopyRight, Flexed, Loading, Spacer, Text} from '../../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import InputFieldAuth from '../../components/common/InputFieldAuth'
import {CommonEnum} from '../../utils'
import {useNavigate} from 'react-router-dom'
import useWindowSize from '../../hooks/useWindowSize'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import DropDown from '../../components/common/DropDown'

const ForgotPassword = () => {
	let _navigate = useNavigate()
	const _regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
	const _windowSize = useWindowSize()
	const [isLoading, setIsLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [role, setRole] = useState('admin')
	const [roleError, setRoleError] = useState('')
	const [roleOption, setRoleOption] = useState([
		{label: 'admin', value: 'admin'},
		{label: 'employee', value: 'employee'}
	])

	const [emailError, setEmailError] = useState('')

	const doReset = (e: any) => {
		e?.preventDefault()
		if (formValidation()) {
			setIsLoading(true)
			api.post(role == 'admin' ? `/admin/verify_email` : `/employee/verify_email`, {
				email: email
			})
				.then(async (res) => {
					setIsLoading(false)
					if (res?.data?.success) {
						// _navigate('/forgotpassword-confirmation')
						_navigate(`/reset-password?email=${email}&role=${role}`)
					} else {
						toast.error(res?.data?.message)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (!_regex.test(email)) {
			setEmailError('Invalid email.')
			isValid = false
		}
		if (email === '') {
			setEmailError('Email is required.')
			isValid = false
		}
		if (role === '') {
			setRoleError('Role is required')
			isValid = false
		}
		return isValid
	}
	return (
		<>
			<MainWrapper>
				<Flexed direction="row" justify="center">
					<ImageContent>
						<CoverImage src="/images/logo.png" alt="login_bg" />
					</ImageContent>
					<LoginContent>
						<Wrapper>
							<InnerWrapper>
								<Row justifyContent="center">
									<Col lg={10}>
										<form onSubmit={doReset}>
											<Text fontSize={_windowSize?.width < 600 ? 1.5 : 1.875} lineHeight={2} fontWeight={600} color="black_3">
												Forgot Your Old Password ?
											</Text>
											<Spacer height={1} />
											<Text fontSize={0.813} color="black_7">
												We Came with the solution enter your email below :
											</Text>
											<Spacer height={1.8} />

											<DropDown
												label="Select your role"
												firstSelected={role}
												options={roleOption}
												hasChanged={(value: any) => {
													setRoleError('')
													if (value === '') {
														setRoleError('Role is required')
													}
													setRole(value)
												}}
												error={roleError}
												dropDownListHeight="100px"
											/>
											<Spacer height={0.8} />
											<InputFieldAuth
												label="Email"
												required
												type="text"
												value={email}
												error={emailError}
												handleChange={(e: any) => {
													setEmailError('')
													if (e === '') {
														setEmailError('Email is required.')
													} else if (!_regex.test(e)) {
														setEmailError('Invalid email.')
													}
													setEmail(e)
												}}
											/>
											<Spacer height={2} />
											<Flexed direction="row" gap={1}>
												<AuthFilledButton onClick={doReset}>Reset</AuthFilledButton>
												<AuthUnFilledButton
													onClick={() => {
														_navigate('/login')
													}}>
													Login
												</AuthUnFilledButton>
											</Flexed>
										</form>
									</Col>
								</Row>
							</InnerWrapper>
							<CopyRight>© 2024 {CommonEnum.AppName}, All rights reserved</CopyRight>
						</Wrapper>
					</LoginContent>
				</Flexed>
			</MainWrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled.div`
	height: 100vh;
`

const LoginContent = styled.div`
	width: 100%;
	height: 100vh;
	background: url(/images/white_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	${media.sm`width: 80%;`};
	${media.md`width: 60%;`};
	${media.lg`width: 50%;`};
`

const Wrapper = styled.div`
	position: relative;
	background: #ffffffcc;
	height: 100%;
	display: flex;
	${media.lg` align-items: center;`};
`

const InnerWrapper = styled(Container)`
	margin-top: 2rem;
	margin-bottom: 0rem;
	${media.sm`margin-top: 4rem;`}
	${media.md`margin-top: 6rem;`}
    ${media.lg`margin-bottom: 6rem; margin-top: 0rem;`}
`

const ImageContent = styled.div`
	width: 50%;
	display: none;
	${media.lg`
		display: flex;
		justify-content: center;
		align-items: center;
	`};
`

const CoverImage = styled.img`
	// height: 100vh;
	// width: 100%;
	width: 20rem;
	object-fit: cover;
	object-position: bottom;
`

export default ForgotPassword
