import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react'
import styled from 'styled-components'
import InputField from './InputField'
import {Col, Row} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {RiDeleteBinLine} from 'react-icons/ri'
import {palette} from '../../styled/colors'
import {Checked, CheckIconWrapper, UnChecked} from '../../pages/Auth/SignUp'
import InputDate from './InputDate'
import moment from 'moment'

const EductionalForm = forwardRef(({index, data, deleteEducationalForm, handleEducationalFormChange}: any, ref) => {
	useImperativeHandle(ref, () => ({
		eductionalFormValidation
	}))
	const [instituteError, setInstituteError] = useState('')
	const [majorError, setMajorError] = useState('')
	const [degreeError, setDegreeError] = useState('')
	const [fromError, setFromError] = useState('')
	const [toError, setToError] = useState('')

	const eductionalFormValidation = () => {
		let isValid = true
		if (data?.institute === '') {
			setInstituteError('Field is required.')
			isValid = false
		}
		if (data?.major === '') {
			setMajorError('Field is required.')
			isValid = false
		}
		if (data?.degree === '') {
			setDegreeError('Field is required.')
			isValid = false
		}
		if (data?.from === '') {
			setFromError('Field is required.')
			isValid = false
		}
		if (data?.to === '') {
			setToError('Field is required.')
			isValid = false
		}
		if (data?.from && data?.to && moment(data.from, 'YYYY-MM-DD').isSameOrAfter(moment(data.to, 'YYYY-MM-DD'))) {
			setToError('Date must be greater than From date.')
			isValid = false
		}

		return isValid
	}
	return (
		<StepperContainer>
			<Step>
				<StepNumber>{index + 1}</StepNumber>
				<StepContent>
					<Row>
						<Col>
							<InputField
								label="Institute / School"
								error={instituteError}
								value={data?.institute}
								required
								handleChange={(e: any) => {
									setInstituteError('')
									if (e === '') {
										setInstituteError('Field is required')
									}
									handleEducationalFormChange(index, 'institute', e)
								}}
							/>
							<Spacer height={1} />
						</Col>
						<Col>
							<InputField
								label="Major / Department"
								error={majorError}
								value={data?.major}
								required
								handleChange={(e: any) => {
									setMajorError('')
									if (e === '') {
										setMajorError('Field is required')
									}
									handleEducationalFormChange(index, 'major', e)
								}}
							/>
							<Spacer height={1} />
						</Col>
						<Col>
							<InputField
								label="Degree"
								error={degreeError}
								value={data?.degree}
								required
								handleChange={(e: any) => {
									setDegreeError('')
									if (e === '') {
										setDegreeError('Field is required')
									}
									handleEducationalFormChange(index, 'degree', e)
								}}
							/>
							<Spacer height={1} />
						</Col>
						<Col>
							<Row>
								<Col lg={6}>
									<InputDate
										label="From"
										error={fromError}
										value={data?.from}
										required
										handleChange={(e: any) => {
											setFromError('')
											if (e === '') {
												setFromError('Field is required')
											}
											handleEducationalFormChange(index, 'from', e)
										}}
									/>

									<Spacer height={1} />
								</Col>
								<Col lg={6}>
									<InputDate
										label="To"
										disabled={data?.currentlyPursuing}
										error={toError}
										value={data?.to}
										required
										handleChange={(e: any) => {
											setToError('')
											if (e === '') {
												setToError('Field is required')
											}
											handleEducationalFormChange(index, 'to', e)
										}}
									/>
									<Spacer height={1} />
								</Col>
							</Row>
						</Col>
						<Col>
							<Spacer height={1} />
							<Flexed gap={0.2}>
								<Flexed direction="row" align="center" gap={0.2}>
									<Text fontSize={0.813} fontWeight={500} color="black_3">
										Currently pursuing
									</Text>
									<CheckIconWrapper>
										{data?.currentlyPursuing ? (
											<Checked
												onClick={() => {
													handleEducationalFormChange(index, 'currentlyPursuing', false)
												}}
											/>
										) : (
											<UnChecked
												onClick={() => {
													handleEducationalFormChange(index, 'currentlyPursuing', true)
												}}
											/>
										)}
									</CheckIconWrapper>
								</Flexed>
							</Flexed>
						</Col>
					</Row>
				</StepContent>
				<DeleteButton onClick={deleteEducationalForm}>
					<RiDeleteBinLine />
				</DeleteButton>
			</Step>
		</StepperContainer>
	)
})

const StepperContainer = styled.div`
    width:100%
	flex-direction: column;
	position: relative; /* Add position relative to the container */

`

const Step = styled.div`
	align-items: center;
	margin-bottom: 20px;
	position: relative; /* Add position relative to the step */
`

const StepNumber = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #007bff;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute; /* Change to absolute positioning */
	left: -15px; /* Adjust the position to place it above the border */
	top: 0;
	transform: translateY(-50%);
`
const DeleteButton = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: ${palette.white};
	color: ${palette.danger};
	border: 1px solid #007bff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute; /* Change to absolute positioning */
	left: -15px; /* Adjust the position to place it above the border */
	top: 100%;
	transform: translateY(-50%);
	cursor: pointer;
`

const StepContent = styled.div`
	border-left: 2px solid #007bff;
	padding-left: 20px;
`

export default EductionalForm
