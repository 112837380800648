import {useState, useEffect, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {MDBDataTableV5} from 'mdbreact'
import {GiSunglasses} from 'react-icons/gi'
import {RiDeleteBinLine} from 'react-icons/ri'
import {FiEdit2} from 'react-icons/fi'
import {Flexed, Spacer, Text} from '../styled/shared'
import {Link, NavLink, createSearchParams, useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../styled/colors'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import DeleteModal from '../components/modals/DeleteModal'
import PostViewFilter from '../components/common/PostViewFilter'
import Button from '../components/common/Button'
import moment from 'moment'
import {Loading} from '../styled/shared'
import {useSelector} from 'react-redux'

const JobPostWidget = () => {
	const {pathname} = useLocation()
	const _navigate = useNavigate()
	const [modalStatus, setModalStatus] = useState('recruitment')
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [postId, setPostId] = useState('')
	const [sidebar, setSidebar] = useState(false)
	const [isLoading, setIsLoading] = useState<any>(false)
	const _loginAdminDetails: any = useSelector<any>((state: any) => state.auth.loginAdminDetails)

	const [users, setUsers] = useState({
		columns: [
			{label: 'Posting Title', field: 'postingTitle', width: 200},
			{label: 'Close Date', field: 'closeDate', width: 50},
			{label: 'Status', field: 'status', width: 50},
			{label: '', field: 'view', sort: String('disabled'), width: 50},
			{label: '', field: 'delete', sort: String('disabled'), width: 50},
			{label: '', field: 'update', sort: String('disabled'), width: 50}
		],
		rows: []
	})
	useEffect(() => {
		allPostings()
	}, [])

	const allPostings = async () => {
		setIsLoading(true)
		await api
			.get(`/recruitment/all_posts?adminId=${_loginAdminDetails ? _loginAdminDetails?.id : ''}`)
			.then((response) => {
				if (response?.data?.success == true) {
					setUsers((preValue) => {
						return {
							columns: [...preValue.columns],
							rows: []
						}
					})
					if (response?.data?.data?.data?.length > 0) {
						response?.data?.data?.data?.map((data: any, index: any) => {
							createUserTable(data)
						})
					}
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const deleteJobPost = async (postId: any) => {
		setIsLoading(true)
		await api
			.delete(`/recruitment/remove_post/${postId}`)
			.then((response) => {
				if (response?.data?.success == true) {
					toast.success('Post deleted successfully')
					allPostings()
					setOpenDeleteModal(false)
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				setIsLoading(false)
				toast.error('Something went wrong. Please try again later.')
			})
	}
	const createUserTable = (data: any) => {
		let _obj = {
			postingTitle: data?.postingTitle,
			closeDate: data?.targetDate ? moment(data?.targetDate).format('YYYY-MM-DD') : '',
			status: data?.status,
			view: (
				<>
					<ViewIcon
						onClick={() => {
							setSidebar(true)
							setPostId(data?.id)
						}}
					/>
				</>
			),

			update: (
				<EditIcon
					onClick={() =>
						_navigate({
							pathname: '/edit-post',
							search: createSearchParams({
								id: data?.id
							}).toString()
						})
					}
				/>
			),
			delete: <DeleteIcon onClick={() => handelDeleteModal(data?.id)} />
		}
		setUsers((preValue): any => {
			return {
				columns: [...preValue.columns],
				rows: [...preValue.rows, _obj]
			}
		})
	}
	const handelDeleteModal = (val: any) => {
		setPostId(val)
		setOpenDeleteModal(true)
	}

	return (
		<>
			<Wrapper direction="row" align="center">
				<Content direction="row" gap={2} align="center" justify="space-between" pointer="pointer">
					<div>
						<Text type="normal" fontWeight={500} color="black">
							Recruitment
						</Text>
					</div>
					<Flexed direction="row" gap={1}>
						<DashboardMenu to="/recruitment" active={pathname === '/recruitment'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/recruitment'}>
								Job Openings
							</StyledText>
						</DashboardMenu>
						<DashboardMenu to="/candidate" active={pathname === '/candidate'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/candidate'}>
								Candidates
							</StyledText>
						</DashboardMenu>
						<DashboardMenu to="/interview" active={pathname === '/interview'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/interview'}>
								Interviews
							</StyledText>
						</DashboardMenu>
					</Flexed>
					<RecruitmentButton to="/post">
						<CreateNewText type="normal" fontWeight={500} fontSize={0.813}>
							Create a New
						</CreateNewText>
					</RecruitmentButton>
				</Content>
			</Wrapper>
			<Section>
				<Container fluid>
					<Row>
						<Col>
							<TableWraper>
								<MDBDataTableV5 responsive hover entriesOptions={[15, 20, 25]} entries={15} pagesAmount={4} data={users} fullPagination searchTop searchBottom={false} />
							</TableWraper>
						</Col>
					</Row>
					<DeleteModal
						modalStatus={modalStatus}
						open={openDeleteModal}
						onCloseModal={() => {
							setOpenDeleteModal(false)
						}}
						deletePost={() => deleteJobPost(postId)}
					/>
				</Container>
			</Section>
			{sidebar && (
				<PostViewFilter
					data={postId}
					show={sidebar}
					onClick={() => {
						setSidebar(true)
					}}
					onClose={() => {
						setSidebar(false)
					}}
				/>
			)}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`

const Content = styled(Flexed)`
	width: 100%;
`
const DashboardMenu = styled(NavLink)<any>`
	border-bottom: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
`
const RecruitmentButton = styled(NavLink)<any>`
	background-color: ${palette.purple_1};
	height: 34px;
	display: inline-flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 3px;
`
const StyledText = styled(Text)`
	padding: 10px 0px 10px 0px;
	color: ${({active}) => (active ? `${palette.purple_1}` : 'black')};
`
const CreateNewText = styled(Text)`
	color: ${palette.white};
`
const Section = styled.div`
	padding: 25px 5px 20px 5px;
`
const TableWraper = styled.div`
	background: rgb(255, 255, 255);
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	padding: 15px;
	border-radius: 5px;
`
const ViewIcon = styled(GiSunglasses)`
	font-size: 1.9rem;
	&:hover {
		background: ${palette.white};
		padding: 0px;
		border-radius: 7px;
	}
`
const EditIcon = styled(FiEdit2)`
	font-size: 1rem;
	&:hover {
		background: ${palette.white};
		padding: 0px;
		border-radius: 7px;
	}
`
const DeleteIcon = styled(RiDeleteBinLine)`
	font-size: 1rem;
	color: red;
	&:hover {
		background: ${palette.white};
		padding: 0px;
		border-radius: 7px;
	}
`

export default JobPostWidget
