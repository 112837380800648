import {useState, useEffect, useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {MDBDataTableV5} from 'mdbreact'
import {GiSunglasses} from 'react-icons/gi'
import {RiDeleteBinLine} from 'react-icons/ri'
import {FiEdit2} from 'react-icons/fi'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {Link, NavLink, createSearchParams, useLocation, useNavigate} from 'react-router-dom'
import {palette} from '../../styled/colors'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import DeleteModal from '../../components/modals/DeleteModal'
import PostViewFilter from '../../components/common/PostViewFilter'
import Button from '../../components/common/Button'
import moment from 'moment'
import {Loading} from '../../styled/shared'
import {Back} from '../employee/EmployeeDetails'
import {FaArrowRight} from 'react-icons/fa6'
import CompanyDocumentModal from '../modals/CompanyDocumentModal'
import CompanyProfileModal from '../modals/CompanyProfileModal'

const Organization = () => {
	const {pathname} = useLocation()
	const _navigate = useNavigate()
	const [modalStatus, setModalStatus] = useState('recruitment')
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [openCompanyDocumentModal, setOpenCompanyDocumentModal] = useState(false)
	const [openCompanyProfileModal, setOpenCompanyProfileModal] = useState(false)
	const [postId, setPostId] = useState('')
	const [sidebar, setSidebar] = useState(false)
	const [isLoading, setIsLoading] = useState<any>(false)

	return (
		<>
			<Wrapper direction="row" align="center">
				<Content direction="row" gap={2} align="center" justify="space-between" pointer="pointer">
					<div>
						<Text type="normal" fontWeight={500} color="black">
							Setup
						</Text>
					</div>
					<Flexed direction="row" gap={1}>
						<DashboardMenu to="/organization" active={pathname === '/organization'}>
							<StyledText type="normal" fontWeight={500} fontSize={0.8} active={pathname === '/organization'}>
								Organization
							</StyledText>
						</DashboardMenu>
					</Flexed>
					<Back onClick={() => _navigate('/dashboard')}>
						<svg _ngcontent-kxy-c151="" width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00acac">
							<path
								_ngcontent-kxy-c151=""
								d="M116 121v260c0 13.785 11.215 25 25 25s25-11.215 25-25V121c0-13.785-11.215-25-25-25S116 107.215 116 121zM211.98 243.107c-5.985 3.555-5.996 12.224 0 15.785l219.648 130.416c7.391 4.39 16.269-2.86 13.317-11.014l-42.561-117.712c-1.455-4.026-1.564-8.549-.078-12.823 45.595-131.625 43.871-124.776 43.871-127.686 0 0 0 0 0-.001 0-6.511-7.428-11.61-13.906-7.763L211.98 243.107zM346 451c0 8.272 6.728 15 15 15 8.271 0 15-6.728 15-15 0-8.271-6.729-15-15-15C352.728 436 346 442.729 346 451zM66 396c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 5.515 4.486 10 10 10S66 401.515 66 396zM226 71c0 13.785 11.215 25 25 25 13.785 0 25-11.215 25-25s-11.215-25-25-25C237.215 46 226 57.215 226 71zM250.998 371.003A10 10 0 10250.998 391.003 10 10 0 10250.998 371.003zM350.998 80.997A10 10 0 10350.998 100.997 10 10 0 10350.998 80.997zM51.003 151.003A10 10 0 1051.003 171.003 10 10 0 1051.003 151.003zM460.998 261.003A10 10 0 10460.998 281.003 10 10 0 10460.998 261.003z"></path>
						</svg>
						Back
					</Back>
				</Content>
			</Wrapper>
			<Section>
				<Container fluid>
					<Row>
						<Col>
							<Wraper>
								<Row>
									<Col>
										<Container fluid>
											<Row>
												<Col lg={4}>
													<Card
														onClick={() => {
															setOpenCompanyProfileModal(true)
														}}>
														<Content direction="row" justify="space-between" align="center" gap={1}>
															<InnerContent direction="row" align="center" gap={1}>
																<Icon _ngcontent-vsq-c155="" viewBox="0 0 32 33" className="imgblue">
																	<path
																		_ngcontent-vsq-c155=""
																		d="M1 32h30M15 31V4.312C15 3.588 15.634 3 16.417 3h11.166C28.366 3 29 3.588 29 4.313V31M19 7h6M19 11h6M19 15h6M19 19h6M19 23h6M3 31V10.312C3 9.588 3.634 9 4.417 9h10.166"></path>
																	<path _ngcontent-vsq-c155="" d="M19 31v-4h6v4M7 13h4M7 17h4M7 21h4M7 25h4M9 9V4.5M9 29v2.5"></path>
																</Icon>
																<Text color="silver_12" fontWeight={500}>
																	Company Profile
																</Text>
															</InnerContent>
															<FaArrowRight />
														</Content>
													</Card>
												</Col>
												<Col lg={4}>
													<Card
														onClick={() => {
															setOpenCompanyDocumentModal(true)
														}}>
														<Content direction="row" justify="space-between" align="center" gap={1}>
															<InnerContent direction="row" align="center" gap={1}>
																<Icon _ngcontent-vsq-c155="" viewBox="0 0 32 33" className="imgblue">
																	<path
																		_ngcontent-vsq-c155=""
																		d="M10 25V7.312C10 6.588 10.634 6 11.417 6h9.166C21.366 6 22 6.588 22 7.313V25M14 10h4M14 14h4M14 18h4M14 22h4M24 32H8v-5.5c0-.828.616-1.5 1.375-1.5h13.25c.76 0 1.375.672 1.375 1.5V32zM16 5V2"></path>
																	<path _ngcontent-vsq-c155="" d="M14 32v-4h4v4M1 32h30"></path>
																</Icon>
																<Text color="silver_12" fontWeight={500}>
																	Docs
																</Text>
															</InnerContent>
															<FaArrowRight />
														</Content>
													</Card>
												</Col>
											</Row>
										</Container>
									</Col>
								</Row>
							</Wraper>
						</Col>
					</Row>
				</Container>
			</Section>
			{openCompanyDocumentModal && (
				<CompanyDocumentModal
					onCloseModal={() => {
						setOpenCompanyDocumentModal(false)
						setIsLoading(false)
					}}
					documentDetail={undefined}
					refreshTable={undefined}
					setIsLoading={() => {
						setIsLoading(true)
					}}
				/>
			)}
			{openCompanyProfileModal && (
				<CompanyProfileModal
					onCloseModal={() => {
						setOpenCompanyProfileModal(false)
						setIsLoading(false)
					}}
					documentDetail={undefined}
					refreshTable={undefined}
					setIsLoading={() => {
						setIsLoading(true)
					}}
				/>
			)}

			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Flexed)`
	background: ${palette.white};
	position: sticky;
	top: 57px;
	width: 100%;
	box-shadow: 0 10px 10px -10px #2123261a;
	height: 50px;
	padding: 0rem 2rem;
	z-index: 2;
`

const Content = styled(Flexed)`
	width: 100%;
`
const InnerContent = styled(Flexed)`
	width: 100%;
`
const DashboardMenu = styled(NavLink)<any>`
	border-bottom: ${({active}) => (active ? `2px solid ${palette.purple_1}` : '')};
`
const RecruitmentButton = styled(NavLink)<any>`
	background-color: ${palette.purple_1};
	height: 34px;
	display: inline-flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 3px;
`
const StyledText = styled(Text)`
	padding: 10px 0px 10px 0px;
	color: ${({active}) => (active ? `${palette.purple_1}` : 'black')};
`
const CreateNewText = styled(Text)`
	color: ${palette.white};
`
const Section = styled.div`
	padding: 25px 5px 20px 5px;
`
const Wraper = styled.div`
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
	padding: 15px;
	border-radius: 5px;
	background: ${palette.silver_5};
	margin-bottom: 10px;
	border: 1px solid ${palette.gray_300};
`

const Icon = styled.svg`
	max-width: 25px;
	fill: #0000;
	stroke: ${palette.purple_1};
	stroke-width: 2px;
	stroke-linecap: round;
`

const Card = styled.div<any>`
	display: flex;
	align-items: center;
	background: ${palette.white};
	margin-bottom: 1rem;
	padding: 10px 45px 10px 25px;
	border: 1px solid ${palette.silver_11};
	border-radius: 7px;
	position: relative;
	min-height: 4rem;
	cursor: pointer;
	& svg {
		color: #f3f5f7;
	}
	&:hover svg {
		color: #a8afb3;
	}
	&:hover div {
		color: #23527c !important;
	}
`

export default Organization
