import React, {useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Text} from '../../styled/shared'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useSelector} from 'react-redux'

const InputPhoneNo = ({label, setFileValue, required, placeholder, value, disabled, error, errorMsg, name, register, handleChange}: any) => {
	const _isDarkTheme: any = false
	// const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);

	return (
		<>
			<Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
				{label} {required ? <Mandatory>*</Mandatory> : ''}
			</Label>
			<div id={`${_isDarkTheme ? 'phoneInput_dark' : 'phoneInput'}`}>
				<PhoneInput
					inputClass="phoneInputFields"
					country={'us'}
					enableAreaCodes={true}
					value={value}
					inputProps={{
						name: 'phone',
						country: 'us',
						required: true,
						autoFocus: true
					}}
					onChange={(phone) => handleChange({name: name, value: phone})}
				/>
			</div>
			{required && error && !disabled && (
				<ErrorText fontSize={0.625} type="small" color="danger">
					{error?.message ? error?.message : error}
				</ErrorText>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

const ErrorText = styled(Text)`
	text-transform: capitalize;
	font-weight: 400;
`

export default InputPhoneNo
