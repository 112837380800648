import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {AuthFilledButton, AuthUnFilledButton, Border, BorderText, CopyRight, Flexed, Loading, Spacer, Text} from '../../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector, useDispatch} from 'react-redux'
import InputFieldAuth from '../../components/common/InputFieldAuth'
import LogInGoogle from '../../components/common/LogInGoogle'
import {CommonEnum} from '../../utils'
import {useNavigate} from 'react-router-dom'
import useWindowSize from '../../hooks/useWindowSize'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import DropDown from '../../components/common/DropDown'
import {loginUserDetailsAction} from '../../actions/authActions'
import useRouter from '../../hooks/router'

const LogIn = () => {
	let _navigate = useNavigate()
	const _dispatch = useDispatch()
	const _windowSize = useWindowSize()
	const _regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
	const [isLoading, setIsLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [role, setRole] = useState('admin')
	const [roleError, setRoleError] = useState('')
	const router = useRouter()
	const [roleOption, setRoleOption] = useState([
		{label: 'admin', value: 'admin'},
		{label: 'employee', value: 'employee'}
	])

	const [emailError, setEmailError] = useState('')
	const [passwordError, setPasswordError] = useState('')

	useEffect(() => {
		sessionStorage.removeItem('authorization')
		sessionStorage.removeItem('userStatus')
		if (router.query && router?.query?.invitations) {
			toast.warn(router?.query?.invitations)
		}
	}, [router.query])

	const doLogin = (e: any) => {
		e.preventDefault()
		if (formValidation()) {
			setIsLoading(true)
			api.post(`/admin/login`, {
				email: email,
				password: password
			})
				.then(async (res) => {
					setIsLoading(false)
					if (res?.data?.success) {
						sessionStorage.setItem('authorization', res?.data?.data?.data?.loginToken)
						sessionStorage.setItem('userStatus', res?.data?.data?.data?.role)
						_navigate('/dashboard')
					} else {
						toast.error(res?.data?.message)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}
	const doLoginEmployee = (e: any) => {
		e.preventDefault()
		if (formValidation()) {
			setIsLoading(true)
			api.post(`/employee/login`, {
				email: email,
				password: password
			})
				.then(async (res) => {
					setIsLoading(false)
					if (res?.data?.success) {
						_dispatch(loginUserDetailsAction(res?.data?.data?.data))
						sessionStorage.setItem('authorization', res?.data?.data?.data?.loginToken)
						sessionStorage.setItem('userStatus', res?.data?.data?.data?.role)
						sessionStorage.setItem('employeeId', res?.data?.data?.data?.id)
						_navigate('/dashboard')
					} else {
						toast.error(res?.data?.message)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (!_regex.test(email)) {
			setEmailError('Invalid email.')
			isValid = false
		}
		if (email === '') {
			setEmailError('Email is required.')
			isValid = false
		}
		if (password === '') {
			setPasswordError('Password is required.')
			isValid = false
		}
		if (role === '') {
			setRoleError('Role is required')
			isValid = false
		}
		return isValid
	}
	return (
		<>
			<MainWrapper>
				<Flexed direction="row" justify="center">
					<ImageContent>
						<CoverImage src="/images/logo.png" alt="login_bg" />
					</ImageContent>
					<LoginContent>
						<Wrapper>
							<InnerWrapper>
								<Row justifyContent="center">
									<Col lg={10}>
										<form onSubmit={role === 'admin' ? doLogin : doLoginEmployee}>
											<Text fontSize={_windowSize?.width < 600 ? 1.5 : 1.875} lineHeight={2} fontWeight={600} color="black_3">
												Modernize your HR operations
											</Text>
											<Spacer height={1} />
											<Text fontSize={0.813} color="black_7">
												Welcome Back, Please Login to your account
											</Text>
											<Spacer height={1.8} />
											<DropDown
												label="Select your role"
												firstSelected={role}
												options={roleOption}
												hasChanged={(value: any) => {
													setRoleError('')
													if (value === '') {
														setRoleError('Role is required')
													}
													setRole(value)
												}}
												error={roleError}
												dropDownListHeight="100px"
											/>
											<Spacer height={0.8} />
											<InputFieldAuth
												label="Email"
												required
												type="text"
												value={email}
												error={emailError}
												handleChange={(e: any) => {
													setEmailError('')
													if (e === '') {
														setEmailError('Email is required.')
													} else if (!_regex.test(e)) {
														setEmailError('Invalid email.')
													}
													setEmail(e)
												}}
											/>
											<Spacer height={0.8} />
											<InputFieldAuth
												label="Password"
												required
												type="password"
												value={password}
												error={passwordError}
												handleChange={(e: any) => {
													setPasswordError('')
													if (e === '') {
														setPasswordError('Password is required.')
													}
													setPassword(e)
												}}
											/>
											<Spacer height={0.5} />
											<Flexed direction="row" justify="flex-end">
												<Text
													fontSize={0.813}
													color="black_7"
													pointer
													onClick={() => {
														_navigate('/forgot-password')
													}}>
													Forgot Password ?
												</Text>
											</Flexed>
											<Spacer height={0.5} />
											<Flexed direction="row" gap={1}>
												<AuthFilledButton type="submit" onClick={role === 'admin' ? doLogin : doLoginEmployee}>
													Login
												</AuthFilledButton>
												<AuthUnFilledButton
													onClick={() => {
														_navigate('/register')
													}}>
													Sign Up
												</AuthUnFilledButton>
											</Flexed>
											<Border>
												<BorderText fontSize={0.813} lineHeight={2} color="black_7">
													OR
												</BorderText>
											</Border>
											<Spacer height={2.8} />
											<Flexed direction="row" justify="center">
												<LogInGoogle />
											</Flexed>
										</form>
									</Col>
								</Row>
							</InnerWrapper>
							<CopyRight>© 2024 {CommonEnum.AppName}, All rights reserved</CopyRight>
						</Wrapper>
					</LoginContent>
				</Flexed>
			</MainWrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled.div`
	height: calc(100vh - 57px);
`

const LoginContent = styled.div`
	width: 100%;
	height: calc(100vh - 57px);
	background: url(/images/white_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	${media.sm`width: 80%;`};
	${media.md`width: 60%;`};
	${media.lg`width: 50%;`};
`

const Wrapper = styled.div`
	position: relative;
	background: #ffffffcc;
	height: 100%;
	display: flex;
	${media.lg` align-items: center;`};
`

const InnerWrapper = styled(Container)`
	margin-top: 2rem;
	margin-bottom: 0rem;
	${media.lg`margin-bottom: 6rem; margin-top: 0rem;`}
`

const ImageContent = styled.div`
	width: 50%;
	display: none;
	${media.lg`
		display: flex;
		justify-content: center;
		align-items: center;
	`};
`

const CoverImage = styled.img`
	// height: 100vh;
	// width: 100%;
	width: 20rem;
	object-fit: cover;
	object-position: bottom;
`
const SelectRole = styled(DropDown)`
	height: 100vh;
	width: 100%;
	object-fit: cover;
	object-position: bottom;
`

export default LogIn
